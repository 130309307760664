import React, { Fragment, useState, useEffect, useCallback } from "react";
import { RiAdvertisementFill } from "react-icons/ri";
import { MdHelp, MdMediaBluetoothOn, MdOutlineContactPhone } from "react-icons/md";
import { Drawer } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdSettingsSuggest } from "react-icons/md";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { BiMobileLandscape } from "react-icons/bi";
import { MdLibraryBooks } from "react-icons/md";
import { FaCookieBite } from "react-icons/fa";
import { MdPrivacyTip } from "react-icons/md";
import DisplaySetting from "../../Home/DisplaySetting";
import WhatsNew from "../../Home/WhatsNew";
import { motion } from "framer-motion";

const ProfileAccountModal = ({ closeDrawerBottom, openBottom }) => {
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;
    const [openDisplaySettings, setOpenDisplaySettings] = useState(false);
    const [openWhatsNew, setOpenWhatsNew] = useState(false);

    const navigate = useNavigate();

    const handleNavigate = useCallback((value) => {
        navigate(`${value}`);
        closeDrawerBottom();
    }, []);

    useEffect(() => {
        setOpenDisplaySettings(false);
        return () => setOpenDisplaySettings(false);
    }, [closeDrawerBottom]);

    return (
        <Fragment>
            <div className={`${openBottom ? "" : "hidden"} w-full`}>
                <Drawer
                    dismiss={!openBottom}
                    placement="bottom"
                    open={openBottom}
                    onClose={closeDrawerBottom}
                    size={window.screen.width > 500 ? 270 : 320}
                    className="w-full  max-h-24 md:max-h-20 absolute p-0 "
                    style={{ zIndex: 1 }}
                >
                    <div className="bg-[#000] h-12 flex items-center p-2">
                        <div className="h-8 w-8 rounded-full">
                            <img
                                alt="ProfileImage"
                                src={`${User_profile?.avatar}`}
                                onClick={() => handleNavigate("/account")}
                                className="object-cover h-8 w-8 rounded-full cursor-pointer"
                            />
                        </div>
                        <p
                            onClick={() => handleNavigate("/account")}
                            className="text-[white] font-bold ml-2 cursor-pointer hover:text-light-blue-600"
                        >
                            {User_profile.first_name} {User_profile.last_name}
                        </p>
                    </div>

                    <div className="flex justify-start w-full h-40 overflow-scroll">
                        {!openDisplaySettings && !openWhatsNew && (
                            <motion.div
                                initial={{ x: "-100%" }}
                                animate={{ x: !openDisplaySettings ? 0 : "-100%" }}
                                transition={{ duration: 0.5 }}
                                className="w-full "
                            >
                                <div className="p-2">
                                    <div className="flex items-center mb-4 md:mb-[10px] cursor-pointer hover:text-green-700 hover:underline">
                                        <MdSettingsSuggest />
                                        <p
                                            className="text-[14px] font-bold ml-2 "
                                            onClick={() => handleNavigate("/settings")}
                                        >
                                            Settings
                                        </p>
                                    </div>
                                    <div className="flex items-center mb-4 md:mb-[10px] cursor-pointer hover:text-green-700 hover:underline">
                                        <TbDeviceMobileMessage />
                                        <p
                                            className="text-[14px] font-bold ml-2 hover:text-green-400 hover:underline"
                                            onClick={() => handleNavigate("/account/updates")}
                                        >
                                            Updates
                                        </p>
                                    </div>
                                    <div className="flex items-center mb-4 md:mb-[10px] cursor-pointer hover:text-green-700 hover:underline">
                                        <BiMobileLandscape />
                                        <p
                                            className="text-[14px] font-bold ml-2 "
                                            onClick={() => setOpenDisplaySettings(true)}
                                        >
                                            Display setting
                                        </p>
                                    </div>
                                    <div className="flex items-center mb-4 md:mb-[10px] cursor-pointer hover:text-green-700 hover:underline">
                                        <RiAdvertisementFill />
                                        <p
                                            className="text-[14px] font-bold ml-2 "
                                            onClick={() => handleNavigate("/commingsoon")}
                                        >
                                            Advertise with us
                                        </p>
                                    </div>
                                    <div className="flex items-center mb-4 md:mb-[10px] cursor-pointer hover:text-green-700 hover:underline">
                                        <TbDeviceMobileMessage />
                                        <p
                                            className="text-[14px] font-bold ml-2 hover:underline"
                                            onClick={() => setOpenWhatsNew(true)}
                                        >
                                            What's New
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                        {openDisplaySettings && (
                            <DisplaySetting
                                openDisplaySettings={openDisplaySettings}
                                setOpenDisplaySettings={setOpenDisplaySettings}
                            />
                        )}
                        {openWhatsNew && (
                            <WhatsNew
                                openWhatsNew={openWhatsNew}
                                setOpenWhatsNew={setOpenWhatsNew}
                            />
                        )}
                    </div>
                    <div className=" bg-[#5353536B] h-full">
                        <div className="p-2">
                            <div className="flex justify-between gap-2 ">
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <MdPrivacyTip className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline flex item-center"
                                        onClick={() => handleNavigate("/privacy-policy")}
                                    >
                                        Privacy Policy
                                    </p>
                                </div>
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <MdLibraryBooks className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline flex item-center"
                                        onClick={() => handleNavigate("/terms-of-use")}
                                    >
                                        Terms of Use
                                    </p>
                                </div>
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <FaCookieBite className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline"
                                        onClick={() => handleNavigate("/cookies")}
                                    >
                                        Cookies Policy
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-between gap-2 mt-1">
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <RiAdvertisementFill className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline"
                                        onClick={() => handleNavigate("/commingsoon")}
                                    >
                                        Advertisement
                                    </p>
                                </div>
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <MdOutlineContactPhone className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline cursor-pointer"
                                        onClick={() => handleNavigate("/contact-us")}
                                    >
                                        Contact Us
                                    </p>
                                </div>
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <MdMediaBluetoothOn className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline"
                                        onClick={() => handleNavigate("/media")}
                                    >
                                        Media
                                    </p>
                                </div>
                                <div className="flex items-center hover:text-green-700 hover:underline cursor-pointer">
                                    <MdHelp className="text-[12px] mr-1" />
                                    <p
                                        className="text-[10px] font-semibold underline"
                                        onClick={() => handleNavigate("/help")}
                                    >
                                        Help
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full">
                            {/* <p className="text-[10px] text-center font-bold  text-red-600">
                        CC HOME PAGE PROFILE PAGE / FE V2 / JULY 03, 2024
                     </p> */}

                            <p className="text-[8px] text-center font-bold">
                                Copyright © 2025 Galileo One Inc
                            </p>
                        </div>
                    </div>
                </Drawer>
            </div>
        </Fragment>
    );
};

export default ProfileAccountModal;
