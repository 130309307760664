import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { enterOtp } from "../Redux/ApiSlice/EnterOtpSlice";
import { ccLogo } from "../Datas/Assests";

const EnterOTP = () => {
    const navigate = useNavigate();

    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();


    const handleChange = (value) => {
        setOtp((prev) => `${prev}${value}`);
    };

    // const handleClick = (value) => {
    //   navigate(`/${value}`);
    // };

    useEffect(() => {
        if (otp.length === 6) {
            dispatch(enterOtp({ email: "syedb1005@tutanota.com", otp: "123456" }));
        }
    }, [otp, dispatch]);


    return (
        <>

            <main className="w-full h-screen md:h-full flex justify-center items-center overflow-hidden">
                <div className="h-screen md:h-full w-full relative">
                    <div className="h-[84%] grid grid-cols-12 justify-center items-center">
                        <div className=" col-span-12 flex  bg-[#fff] flex-col  items-center  border-black">
                            <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
                                <p className="text-[#ff3b3b] absolute top-0 w-full text-center font-extrabold text-[14px] md:text-[10px]">
                                    Copyright © 2025 Galileo One Inc
                                </p>
                                {/* <p className="text-[#ff3b3b] absolute top-0 w-full text-center font-extrabold text-[14px] md:text-[10px]">
                                    CC LOST MY PASSWORD OTP - FE - V3 - OCT 11, 2024
                                </p> */}
                            </div>
                            <img src={ccLogo} className="h-28 w-28" alt="logo" />

                            <h3 className="font-extrabold text-xl md:text-md">Enter OTP</h3>
                            <p className="text-xl md:text-sm font-semibold text-center w-full m-4 md:m-2 px-[4%]">
                                We sent a one-time passcode to
                                <span className="text-[#00A3FF] pl-1">marydavis@gmail.com</span>.
                                Please enter your code below
                            </p>
                        </div>
                        <div className="col-span-12 overflow-auto w-full bg-[#fff]">
                            <div className="h-full p-3 flex flex-col items-center">
                                <form className="w-full">
                                    <label className="text-base font-bold mb-2">
                                        marydavis@gmail.com
                                    </label>
                                    <div className="h-[4rem] flex flex-row gap-3 items-center">
                                        <input
                                            className="w-2/12 bg-gray-100  h-3/4"
                                            type="text"
                                            placeholder=""
                                            maxLength={1}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <input
                                            className="w-2/12 bg-gray-100  h-3/4"
                                            type="text"
                                            placeholder=""
                                            maxLength={1}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <input
                                            className="w-2/12 bg-gray-100  h-3/4"
                                            type="text"
                                            placeholder=""
                                            maxLength={1}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <input
                                            className="w-2/12 bg-gray-100  h-3/4"
                                            type="text"
                                            placeholder=""
                                            maxLength={1}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <input
                                            className="w-2/12 bg-gray-100  h-3/4"
                                            type="text"
                                            placeholder=""
                                            maxLength={1}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <input
                                            className="w-2/12 bg-gray-100  h-3/4"
                                            type="text"
                                            placeholder=""
                                            maxLength={1}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex justify-between mt-2 mb-2">
                                        <p className="text-[#00A3FF] text-[12px] font-bold">
                                            Resend OTP
                                        </p>
                                        <p
                                            className="text-[#00A3FF] text-[12px] font-bold z-20"
                                            onClick={() => navigate("/changepassword")}
                                        >
                                            Re-Entry My Email
                                        </p>
                                    </div>
                                </form>
                                <div className="flex justify-center items-center">
                                    <button
                                        className="bg-black text-[#fff] px-5 py-2 rounded-md font-bold"
                                        onClick={() => navigate(-1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </>
    );
};

export default EnterOTP;
