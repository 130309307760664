import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Input } from "@material-tailwind/react";
import PulseLoader from "react-spinners/PulseLoader";
import { REACT_APP_MAIN_URL } from "../API/MainUrl";
import { ccLogo } from "../Datas/Assests";
import { HiEyeSlash, HiMiniEye } from "react-icons/hi2";

export default function SecurityPage() {
   const [isLoading, setIsLoading] = useState(false);
     const [showPassword, setShowPassword] = useState(false);

   const navigate = useNavigate();
   const loginSchema = Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email Required"),
      password: Yup.string().required("Please Enter your password"),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      // ),
   });

   const navigateFun = (values) => {
      setTimeout(() => {
         navigate(`/${values}`);
         toast.dismiss();
      }, 1000);
   };

   const formik = useFormik({
      initialValues: {
         email: "",
         password: "",
      },
      validationSchema: loginSchema,
      onSubmit: (values) => {
         setIsLoading(true);
         axios({
            method: "POST",
            url: `${REACT_APP_MAIN_URL}api/security_login/`,
            data: values,
            headers: { "Content-Type": "application/json" },
        })
            .then(function (response) {
                const { status } = response;
                if (status === 200) {
                    let randomId = (Math.random() + 1).toString(36).substring(7);
                    localStorage.setItem("security_login", randomId);
                    toast.success("Security Login success");
                    navigateFun("entrypage");
                }
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error.response); // Log for debugging
        
                if (error.response) {
                    const errorMessage = error.response.data.error || "Unauthorized access. Please try again.";
                    toast.error(errorMessage);
                } else {
                    toast.error("Network error. Please check your connection.");
                }
        
                setIsLoading(false);
            });
   
      },
   });

   return (
      <>
         <Toaster position="top-right" />
         <div className="h-full justify-center items-center flex flex-col bg-gradient-to-tr bg-blue-200">
            <div className="flex flex-col justify-center items-center w-full absolute bottom-0">
               {" "}
               {/* <span className="h-full text-[#ff3b3b] font-bold text-[13px] flex items-center uppercase">
                  CC SECURITY PAGE - FE - V3 - April 02, 2024
               </span> */}
               <p className="text-[12px] text-center font-bold">
                  Copyright © 2024 by Galileo One Inc
               </p>
            </div>
            <div className="absolute flex justify-center items-center w-full top-24 md:top-16">
               {/* <p className="text-[5rem] text-center w-full">🔒</p> */}
               <img src={ccLogo} className="h-28 w-28" alt="logo" />

            </div>
            <div className="max-w-sm w-3/4 ">
               <form onSubmit={formik.handleSubmit}>
                  <div className="overflow-hidden rounded-[6px] border bg-[#f9f9f9] shadow-[0px_0px_15px_10px_#0000004d] hover:shadow-[0px_0px_15px_17px_#0000004d] transition-all duration-500 ease-in-out">
                     <h3 className="text-[25px] font-bold uppercase text-center mt-6 mb-2 text-[#2e2e2e]">
                        Security Login
                     </h3>
                     <p className="text-[10px] font-bold text-center  my-3 px-4  text-[#2e2e2e]">Enter your email and password to login</p>
                     <div className="px-4 mb-3">
                        <Input
                           required
                           type="email"
                           name="email"
                           label="Email Address"
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           className="w-full p-3"
                        />
                     </div>
                     <div className="px-4 mb-3 relative">
                        <Input
                           required
                           type={showPassword ? "text" : "password"}
                           name="password"
                           label="Password"
                           value={formik.values.password}
                           onChange={formik.handleChange}
                           className="w-full p-3 pr-6"
                        />
                        <p className="absolute top-3 z-50 right-5 cursor-pointer">
                           {
                              showPassword ? <HiEyeSlash className="text-gray-500" onClick={() => setShowPassword(!showPassword)} /> : <HiMiniEye className="text-gray-500" onClick={() => setShowPassword(!showPassword)} />
                           }

                        </p>


                     </div>
                     <div className="px-4 mb-8">
                        <button className="hover:border-[2px] border-[2px] hover:border-[#000000] border-[#000000] bg-[#000000] hover:bg-white rounded w-full px-4 py-3 text-[#ffffff] hover:text-[#000000] font-semibold transition-all duration-500 ease-in-out">
                           {isLoading ? (
                              <PulseLoader
                                 color={"#36d7b7"}
                                 loading={isLoading}
                                 size={10}
                              />
                           ) : (
                              "Login"
                           )}
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </>
   );
}
