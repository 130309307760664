import React, { useState, useRef, useEffect, Fragment, useCallback } from "react";
import { GoHome } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { BsPostcardHeart, BsBookmarkCheck } from "react-icons/bs";
import { IoLocationOutline, IoPersonAddOutline, IoSettingsOutline } from "react-icons/io5";

const SliderMenu = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();


    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, handleClickOutside]);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    return (
        <Fragment>
            <AnimatePresence>
                {isOpen && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 z-10">
                        <motion.div
                            ref={menuRef}
                            initial={{ x: "100%" }}
                            animate={{ x: 0 }}
                            exit={{ x: "100%" }}
                            transition={{ type: "tween", duration: 0.3 }}
                            className="absolute top-0 right-0 w-3/5 h-full bg-black shadow-lg z-30"
                        >
                            <ul className="p-4">
                                <div className="flex items-center gap-x-2 text-gray-400 hover:text-white w-fit">
                                    <BsPostcardHeart className="text-[18px] cursor-pointer" onClick={() => navigate("/neighborhood/posted")} />
                                    <li className="py-2 cursor-pointer" onClick={() => navigate("/neighborhood/posted")}>Your Posts</li>
                                </div>
                                <div className="flex items-center gap-x-2 text-gray-400 hover:text-white w-fit">
                                    <BsBookmarkCheck className="text-[18px] cursor-pointer" onClick={() => navigate("/neighborhood/saved")} />
                                    <li className="py-2 cursor-pointer" onClick={() => navigate("/neighborhood/saved")}>Your Saved</li>
                                </div>
                                <div className="flex items-center gap-x-2 text-gray-400 hover:text-white w-fit">
                                    <IoPersonAddOutline className="text-[18px] cursor-pointer" onClick={() => navigate("/neighborhood/invite")} />
                                    <li className="py-2 cursor-pointer" onClick={() => navigate("/neighborhood/invite")}>Invite</li>
                                </div>
                                <div className="flex items-center gap-x-2 text-gray-400 hover:text-white w-fit" onClick={() => navigate("/neighborhood/location")}>
                                    <IoLocationOutline className="text-[18px] cursor-pointer" onClick={() => navigate("/neighborhood/location")} />
                                    <li className="py-2 cursor-pointer">Location</li>
                                </div>
                                <div className="flex items-center gap-x-2 text-gray-400 hover:text-white w-fit" onClick={() => navigate("/neighborhood/your-neighbor")}>
                                    <GoHome className="text-[18px] cursor-pointer" onClick={() => navigate("/neighborhood/your-neighbor")} />
                                    <li className="py-2 cursor-pointer">Neighbor</li>
                                </div>
                                <div className="flex items-center gap-x-2 text-gray-400 hover:text-white w-fit" onClick={() => navigate("/neighborhood/setting")}>
                                    <IoSettingsOutline className="text-[18px] cursor-pointer" onClick={() => navigate("/neighborhood/setting")} />
                                    <li className="py-2 cursor-pointer">Settings</li>
                                </div>
                            </ul>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </Fragment>
    );
};

export default SliderMenu;