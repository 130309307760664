import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";


export const UserlikedVideos = createAsyncThunk(
    "UserlikedVideos",
    async (id) => {
      const response = await axiosInstance.get(`/post/my_likes/`);
      try {
        const result = response.data;
        return result;
      } catch (error) {
        return error;
      }
    }
  );
  
  export const userLikedPosts = createSlice({
    name: "userLikedPosts",
    initialState: {
      User_likedVideos: [],
      loading: false,
      error: null,
    },
    extraReducers: (builder) => {
      // account page UserlikedVideos action promise
      builder.addCase(UserlikedVideos.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(UserlikedVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.User_likedVideos = action.payload;
      });
      builder.addCase(UserlikedVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      
    },
  });
  
  export default userLikedPosts.reducer;
  