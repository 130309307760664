import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import AccountProfileSection from '../../Component/AccountPage/AccountProfileSection'
import AccountTab from '../../Component/AccountPage/AccountTab'
import { SavedTab } from '../../Component/AccountPage/SavedTab'
import AccountSidebar from '../../Component/AccountPage/AccountSidebar'
import AccountTabModal from '../../Component/AccountPage/AccountTabModal'
import { useOutsideClick } from '../../Hooks/useOutsideClick'
import { EditSavedFolderModal } from '../../Component/Modals/AccountPageModal/EditSavedFolderModal'
import { useDispatch, useSelector } from 'react-redux'
import { createFolder, deleteSavedFolders, getSavedFolders, renameSavedFolders } from '../../Redux/ApiSlice/SavedVideosSlice'
import { DeleteSavedFolderModal } from '../../Component/Modals/AccountPageModal/DeleteSavedFolderModal'
import { CreateSavedFolderModal } from '../../Component/Modals/AccountPageModal/CreateSavedFolderModal'

export default function Saved() {
    const dispatch = useDispatch();
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const savedVideo = useSelector((state) => state.savedVideos);
    const { loading, SavedFolderList } = savedVideo;
    const { User_profile } = ProfileDetails;
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const ref = useRef(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // for sidebar

    const [EditModal, setEditModal] = useState(false); //rename saved folder modal
    const [selectFolderId, setSelectFolderId] = useState("");
    const [selectFolder, setSelectFolder] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false); //delete saved folder modal
    const [selectedItem, setSelectedItem] = useState(null);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [folderName, setFolderName] = useState("");

    const handleCreateModal = () => setOpenCreateModal(!openCreateModal);

    const handleOpenEditModal = (id) => {
        setEditModal(!EditModal);
        setSelectFolderId(id);
        const data = SavedFolderList?.data?.filter((item) => item.id === id)
        setSelectFolder(data[0]);
    };

    const renameFolderName = async (folderName) => {
        await dispatch(
            renameSavedFolders({ name: folderName, folderId: selectFolderId })
        );
        dispatch(getSavedFolders(User_profile.id));

        handleOpenEditModal(null);
    };

    const handleOpenDeleteModal = (id) => {
        setDeleteModal(!deleteModal);
        setSelectFolderId(id);
        const data = SavedFolderList?.data?.filter((item) => item.id === id)
        setSelectFolder(data[0]);
    };

    const deleteCreatedFolder = async (id) => {
        await dispatch(deleteSavedFolders(id));
        handleOpenDeleteModal(null);
        await dispatch(getSavedFolders(User_profile.id));
    };
    const fetchData = useCallback(async () => {
        await dispatch(getSavedFolders(User_profile.id));
    }, [dispatch, User_profile.id]);


    const handleCreateFolder = async (value) => {
        if (value !== "") {
            await dispatch(createFolder({ name: value, userId: User_profile.id }));
            fetchData();
            handleCreateModal();
            setFolderName("");
        } else {
            alert("Please enter folder name");
        }
    };

    const handleClickOutside = () => {
        setShow(null);
        setActiveTab("");
    };
    useOutsideClick(ref, handleClickOutside);

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    //initial fetch
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Fragment>
            <div className={`bg-[#fff] dark:bg-[#000] overflow-hidden h-full relative ${isDrawerOpen ? "" : "overflow-y-scroll"}`}>
                {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    CC ACCOUNT SAVED PAGE, FE, V10 / JULY 01, 2024
                </p> */}
                <p className="text-[#ff3b3b] text-center w-full mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    Copyright © 2025 Galileo One Inc
                </p>
                <div className="">
                    <AccountProfileSection
                        handleOpenSideBar={openDrawer}
                        handleCloseSideBar={closeDrawer}
                        isDrawerOpen={isDrawerOpen}
                        setActiveTab={setActiveTab}
                        setShow={setShow}
                        show={show}
                    />
                </div>

                <div className="sticky top-0 z-30">
                    <AccountTab activeTab={"Saved"} />
                </div>

                <SavedTab
                    loading={loading}
                    SavedFolderList={SavedFolderList}
                    handleCreateModal={handleCreateModal}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                />

                <div className="grid grid-cols-12 overflow-hidden">
                    <div className="col-span-12">
                        <AccountSidebar
                            openDrawer={openDrawer}
                            closeDrawer={closeDrawer}
                            isDrawerOpen={isDrawerOpen}
                            setIsDrawerOpen={setIsDrawerOpen}
                        />
                    </div>
                </div>
            </div>

            {show && (
                <div className={"absolute h-full w-full top-0 overflow-hidden"}>
                    <div
                        className="absolute h-screen w-full top-0 opacity-100 bg-opacity-70 bg-black z-50"
                        onClick={handleClickOutside}
                    />
                    <div
                        ref={ref}
                        className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
                    >
                        <AccountTabModal
                            ref={ref}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            )}

            <EditSavedFolderModal
                selectFolderId={selectFolderId}
                EditModal={EditModal}
                selectFolder={selectFolder}
                handleOpenEditModal={handleOpenEditModal}
                renameFolderName={renameFolderName}
            />

            <DeleteSavedFolderModal
                open={deleteModal}
                handleOpen={handleOpenDeleteModal}
                deleteItem={selectedItem}
                selectFolder={selectFolder}
                selectFolderId={selectFolderId}
                deleteCreatedFolder={deleteCreatedFolder}
            />

            <CreateSavedFolderModal
                open={openCreateModal}
                handleOpen={handleCreateModal}
                handleCreateFolder={handleCreateFolder}
                setFolderName={setFolderName}
                folderName={folderName}
            />
        </Fragment>
    )
}
