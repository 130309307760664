import React, { Fragment, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa6";
import { BiWorld } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { useDispatch, useSelector } from "react-redux";
import { IoCaretBackCircle, IoSearch } from "react-icons/io5";
import { groupSearch } from "../../Redux/ApiSlice/SearchSlice";
import { groupJoin } from "../../Redux/ApiSlice/Group/GroupSlice";

const GroupListView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const searchResults = useSelector((state) => state.SearchItems.SearchResult);

    useEffect(() => {
        const fetchGroupSearchResults = async () => {
            setLoading(true);
            await dispatch(groupSearch(query));
            setLoading(false);
        };

        fetchGroupSearchResults();
    }, [dispatch, query]);

    const handleSearchChange = (e) => {
        setQuery(e.target.value);
    };

    const handleJoinGroup = async (groupId) => {
        try {
            const responce = await dispatch(groupJoin(groupId));
            if (
                responce?.playload?.message ===
                "Join request has been sent to the group owner"
            ) {
                setGroupSearchData(
                    groupSearchData.map((data) => {
                        if (data.id === groupId) {
                            return { ...data, join_request_status: "pending" };
                        }
                        return data;
                    })
                );
            } else {
                navigate(`/createdgroup/${groupId}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Fragment>
            <div className="sticky top-0">
                <div className="h-9 flex justify-center items-center bg-black relative">
                    <p className="text-[16px] font-semibold text-white">Groups</p>
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="absolute left-2 text-white text-[20px] cursor-pointer top-1/2 transform -translate-y-1/2"
                    />
                </div>
                <div className=" bg-white dark:bg-black py-2 border-b border-gray-300 dark:border-gray-800">
                    <div className="relative mx-2">
                        <input
                            type="text"
                            placeholder="Search groups..."
                            value={query}
                            onChange={handleSearchChange}
                            className="w-full py-[6px] pl-1.5 pr-8 text-[14px] border border-gray-300 rounded-md focus:outline-none focus:ring-[1px] focus:ring-blue-600"
                        />
                        <IoSearch className="absolute text-[18px] right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-[50vh]">
                    <PuffLoader
                        size={100}
                        color={"#616163"}
                    />
                </div>
            ) : (
                <div className="">
                    {searchResults.length > 0 ? (
                        searchResults.map((data, index) => (
                            <div key={index} className="border-b border-gray-200 dark:border-gray-800 p-2">
                                <div className="grid grid-cols-12 items-start">
                                    <div className="col-span-2">
                                        <img
                                            alt={data.group_name}
                                            src={data.group_profile_picture}
                                            className="w-10 h-10 rounded-full object-cover border border-gray-300 dark:border-gray-800 cursor-pointer"
                                        />
                                    </div>
                                    <div className="col-span-7 flex flex-col">
                                        <p className="font-semibold text-[12px] text-black dark:text-white">{data.group_name}</p>
                                        {data.group_type === "private" ? (
                                            <div className="flex items-center gap-x-[3px]">
                                                <p className="text-[10px] text-gray-500 dark:text-gray-700">private</p>
                                                <FaLock className="text-[8px] text-gray-500 dark:text-gray-700" />
                                            </div>
                                        ) : (
                                            <div className="flex items-center gap-x-[3px]">
                                                <p className="text-[10px] text-gray-500 dark:text-gray-700">public</p>
                                                <BiWorld className="text-[9px] text-gray-500 dark:text-gray-700" />
                                            </div>
                                        )}
                                        <p className="text-[12px] text-gray-800 dark:text-gray-300 line-clamp-1 leading-[14px] mt-[6px]">
                                            {data.description}
                                        </p>
                                    </div>
                                    <div className="col-span-3 flex flex-col justify-between items-end">
                                        {data.join_request_status !== "pending" ? (
                                            data?.user_joined ? (
                                                <button
                                                    className="bg-gray-600 text-white dark:bg-white dark:text-black rounded-md text-[11px] p-1 w-full font-semibold"
                                                    onClick={() => navigate(`/createdgroup/${data.id}`)}
                                                >
                                                    Joined
                                                </button>
                                            ) : (
                                                <button
                                                    className="bg-black text-white dark:bg-white dark:text-black rounded-md text-[11px] p-1 w-full font-semibold"
                                                    onClick={() => handleJoinGroup(data.id)}
                                                >
                                                    Join
                                                </button>
                                            )
                                        ) : (
                                            <button
                                                className="bg-light-blue-500 text-white rounded-md text-[11px] p-1 w-full font-semibold"
                                                onClick={() => navigate(`/othergroup/${data.id}`)}
                                            >
                                                Requested
                                            </button>
                                        )}
                                        <div class="flex -space-x-2 overflow-hidden mt-2">
                                            {data?.members.map((member, index) => (
                                                <>
                                                    {index < 3 && (
                                                        <img
                                                            alt="img"
                                                            src={member?.profile_pic}
                                                            class="inline-block h-5 w-5 rounded-full object-cover"
                                                        />
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="px-2 pt-10 text-[14px] text-center text-gray-500">
                            No groups found
                        </p>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default GroupListView;