import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";


export const getNearByNeighbors = createAsyncThunk("getNearByNeighbors", async (data) => {
    try {
        const response = await axiosInstance.get(`/location/nearby/`);
        const result = response.data;
        return result

    } catch (error) {
        console.error("Error:", error.response.data);
    }
});

export const NeighborhoodSlice = createSlice({
    name: "Neighborhood",
    initialState: {
        neighborhood: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // getNearByNeighbors action promises
        builder.addCase(getNearByNeighbors.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getNearByNeighbors.fulfilled, (state, action) => {
            state.loading = false;
            state.neighborhood = action.payload;
        });
        builder.addCase(getNearByNeighbors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default NeighborhoodSlice.reducer;