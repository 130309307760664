
import React from "react";
import { TrendingTitle } from "../../Component/HashTag/TrendingTitle";
import { HashtagRecentviews } from "../../Component/HashTag/HashtagRecentviews";

export default function HashTagPopular() {
   return (
      <div className="h-full bg-white dark:bg-black relative overflow-hidden">
         <TrendingTitle title={"Recent"} />
         <div className="mt-2 absolute w-full h-[-webkit-fill-available] overflow-scroll no-scrollbar">
            <HashtagRecentviews />
         </div>
         <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold uppercase">
            Copyright © 2025 Galileo One Inc
         </p>
      </div>
   );
}
