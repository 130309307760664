import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { recentProducts } from "../../Datas/Datas";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1,
    },
};

const DeliveredProducts = () => {
    const navigate = useNavigate();
    return (
        <div className="px-3 mb-2 ">
            <div className="flex justify-between items-center">
                <p className="text-[#000] dark:text-white font-bold text-[20px] md:text-[16px]">
                    Delivered Orders
                </p>
                <div className="text-[#000] dark:text-white ">
                    <FaArrowAltCircleRight className="cursor-pointer text-[20px]" />
                </div>
            </div>
            <div className="mt-3 -mx-[5px]">
                <Carousel
                    swipeable={true}  
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {recentProducts.map((data) => (
                        <div className="px-[5px] py-1" key={data.id}>
                            <img
                                src={data.imgUrl}
                                alt={data.name}
                                onClick={() => navigate("/shopdetails")}
                                className="img-shadow rounded-2xl object-cover bg-[#fff] cursor-pointer"
                            />
                            <div className="text-[#000] dark:text-white mt-1 px-1 text-start">
                                <p className="line-clamp-1 text-[11px]">{data.name}</p>
                                <p className="line-clamp-1 text-[11px]">{data.description}</p>
                                <p
                                    className="text-[20px] md:text-[14px] font-bold"
                                    onClick={() => navigate("/shopdetails")}
                                >
                                    ${data.price}
                                </p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    )
}

export default DeliveredProducts