import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { IoCaretBackCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { UserAccountFollow } from "../../Redux/ApiSlice/AccountProfileSlice";
import { suggestedContentBasedUser } from "../../Redux/ApiSlice/RecommendationSlice";

const ContentBasedUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, contentBasedUser, error } = useSelector((state) => state.recommendationUser);

    useEffect(() => {
        dispatch(suggestedContentBasedUser());
    }, [dispatch]);

    const handleFollowFun = async (id) => {
        try {
            const response = await dispatch(UserAccountFollow(id));
            if (response.meta.requestStatus === 'fulfilled') {
                // Update the state or perform any other actions after a successful follow
                dispatch(suggestedContentBasedUser());
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="bg-white dark:bg-black">
            <div className="sticky top-0">
                <div className="flex items-center justify-center bg-black relative p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[18px] text-white absolute top-1/2 left-2 transform -translate-y-1/2 cursor-pointer"
                    />
                    <p className="text-[14px] font-semibold text-white">
                        Content Based Friends
                    </p>
                </div>
            </div>

            {loading &&
                <div className="flex justify-center items-center h-[70vh]">
                    <ClipLoader
                        size={40}
                        color={"#000000"}
                    />
                </div>
            }

            {error &&
                <div className="flex justify-center items-center h-[70vh]">
                    <p className="text-[10px] text-red-500">Error: {error}</p>
                </div>
            }

            {!loading && !error && contentBasedUser.length === 0 && (
                <div className="flex justify-center items-center h-[69vh]">
                    <p className="text-[12px] text-gray-500">
                        No Content Based Recommended Friends Found.
                    </p>
                </div>
            )}

            {contentBasedUser.map((data) => (
                <div key={data.id} className="p-2 border-b border-gray-300 dark:border-gray-700 flex items-start w-full">
                    <div className="h-10 w-14">
                        <img
                            alt={data.name}
                            src={data.avatar_url}
                            className="h-10 w-10 rounded-full object-cover cursor-pointer border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800"
                        />
                    </div>
                    <div className="flex justify-between w-full">
                        <div className="flex flex-col mt-[2px]">
                            <p className="text-[13px] font-semibold line-clamp-1 cursor-pointer text-black dark:text-white">
                                {data.first_name} {data.last_name}
                            </p>
                            <div className="flex items-center gap-x-3 mt-0.5">
                                <p className="text-[10px] text-gray-500">
                                    Followers <span className="font-semibold">{data.followers_count}</span>
                                </p>
                                <p className="text-[10px] text-gray-500">
                                    Following <span className="font-semibold">{data.following_count}</span>
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <button
                                onClick={() => handleFollowFun(data.recommended_user.id)}
                                className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full text-[12px]"
                            >
                                Follow <FaUserPlus />
                            </button>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default ContentBasedUser