import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";

/* get the group list */
export const getGroupModeratorsList = createAsyncThunk(
    "getGroupModeratorsList",
    async (GroupId) => {
        try {
            const response = await axiosInstance.get(`/group/groups/${GroupId}/admins/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const promoteGroupModerator = createAsyncThunk(
    "promoteGroupModerator",
    async ({ groupId, data }) => {
        try {
            const response = await axiosInstance.post(`/group/groups/${groupId}/promote-demote/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);
export const dePromoteGroupModerator = createAsyncThunk(
    "dePromoteGroupModerator",
    async ({ groupId, data }) => {
        try {
            const response = await axiosInstance.delete(`/group/groups/${groupId}/promote-demote/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const GroupModeratorsSlice = createSlice({
    name: "GroupModeratorsSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        // get group list action promises
        builder.addCase(getGroupModeratorsList.pending, (state) => {
            state.loading = true;
            state.error = true
        });
        builder.addCase(getGroupModeratorsList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = false
        });
        builder.addCase(getGroupModeratorsList.rejected, (state, action) => {
            state.loading = false;
            state.error = true
        });

    },
});

export default GroupModeratorsSlice.reducer;
