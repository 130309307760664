import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";



export const createGroupPost = createAsyncThunk("createGroupPost", async ({ groupId, form_data }) => {
    try {
        const response = await axiosInstance.post(
            `/group/groups/${groupId}/posts/`,
            form_data,
            {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const deleteGroupPost = createAsyncThunk("deleteGroupPost", async ({ groupId, postId }) => {
    try {
        const response = await axiosInstance.delete(
            `/group/groups/${groupId}/posts/${postId}/delete/`);
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});



export const updateGroupPost = createAsyncThunk("updateGroupPost", async ({ postId, form_data }) => {
    try {
        const response = await axiosInstance.patch(
            `group/groups/posts/update/${postId}/`,
            form_data,
            {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});




export const getSinglePost = createAsyncThunk("getSinglePost", async ({ postId }) => {
    try {
        const response = await axiosInstance.get(`/group/groups/posts/${postId}/`);
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});




/* get the group getGroupPostsList  */
export const getGroupPostsList = createAsyncThunk(
    "getGroupPostsList",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/groups/${groupId}/posts/list/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const mostCommentedPostsList = createAsyncThunk(
    "mostCommentedPostsList",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/group/${groupId}/most_commented_post/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const trendingPostsList = createAsyncThunk(
    "trendingPostsList",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/groups/${groupId}/trending/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const topPostsList = createAsyncThunk(
    "topPostsList",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/group/top/${groupId}/posts/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);
export const newPostsList = createAsyncThunk(
    "newPostsList",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/group/new/${groupId}/posts/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);




export const GroupPostSlice = createSlice({
    name: "GroupPostSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        builder.addCase(getGroupPostsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getGroupPostsList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getGroupPostsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(getSinglePost.pending, (state) => {
            state.loading = true;
            state.data = {}
        });
        builder.addCase(getSinglePost.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getSinglePost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(mostCommentedPostsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(mostCommentedPostsList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(mostCommentedPostsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(trendingPostsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(trendingPostsList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(trendingPostsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(topPostsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(topPostsList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(topPostsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(newPostsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(newPostsList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(newPostsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(createGroupPost.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createGroupPost.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(createGroupPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export default GroupPostSlice.reducer;
