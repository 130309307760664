import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";

export const hideGroupPost = createAsyncThunk("hideGroupPost", async ({ groupId, postId }) => {
    try {
        const response = await axiosInstance.post(
            `/group/groups/${groupId}/posts/${postId}/toggle-visibility/` , {});
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const getGroupPostHide = createAsyncThunk("getGroupPostHide", async ({ groupId }) => {
    try {
        const response = await axiosInstance.get(
            `/group/groups/${groupId}/hidden-posts/` , {});
        const result = response.data;
        return result;
    }
    catch (error) {
        return error;
    }
});

export const GroupPostHideSlice = createSlice({
    name: "GroupPostHideSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(hideGroupPost.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(hideGroupPost.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(hideGroupPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(getGroupPostHide.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getGroupPostHide.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getGroupPostHide.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default GroupPostHideSlice.reducer;