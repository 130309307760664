import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import AccountProfileReducer from "./ApiSlice/AccountProfileSlice";
import HomepageVideoReducer from "./ApiSlice/HomePageVideoSlice";
import UploadvideoReducer from "./ApiSlice/UploadVideoSlice";
import lostPasswordReducer from "./ApiSlice/LostPasswordSlice";
import EnterOtpReaducer from "./ApiSlice/EnterOtpSlice";
import SearchReducer from "./ApiSlice/SearchSlice";
import HashTagReducer from "./ApiSlice/HashTagSlice";
import SavedVideoReducer from "./ApiSlice/SavedVideosSlice";
import UserStatusReducer from "./ApiSlice/UserStatusSlice";
import WatchVideoReducer from "./ApiSlice/WatchVideoSlice";
import MessagesReducer from "./ApiSlice/MessagesSlice";
import NotificationReducer from "./ApiSlice/NotificationSlice";
import AccountPrivateReducer from "./ApiSlice/AccountPrivateSlice";
import { setUploadProgress } from "./ApiSlice/UploadVideoSlice";
import CommentsReducer from "./ApiSlice/CommentsSlice";
import GroupReducer from "./ApiSlice/Group/GroupSlice";
import GroupPostReducer from "./ApiSlice/Group/GroupPostsSlice";
import GroupMemberReducer from "./ApiSlice/Group/GroupMembersSlice";
import GroupModeratorsReducer from "./ApiSlice/Group/GroupModeratorsSlice";
import GroupPostReportReducer from "./ApiSlice/Group/GroupPostReportSlice";
import GroupSavedReducer from "./ApiSlice/Group/GroupPostSaveSlice";
import GroupSearchReducer from "./ApiSlice/Group/GroupSearchSlice";
import ContactUsReducer from "./ApiSlice/ContactUs";
import GroupReportReducer from "./ApiSlice/Group/GroupReport";
import GroupHideReducer from "./ApiSlice/Group/GroupPostHide";
import GroupPostCommentsSlice from "./ApiSlice/Group/GroupPostCommentsSlice";
import userLikedPostsReducer from "./ApiSlice/LikedVideosSlice";
import RecommendationReducer from "./ApiSlice/RecommendationSlice";
import  LocationReducer  from "./ApiSlice/LocationSlice";
import NeighborhoodReducer from "./ApiSlice/Neighborhood/NeighborhoodSlice";

const rootReducer = combineReducers({
    AccountProfile: AccountProfileReducer,
    SearchItems: SearchReducer,
    HomePageVideo: HomepageVideoReducer,
    Uploadvideo: UploadvideoReducer,
    lostPassword: lostPasswordReducer,
    EnterOtp: EnterOtpReaducer,
    HashTag: HashTagReducer,
    savedVideos: SavedVideoReducer,
    UserPrivate_Status: UserStatusReducer,
    WatchVideo: WatchVideoReducer,
    messages: MessagesReducer,
    Notification: NotificationReducer,
    AccountPrivate: AccountPrivateReducer,
    ProgressBar: setUploadProgress,
    comments: CommentsReducer,
    group: GroupReducer,
    groupPosts: GroupPostReducer,
    groupMembers: GroupMemberReducer,
    groupModerators: GroupModeratorsReducer,
    groupPostReport: GroupPostReportReducer,
    groupSavedPosts: GroupSavedReducer,
    groupSearch: GroupSearchReducer,
    GroupReport: GroupReportReducer,
    contactUs: ContactUsReducer,
    GroupHiddenPosts: GroupHideReducer,
    groupPostComments: GroupPostCommentsSlice,
    userLikedPosts: userLikedPostsReducer,
    recommendationUser: RecommendationReducer,
    LocationAddress: LocationReducer ,
    Neighborhood: NeighborhoodReducer,
});

// List of reducers to persist
const reducersToPersist = ["AccountProfile"];

// Persist configuration
const persistConfig = {
    key: "root",
    storage,
    whitelist: reducersToPersist, // Add the reducers you want to persist
};


// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);