import React from "react";
import { PostsTab } from "./PostsTab";
import { useSelector } from "react-redux";
import { FollowersTab } from "./FollowersTab";
import { FollowingTab } from "./FollowingTab";
import { FaUserGroup } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { BsMailbox2 } from "react-icons/bs";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { Cog6ToothIcon, Square3Stack3DIcon, UserCircleIcon } from '@heroicons/react/24/solid';

export default function AccountTabModal({ setActiveTab, activeTab }) {
    const ProfileDetails = useSelector((state) => state.AccountProfile);

    const {
        User_profile,
        User_followingList,
        User_followersList,
        User_posts,
    } = ProfileDetails;

    console.log("User_profile", User_profile);
    console.log("User_followingList", User_followingList);
    console.log("User_followersList", User_followersList);
    console.log("User_posts", User_posts);

    const data = [
        {
            label: "Followers",
            value: "followers",
            icon: FaUserGroup,
            desc: <FollowersTab
                datalist={User_followersList?.followers}
            />,
        },
        {
            label: "Following",
            value: "followings",
            icon: FaUserCheck,
            desc: <FollowingTab
                datalist={User_followingList?.following}
            />,
        },
        {
            label: "Posts",
            value: "posts",
            icon: BsMailbox2,
            desc: <PostsTab activeTab={activeTab}
                User_posts={User_posts} User_profile={User_profile}
            />,
        },
    ];

    return (
        <div className="h-full w-full mb-2">
            <Tabs
                value={activeTab}
                className="absolute h-[-webkit-fill-available] w-full pt-2 z-50 bg-white dark:bg-[#0e0e0e]"
            >
                <TabsHeader className="mx-2 dark:bg-gray-800">
                    {data.map(({ label, value, icon }) => (
                        <Tab key={value} value={value}>
                            <div
                                onClick={() => setActiveTab(value)}
                                className="flex items-center dark:text-black gap-2 text-[10px] md:text-[11px] font-bold"
                            >
                                {React.createElement(icon, { className: "w-3 h-3" })}
                                {label}
                            </div>
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody className="h-[-webkit-fill-available] overflow-y-scroll no-scrollbar">
                    {data.map(({ value, desc }) => (
                        <TabPanel
                            uppercase
                            key={value}
                            value={value}
                            className={`h-[-webkit-fill-available] h-max-[-webkit-fill-available] w-full p-0 mt-2`}
                        >
                            {desc}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </div>
    );
}
