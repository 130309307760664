import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Footer from "../Component/Footer/Footer";
import { REACT_APP_MAIN_URL } from "../API/MainUrl";
import { Checkbox } from "@material-tailwind/react";
import { ccLogo } from "../Datas/Assests";

const ChangePassword = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const uid = queryParams.get("uid");
    const token = queryParams.get("token");

    const navigate = useNavigate();

    const handleSubmitPassword = async (values) => {
        toast.loading("Loading...");
        try {
            const formData = new FormData();
            formData.append("password", values.password);
            formData.append("confirm_password", values.confirmPassword);
            formData.append("token", token);
            formData.append("uid", uid);
            const response = await axios.post(
                `${REACT_APP_MAIN_URL}api/reset-password/`,
                formData
            );
            const result = response.data;
            if (result.success === true) {
                toast.dismiss();
                await toast.success(result.message);
                formik.resetForm();
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            }
            return result;
        } catch (error) {
            toast.dismiss();
            toast.error(error.response.data.message);
            return error;
        }
    };

    const validateSchema = Yup.object({
        password: Yup.string()
            .required("Please Enter your password")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
            ),

        confirmPassword: Yup.string()
            .required()
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: validateSchema,

        onSubmit: (values, { resetForm }) => {
            console.log(values);
            handleSubmitPassword(values);
        },
    });

    return (
        <Fragment>
            <div className="h-full w-full relative overflow-hidden">
                <div className="h-full grid grid-cols-12  w-full px-4">
                    <div className="col-span-12 flex items-center bg-[#fff] flex-col justify-center">
                        <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
                            <p className="text-[#ff3b3b] font-extrabold text-[14px] md:text-[10px] absolute top-0 w-full text-center ">
                                Copyright © 2025 Galileo One Inc
                            </p>
                        </div>
                        <img src={ccLogo} className="h-28 w-28" alt="logo" />
                        <p className="text-xl md:text-md font-semibold text-center w-full m-2">
                            Create New password
                        </p>
                        <p className="text-[16px] md:text-[14px] text-center font-bold">
                            Password must be a minimum of 8 characters
                        </p>
                    </div>

                    <div className="col-span-12 overflow-auto w-full bg-[#fff] ">
                        <div className="mobile-forgotpassword-form-container">
                            <form className="w-full h-full" onSubmit={formik.handleSubmit}>
                                <div className="flex flex-col mb-4 w-full">
                                    <label className="mb-2">New password</label>
                                    <input
                                        className="border border-black p-3 md:p-2 outline-none"
                                        type="password"
                                        name="password" // Ensure this matches initialValues.password
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                        placeholder="Enter New Password"
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <p className="text-[#ff3b3b] md:text-[15px]  font-medium">
                                            {formik.errors.password}
                                        </p>
                                    )}
                                </div>
                                <div className="flex flex-col mb-4 w-full">
                                    <label className="mb-2">Confirm New Password</label>
                                    <input
                                        className="border border-black p-3 md:p-2 outline-none"
                                        type="password"
                                        name="confirmPassword" // Ensure this matches initialValues.confirmPassword
                                        onChange={(e) => {
                                            console.log("Password Input Changed:", e.target.value);
                                            formik.handleChange(e);
                                        }}
                                        placeholder="Confirm Password"
                                        value={formik.values.confirmPassword}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.confirmPassword &&
                                        formik.errors.confirmPassword && (
                                            <p className="text-[#ff3b3b] md:text-[15px]  font-medium">
                                                {formik.errors.confirmPassword}
                                            </p>
                                        )}
                                </div>

                                <div className="flex items-center mt-2 mb-4 md:mb-2">
                                    <p className="flex gap-2 items-center text-sm font-semibold">
                                        <Checkbox color="blue" />
                                        Logout from all devices
                                    </p>
                                </div>
                                <div className="w-full flex justify-center items-center ">
                                    <button
                                        className="bg-black text-[#fff] text-sm p-4 px-3 py-2 rounded-md font-bold"
                                        type="submit">
                                        Reset & Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="top-right" />
        </Fragment>
    );
};

export default ChangePassword;
