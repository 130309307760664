import React, { useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { helpPageData } from "../../Datas/Datas";
import HelpOptions from "../../Component/Help/HelpOptions";
import TitleSection from "../../Component/Help/TitleSection";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { IoArrowBackCircle, IoClose } from "react-icons/io5";

export default function HelpMain() {
    const [ShowMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();

    useOutsideClick(menuRef, () => {
        setShowMenu(false);
    });

    return (

        <div className={`p-2 px-4 h-full relative overflow-Y-scroll`}>
            <p className="text-[red] text-[12px] w-full text-center font-bold ">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="sticky top-0 flex justify-between items-center z-50">
                <IoArrowBackCircle
                    onClick={() => navigate(-1)}
                    className="text-[1.5rem] dark:text-gray-400 cursor-pointer"
                />
                <div className="relative">
                    {!ShowMenu ? (
                        <FaBars
                            className={`text-[1.3rem] dark:text-gray-400 cursor-pointer`}
                            onClick={() => setShowMenu(!ShowMenu)}
                        />
                    ) : (
                        <IoClose
                            onClick={() => setShowMenu(!ShowMenu)}
                            className={` text-[1.5rem] dark:text-red-800   duration-700 transform cursor-pointer`
                            }
                        />
                    )}
                    {ShowMenu && (
                        <div
                            ref={menuRef}
                            className={`bg-[#282525] absolute z-50 right-0 duration-10 transform 
                                 w-36`
                            }
                        >
                            <p className="text-white dark:text-gray-400 text-[12px] font-semibold p-2 hover:bg-gray-500 dark:hover:text-gray-200 ">
                                Get Started
                            </p>
                            <p className="text-white dark:text-gray-400 text-[12px] font-semibold p-2 hover:bg-gray-500 dark:hover:text-gray-200 ">
                                Manage Your account
                            </p>
                            <p className="text-white dark:text-gray-400 text-[12px] font-semibold p-2 hover:bg-gray-500 dark:hover:text-gray-200 ">
                                Account Privacy
                            </p>
                            <p className="text-white dark:text-gray-400 text-[12px] font-semibold p-2 hover:bg-gray-500 dark:hover:text-gray-200 ">
                                Policies and Reporting
                            </p>
                            <p className="text-white dark:text-gray-400 text-[12px] font-semibold p-2 hover:bg-gray-500 dark:hover:text-gray-200 ">
                                Advertisement
                            </p>
                            <p className="text-white dark:text-gray-400 text-[12px] font-semibold p-2 hover:bg-gray-500 dark:hover:text-gray-200 ">
                                Frequently asked questions
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center mt-6">
                <TitleSection />
            </div>
            <div className="mt-4 flex flex-col justify-center items-center">
                <HelpOptions helpPageData={helpPageData} />
            </div>
        </div>

    );
}
