import React, { Fragment, useEffect, useRef, useState } from "react";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import AccountTab from "../../Component/AccountPage/AccountTab";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    deleteSavedVideo,
    getIndividualFolderData,
} from "../../Redux/ApiSlice/SavedVideosSlice";
import { IoArrowBackCircle } from "react-icons/io5";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";
import Skeleton from "react-loading-skeleton";
import AccountSidebar from "../../Component/AccountPage/AccountSidebar";
import AccountTabModal from "../../Component/AccountPage/AccountTabModal";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import MoveVideoSaveFolder from "../../Component/Modals/AccountPageModal/MoveVideoSaveFolder";

export default function SavedFolderData() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectVideo = useSelectVideo();
    const { id } = useParams();
    const savedVideo = useSelector((state) => state.savedVideos);
    const { loading, FolderData } = savedVideo;
    const { videos } = FolderData;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [moveModalOpen, setMoveModalOpen] = useState(false);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [showSavedPopup, setShowSavedPopup] = useState(null);

    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const ref = useRef(null);

    const handleClickOut = () => {
        setShow(null);
        setActiveTab("");
    };
    useOutsideClick(ref, handleClickOut);


    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const menuRef = useRef(null);
    const [postId, setpostId] = useState(null);

    const fetchData = async () => {
        await dispatch(getIndividualFolderData(id));
    };

    const deleteFolderVideo = async (postId) => {
        await dispatch(deleteSavedVideo({ folder_id: id, post_id: postId }));
        fetchData();
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setpostId(null); // Close menu if clicked outside
        }
    };

    const handleSelectVideo = async (id) => {
        selectVideo(id, videos);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMoveVideo = (postId) => {
        setSelectedPostId(postId);
        setShowSavedPopup(null);
        setMoveModalOpen(true);
    };

    const handleMoveComplete = () => {
        fetchData();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div className="bg-[#fff] dark:bg-black dark:text-white overflow-hidden h-full relative ">
                {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    CC ACCOUNT saved folder data PAGE-FE - V10 /MAR 25,2024
                </p> */}
                <p className="text-[#ff3b3b] text-center w-full mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    Copyright © 2025 Galileo One Inc
                </p>
                <div className="">
                    <AccountProfileSection
                        handleOpenSideBar={openDrawer}
                        handleCloseSideBar={closeDrawer}
                        isDrawerOpen={isDrawerOpen}
                        setActiveTab={setActiveTab}
                        setShow={setShow}
                        show={show}
                    />
                </div>

                <AccountTab activeTab={"Saved"} />

                <div className="flex justify-between items-center px-2">
                    <IoArrowBackCircle
                        onClick={() => navigate(-1)}
                        className="cursor-pointer text-[20px] text-black dark:text-white"
                    />
                    <p className="text-black dark:text-white text-[10px]">{videos?.length} video</p>
                </div>

                <div className="p-2 pt-0 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
                    {loading && (
                        <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar">
                            {[1, 2, 3, 4, 5, 6].map((data) => (
                                <div key={data} className="col-span-1">
                                    <Skeleton
                                        height={170}
                                        width={150}
                                    // baseColor="#202020"
                                    // highlightColor="#444"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {!loading && videos?.length ? (
                        <div className="overflow-y-scroll no-scrollbar">
                            <div className="grid grid-cols-2 gap-3 pt-1 overflow-scroll no-scrollbar">
                                {videos?.map((item) => (
                                    <VideoCard
                                        belowDecs={true}
                                        threeDots={true}
                                        id={item.id}
                                        video={item.video_url}
                                        likes_count={item.likes_count}
                                        avatar_url={item.created_by.avatar_url}
                                        handleDeletePost={deleteFolderVideo}
                                        handleMoveVideo={handleMoveVideo}
                                        body={item.post_description}
                                        view_count={item.view_count}
                                        selectVideo={handleSelectVideo}
                                        isSavedView={true}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="h-full w-full flex justify-center items-center">
                            <p className="text-[12px] text-gray-500 font-bold">EMPTY FOLDER</p>
                        </div>
                    )}
                </div>

                <div className="grid grid-cols-12 overflow-hidden">
                    <div className="col-span-12">
                        <AccountSidebar
                            isDrawerOpen={isDrawerOpen}
                            setIsDrawerOpen={setIsDrawerOpen}
                            openDrawer={openDrawer}
                            closeDrawer={closeDrawer}
                        />
                    </div>
                </div>
            </div>
            {show && (
                <div className={"absolute h-full w-full top-0 overflow-hidden"}>
                    <div
                        className="absolute h-screen w-full top-0 opacity-100 bg-opacity-70 bg-black z-50"
                        onClick={handleClickOut}
                    />
                    <div
                        ref={ref}
                        className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
                    >
                        <AccountTabModal
                            ref={ref}
                            // data={data}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            )}

            <MoveVideoSaveFolder
                loading={loading}
                SavedFolderList={savedVideo.SavedFolderList}
                isOpen={moveModalOpen}
                handleClose={() => setMoveModalOpen(false)}
                postId={selectedPostId}
                sourceFolderId={id}
                onMoveComplete={handleMoveComplete}
            />
        </Fragment>
    );
}