import React, { Fragment } from "react";
import Payment from "../../Component/shoppage/Payment";

const PaymentPage = () => {
   return (
      <Fragment>
         <div className="grid grid-cols-1 dark:bg-black dark:text-white">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               Copyright © 2025 Galileo One Inc
            </p>
            <Payment />
         </div>
      </Fragment>
   );
};

export default PaymentPage;
