import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCaretBackCircleSharp } from "react-icons/io5";

export default function PrivacyPolicy() {
    const navigate = useNavigate();
    const location = useLocation();
    const [PrivacyPolicy, setPrivacyPolicy] = useState(false);

    useEffect(() => {
        if (location.pathname === "/privacy-policys") {
            setPrivacyPolicy(true);
        }
        return () => setPrivacyPolicy(false);
    }, [location.pathname]);

    const information = [
        "Log Information: We collect log information about your use of the Platform, including the type of browser that you use; the time, duration, and frequency of your access; Platform pages viewed; your IP address; and the page you visited before visiting our Platform.",
        "Device Information: We collect information about the computer or mobile device that you use to access our Platform, including the hardware model, operating system and version, unique device identifiers, and mobile network information.",
        "Geolocation Information: We may collect information about the location of your device each time you use our Platform based on your consent to the collection of this information, such as when you enable location services. We may also collect information, such as your IP address, that can sometimes be used to approximate your location based on your device settings.",
        "Information Collected by Cookies and Other Tracking Technologies: We and our service providers collect information using various technologies, including cookies and pixel tags (which are also called clear GIFs, web beacons, or pixels). Cookies are small data files stored on your hard drive or in device memory that help us improve our Platform and your experience, and track usage of our Platform. Pixel tags are electronic images that may be used in our Platform or emails, and track usage of our Platform and effectiveness of communications.",
        "Other Information: Based on your consent, we may collect other information from your device, such as photos from your camera roll or calendar information you want to manage via the Platform.",
    ];

    const KeepYourInformation = [
        "If we need it to operate or provide our Products. For example, we need to keep some of your information to maintain your account.",
        "The feature we use it for, and how that feature works. For example, messages sent using Messenger's vanish mode are retained for less time than regular messages.",
        " How long we need to retain the information to comply with certain legal obligations.If we need it for other legitimate purposes, such as to prevent harm; investigate possible violations of our terms or policies; promote safety, security and integrity; or protect ourselves, including our rights, property or products",
        "In some instances, and for specific reasons, we'll keep information for an extended period of time.",
    ];

    const contest = [
        "The type of information and the purpose for which it is processed;",
        " Any legal requirement to retain the data, including where data was processed on the basis of a Legal Obligation or until the Statute of Limitations has elapsed with respect to possible legal claims or investigations;",
        "Whether the data is required for Trust & Safety purposes. For example, where users breach our Terms of Service, we may terminate their account and prevent them from accessing our Services, in order to protect other users. In such circumstances, it may be necessary to retain certain personal data, even after account termination.",
    ];

    const policy = [
        {
            dec: "What data we collect and how we will use it?",
            id: "WhatDataWeCollect",
        },
        { dec: "Methods of collection.", id: "MethodsOfCollection" },
        { dec: "Customer communication.", id: "CustomerCommunication" },
        { dec: "How do we use your information?", id: "HowDoWeUseYourInformation" },
        { dec: "Information you provide to us.", id: "InformationYouProvideToUs" },
        {
            dec: "Information you collect automatically",
            id: "InformationYouCollectAutomatically",
        },
        {
            dec: "How long do we keep your information?",
            id: "HowLongDoWeKeepYourInformation",
        },
        {
            dec: "How will you know that the policy has been changed?",
            id: "HowWillYouKnowThePolicyHasBeenChanged",
        },
        {
            dec: "Information collected using cookies and web tags.",
            id: "InformationCollectedUsingCookiesAndWebTags",
        },
        { dec: "Information about contacts.", id: "InformationAboutContacts" },
        { dec: "Consent.", id: "Consent" },
        { dec: "Data retention.", id: "DataRetention" },
    ];

    const scrollToAnswer = (id) => {
        document.getElementById(id).scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <div className={`${PrivacyPolicy ? "md:px-28" : ""} grid grid-cols-12 `}>
            {/* <div className="col-span-12 bg-[#D9D9D9] sticky top-0 h-6">
                <p className="text-red-700 text-[10px] font-bold text-center pt-[4px]">
                Copyright © 2025 Galileo One Inc
                </p>
                <p className="text-red-700 text-[10px] font-bold text-center pt-[4px]">
                CC PRIVACY POLICY / FE / V2 / JULY 06, 2024
                </p>
            </div> */}
            <div className="col-span-12 px-3">
                <p className="text-center font-bold text-[21px] mt-2">
                    <IoCaretBackCircleSharp
                        onClick={() => navigate(-1)}
                        className={`${PrivacyPolicy ? "text-[24px]" : "text-[20px]"} text-black dark:text-white cursor-pointer `}
                    />
                </p>
                <h1
                    className={`${PrivacyPolicy ? "text-[36px]" : "text-[24px]"
                        } text-center font-extrabold mb-4 text-black dark:text-white`}
                >
                    Privacy Policy
                </h1>
                <h3
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        } font-extrabold my-2 text-justify text-black dark:text-white`}
                >
                    What is the Privacy Policy and what does it cover?
                </h3>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mb-1 text-black dark:text-white`}
                >
                    We want you to understand what information we collect, and how we use
                    and share it. That's why we encourage you to read our Privacy Policy.
                    In the Privacy Policy, we explain how we collect, use, share, retain
                    and transfer information. We also let you know your rights. Each
                    section of the Policy includes helpful examples and simpler language
                    to make our practices easier to understand. We've also added links to
                    resources where you can learn more about the privacy topics that
                    interest you.
                </p>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify text-black dark:text-white`}
                >
                    It's important to us that you know how to control your privacy, so we
                    also show you where you can manage your information in the settings of
                    the Products you use. You can update these settings to shape your
                    experience.
                </p>
                <p className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"} mt-2 text-black dark:text-white`}>
                    Terms of Service:
                </p>
                <ol className="mt-2">
                    {policy.map((item, index) => (
                        <li
                            onClick={() => scrollToAnswer(item.id)}
                            className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                                } text-blue-700 dark:text-blue-500 hover:text-blue-800 text-justify cursor-pointer my-2`}
                        >
                            {index + 1}. {item.dec}
                        </li>
                    ))}
                </ol>
            </div>

            <div className="col-span-12 px-3 mt-5" id="WhatDataWeCollect">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        } mt-2 font-bold text-black dark:text-white`}
                >
                    1. What data we collect and how we will use it?
                </h2>
                <p className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"} mt-1 text-black dark:text-white`}>
                    Show the user how the data is collected and what we do with it. Refer
                    document for more information.
                </p>
            </div>
            <div className="col-span-12 px-3 mt-5" id="MethodsOfCollection">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    2. Methods of collection
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    How we are going to collect the user data mentioned above table.
                    <br /> We collect obvious data such as user payment information when
                    they checkout in shopping site, advertisement page.
                    <br /> We collect data that users disclose in online forms, opt-in pop
                    ups and checkout pages such as email id, phone number.
                    <br /> We may collect information for our website back end, like
                    shared API's IP addresses and users’ location.
                </p>
            </div>
            <div className="col-span-12 px-3 mt-5" id="CustomerCommunication">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    3. Customer communication
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We may send users regular emails, text about flash sales, provide
                    transaction updates through SMS or emails.
                    <br /> If, for any reason, users don’t want to have their information
                    collected, they can unsubscribe.
                </p>
            </div>
            <div className="col-span-12 px-3 mt-5" id="HowDoWeUseYourInformation">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    4. How do we use your information?
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We use information we collect to provide a personalized experience to
                    you, including ads, along with the other purposes we explain in detail
                    below
                </p>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    For some of these purposes, we use information across our Products and
                    across your devices. The information we use for these purposes is
                    automatically processed by our systems. But in some cases, we also use
                    manual review to access and review your information.
                </p>

                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    To use less information that’s connected to individual users, in some
                    cases we de-identify or aggregate information. We might also anonymize
                    it so that it no longer identifies you. We use this information in the
                    same ways we use your information as described in this section.
                </p>
            </div>

            <div className="col-span-12 px-3 mt-5" id="InformationYouProvideToUs">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    5. Information You Provide to Us
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We collect information that you provide directly to us. For example,
                    we collect information that you provide when you create an account,
                    choose interests or groups, fill out a form, or communicate with us.
                    The types of information that we may collect include identifiers, such
                    as your name, username, password, email address, postal address, and
                    phone number; payment and commercial information, such as payment
                    method data and purchases, if any; demographic information and any
                    other personal information that you choose to provide, such as photos,
                    choices of interests, groups, and professional or employment-related
                    information, including your role, company, and years of experience.
                    Certain information about yourself is optional to provide. Your choice
                    of groups is also optional, but we are required to process this
                    information in order to administer your account, and to indicate that
                    you are a member of the groups that you join.
                    <br /> On occasion, the information that you give us when you join a
                    group may imply information about your beliefs, political views,
                    health conditions, or your sexual identity. This, and other kinds of
                    sensitive information, is given special protection in accordance with
                    applicable law. Posting personal or sensitive information about others
                    without their permission is against our Community Guidelines.
                </p>
            </div>

            <div className="col-span-12 px-3 mt-5">
                <div className="grid grid-cols-12 border-[1px] border-gray-800">
                    <div
                        className={`col-span-12 text-center ${PrivacyPolicy ? "text-[16px] p-2" : "text-[14px]"
                            } font-semibold text-black dark:text-white`}
                    >
                        Information Others Provide
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0 text-black dark:text-white`}
                    >
                        Category
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        Example
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0  text-black dark:text-white`}
                    >
                        Updated Contact Information
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        We might receive information about you from other sources and add it
                        to our account information. This may include updated delivery and
                        address information from our shippers or other sources so that we
                        can correct our records and deliver your next purchase or
                        communication more easily.
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0 text-black dark:text-white`}
                    >
                        Non-User Contact Information
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        Some Group Companies provide an option to invite a friend to their
                        Service, in which case the Group Company we will ask you for that
                        person's email address and automatically send an email invitation to
                        them.
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0 text-black dark:text-white`}
                    >
                        User Reports
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        Users can report content to us, where they feel it infringes
                        intellectual property rights or otherwise violates out our Terms of
                        Service.
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0  text-black dark:text-white`}
                    >
                        Analytic and Aggregator Information
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        Our Group Companies receive Your Information from ad networks,
                        behavioural advertising vendors, market research, and social media
                        companies or similar companies. Further, some of the Information
                        Collected Through Cookies may be provided by third party analytic
                        companies.
                    </div>
                </div>
            </div>

            <div
                className="col-span-12 px-3 mt-5"
                id="InformationYouCollectAutomatically"
            >
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    6. Information We Collect Automatically When You Use the Platform
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    When you use our Platform, we automatically collect internet and other
                    electronic network activity information about you and other
                    information, including:
                </p>
                <ol
                    className={`${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                        } text-justify  pl-3 text-black dark:text-white`}
                >
                    {information.map((item, index) => (
                        <li className="mt-2 text-black dark:text-white" key={index}>
                            {index + 1}.{item}
                        </li>
                    ))}
                </ol>
            </div>

            <div className="col-span-12 px-3 mt-5">
                <div className="grid grid-cols-12 border-[1px] border-gray-800">
                    <div
                        className={`col-span-12 text-center ${PrivacyPolicy ? "text-[16px] p-2" : "text-[14px]"
                            } font-semibold text-black dark:text-white`}
                    >
                        Automatically Collected Information
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0 text-black dark:text-white`}
                    >
                        Category
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        Example
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0  text-black dark:text-white`}
                    >
                        Usage Information
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        We automatically collect information about the content and people
                        you interact with, the features and add-ons you use and other
                        actions you take, including applying to certain work opportunities
                        advertised via our Services.
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0  text-black dark:text-white`}
                    >
                        Location Data
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        While users can provide their location or shipping address in the
                        course of using our Services, we also automatically collect certain
                        location information, including your IP address and location
                        information provided by your device.
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0 text-black dark:text-white`}
                    >
                        Device Information
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        We automatically collect information from your browser or your
                        device when accessing our Services, and record this data in log
                        files. This includes information such as your unique device
                        identifier, device attributes, device signals, data from device
                        settings, networks and ads data.
                    </div>
                    <div
                        className={`col-span-5 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } t border-b-0 font-semibold px-2 py-2 border-[1px] border-gray-800 border-x-0 text-black dark:text-white`}
                    >
                        Information Collected Through Cookies
                    </div>
                    <div
                        className={`col-span-7 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                            } border-b-0 px-2 py-2 border-[1px] border-gray-800  border-r-0 text-black dark:text-white`}
                    >
                        When you visit a Service, we use cookies and related technologies
                        (such as clear GIFs/web beacons) to identify the browser, to
                        identify which page variant a visitor has seen, to determine if a
                        visitor has clicked on a page variant, and to monitor traffic
                        patterns and gauge popularity of service options. Please see our
                        Cookie Policy for more information.
                    </div>
                </div>
            </div>

            <div
                className="col-span-12 px-3 mt-5"
                id="HowLongDoWeKeepYourInformation"
            >
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    7. How long do we keep your information?
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We keep information as long as we need it to provide our Products,
                    comply with legal obligations or protect our or other’s interests. We
                    decide how long we need information on a case-by-case basis. Here’s
                    what we consider when we decide:
                </p>
                <ol
                    className={`${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                        } text-justify list-disc pl-3 text-black dark:text-white`}
                >
                    {KeepYourInformation?.map((item, index) => (
                        <li className="mt-2 text-black dark:text-white" key={index}>
                            {item}
                        </li>
                    ))}
                </ol>
            </div>
            <div
                className="col-span-12 px-3 mt-5"
                id="HowWillYouKnowThePolicyHasBeenChanged"
            >
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    8. How will you know the policy has changed?
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We'll notify you before we make material changes to this Policy.
                    You’ll have the opportunity to review the revised Policy before you
                    choose to continue using our Products.
                </p>
            </div>
            <div
                className="col-span-12 px-3 mt-5"
                id="InformationCollectedUsingCookiesAndWebTags"
            >
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    9. Information Collected Using Cookies and Web Tags:
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    Cookies are text files that may be sent to and saved by your web
                    browser when you access a website; your web browser stores these
                    cookies in a way associated with each website you visit, and you can
                    generally see your cookies through your browser settings, depending on
                    what browser you’re using. A web tag is code or a pixel embedded in a
                    web page, or email, that allows Tumblr or a third party to see that
                    you have looked at that page. We use cookies and web tags to enable
                    our servers to recognize your web browser and tell us how and when you
                    use the Services, as described above in “Information Related to Use of
                    the Services.” Our cookies do not, by themselves, contain information
                    that personally identifies you, and we don't combine the general
                    information collected through cookies with other such information to
                    tell us who you are. However, we do use cookies to identify that you
                    have logged in, and that your web browser has accessed the Services,
                    and we may associate that information with your Account if you have
                    one. We may also store unique or near-unique identifiers that we
                    associate with your Account in our cookies. This information, in turn,
                    is sometimes used as described above in “Information Related to Use of
                    the Services.” Most web browsers have an option for turning off the
                    cookie feature, which will prevent your browser from accepting new
                    cookies, as well as (depending on the sophistication of your web
                    browser) allowing you to decide on acceptance of each new cookie in a
                    variety of ways. If you disable cookies, you won't be able to log into
                    your Account, and so won’t be able to use the vast majority of our
                    Services; as such, we don't recommend disabling your cookies when
                    using the Services. Some services that we use (including Third Party
                    Services), such as Google Analytics, Nielsen, and comScore, use web
                    tags and may also place their own cookies on your browser, and
                    individual blogs on our network may contain code that places their own
                    cookies. We also run limited-time studies using web tags, sometimes
                    with third parties, to, for example, measure the effectiveness of our
                    advertising or email. Note that, unless otherwise disclosed, this
                    Privacy Policy covers our use of cookies only and does not cover the
                    use of cookies by third parties.
                </p>
            </div>
            <div className="col-span-12 px-3 mt-5" id="InformationAboutContacts">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    10. Information About Your Contacts:
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    Certain features of the Services allow you to provide us with your
                    contact lists, so that we can connect you with people in our Services
                    that are also on those contact lists. For example, you can temporarily
                    connect your email contact information to your Account, so that we can
                    provide you with a list of your email contacts that use the Services.
                    As another example, you can send us your mobile phone contact
                    information through our mobile applications, which then allows us to
                    provide you with a list of those contacts that use the Services so
                    that you can “follow” their blogs. We will give you a choice as to
                    whether or not you provide us such information, and we will disclose
                    fully, within the appropriate feature, how we use that information.
                </p>
            </div>

            <div className="col-span-12 px-3 mt-5">
                <table className="border-[1px] border-collapse border-gray-800">
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <th
                            className={`border-[1px] border-collapse  text-[10px]
                            ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"}
                                font-bold px-2 border-gray-800 text-black dark:text-white`}
                        >
                            Category
                        </th>
                        <th
                            className={`border-[1px] border-collapse  text-[10px]
               ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"}
                font-bold px-2 border-gray-800 text-black dark:text-white`}
                        >
                            Description
                        </th>
                        <th
                            className={`border-[1px] border-collapse  text-[10px]
               ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"}
                font-bold px-2 border-gray-800 text-black dark:text-white`}
                        >
                            Legal Basis for Processing
                        </th>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className="border-[1px] border-collapse text-[10px] font-semibold text-center border-gray-800 text-black dark:text-white">
                            Account Data
                        </td>
                        <td
                            className={`border-[1px]     ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            In order to use certain features (like accessing content), you
                            need to create a user account, which requires us to collect and
                            store your email address, password, and account settings. To
                            create an instructor account, we collect and store your name,
                            email address, password, and account settings. As you use certain
                            features on the site, you may be prompted to submit additional
                            information including occupation, government ID information,
                            verification photo, date of birth, race/ethnicity, skill
                            interests, and phone number. Upon account creation, we assign you
                            a unique identifying number.
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Performance of contract</li>
                                <li>
                                    Legitimate interests (service provisioning, identity
                                    verification, fraud prevention and security, communication)
                                </li>
                            </ol>
                        </td>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className="border-[1px] border-collapse text-[10px] font-semibold text-center border-gray-800 text-black dark:text-white">
                            Profile Data
                        </td>
                        <td
                            className={`border-[1px] ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            You can also choose to provide profile information like a photo,
                            headline, biography, language, website link, social media
                            profiles, country, or other data. Your Profile Data will be
                            publicly viewable by others.
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Performance of contract</li>
                                <li>
                                    Legitimate interests (enhanced platform functionality, convey
                                    content source information)
                                </li>
                            </ol>
                        </td>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className="border-[1px] border-collapse text-[10px] font-semibold text-center border-gray-800 text-black dark:text-white">
                            Shared Content
                        </td>
                        <td
                            className={`border-[1px] ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            Parts of the Services let you interact with other users or share
                            content publicly, including by uploading courses and other
                            educational content, posting reviews about content, asking or
                            answering questions, sending messages to students or instructors,
                            or posting photos or other work you upload. Such shared content
                            may be publicly viewable by others depending on where it is
                            posted.
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Performance of contract</li>
                                <li>
                                    Legitimate interests (service provisioning, enhanced platform
                                    functionality)
                                </li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="col-span-12 px-3 mt-5" id="Consent">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    11. Legal Basis: Consent
                </h2>
            </div>

            <div className="col-span-12 px-3 mt-1">
                <table className="border-[1px] border-collapse border-gray-800">
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <th
                            className={`border-[1px] border-collapse  text-[10px]
                            ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} font-bold px-2 border-gray-800 text-black dark:text-white`}
                        >
                            Why and how we process your data
                        </th>
                        <th
                            className={`border-[1px] border-collapse  text-[10px]
               ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"}
                font-bold px-2 border-gray-800 text-black dark:text-white`}
                        >
                            Categories of data
                        </th>
                        <th
                            className={`border-[1px] border-collapse  text-[10px]
               ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"}
                font-bold px-2 border-gray-800 text-black dark:text-white`}
                        >
                            CCPA Purpose
                        </th>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} border-gray-800 text-justify px-2 text-black dark:text-white`}

                        >
                            Depending on the Service, users may decide to customize their
                            profile and provide us with additional information for this
                            purpose. Users have control over what additional information they
                            provide and can amend or update their profile at any time.
                        </td>
                        <td
                            className={`border-[1px]     ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            Profile Information User-Generated Content
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li> Consent</li>
                                <li>Business Purposes</li>
                            </ol>
                        </td>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} border-gray-800 text-justify px-2 text-black dark:text-white`}
                        >
                            We may occasionally ask you to complete surveys to assist us with
                            product or marketing research, or to evaluate our customer
                            service.
                        </td>
                        <td
                            className={`border-[1px]     ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            Survey Information
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Consentt</li>
                                <li>Business </li>
                                <li>Commercial Purposes</li>
                            </ol>
                        </td>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} border-gray-800 text-justify px-2 text-black dark:text-white`}
                        >
                            Our Services allow people to build communities, to promote their
                            creative works and to connect with interested parties. Users may
                            choose to add their location information, so as to inform others
                            of where they are based.
                        </td>
                        <td
                            className={`border-[1px] ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            Location Data
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Consentt</li>
                                <li>Business Purposes</li>
                                <li>Commercial Purposes</li>
                            </ol>
                        </td>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} border-gray-800 text-justify px-2 text-black dark:text-white`}                        >
                            Users can adjust their device-based settings to enable the sharing
                            of certain information (e.g., Location Data) on the basis of
                            consent obtained for cookies. We use this information for various
                            purposes, including linking Service users’ accounts with their
                            device, for the purpose of delivering targeted advertising.
                        </td>
                        <td
                            className={`border-[1px] ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            Location Data Device Information Analytic Information
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Consentt</li>
                                <li>Business Purposes</li>
                                <li>Commercial Purposes</li>
                            </ol>
                        </td>
                    </tr>
                    <tr className="border-[1px] border-collapse border-gray-800">
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} border-gray-800 text-justify px-2 text-black dark:text-white`}
                        >
                            From time to time, we may offer contests or other promotions that
                            users may choose to enter. We use Your Information as necessary to
                            process your request to enter the contest or take part in a
                            promotion, notify you if you have won or to process delivery of a
                            prize or other related purposes.
                        </td>
                        <td
                            className={`border-[1px] ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                                } border-collapse border-gray-800 text-black dark:text-white`}
                        >
                            Contest Data User-Generated Content Identity Information
                        </td>
                        <td className={`border-[1px] border-collapse ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"} px-4 border-gray-800 text-black dark:text-white`}>
                            <ol className="list-disc">
                                <li>Consentt</li>
                                <li>Business Purposes</li>
                                <li>Commercial Purposes</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="col-span-12 px-3 mt-5">
                <p className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"} text-justify mt-1 text-black dark:text-white`}>
                    Users have the right to withdraw their consent at any time. If they
                    choose to exercise this right, our Group Companies will cease
                    processing personal data on this basis. However, please note that a
                    decision to withdraw your consent cannot affect the lawfulness of
                    processing previously carried out on this basis.
                </p>
            </div>
            <div className="col-span-12 px-3 mt-5" id="DataRetention">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    11. Legal Basis: Consent
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We will keep personal data only for as long as is necessary for the
                    purposes for which that personal data is processed, including to
                    provide our Services. The storage periods are determined on a
                    case-by-case basis and will depend on several factors, including:
                </p>
                <ol className={`list-disc pl-3 ${PrivacyPolicy ? "text-[14px]" : "text-[10px]"
                    } text-justify text-black dark:text-white`}>
                    {contest.map((item, index) => (
                        <li className="mt-2" key={index}>
                            {item}
                        </li>
                    ))}
                </ol>
            </div>
            <div className="col-span-12 px-3 my-5">
                <h2
                    className={`${PrivacyPolicy ? "text-[20px]" : "text-[14px]"
                        }  font-bold text-black dark:text-white`}
                >
                    Revisions to the Privacy Policy
                </h2>
                <p
                    className={`${PrivacyPolicy ? "text-[16px]" : "text-[12px]"
                        } text-justify mt-1 text-black dark:text-white`}
                >
                    We may change this Privacy Policy from time to time. But when we do,
                    we’ll let you know one way or another. Sometimes, we’ll let you know
                    by revising the date at the top of the Privacy Policy that’s available
                    on our website and mobile application. Other times, we may provide you
                    with additional notice (such as adding a statement to our websites’
                    homepages or providing you with an in-app notification).
                </p>
            </div>
        </div>
    );
}
