import React, { Fragment, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCaretBackCircleSharp } from "react-icons/io5";

const Account = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const [itsMobile, setItsMobile] = useState(false);
   const { pathname } = location;

   useEffect(() => {
      setItsMobile(true);
      return () => { setItsMobile(false); };
   }, []);

   return (
      <Fragment>
         <div
            className={`px-3 ${itsMobile
               ? "lg:my-3"
               : "lg:px-20 mx-0 lg:mx-20 lg:my-10 lg:shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px]"
               } my-0 shadow-none rounded-none lg:rounded-xl dark:text-white`}
         >
            <p className="text-center font-bold text-[21px] mt-2">
               <IoCaretBackCircleSharp
                  onClick={() => navigate(-1)}
                  className="cursor-pointer dark:text-black dark:bg-white rounded-full"
               />
            </p>
            <p className="text-center font-bold text-[22px] mb-2">Accounts</p>
            <p className="text-justify text-[12px]">
               We use your Name and other Identifying details to verify your account
               and they remain private. Change identifying details for your account
               here.
            </p>
            <div className="mt-4">
               <p className="text-start font-bold text-[16px]">Name</p>
               <p className="text-justify text-[12px] mt-1">
                  This is how your name will appear around CC and how others will
                  recognize you. Manage how your name appears on CC.{" "}
               </p>
               <div className="flex gap-2 justify-between mt-3 w-full">
                  <input
                     type="text"
                     placeholder="First Name"
                     className="focus:outline-none bg-[#d9d9d9] dark:bg-white dark:placeholder:text-black p-2 w-[90%]"
                  />
                  <input
                     type="text"
                     placeholder="Last Name"
                     className="focus:outline-none bg-[#d9d9d9] dark:bg-white dark:placeholder:text-black p-2 w-[90%]"
                  />
               </div>
               <p className="text-justify text-[12px] mt-4">
                  Your email address is not revealed to other users. If you change
                  your email address, you will need to re-verify it.
               </p>
               <div className={`${itsMobile ? "w-full" : "w-[70%]"} mt-5`}>
                  <input
                     type="text"
                     placeholder="marydevis@cc.com"
                     className="focus:outline-none bg-[#d9d9d9] dark:bg-white dark:placeholder:text-black py-2 pl-2 pr-[5.5rem] w-full"
                  />
                  <p className={`text-[#00C036] absolute flex items-center gap-1 text-[13px] z-10 mt-[-1.8rem] ${itsMobile ? "right-8" : "left-[13.5rem]"}`}>
                     <FaCheckCircle /> Verified
                  </p>
               </div>
               <p className="text-justify text-[12px] mt-5">
                  You can attach a secondary email address to your profile. We won’t
                  send emails to this address! If you add a secondary email address,
                  you will need to verify it.
               </p>
               <p className="text-justify text-[12px] mt-2">
                  Once you add and verify your secondary email, you can switch your
                  primary and secondary emails. This helps to secure your account and
                  provides a way for you to access your account should you loose
                  access to your primary email.
               </p>
               <button className="bg-black px-3 py-1 rounded-full dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black   text-white text-[12px] mt-3">
                  Add Secondary Email
               </button>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Password</p>
               <p className="text-justify text-[12px] mt-1">
                  When you change your password, you will be automatically signed out
                  from your other browsers and devices. Improve your security with a
                  strong password.
               </p>
               <button className="bg-black px-3 py-1 rounded-full dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black  text-white text-[12px] mt-3">
                  Change Password
               </button>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Custom Profile URL</p>
               <p className="text-justify text-[12px] mt-1">
                  Create a custom URL for your profile page. Once you change it, the
                  links with your old URL will not work. Your CC PROFILE url will
                  appear as. http://www.cc.com/profile/
               </p>
               <table className="w-full border-[#232323] dark:border-[#fff] border-2 mt-4 rounded">
                  <tr>
                     <td className=" border-[#232323] dark:border-[#fff] border-2  p-2 text-[#232323] dark:text-[#fff] text-[12px]">
                        http://www.cc.com
                     </td>
                     <td className=" border-[#232323] dark:border-[#fff] border-2  p-2 text-[#232323] dark:text-[#fff] text-[12px]">
                        JOHN_OFFICIALS
                     </td>
                  </tr>
               </table>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Custom Profile URL</p>
               <p className="text-[12px] mt-1">
                  Please Visit your Profile page to edit your Profile.{" "}
                  <span className="text-[14px] font-bold underline text-[#000000eb] dark:text-blue-gray-500">Learn more</span>
               </p>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Download Profile Data</p>
               <p className="text-justify text-[12px] mt-1">
                  Download your privacy data, as required by GDPR. This includes
                  account preferences, profile information, and your photos and
                  videos. This process will take some time, so we’ll send you an email
                  when it’s ready for you to download. This may take upto 30 days.
                  Please note that if you delete your account before you receive your
                  data, your request cannot be fulfilled. Once we have retrieved your
                  data, you will receive an email (at johnofficial156@protonmail.com)
                  with a link to the archive.
               </p>
               <button className="bg-black px-3 py-1 rounded-full text-white text-[12px] dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black   mt-5 mb-2">
                  Request profile data
               </button>
            </div>
         </div>
      </Fragment>
   );
};

export default Account;
