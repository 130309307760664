import React, { Fragment } from "react";
import OrderDetails from "../../Component/shoppage/OrderDetails";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";
import RecentOrder from "../../Component/shoppage/RecentOrder";

const OrderDetailsPage = () => {
   return (
      <Fragment>
         <div className="">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               Copyright © 2025 Galileo One Inc
            </p>
            <InputButtonBlack />
            <OrderDetails />
            <RecentOrder />
         </div>
      </Fragment>
   );
};

export default OrderDetailsPage;
