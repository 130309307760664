import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { basketProducts } from "../../Datas/Datas";
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";

const StarRating = ({ rating }) => {
    const navigate = useNavigate();
    const [fullStars, setFullStars] = useState(Math.floor(rating));
    const [halfStar, setHalfStar] = useState(rating % 1 !== 0);

    const renderStars = () => {
        let stars = [];

        // Render full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(<IoStar key={i} />);
        }

        // Render half star if needed
        if (halfStar) {
            stars.push(<IoStarHalf key="half" />);
        }

        // Render remaining blank stars
        const remainingStars = 5 - stars.length;
        for (let i = 0; i < remainingStars; i++) {
            stars.push(<IoStarOutline key={`blank-${i}`} />);
        }

        return stars;
    };

    return (
        <div className="flex items-center justify-start text-yellow-800 text-[12px]" onClick={() => navigate("/shopdetails")}>
            {renderStars()}
        </div>
    );
};

const MyBasket = () => {
    const navigate = useNavigate();

    return (
        <div className="px-3 pt-3 dark:bg-black">
            <p className="text-center text-[#000] dark:text-white font-bold text-[20px] mb-4">
                My Basket ({basketProducts.length})
            </p>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-x-[10px] mb-1">
                {basketProducts.map((data) => (
                    <div className="mb-5" key={data.id}>
                        <img
                            src={data.imgUrl}
                            alt={data.name}
                            onClick={() => navigate("/shopdetails")}
                            className="img-shadow rounded-2xl h-[9rem] w-full object-cover bg-[#fff]"
                        />
                        <div className="text-[#000] dark:text-white mt-2 px-1 text-start">
                            <p
                                onClick={() => navigate("/shopdetails")}
                                className="line-clamp-1 text-[13px] font-semibold"
                            >
                                {data.name}
                            </p>
                            <p
                                onClick={() => navigate("/shopdetails")}
                                className="line-clamp-1 text-[11px]"
                            >
                                {data.description}
                            </p>

                            <div className="flex items-center gap-x-2 justify-between">
                                <StarRating rating={data.rating} />
                                <p className="text-[8px] text-gray-600">400+ Ratings</p>
                            </div>
                            <div className="flex justify-between items-center mt-1">
                                <p className="text-[18px] md:text-[11px] font-bold" onClick={() => navigate("/shopdetails")}>
                                    ${data.price}
                                </p>
                                <select className="bg-[#f8000080] rounded-lg h-[20px] px-[2px] text-[10px] focus:outline-none">
                                    <option
                                        className="text-[10px] bg-gray-100 hover:bg-gray-200"
                                        value={1}
                                    >
                                        Qty - 1
                                    </option>
                                    <option
                                        className="text-[10px] bg-gray-100 hover:bg-gray-200"
                                        value={2}
                                    >
                                        Qty - 2
                                    </option>
                                    <option
                                        className="text-[10px] bg-gray-100 hover:bg-gray-200"
                                        value={3}
                                    >
                                        Qty - 3
                                    </option>
                                    <option
                                        className="text-[10px] bg-gray-100 hover:bg-gray-200"
                                        value={4}
                                    >
                                        Qty - 4
                                    </option>
                                    <option
                                        className="text-[10px] bg-gray-100 hover:bg-gray-200"
                                        value={5}
                                    >
                                        Qty - 5
                                    </option>
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-[5px]">
                                <Button className="bg-gray-300 text-[9px] md:text-[7px] text-[#000] px-2 py-2 shadow-none">
                                    Remove
                                </Button>
                                <Button
                                    onClick={() => navigate("/wishlist")}
                                    className="bg-gray-300 text-[9px] md:text-[7px] text-[#000] px-2 py-2 md:py-2 md:px-1 shadow-none"
                                >
                                    Move to Wishlist
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Button className="mb-4 w-full dark:bg-gray-700" onClick={() => navigate("/shopaddress")}>
                Proceed To Purchase Now
            </Button>
        </div>
    );
};

export default MyBasket;
