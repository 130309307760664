import React, { useEffect, useState } from "react";

export const EditSavedFolderModal = ({ handleOpenEditModal, EditModal, renameFolderName, selectFolder }) => {
    const [folderName, setFolderName] = useState("");

    const handleChangeFolderName = (e) => {
        setFolderName(e.target.value);
    };

    const handleSubmit = () => {
        renameFolderName(folderName, selectFolder?.id);
    };

    useEffect(() => {
        setFolderName(selectFolder?.name);
    }, [selectFolder]);

    if (!EditModal) return null;

    return (
        <div className="absolute top-0 inset-0 p-5 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-2 border-2 dark:bg-black rounded-lg shadow-lg w-96  border-gray-200 dark:border-gray-700">
                <div className="">
                    <h2 className="text-lg font-semibold mb-2 text-black dark:text-white">Rename Folder</h2>
                    <input
                        type="text"
                        value={folderName}
                        onChange={handleChangeFolderName}
                        placeholder="Rename Folder Name"
                        className="w-full px-3 py-1 border border-gray-300 dark:border-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="flex justify-center gap-4 p-2">
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-1 text-[13px] bg-black dark:bg-cyan-500 text-white rounded-lg hover:bg-gray-800 hover:dark:bg-cyan-600"
                    >
                        Save
                    </button>
                    <button
                        onClick={() => handleOpenEditModal(null)}
                        className="px-4 py-1 text-[13px] bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};