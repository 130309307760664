import React from "react";
import { ccLogo } from "../../Datas/Assests";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { SocialIcon } from "react-social-icons";
import KvLogo from "../../Assets/logo/kv_logo.png"
import chirpLogo from "../../Assets/logo/c_logo.png"
import { loginPageDomainName } from "../../Datas/Datas";

export default function SignupPage() {
    const navigate = useNavigate();

    const handleClick = (value) => {
        navigate(`/${value}`);
    };
    return (
        <div className="w-full h-full bg-white">
            <div className="h-[100%] py-4">
                <div className="absolute top-0 w-full flex justify-center">
                    {/* <p className="text-red-700 fon-bold text-[16px] md:text-[12px]">
                        CC REGISTER-1 - FE - V3 - APRIL 30, 2024
                    </p> */}
                    <p className="text-red-700 fon-bold text-[16px] md:text-[12px]">
                        Copyright © 2025 Galileo One Inc
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className=" text-[#000] text-[16px] font-bold">
                        Signup to Chaa Chingg
                    </p>
                    <img src={ccLogo} className="h-28 w-28" alt="logo" />
                    <p className="text-[16px] font-bold">Watch Create Enjoy</p>
                </div>
                <div className="w-full h-[65%] p-3 md:p-2 flex flex-col items-center justify-center bg-[#fff]">
                    <div className=" bg-[#505052] h-[10%] w-[80%] m-[2%] px-2 py-[6px] flex justify-around items-center cursor-pointer relative rounded-md">
                        <div
                            onClick={() => handleClick("register")}
                            className=" text-[#fff] text-[14px] md:text-[12px] font-bold"
                        >
                            <p>Phone Number / Email / Username</p>
                        </div>
                    </div>
                    <div className="bg-[#505052] h-12 w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
                        <div className="absolute left-4">
                            <img
                                alt="media"
                                src={chirpLogo}
                                className="h-[25px] w-[25px] rounded-[50%] object-cover "
                            />
                        </div>
                        <div className="font-bold text-[14px] md:text-[12px] text-[#fff]">
                            <p>Continue with Chirp</p>
                        </div>
                    </div>

                    <div className="bg-[#505052] h-12 w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
                        <div className="absolute left-4">
                            <img
                                alt="media"
                                src={KvLogo}
                                className="h-[25px] w-[25px] rounded-[50%] object-cover"
                            />
                        </div>
                        <div className="font-bold text-[14px] md:text-[12px] text-[#fff]">
                            <p>Continue with KV</p>
                        </div>
                    </div>

                    {loginPageDomainName?.map((item) => (
                        <div className="bg-[#505052] h-12 w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
                            <div className="absolute left-4">
                                {item.Icon === "apple" ? (
                                    <img
                                        alt="media"
                                        className="h-[25px] w-[25px] rounded-full  object-cover "
                                        src={"https://th.bing.com/th/id/R.d8426cb195ac7b393f853110480f0d34?rik=KA%2fqJN5JGzzYQQ&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fRTA%2fEjA%2fRTAEjAoyc.png&ehk=ekPZKDonspqQaibYUd0APp4mV2bUfx9IuplBvX831cI%3d&risl=&pid=ImgRaw&r=0"}
                                    />
                                ) : (
                                    <SocialIcon
                                        url="#"
                                        network={item.Icon}
                                        style={{ height: "25px", width: "25px" }}
                                    />
                                )}
                            </div>
                            <div className="font-bold text-[14px] md:text-[12px] text-[#fff]">
                                <p>{item.name}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center items-center">
                    <p
                        onClick={() => navigate(-1)}
                        className="text-[13px] text-blue-700 cursor-pointer font-bold flex gap-2 items-center underline"
                    >
                        <IoMdArrowBack /> Go Back
                    </p>
                </div>
            </div>
        </div>
    );
}

