import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { moveVideoToFolder } from "../../../Redux/ApiSlice/SavedVideosSlice";

const MoveVideoSaveFolder = ({
    loading,
    SavedFolderList,
    isOpen,
    handleClose,
    postId,
    sourceFolderId,
    onMoveComplete
}) => {
    const dispatch = useDispatch();
    const [selectedFolderId, setSelectedFolderId] = useState("");
    const [isMoving, setIsMoving] = useState(false);
    
    // Filter out the current folder
    const availableFolders = SavedFolderList?.data?.filter(
        folder => folder.id !== sourceFolderId && folder.id !== SavedFolderList?.id
    ) || [];

    // Reset selected folder when modal opens
    useEffect(() => {
        if (isOpen) {
            setSelectedFolderId("");
        }
    }, [isOpen]);

    const handleMove = async () => {
        if (!selectedFolderId) return;
        
        try {
            setIsMoving(true);
            await dispatch(moveVideoToFolder({
                sourceFolderId,
                targetFolderId: selectedFolderId,
                postId
            })).unwrap();
            
            // Close modal and notify parent component
            handleClose();
            if (onMoveComplete) onMoveComplete();
        } catch (error) {
            console.error("Error moving video:", error);
        } finally {
            setIsMoving(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-md p-4 w-[80%] max-w-sm">
                <h3 className="text-sm font-medium mb-3 text-black dark:text-white">Move to folder</h3>
                
                <div className="mb-4">
                    <label className="block text-xs text-gray-700 dark:text-gray-300 mb-1">
                        Select destination folder
                    </label>
                    
                    {availableFolders.length === 0 ? (
                        <p className="text-xs text-gray-500 dark:text-gray-400 py-1">
                            No other folders available to move to
                        </p>
                    ) : (
                        <select
                            className="w-full px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-black dark:text-white text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
                            placeholder="Select a folder"
                            value={selectedFolderId}
                            onChange={(e) => setSelectedFolderId(e.target.value)}
                            disabled={loading || isMoving}
                        >
                            {availableFolders.map((folder) => (
                                <option key={folder.id} value={folder.id}>
                                    {folder.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                
                <div className="flex justify-end space-x-2">
                    <button 
                        onClick={handleClose}
                        className="px-3 py-1 text-xs border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleMove}
                        disabled={!selectedFolderId || loading || isMoving || availableFolders.length === 0}
                        className={`px-3 py-1 text-xs rounded-md text-white ${
                            !selectedFolderId || loading || isMoving || availableFolders.length === 0
                                ? "bg-gray-400"
                                : "bg-blue-500 hover:bg-blue-600"
                        }`}
                    >
                        {isMoving ? "Moving..." : "Move"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MoveVideoSaveFolder;