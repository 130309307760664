import React, { Fragment } from "react"
import CreateGroup from "../../Component/grouppage/CreateGroup"

const CreateGroupPage = () => {
    return (
        <Fragment>
            <CreateGroup />
        </Fragment>
    )
}

export default CreateGroupPage