import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";
 

export const searchGroupMedia = createAsyncThunk(
    "searchGroupMedia",
    async ({ groupId, query }) => {
        try {
            const response = await axiosInstance.get(`/group/posts/search/${groupId}/?search=${query}`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);


export const searchGroupComments = createAsyncThunk(
    "searchGroupComments",
    async ({ groupId, query }) => {
        try {
            const response = await axiosInstance.get(`/group/comments/search/${groupId}/?search=${query}`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const GroupSearchSlice = createSlice({
    name: "GroupSearchSlice",
    initialState: {
        data: [],
        commentData: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        builder.addCase(searchGroupMedia.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchGroupMedia.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(searchGroupMedia.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(searchGroupComments.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchGroupComments.fulfilled, (state, action) => {
            state.commentData = action.payload;
            state.loading = false;
        });
        builder.addCase(searchGroupComments.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export default GroupSearchSlice.reducer;
