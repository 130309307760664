import React, { useEffect, useState } from "react";
import SlotCounter from "react-slot-counter";

const Ticker = () => {
    const [dollars, setDollars] = useState(() => {
        const savedDollars = localStorage.getItem('dollars');
        return savedDollars ? parseInt(savedDollars, 10) : 0;
    });
    const [cents, setCents] = useState(() => {
        const savedCents = localStorage.getItem('cents');
        return savedCents ? parseInt(savedCents, 10) : 0;
    });
    const [selectedDate, setSelectedDate] = useState(() => {
        const savedDate = localStorage.getItem('selectedDate');
        return savedDate ? new Date(savedDate) : new Date("2024-12-15T00:00:00Z");
    });

    const secondsInMonth = 30 * 24 * 60 * 60; // Simplified to 30 days for now
    const targetAmount = 2500000
    const secondsPerDollar = secondsInMonth / targetAmount / 100; // Calculate the seconds for 1 cent

    useEffect(() => {
        const interval = setInterval(() => {
            const elapsedSeconds = Math.floor((new Date() - selectedDate) / 1000); // Calculate seconds passed since the selected date

            const newCents = Math.floor(elapsedSeconds / secondsPerDollar) % 100;
            const newDollars = Math.floor(elapsedSeconds / (secondsPerDollar * 100)) + Math.floor(newCents / 100);

            setCents(newCents);
            setDollars(newDollars);

            // Save to localStorage to persist values across refreshes
            localStorage.setItem('dollars', newDollars);
            localStorage.setItem('cents', newCents);
            localStorage.setItem('selectedDate', selectedDate.toISOString());
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [selectedDate]);

    const formatNumber = (value) => {
        return value.toString().padStart(8, "0");
    };

    return (
        <div className="flex flex-col items-center justify-center">
            {/* {/ Countdown Display /} */}
            <div className="px-[5px] py-[5px] bg-[#38B6FF] rounded-lg z-30">
                {/* {/ Slot Counter with Borders /} */}
                <div className=" flex space-x-[4.4px]  justify-center">
                    {/* formatNumber(dollars) */}
                    {`${formatNumber(dollars)}.${cents.toString().padStart(2, "0")}`
                        .split("")
                        .map((char, index) => (
                            <div
                                key={index}
                                className="px-[4px] font-black flex items-center justify-center text-[18px] text-black rounded-[2px] bg-[#38B6FF] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                            >
                                {/* {/ <SlotCounter value={char} /> /} */}
                                <p>{char}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default Ticker