import React, { Fragment } from "react";
import OrderPlaced from "../../Component/shoppage/OrderPlaced";

const OrderPlacedPage = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
            Copyright © 2025 Galileo One Inc
         </p>
         <OrderPlaced />
      </Fragment>
   );
};

export default OrderPlacedPage;
