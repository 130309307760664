import React from "react";
import { Link } from "react-router-dom";

export default function SmallMenu({ menuItems }) {
   return (
      <div className="absolute top-5 right-0 z-10 mt-2 w-[6.5rem] rounded-md bg-[#f3f3f3] shadow-2xl">
         <div className="py-1" role="none">
            {menuItems.map((data) => (
               <Link
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-[#404040] hover:text-white"
               >
                  {data.name}
               </Link>
            ))}
         </div>
      </div>
   );
}
