import React, { Fragment, useState } from "react";
import DesktopNav from "./DesktopNav";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const WhatsNew = ({ itsMobile }) => {
   const [isChecked6, setIsChecked6] = useState(false);
   const [isChecked7, setIsChecked7] = useState(false);
   const [isChecked8, setIsChecked8] = useState(false);
   const navigate = useNavigate();

   const handleCheckbox6 = () => {
      setIsChecked6(!isChecked6);
   };

   const handleCheckbox7 = () => {
      setIsChecked7(!isChecked7);
   };
   const handleCheckbox8 = () => {
      setIsChecked8(!isChecked8);
   };

   return (
      <div className={`px-3 dark:text-[#fff]`}>
         <p className="text-center font-bold text-[21px] mt-4">
            <IoCaretBackCircleSharp
               onClick={() => navigate(-1)}
               className="cursor-pointer dark:text-black dark:bg-white rounded-full"
            />
         </p>
         <p className="text-center font-bold text-[22px] mb-1">What’s New</p>
         <p className="text-justify font-normal text-[12px] mb-2">
            Here’s what we’ve been up to. They're not official features, so if
            something weird happens, please let us know! And if it keeps being
            weird, you can just turn it off.
         </p>
         <div className="mt-5 shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] p-2 rounded-2xl flex flex-col gap-y-6">
            <div className="md:py-0">
               <div className="flex justify-between items-center pl-2 mt-1">
                  <p className="text-start text-[12px] font-normal">
                     Schedule Posts .<span className="underline text-blue-400 cursor-pointer">Learn More</span>
                  </p>
                  <label className="relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked7}
                        onChange={handleCheckbox7}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked7 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked7
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked7 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
            <div className="md:py-0">
               <div className="flex justify-between items-center pl-2 mt-1">
                  <p className="text-start text-[12px] font-normal">
                     Schedule DM’s .<span className="underline text-blue-400 cursor-pointer">Learn More</span>
                  </p>
                  <label className="relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked6}
                        onChange={handleCheckbox6}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked6 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked6
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked6 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
            <div className="md:py-0">
               <div className="flex justify-between items-center pl-2 mt-1">
                  <p className="text-start text-[12px] font-normal">
                     Auto delete Feeds .
                     <span className="underline text-blue-400 cursor-pointer">Learn More</span>
                  </p>
                  <label className="relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked8}
                        onChange={handleCheckbox8}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked8 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked8
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked8 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
         </div>
      </div>
   );
};

export default WhatsNew;
