import React, { useCallback, useState, useEffect } from "react";
import { FaCrown } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { IoArrowBackCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
    dePromoteGroupModerator,
    getGroupModeratorsList,
} from "../../Redux/ApiSlice/Group/GroupModeratorsSlice";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";
import { getGroupDetails } from "../../Redux/ApiSlice/Group/GroupSlice";
import { messagesChatRoomCreate } from "../../Redux/ApiSlice/MessagesSlice";

const CreatedGroupModerators = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { groupId } = params;
    const moderatorslist = useSelector((state) => state.groupModerators);
    const { data, loading, error } = moderatorslist;
    const [individualChecked, setIndividualChecked] = useState({});
    const [showDeleteOption, setShowDeleteOption] = useState(false);
    console.log(error, "error");

    const groupDetails = useSelector((state) => state.group);

    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;

    const [showMenu, setShowMenu] = useState(false);

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };
    const handleIndividualChange = (event, id) => {
        const { checked } = event.target;
        setIndividualChecked((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    const getGroupModerators = useCallback(async () => {
        try {
            const response = await dispatch(getGroupModeratorsList(groupId));
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }, [dispatch]);

    const handleRemoveModerators = async () => {
        try {
            const response = await dispatch(
                dePromoteGroupModerator({
                    groupId,
                    data: { user_ids: Object.keys(individualChecked) },
                })
            );
            if (response.type === "deleteGroupMembersList/fulfilled") {
                handleCancel();
                toast.success(response?.payload?.message);
                getGroupModerators();
            }
        } catch (error) {
            console.log(error);
            handleCancel();
        }
    };

    const getGroupData = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails(groupId));
            const result = response.payload;
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    const handleCancel = () => {
        setIndividualChecked({});
        setShowDeleteOption(false);
    };

    const handleMesage = async (id) => {
        try {
            const response = await dispatch(messagesChatRoomCreate(id));
            if (response.type === "messagesChatRoomCreate/fulfilled") {
                navigate(`/mobileMessages/userChat/${response.payload.id}`);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        getGroupModerators();
        getGroupData();
    }, [getGroupModerators]);

    return (
        <div className="bg-white dark:bg-black h-full overflow-y-scroll relative">
            <Toaster position="top-right" />
            <p className="absolute top-0 left-1/2 transform -translate-x-1/2 text-center text-[#ff3b3b] font-bold md:text-[10px]"            >
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="grid grid-cols-12 px-2 pt-3 pb-1 items-center border-b border-gray-300 dark:border-gray-700">
                <div className="col-span-2">
                    <IoArrowBackCircle
                        className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="col-span-8 text-center">
                    <p className="text-black dark:text-white text-[18px] md:text-[14px]">
                        {groupDetails?.data?.group_name}
                    </p>
                </div>
                <div className="col-span-2 flex justify-end">
                    {showMenu ? (
                        <p
                            className="text-black dark:text-white hover:text-red-600 text-[15px] md:text-[12px] font-bold cursor-pointer"
                            onClick={() => setShowMenu(false)}
                        >
                            Close
                        </p>
                    ) : (
                        <HiDotsVertical
                            className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                            onClick={() => setShowMenu(!showMenu)}
                        />
                    )}
                </div>
            </div>

            {showMenu && (
                <div className="absolute top-15 md:top-8 right-2 bg-white border border-gray-300 z-50 rounded">
                    {groupDetails?.data?.current_user_role !== "member" && (
                        <p
                            onClick={() => setShowDeleteOption(!showDeleteOption)}
                            className="text-black hover:text-red-500 text-[12px] px-2 py-1 cursor-pointer border-b border-gray-300"
                        >
                            DePromote{" "}
                        </p>
                    )}
                    <p className="text-black hover:text-red-500 text-[12px] px-2 py-1 cursor-pointer">
                        Menu 02
                    </p>
                </div>
            )}

            {loading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                    <div className="grid grid-cols-12 p-2 py-0 mt-1" key={item}>
                        <div className="col-span-1 flex justify-center items-center">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={20}
                                circle
                            />
                        </div>
                        <div className="col-span-2 flex justify-start items-center">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={30}
                                width={30}
                                circle={true}
                            />
                        </div>
                        <div className="col-span-4">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={100}
                            />
                        </div>
                        <div className="col-span-5 flex justify-end  gap-2 items-center">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={20}
                                circle
                            />
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={30}
                            />
                        </div>
                    </div>
                ))
                : data?.admins?.map((data) => (
                    <>
                        <div
                            key={data.id}
                            className="grid grid-cols-12 px-2 py-1.5 items-center border-b border-gray-300 dark:border-gray-700"
                        >
                            <div className="col-span-1 flex justify-start items-center">
                                {data.is_admin && !showDeleteOption ? (
                                    <FaCrown className="text-[25px] md:text-[20px] text-yellow-800" />
                                ) : null}
                                {data.is_admin && showDeleteOption && (
                                    <div class="inline-flex items-center ml-[-10px]">
                                        <label
                                            class="relative flex items-center rounded-full cursor-pointer"
                                            htmlFor={`check-${data.id}`}
                                        >
                                            <input
                                                type="checkbox"
                                                id={`check-${data.id}`}
                                                checked={individualChecked[data.id] || false}
                                                onChange={(e) => handleIndividualChange(e, data.id)}
                                                class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-100 before:opacity-0 before:transition-opacity checked:border-none checked:bg-cyan-400 checked:before:bg-white"
                                            />
                                            <span class="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-3.5 w-3.5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    stroke="currentColor"
                                                    strokeWidth="1"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-span-2">
                                <img
                                    alt={data.firt_name}
                                    src={data.avatar}
                                    onClick={() => handleNavigate(data?.id)}
                                    className="h-10 w-10 md:h-9 md:w-9 rounded-full object-cover cursor-pointer"
                                />
                            </div>
                            <div className="col-span-5 pr-0 md:pr-1 -ml-2">
                                <p
                                    onClick={() => handleNavigate(data?.id)}
                                    className="text-[16px] md:text-[13px] text-black dark:text-white leading-[14px] cursor-pointer"
                                >
                                    {data.first_name} {data.last_name}
                                </p>
                            </div>
                            <div className="col-span-4 flex justify-end gap-x-2 items-center">
                                <button className="h-9 w-9 md:h-7 md:w-7 bg-black dark:bg-white text-white dark:text-black flex justify-center items-center rounded-full">
                                    <FaUserPlus className="text-[16px] md:text-[13px]" />
                                </button>
                                {User_profile?.id !== data.id && (
                                    <button
                                        onClick={() => handleMesage(data?.id)}
                                        className="text-[14px] md:text-[10px] bg-black dark:bg-white text-white dark:text-black rounded-sm font-bold px-[6px] py-1"
                                    >
                                        Message
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                ))}

            {!loading && error && (
                <div className="grid grid-cols-12 p-2 py-0 mt-1">
                    <p className="text-black dark:text-white text-center col-span-12">
                        You must be a member or the owner to view the admins.
                    </p>
                </div>
            )}

            {showDeleteOption && (
                <div className="absolute z-30 py-2 bottom-0 bg-gray-300 dark:bg-gray-900 flex justify-between gap-2 w-full px-3">
                    <button
                        onClick={() => handleCancel()}
                        className="bg-white dark:bg-black text-black dark:text-white rounded-full text-[14px] hover:font-bold py-[4px] px-6 cursor-pointer"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => handleRemoveModerators()}
                        className="bg-white dark:bg-black text-black dark:text-white rounded-full text-[14px] hover:font-bold py-[4px] px-6 cursor-pointer"
                    >
                        Remove
                    </button>
                </div>
            )}
        </div>
    );
};

export default CreatedGroupModerators;
