import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { useFormik } from "formik";
import { ccLogo } from "../../Datas/Assests";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { REACT_APP_MAIN_URL } from "../../API/MainUrl";
import { HiEyeSlash, HiMiniEye } from "react-icons/hi2";

export default function Register() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClick = (value) => {
        navigate(`/${value}`);
    };

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password1: "",
            password2: "",
            date_of_birth: "",
            gender: "",
        },
        onSubmit: (values) => {
            const date = moment(values.date_of_birth);

            // Original date string
            const originalDateString = date._i;

            // Parse the original date string with moment.js
            const originalDate = moment(originalDateString);

            // Format the date in the desired format
            const rearrangedDateString = originalDate.format("YYYY-MM-DD");

            // Output: "2001-05-06"
            if (
                values.date_of_birth &&
                values.email &&
                values.first_name &&
                values.gender &&
                values.password2 &&
                values.last_name &&
                values.password1
            ) {
                const loadingToastId = toast.loading('Signing up...');
                axios({
                    method: "post",
                    url: `${REACT_APP_MAIN_URL}api/signup/`,
                    data: { ...values, date_of_birth: rearrangedDateString },
                    headers: { "Content-Type": "application/json" },
                })
                    .then(function (response) {
                        console.log(response, "response");
                        const { status } = response;
                        if (status === 200) {
                            toast.success(
                                "Sign up is Completed, Please activate your account in the given mail",
                                { id: loadingToastId }
                            );
                            setTimeout(() => {
                                navigate("/login");
                            }, 4000);
                        }
                    })
                    .catch(function (error) {
                        console.log(error.response); // Log the error response
                
                        if (error.response) {
                            try {
                                const parsedMessage = JSON.parse(error.response.data.message); // Parse the nested JSON
                                if (parsedMessage.email) {
                                    toast.error(parsedMessage.email[0].message, { id: loadingToastId });
                                } else {
                                    toast.error("An error occurred. Please try again.", { id: loadingToastId });
                                }
                            } catch (err) {
                                toast.error("Invalid response format", { id: loadingToastId });
                            }
                        } else {
                            toast.error("Network error. Please try again.", { id: loadingToastId });
                        }
                    });
                


            } else {
                toast.error(`Please fill the Field`);
            }
        },
    });

    return (<div className="w-full h-[100%] flex justify-center items-center md:overflow-scroll">
        {toast && <Toaster position="top-right" />}
        <div className="h-[100%] w-full relative">
            <div className="h-[100%] grid grid-cols-12 w-full overflow-x-hidden  px-2 overflow-scroll">
                <div className="col-span-12 flex justify-center w-full bg-[#fff] items-center  border-black">
                    {/* <p className="text-[#ff3b3b] font-bold text-[12px]">
                        CC REGISTER-2 / FE / V7 / DEC 13, 2024
                    </p> */}
                    <p className="text-[#ff3b3b] font-bold text-[12px]">
                        Copyright © 2025 Galileo One Inc
                    </p>
                </div>
                <div className="col-span-12 w-full flex justify-center items-center bg-[#ffffff] flex-col">
                    <img src={ccLogo} className="h-24 w-24" alt="logo" />
                    <h3 className="font-bold text-base">Let’s get started!</h3>
                </div>
                <div className="col-span-12 w-full h-[75%] bg-[#ffffff] no-scrollbar py-2">
                    <div className="h-full flex items-center justify-center">
                        <div className=" w-full h-full">
                            <div className="mobile-register-form-container p-2 -mt-2">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="flex flex-col h-[3.7rem] mb-2">
                                        <label className="text-[13px]">First name</label>
                                        <input
                                            required
                                            name="first_name"
                                            type="text"
                                            onChange={formik.handleChange}
                                            placeholder="Enter your first name"
                                            className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-3  w-full"
                                        />
                                    </div>
                                    <div className="flex flex-col h-[3.7rem] mb-2">
                                        <label className="text-[13px]">Last name</label>
                                        <input
                                            required
                                            name="last_name"
                                            type="text"
                                            onChange={formik.handleChange}
                                            placeholder="Enter your last name"
                                            className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-3  w-full"
                                        />
                                    </div>

                                    <div className="flex flex-col h-[3.7rem] mb-2">
                                        <label className="text-[13px]">Email</label>
                                        <input
                                            required
                                            name="email"
                                            type="email"
                                            onChange={formik.handleChange}
                                            placeholder="Enter your Email"
                                            className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-3  w-full"
                                        />
                                    </div>
                                    <div className="flex flex-col h-[3.7rem] mb-2">
                                        <label className="text-[13px]">Password</label>
                                        <div className="relative h-full w-full">
                                            <input
                                                required
                                                name="password1"
                                                type={showPassword ? "text" : "password"}
                                                onChange={formik.handleChange}
                                                placeholder="Enter your Password"
                                                className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-3  w-full"
                                            />
                                            <p className="absolute top-[0.7rem] right-2 cursor-pointer">
                                                {showPassword ?
                                                    <HiEyeSlash className="text-gray-500" onClick={() => setShowPassword(!showPassword)} /> : <HiMiniEye className="text-gray-500" onClick={() => setShowPassword(!showPassword)} />
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col h-[3.7rem] mb-2">
                                        <label className="text-[13px]">Confirm Password</label>
                                        <div className="relative h-full w-full">
                                            <input
                                                required
                                                name="password2"
                                                type={showConfirmPassword ? "text" : "password"}
                                                onChange={formik.handleChange}
                                                placeholder="Re-Enter Password"
                                                className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-2 w-full "
                                            />
                                            <p className="absolute top-[0.7rem] right-2 cursor-pointer">
                                                {showConfirmPassword ?
                                                    <HiEyeSlash className="text-gray-500" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> : <HiMiniEye className="text-gray-500" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col h-[3.7rem] mb-2">
                                        <label className="text-[13px]">Language</label>
                                        <select
                                            // required
                                            type="text"
                                            name="language"
                                            placeholder="Prefer not to answer"
                                            className="h-full border border-black text-[14px] rounded-md outline-none px-2 w-full cursor-pointer"
                                        >
                                            <option value="" selected>Select Preferred Language</option>
                                            <option value="English">English</option>
                                            <option value="Spanish">Spanish</option>
                                        </select>
                                    </div>
                                    <div className="grid grid-cols-4 gap-3">
                                        <div className="col-span-2">
                                            <div className="flex flex-col h-[3.7rem]">
                                                <label className="text-[13px]">Date of Birth </label>
                                                <input
                                                    required
                                                    type="date"
                                                    name="date_of_birth"
                                                    onChange={formik.handleChange}
                                                    placeholder="mm/dd/yyyy"
                                                    className="h-full border border-black text-[14px] rounded-md outline-none px-2  w-full"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-2 col-span-2">
                                            <div className="flex flex-col w-full h-[3.7rem]">
                                                <label className="text-[13px]">Gender (optional) </label>
                                                <select
                                                    required
                                                    type="password"
                                                    name="gender"
                                                    onChange={formik.handleChange}
                                                    placeholder="Prefer not to answer"
                                                    className="h-full border border-black text-[14px] rounded-md outline-none px-2 w-full cursor-pointer"
                                                >
                                                    <option value="" selected>Select Gender</option>
                                                    <option value="F">Female</option>
                                                    <option value="M">Male</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 md:mb-2 flex space-x-2 items-center">
                                        <input type="checkbox" required className="border-gray-300 rounded h-3 w-3" />
                                        <p className="text-[12px] leading-[14px]">
                                            By creating an account, I agree to the
                                            <span className="ml-[3px] mr-[3px] text-[#00a3ff]">Terms of Use, Privacy Policy</span>
                                            and <span className="ml-[3px] text-[#00a3ff]">Cookies Policy.</span>
                                        </p>
                                    </div>
                                    <button
                                        type="submit"
                                        className="p-2 mt-2 mb-2 rounded-md bg-black text-white font-bold w-full"
                                    >
                                        Create Account
                                    </button>
                                    <p className="text-[16px] text-center my-2">
                                        Already have an account?
                                        <span
                                            onClick={() => handleClick("login")}
                                            className="font-bold text-blue-700  ml-1 cursor-pointer underline"
                                        >
                                            Log in
                                        </span>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
}
