import React, { useRef, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { CgProfile } from "react-icons/cg";
import { FaShare } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoArrowBackCircle } from "react-icons/io5";
import { LuMicOff } from "react-icons/lu";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { TbUserCancel } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useOutsideClick } from "../../Hooks/useOutsideClick";

export default function MessagesProfileOtherUser() {
    const navigate = useNavigate();
    const menuRef = useRef(null);
    const { id } = useParams();
    const getAllPost = useSelector((state) => state.HomePageVideo);
    const { AllPosts } = getAllPost;
    const [showMenu, setShowMenu] = useState(false);
    const messagesChatData = useSelector((state) => state.messages);
    const { individualMessages } = messagesChatData;
    const { users } = individualMessages;
    useOutsideClick(menuRef, () => setShowMenu(false));

    return (
        <div className="h-full overflow-hidden relative bg-white dark:bg-black">
            {/* <p className="absolute text-red-600 w-full text-center  text-[8px] font-bold uppercase">
                CC Otheruserchat Profile, FE, V2, June 11, 2024
            </p> */}
            <p className="absolute text-red-600 w-full text-center  text-[8px] font-bold uppercase">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="px-3 mt-4">
                <div className=" flex items-center justify-between mb-2 relative">
                    <IoArrowBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[23px] cursor-pointer text-black dark:text-white"
                    />
                    <HiDotsVertical
                        onClick={() => setShowMenu(!showMenu)}
                        className="text-[22px] cursor-pointer text-black dark:text-white"
                    />
                    {showMenu && (
                        <div
                            ref={menuRef}
                            className="absolute top-6 right-0 z-10 w-28 bg-white border border-gray-400 dark:border-gray-600 rounded-md shadow-lg overflow-hidden"
                        >
                            <p className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-500 hover:text-white cursor-pointer border-b border-gray-400">
                                Mute
                            </p>
                            <p className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-500 hover:text-white cursor-pointer border-b border-gray-400">
                                Block
                            </p>
                            <p className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-500 hover:text-white cursor-pointer border-b border-gray-400">
                                Report
                            </p>
                            <p className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-500 hover:text-white cursor-pointer">
                                Share
                            </p>
                        </div>
                    )}
                </div>

                <div className="flex flex-col justify-between">
                    {users?.map((data) => (
                        <div className="flex flex-col justify-center items-center">
                            <Avatar
                                src={data.avatar_url}
                                alt="avatar"
                                className="h-24 w-24 object-cover bg-gray-200 dark:bg-gray-800"
                            />
                            <p className="mt-1 text-black dark:text-white">
                                {data.first_name} {data.last_name}
                            </p>
                        </div>
                    ))}
                    <div className="flex justify-center px-3 gap-8 mt-4 text-black dark:text-white">
                        <div className=" flex flex-col justify-center items-center cursor-pointer ">
                            <CgProfile className="text-[1.2rem]" />
                            <p className="text-[11px] mt-[3px]">Profile</p>
                        </div>
                        <div className="flex flex-col justify-center items-center cursor-pointer">
                            <LuMicOff className="text-[1.2rem]" />
                            <p className="text-[11px] mt-[3px]">Mute</p>
                        </div>
                        <div className="flex flex-col justify-center items-center cursor-pointer">
                            <TbUserCancel className="text-[1.2rem]" />
                            <p className="text-[11px] mt-[3px]">Report</p>
                        </div>
                        <div className="flex flex-col justify-center items-center cursor-pointer">
                            <FaShare className="text-[1.2rem]" />
                            <p className="text-[11px] mt-[3px]">Share</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center px-3 mt-4">
                <p className="text-[14px] font-normal text-gray-500 dark:text-gray-600">Friends</p>
                <div className="">
                    <div
                        class="flex -space-x-2 overflow-hidden cursor-pointer"
                        onClick={() => navigate(`/mobileMessages/mutualfriend/list/${id}`)}
                    >
                        <img
                            alt="friends"
                            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            class="inline-block h-6 w-6 rounded-full"
                        />
                        <img
                            alt="friends"
                            src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            class="inline-block h-6 w-6 rounded-full"
                        />
                        <img
                            alt="friends"
                            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                            class="inline-block h-6 w-6 rounded-full"
                        />
                        <img
                            alt="friends"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            class="inline-block h-6 w-6 rounded-full"
                        />
                    </div>
                </div>
            </div>
            <div className="mt-2 h-[-webkit-fill-available] absolute w-full">
                <p className="text-[14px] font-bold px-3 text-black dark:text-white">Shared Media</p>
                <div className="grid grid-cols-3 gap-[6px] px-3 mt-1 pb-3 justify-center items-start h-[-webkit-fill-available] absolute w-full overflow-y-scroll no-scrollbar">
                    {AllPosts.map((data) => (
                        <div
                            key={data.id}
                            className="col-span-1 h-32 bg-gray-200 w-full cursor-pointer relative"
                        >
                            <MdOutlineVideoLibrary className="absolute text-[1rem] text-white right-0" />
                            <video
                                src={`${data.video_url}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
