import React from "react";
import { TrendingTitle } from "../../Component/HashTag/TrendingTitle";
import { Avatar } from "@material-tailwind/react";

export default function HashTagOtherUser() {
    const users = [
        {
            id: 1,
            name: "Isabella Rodriguez",
            joined: true,
            following: false,
            userImg: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 2,
            name: "James Wilson",
            following: true,
            userImg: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 3,
            name: "Emily Johnson",
            following: false,
            userImg: "https://images.unsplash.com/photo-1534008757030-27299c4371b6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 4,
            name: "Jacob Smith",
            joined: true,
            following: true,
            userImg: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 5,
            name: "Olivia Brown",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1503104834685-7205e8607eb9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 6,
            name: "Ethan_Martinez",
            following: true,
            userImg: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 7,
            name: "Sophia Garcia",
            following: true,
            userImg: "https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 8,
            name: "Ava_Anderson",
            following: true,
            userImg: "https://images.unsplash.com/photo-1560238970-cc0ae073d3a5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 9,
            name: "Michael Thompson",
            following: true,
            userImg: "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },

        {
            id: 10,
            name: "William Davis",
            following: true,
            userImg: "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ];

    return (
        <div className="h-full bg-white dark:bg-black relative overflow-hidden">
            <TrendingTitle title={"Other users"} searchColor={true} />
            <div className="mt-2 absolute w-full h-[-webkit-fill-available] overflow-scroll no-scrollbar">
                {users.map((item) => (
                    <div className="py-1.5 border-b border-gray-400 dark:border-gray-800" key={item.id}>
                        <div className="grid grid-cols-12 items-center justify-center">
                            <div className="col-span-2 flex justify-center items-center">
                                <Avatar
                                    alt="avatar"
                                    src={item.userImg}
                                    className="h-10 w-10 border border-gray-400 dark:border-gray-800"
                                />
                            </div>
                            <div className="col-span-8 flex flex-col justify-center items-start">
                                <p className="text-black dark:text-white text-[13px] font-bold line-clamp-1">@{item.name}{item.id}</p>
                            </div>
                            <div className="col-span-2 flex justify-center items-center">
                                <p className="text-black dark:text-white text-[14px] font-semibold ">
                                    {item.id}K
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <p className="text-[9px] text-red-500 absolute top-0 h-2 w-full flex justify-center font-bold uppercase">
                Copyright © 2025 Galileo One Inc
            </p>
            {/* <p className="text-[9px] text-red-500 absolute top-0 h-2 w-full flex justify-center font-bold uppercase">
                CC HASHTAG Other user VIDEOS, FE, V2, JUNE 20, 2024
            </p> */}
        </div>
    );
}