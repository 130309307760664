import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AccountProfile } from "../../Redux/ApiSlice/AccountProfileSlice";
import { getHomePageVideo } from "../../Redux/ApiSlice/HomePageVideoSlice";
import { Typography } from "@material-tailwind/react";
import { REACT_APP_MAIN_URL } from "../../API/MainUrl";
import { HiEyeSlash, HiMiniEye } from "react-icons/hi2";
import { ccLogo } from "../../Datas/Assests";
import BeatLoader from "react-spinners/BeatLoader";

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [keepMeLogin, setKeepMeLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /* formik section */
    const loginSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email Required"),
        password: Yup.string().required("Please Enter your password"),
    });

    const handleGetData = async () => {
        await dispatch(AccountProfile());
        const response = await dispatch(getHomePageVideo(1));
        const videoId = response?.payload?.results?.post[0]?.id;
        setTimeout(() => {
            // navigate("/home");
            navigate(`/home/${videoId}`);
        }, 1000);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            axios({
                method: "POST",
                url: `${REACT_APP_MAIN_URL}api/login/`,
                data: values,
                headers: { "Content-Type": "application/json" },
            })
                .then(function (response) {
                    const { data, status } = response;
                    if (status === 200) {
                        if (keepMeLogin) {
                            localStorage.setItem("email", values.email);
                            localStorage.setItem("password", values.password);
                            localStorage.setItem("keepMeLogin", keepMeLogin);
                        } else {
                            localStorage.removeItem("email");
                            localStorage.removeItem("password");
                            localStorage.setItem("keepMeLogin", keepMeLogin);
                        }

                        localStorage.setItem("accessToken", data?.access);
                        localStorage.setItem("new_User", true);
                        toast.success("Successfully logged in!");
                        setIsLoading(false);
                        handleGetData();
                    }
                })
                .catch(function (error) {
                    console.log(error.response); // Debugging: log the error response

                    if (error.response) {
                        const errorMessage =
                            error.response.data.detail || "Login failed. Please try again.";
                        toast.error(errorMessage);
                    } else {
                        toast.error("Network error. Please try again.");
                    }
                    setIsLoading(false);
                });
        },
    });

    const handleClick = (value) => {
        navigate(`/${value}`);
    };
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");

    const handleChangeKeepLogin = () => {
        setKeepMeLogin(!keepMeLogin);
    };

    useEffect(() => {
        if (email && password) {
            formik.setValues({
                email,
                password,
            });
            setKeepMeLogin(!keepMeLogin);
        }
    }, []);

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="h-full w-full relative md:overflow-hidden">
                <div className="h-full w-full">
                    <div className="grid grid-cols-12 h-[100%] overflow-y-scroll md:overflow-hidden">
                        <div className="col-span-12 flex justify-center items-center bg-[white] flex-col">
                            <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
                                {/* <p className="text-[#ff3b3b] font-extrabold text-[16px] md:text-[12px]  text-center w-full">
                  CC LOGIN - FE - V6 - DECEMBER 13, 2024
                </p> */}
                                <p className="text-[#ff3b3b] font-extrabold text-[16px] md:text-[12px]  text-center w-full">
                                    Copyright © 2025 Galileo One Inc
                                </p>
                            </div>
                            <img src={ccLogo} className="h-28 w-28" alt="logo" />
                            <p className=" text-lg md:text-[16px] font-bold text-center w-full px-[5%] md:pb-2 pt-1 ">
                                A whole new journey starts right here.
                            </p>
                            <p className="text-[24px] font-bold w-full text-center">
                                CC
                            </p>
                        </div>
                        <div className="col-span-12 w-full px-5 bg-[#fff] ">
                            <form onSubmit={formik.handleSubmit}>
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="mb-1 text-[12px]"
                                >
                                    Your Email
                                </Typography>
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    placeholder="Enter your email address"
                                    className="w-full border-[1px] border-gray-700 focus:outline-none p-2 rounded"
                                />
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="mb-1 mt-3 text-[12px]"
                                >
                                    Password
                                </Typography>
                                <div className="relative">
                                    <input
                                        required
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        placeholder="Enter your password"
                                        className="w-full border-[1px] border-gray-700 focus:outline-none p-2 pr-8 rounded"
                                    />
                                    <p className="absolute top-3 z-50 right-2 cursor-pointer">
                                        {showPassword ? (
                                            <HiEyeSlash
                                                className="text-gray-500"
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        ) : (
                                            <HiMiniEye
                                                className="text-gray-500"
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        )}
                                    </p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2 my-3 text-sm font-normal">
                                        <div class="inline-flex items-center">
                                            <label
                                                htmlFor="green"
                                                class="relative flex items-center rounded-full cursor-pointer"
                                            >
                                                <input
                                                    id="green"
                                                    type="checkbox"
                                                    checked={keepMeLogin}
                                                    onChange={handleChangeKeepLogin}
                                                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-green-500 checked:bg-green-500 checked:before:bg-green-500 hover:before:opacity-10"
                                                />
                                                <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="h-3.5 w-3.5"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        stroke="currentColor"
                                                        stroke-width="1"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <p>Keep me logged in</p>
                                    </div>
                                    <p
                                        onClick={() => handleClick("resetpassword")}
                                        className="text-[14px] font-bold text-end text-[#00a3ff] cursor-pointer "
                                    >
                                        Forgot password
                                    </p>
                                </div>
                                <div className="w-full flex justify-center items-center flex-col mt-4">
                                    <button className="w-[80%] text-[18px] bg-black text-white disabled:text-[black] px-6 p-2 md:p-1 md:px-4 rounded-lg font-bold">
                                        {isLoading ? (
                                            <BeatLoader
                                                size={15}
                                                color={"#fff"}
                                                loading={isLoading}
                                            />
                                        ) : (
                                            "Login"
                                        )}
                                    </button>
                                    <p className="text-[14px] flex justify-start font-semibold mt-6">
                                        Trouble logging in?
                                        <span
                                            onClick={() => handleClick("help")}
                                            className="underline text-[#00a3ff] cursor-pointer pl-2"
                                        >
                                            Get help
                                        </span>
                                    </p>
                                    <p
                                        className="text-[#00a3ff] text-[16px]  font-semibold mt-10 cursor-pointer hover:text-red-500"
                                        onClick={() => handleClick("signup")}
                                    >
                                        Create New CC Account
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default LoginPage;
