import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";

/* get the group list */
export const inviteMembers = createAsyncThunk(
    "inviteMembers",
    async ({groupId , data }) => {
        try {
            const response = await axiosInstance.post(`/group/groups/${groupId}/members/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);
/* get the group list */
export const searchMembers = createAsyncThunk(
    "inviteMembers",
    async ({ groupId, query }) => {
        try {
            const response = await axiosInstance.get(`/group/groups/${groupId}/users/search/?q=${query}`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);







export const GroupInviteMemberSlice = createSlice({
    name: "GroupInviteMemberSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        // get group list action promises
        builder.addCase(inviteMembers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(inviteMembers.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(inviteMembers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export default GroupInviteMemberSlice.reducer;
