import React, { Fragment, useState } from "react";
import SliderMenu from "./SliderMenu";
import { HiMiniBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { TbCurrentLocation } from "react-icons/tb";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { postLocation } from "../../Redux/ApiSlice/LocationSlice";
import toast, { Toaster } from "react-hot-toast";

const NeighborLocation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentLocation, setCurrentLocation] = useState("Downtown, Springfield");
    const [newLocation, setNewLocation] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationDetails, setLocationDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSaveLocation = () => {
        if (newLocation.trim()) {
            setCurrentLocation(newLocation);
            setNewLocation("");
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const getAddressFromCoordinates = async (lat, lng) => {
        setIsLoading(true);
        try {
            // Using OpenStreetMap's Nominatim API for reverse geocoding
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`
            );
            const data = await response.json();

            setLocationDetails(data);

            // Update current location with the detected address
            if (data.display_name) {
                // Create a more user-friendly version of the location
                const city = data.address.city || data.address.town || data.address.village || '';
                const state = data.address.state || '';
                const country = data.address.country || '';
                const formattedLocation = [city, state, country].filter(Boolean).join(', ');

                setCurrentLocation(formattedLocation);
                setNewLocation(formattedLocation);
            }
        } catch (error) {
            console.error("Error fetching address details:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const uploadLoacation = async (lat, lng) => {
        try {
            const responce = await dispatch(postLocation({ latitude: lat, longitude: lng }));
            if (responce?.payload?.message) {
                toast.success(responce?.payload?.message);
            }
        } catch (error) {
            toast.error("Error fetching address details");
        }
    }

    const handleDetectLocation = () => {
        if (navigator.geolocation) {
            setIsLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    setLatitude(lat);
                    setLongitude(lng);
                    uploadLoacation(lat, lng);
                    getAddressFromCoordinates(lat, lng);
                },
                (error) => {
                    console.error("Error detecting location: ", error);
                    setIsLoading(false);
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="sticky top-0 bg-white dark:bg-black pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer dark:text-gray-400"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px] dark:text-gray-400">
                            Neighbor Location
                        </p>
                        <p className="flex items-center text-[10px] text-gray-500">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            {currentLocation}
                        </p>
                    </div>
                    <HiMiniBars3
                        onClick={toggleMenu}
                        className="text-[24px] dark:text-gray-400 cursor-pointer"
                    />
                </div>
            </div>

            <div className="p-4">
                <div className="mb-4">
                    <label className="block text-[14px] font-medium dark:text-gray-400 text-black">
                        Set New Location
                    </label>
                    <input
                        type="text"
                        name="header"
                        placeholder="Enter new location"
                        value={newLocation}
                        onChange={(e) => setNewLocation(e.target.value)}
                        className="mt-1 block w-full placeholder:text-[13px] border dark:bg-[#242020] dark:text-gray-200 border-gray-300 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>
                <div className="flex justify-center items-center w-full mb-4">
                    <button
                        onClick={handleSaveLocation}
                        className="w-[100%] bg-[#3d3d3b] hover:bg-[#252524] text-white py-2 rounded-lg transition-colors duration-300"
                    >
                        Save Location
                    </button>
                </div>
                <div className="flex justify-center items-center w-full mb-4">
                    <button
                        onClick={handleDetectLocation}
                        disabled={isLoading}
                        className="flex items-center justify-center w-[100%] bg-[#3d3d3b] hover:bg-[#252524] text-white py-2 rounded-lg transition-colors duration-300"
                    >
                        <TbCurrentLocation className="text-[16px] mr-1" />
                        {isLoading ? "Detecting..." : "Detect Location"}
                    </button>
                </div>

                {locationDetails && (
                    <div className="mt-4 p-3 border border-gray-200 rounded-lg bg-gray-50 dark:bg-[#2e2b2b]">
                        <h3 className="font-bold text-lg mb-2 dark:text-gray-200">Location Details</h3>
                        {locationDetails.address && (
                            <div className="space-y-1 text-sm">
                                {locationDetails.address.road && (
                                    <p className="dark:text-gray-100"><span className="font-medium dark:text-gray-400">Street:</span> {locationDetails.address.road}</p>
                                )}
                                {(locationDetails.address.suburb || locationDetails.address.neighbourhood) && (
                                    <p className="dark:text-gray-100"><span className="font-medium dark:text-gray-400">Area:</span> {locationDetails.address.suburb || locationDetails.address.neighbourhood}</p>
                                )}
                                {(locationDetails.address.city || locationDetails.address.town || locationDetails.address.village) && (
                                    <p className="dark:text-gray-100"><span className="font-medium dark:text-gray-400">City:</span> {locationDetails.address.city || locationDetails.address.town || locationDetails.address.village}</p>
                                )}
                                {locationDetails.address.state && (
                                    <p className="dark:text-gray-100"><span className="font-medium dark:text-gray-400">State:</span> {locationDetails.address.state}</p>
                                )}
                                {locationDetails.address.postcode && (
                                    <p className="dark:text-gray-100"><span className="font-medium dark:text-gray-400">Postal Code:</span> {locationDetails.address.postcode}</p>
                                )}
                                {locationDetails.address.country && (
                                    <p className="dark:text-gray-100"><span className="font-medium dark:text-gray-400">Country:</span> {locationDetails.address.country}</p>
                                )}
                                <p className="text-xs text-gray-100 mt-2">
                                    <span className="font-medium dark:text-gray-500">Coordinates:</span> {latitude.toFixed(6)}, {longitude.toFixed(6)}
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {menuOpen &&
                <SliderMenu
                    onClose={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    );
};

export default NeighborLocation;