import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";


export const createFeedback = createAsyncThunk("createFeedback", async (data) => {
    const response = await axiosInstance.post(`/contact_us/feedback/`, data);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const mediaInquiry = createAsyncThunk("mediaInquiry", async (data) => {
    const response = await axiosInstance.post(`/contact_us/media-inquiry/`, data);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});
    


export const ContactUs = createSlice({
    name: "ContactUs",
    initialState: {
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        builder.addCase(createFeedback.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(createFeedback.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(createFeedback.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(mediaInquiry.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(mediaInquiry.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(mediaInquiry.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export default ContactUs.reducer;
