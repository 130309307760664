import React, { useEffect, useRef, useState } from "react";
import { BsFillMicMuteFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { MdHideSource } from "react-icons/md";
import { TbDeviceMobileOff } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRead } from "../../Redux/ApiSlice/NotificationSlice";

export function UpdatesTab({ setEmptypage, grouppage }) {
   const NotificationData = useSelector((state) => state.Notification);
   const accountProfile = useSelector((state) => state.AccountProfile);
   const { notificationList } = NotificationData;
   const { User_profile } = accountProfile;
   const [menuCommentId, setMenuCommentId] = useState(null);
   const menuRef = useRef(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [searchTerm, setSearchTerm] = useState("");

   const handleChange = (event) => {
      setSearchTerm(event.target.value);
   };

   const results = !searchTerm
      ? notificationList
      : notificationList.filter((item) =>
         item?.body.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuCommentId(null); // Close menu if clicked outside
      }
   };

   const toggleMenu = (commentId) => {
      if (menuCommentId === commentId) {
         setMenuCommentId(null); // Close menu if already open
      } else {
         setMenuCommentId(commentId); // Open menu for this comment
      }
   };

   const handleNavigate = (id, messageId, post_id) => {
      if (User_profile.id === id) {
         dispatch(postRead(messageId));
         return null;
      } else if (post_id) {
         return navigate(`/watchvideo/${post_id}`);
      } else {
         dispatch(postRead(messageId));
         return navigate(`/otheruseraccount/${id}`);
      }
   };

   useEffect(() => {
      if (grouppage === false) setEmptypage("");

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   return (
      <div className="p-2">
         {notificationList.length && (
            <div className="flex mb-2 justify-center items-center rounded-md overflow-hidden">
               <div className="bg-black flex py-1.5 w-full justify-center items-center rounded-md overflow-hidden">
                  <div className="w-[10%] flex justify-center">
                     <FaSearch className="cursor-pointer text-white" />
                  </div>
                  <div className="w-full mr-2">
                     <input
                        type="text"
                        onChange={handleChange}
                        placeholder={`Find Updates`}
                        className="w-full placeholder:text-sm font-medium outline-none px-1 py-[2px] rounded text-[#000]"
                     />
                  </div>
               </div>
            </div>
         )}
         {results.length ? (
            <div className="no-scrollbar ">
               <div className="overflow-scroll no-scrollbar">
                  {results?.map((item) => (
                     <div className="pt-2">
                        <div className="grid grid-cols-12 items-center">
                           <div className="col-span-2">
                              <img
                                 alt="img"
                                 src={item.created_by?.avatar_url}
                                 onClick={() => handleNavigate(item.created_by.id, item.id)}
                                 className="rounded-full h-8 w-8 object-cover cursor-pointer"
                              />
                           </div>
                           <div className="col-span-7 ml-[-10px]">
                              <p
                                 onClick={() => handleNavigate(item.created_by.id, item.id, item.post_id)}
                                 className="font-bold text-[10px] text-[#000] leading-3 cursor-pointer"
                              >
                                 {item.body}
                              </p>
                           </div>
                           <div className="col-span-2 flex items-center justify-center">
                              <p className="font-bold text-[8px] text-[#00A3FF]">
                                 {item?.time_since_created}
                              </p>
                           </div>
                           <div className="col-span-1 mr-[-6px] flex items-center justify-end">
                              <BsThreeDotsVertical
                                 onClick={() => toggleMenu(item?.id)}
                                 className="cursor-pointer text-black"
                              />
                           </div>
                           {menuCommentId === item?.id && (
                              <div
                                 ref={menuRef}
                                 className="absolute z-30 bg-[#000] w-[8rem] p-1 rounded right-3 -mt-[2.5rem] overflow-scroll no-scrollbar"
                              >
                                 <p className="flex items-center gap-2 mb-[3px] text-[#fff] hover:text-[#bf3131] text-[12px] font-bold cursor-pointer">
                                    <BsFillMicMuteFill className="text-[15px]" /> Mute
                                 </p>
                                 <p className="flex items-center gap-2 mb-[3px] text-[#fff] hover:text-[#bf3131] text-[12px] font-bold cursor-pointer">
                                    <MdHideSource className="text-[15px]" /> Hide updates
                                 </p>
                                 <p className="flex items-center gap-2 mb-[3px] text-[#fff] hover:text-[#bf3131] text-[12px] font-bold cursor-pointer">
                                    <TbDeviceMobileOff className="text-[15px]" /> Turn off Updates
                                 </p>
                              </div>
                           )}
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         ) : (
            <div className="h-full">
               <p className="h-[20%] text-[12px] font-bold text-[#000] mt-1">Today</p>
               <div className="h-[80%] flex justify-center items-center">
                  <p className="text-[10px] text-[#c0b8b8] -mt-16 font-semibold">
                     No Updates Yet
                  </p>
               </div>
            </div>
         )}
      </div>
   );
}
