import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import SecurityPage from "./pages/SecurityPage";
import SecurityPageSettings from "./pages/settings/SecurityPage";
import CommingSoonPage from "./pages/CommingSoonPage";
import WebLayout from "./Layouts/WebLayout";
import AccountPage from "./pages/AccountPage";
import AccountPageSettings from "./pages/settings/AccountPage";
import HashTagPage from "./pages/HashTagPage";
import LiveStreamPage from "./pages/LiveStreamPage";
import CreatedInduvitualGroup from "./pages/CreatedInduvitualGroup";
import CreatedGroupInfo from "./Component/CreatedGroup/CreatedGroupInfo";
import CreatedGroupUpdates from "./Component/CreatedGroup/CreatedGroupUpdates";
import CreatedGroupChatRoom from "./Component/CreatedGroup/CreatedGroupChatRoom";
import SearchResultPage from "./pages/SearchResultPage";
import CreatedGroupSetting from "./Component/CreatedGroup/CreatedGroupSetting";
import CreatedGroupMembers from "./Component/CreatedGroup/CreatedGroupMembers";
import Upload from "./Component/videopage/Upload";
import LostPassword from "./pages/LostPassword";
import EnterOTP from "./pages/EnterOTP";
import ChangePassword from "./pages/ChangePassword";
import Create from "./Component/videopage/Create";
import Error400 from "./pages/ErrorPages/Error400";
import Error401 from "./pages/ErrorPages/Error401";
import Error403 from "./pages/ErrorPages/Error403";
import Error404 from "./pages/ErrorPages/Error404";
import Error408 from "./pages/ErrorPages/Error408";
import Error503 from "./pages/ErrorPages/Error503";
import Error500 from "./pages/ErrorPages/Error500";
import Error501 from "./pages/ErrorPages/Error501";
import Error502 from "./pages/ErrorPages/Error502";
import ShopPage from "./pages/shop/ShopPage";
import ShopDetails from "./pages/shop/ShopDetails";
import Category from "./pages/shop/Category";
import WishlistPage from "./pages/shop/WishlistPage";
import BasketPage from "./pages/shop/BasketPage";
import AddressPage from "./pages/shop/AddressPage";
import PaymentPage from "./pages/shop/PaymentPage";
import OrderPlacedPage from "./pages/shop/OrderPlacedPage";
import OrderPage from "./pages/shop/OrderPage";
import OrderDetailsPage from "./pages/shop/OdrderDetailsPage";
import CreateGroupPage from "./pages/group/CreateGroupPage";
import InviteMemberPage from "./pages/group/InviteMemberPage";
import Updates from "./pages/Account/Updates";
import Repost from "./pages/Account/Repost";
import Saved from "./pages/Account/Saved";
import Private from "./pages/Account/Private";
import Liked from "./pages/Account/Liked";
import Group from "./pages/Account/Group";
import Posted from "./pages/Account/Posted";
import Following from "./pages/Account/Following";
import Follower from "./pages/Account/Follower";
import Posts from "./pages/Account/Posts";
import OtherUserAccount from "./pages/OtherUserAccount/OtherUserAccount";
import HashTagStore from "./pages/HashTag/HashTagStore";
import HashTagTrending from "./pages/HashTag/HashTagTrending";
import MessagesPageMobile from "./pages/MessagesPage";
import HashTagRecent from "./pages/HashTag/HashTagRecent";
import HashTagPopular from "./pages/HashTag/HashTagPopular";
import HashTagOtherUser from "./Component/HashTag/HashTagOtherUser";
import DraftNewMessages from "./pages/MessagesSub/DraftNewMessages";
import CreatedGroupSearch from "./Component/CreatedGroup/CreatedGroupSearch";
import CreatedGroupPost from "./Component/CreatedGroup/CreatedGroupPost";
import GroupChat from "./pages/MessagesSub/GroupChat";
import UserChat from "./pages/MessagesSub/UserChat";
import { useDispatch } from "react-redux";
import OrderCancel from "./Component/shoppage/OrderCancel";
import CreatedGroupModerators from "./Component/CreatedGroup/CreatedGroupModerators";
import OtherUserGroupsPage from "./pages/OtherUserGroupsPage";
import { AccountProfile } from "./Redux/ApiSlice/AccountProfileSlice";
import OtherGroupSearch from "./Component/OtherUserGroup/OtherGroupSearch";
import OtherGroupPost from "./Component/OtherUserGroup/OtherGroupPost";
import OtherGroupInfo from "./Component/OtherUserGroup/OtherGroupInfo";
import OtherGroupModerators from "./Component/OtherUserGroup/OtherGroupModerators";
import OtherGroupMember from "./Component/OtherUserGroup/OtherGroupMember";
import OtherGroupChatroom from "./Component/OtherUserGroup/OtherGroupChatroom";
import SavedFolderData from "./pages/Account/SavedFolderData";
import HelpMain from "./pages/Help/HelpMain";
import AfterInstallation from "./pages/AfterInstallation";
import ReadMoreAnswers from "./pages/Help/ReadMoreAnswers";
import Questions from "./pages/Help/Questions";
import MessagesProfileOtherUser from "./pages/MessagesSub/MessagesProfileOtherUser";
import ContactUsMain from "./pages/ContactUs/ContactUsMain";
import LoginPage from "./pages/Login/LoginPage";
import SignupPage from "./pages/SignUp/SignupPage";
import HomeMainPage from "./pages/HomeMainPage";
import { HomepageVideo } from "./Redux/ApiSlice/HomePageVideoSlice";
import CreateLiveStream from "./pages/LiveSream/CreateLiveStream";
import TrendingMain from "./pages/TrendingVideos/TrendingMain";
import UploadStream from "./pages/LiveSream/UploadStream";
import StreamSettings from "./pages/LiveSream/StreamSettings";
import ForgotPasswordContainer from "./pages/Account/Private/ForgotPasswordContainer";
import ResetPasswordContainer from "./pages/Account/Private/ResetPasswordContainer";
import SetPassword from "./pages/Account/Private/SetPassword";
import AccountLayOut from "./pages/Account/AccountLayOut";
import EnterOtpContainer from "./pages/Account/Private/EnterOtpContainer";
import Cookies from "./pages/Cookies";
import Media from "./pages/Media";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SettingsPage from "./pages/settings/SettingsPage";
import PreferencesPage from "./pages/settings/PreferencesPage";
import NotificationsPage from "./pages/settings/NotificationsPage";
import PrivacyPage from "./pages/settings/PrivacyPage";
import WhatsNewPage from "./pages/settings/WhatsNewPage";
import PrivateCreate from "./pages/Account/PrivateCreate";
import PrivateEmpty from "./pages/Account/Private/PrivateEmpty";
import WatchVideo from "./pages/WatchVideo/WatchVideo";
import GroupPostReport from "./pages/group/GroupPostReport";
import GroupPostComments from "./pages/group/GroupPostComments";
import MutualFriendsList from "./pages/MessagesSub/MutualFriendsList";
import OtherUserPostComments from "./pages/OtherUserGroup/OtherUserPostComments";
import OtherUserPostReport from "./pages/OtherUserGroup/OtherUserPostReport";
import Register from "./pages/SignUp/Register";
import OtherUserGroupList from "./pages/OtherUserGroup/OtherUserGroupList";
import ProfileEdit from "./pages/Account/ProfileEdit";
import AchivedMessages from "./pages/MessagesSub/AchivedMessages";
import DisplayPage from "./pages/settings/DisplayPage";
import PrivatePages from "./Routes/PrivatePages";
import SavedPosts from "./pages/group/SavedPosts";
import GroupSinglePost from "./pages/group/GroupSinglePost";
import HiddenPosts from "./pages/group/HiddenPosts";

import Neighborhood from "./pages/Neighborhood";
import PostCreate from "./pages/neighborhood/PostCreate";
import NewsDetails from "./pages/neighborhood/NewsDetails";
import NeighborSaved from "./pages/neighborhood/NeighborSaved";
import YourNeighborPost from "./pages/neighborhood/YourNeighborPost";
import NeighborInvite from "./pages/neighborhood/NeighborInvite";
import YourNeighbor from "./pages/neighborhood/YourNeighbor";
import NeighborLocation from "./pages/neighborhood/NeighborLocation";
import NeighborSettings from "./pages/neighborhood/NeighborSettings";
import PaymentDetails from "./Component/shoppage/PaymentDetails";
import Favorites from "./Component/shoppage/Favorites";
import SellProduct from "./Component/shoppage/SellProduct";
import Notification from "./pages/Notification";
import CreatedGroupInvite from "./Component/CreatedGroup/CreatedGroupInvite";
import CreatedGroupRequest from "./Component/CreatedGroup/CreatedGroupRequest";
import { ThemeProvider } from "./Context/ThemeContext";
import GroupListView from "./pages/group/GroupListView";
import NewsCreate from "./pages/neighborhood/NewsCreate";
import FollowSuggestion from "./pages/users/FollowSuggestion";
import RecommendedUser from "./pages/users/RecommendedUser";
import FriendshipSuggestion from "./pages/users/FriendshipSuggestion";
import ContentBasedUser from "./pages/users/ContentBasedUser";
import ResetPassword from "./Component/AccountPage/PrivateTab/ResetPassword";
import { PrivateTab } from "./Component/AccountPage/PrivateTab";


function App() {
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem("accessToken");
    const security_login = localStorage.getItem("security_login");

    const getData = async () => {
        if (accessToken && security_login) {
            // await dispatch(HomepageVideo());
            dispatch(AccountProfile());
        }
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <WebLayout>
                                <SecurityPage />
                            </WebLayout>
                        }
                    ></Route>

                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <LoginPage />
                                </WebLayout>
                            </PublicRoute>
                        }
                    ></Route>
                    <Route
                        path="/signup"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <SignupPage />
                                </WebLayout>
                            </PublicRoute>
                        }
                    ></Route>
                    <Route
                        path="/register"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <Register />
                                </WebLayout>
                            </PublicRoute>
                        }
                    ></Route>

                    {/* new router private route */}
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <PrivatePages />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path="/home"
                            element={
                                <WebLayout>
                                    <HomeMainPage />
                                </WebLayout>
                            }
                        />

                        <Route
                            path="/home/:id"
                            element={
                                <WebLayout>
                                    <HomeMainPage />
                                </WebLayout>
                            }
                        />
                        <Route
                            path="/watchvideo/:id"
                            element={
                                <WebLayout>
                                    <WatchVideo />
                                </WebLayout>
                            }
                        />
                    </Route>

                    {/* {/* account router start */}

                    <Route
                        path="/account"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <AccountPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/updates"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Updates />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/repost"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Repost />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/saved"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Saved />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/saved/folder/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <SavedFolderData />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/createpassword"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <PrivateCreate />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/private/empty"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <PrivateEmpty />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/private"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                <AccountLayOut>
                                    {/* <ResetPasswordContainer /> */}
                                       <PrivateTab />
                                    {/* <ResetPassword/> */}
                                    </AccountLayOut>
                                    {/* <Private /> */}
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/private/forgotpassword"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <ForgotPasswordContainer />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/private/resetpassword"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <AccountLayOut>
                                    {/* <ResetPasswordContainer /> */}
                                    <ResetPassword/>
                                    </AccountLayOut>
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/private/setPassword"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <AccountLayOut>
                                        <SetPassword />
                                    </AccountLayOut>
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/private/enter-otp"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    {/* <AccountLayOut> */}
                                    <EnterOtpContainer />
                                    {/* </AccountLayOut> */}
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/liked"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Liked />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/group"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Group />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        path="/account/post"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Posted />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/posted"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Posts />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/following"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Following />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/account/followers"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Follower />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        path="/account/edit/profile"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <ProfileEdit />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    {/* other users account pages */}
                    <Route
                        path="/otheruseraccount/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherUserAccount />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    {/* account router end */}
                    <Route
                        path="/mobileMessages"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <MessagesPageMobile />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/mobileMessages/draft"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <DraftNewMessages />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/mobileMessages/GroupChat/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <GroupChat />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/mobileMessages/userChat/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <UserChat />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/mobileMessages/user-detail/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <MessagesProfileOtherUser />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/mobileMessages/mutualfriend/list/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <MutualFriendsList />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/mobileMessages/achived"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <AchivedMessages />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        path="/LiveStream"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <LiveStreamPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createLiveStream"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreateLiveStream />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/upload-Stream"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <UploadStream />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/settings-Stream"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <StreamSettings />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/search"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <SearchResultPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    {/* hashtag page start */}
                    <Route
                        path="/hashtag"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HashTagPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/hashtag/store"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HashTagStore />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/hashtag/recent"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HashTagRecent />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/hashtag/otheruser"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HashTagOtherUser />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/hashtag/popular"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HashTagPopular />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/hashtag/trending"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HashTagTrending />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>

                    {/* hashtag page end */}

                    <Route
                        path="/createdgroupupdates"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupUpdates />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/chatroom/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupChatRoom />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/invite"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupInvite />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/moderators/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupModerators />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/member/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupMembers />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/created/group/:groupId/settings/"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupSetting />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/info"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupInfo />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedInduvitualGroup />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/search/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupSearch />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/post/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupPost />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/group/update/:groupId/post/:postId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupPost />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/:groupId/post/:postId/comment"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <GroupPostComments />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/:groupId/postreport/:postId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <GroupPostReport />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createdgroup/:groupId/saved/"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <SavedPosts />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/createdgroup/request/:groupId" // created group request
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreatedGroupRequest />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/createdgroup/:groupId/hidden/"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <HiddenPosts />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/group/:groupId/post/:postId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <GroupSinglePost />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/group/list-view"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <GroupListView />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />

                    {/* OtherUser Group */}
                    <Route
                        path="/othergroup/list/:userId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherUserGroupList />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherUserGroupsPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/search/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherGroupSearch />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/post"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherGroupPost />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/info"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherGroupInfo />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/moderators"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherGroupModerators />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/member"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherGroupMember />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/chatroom"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherGroupChatroom />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/othergroup/post/comment"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherUserPostComments />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/othergroup/postreport"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OtherUserPostReport />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        path="/uploadvideo"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Upload />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/createvideo"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Create />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    ></Route>

                    {/* Shop Page Routing Start */}
                    <Route
                        path="/Shop"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <ShopPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shopdetails"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <ShopDetails />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shopcategory"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Category />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/wishlist"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <WishlistPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/basket"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <BasketPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shopaddress"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <AddressPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shoppayment"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <PaymentPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shoporderplaced"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OrderPlacedPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shop/payment-details"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <PaymentDetails />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shop/favorites"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Favorites />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shop/sell-products"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <SellProduct />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/shop/favorites"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Favorites />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/order"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OrderPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    
                    <Route
                        path="/ordercancel"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OrderCancel />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/orderdetails"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <OrderDetailsPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />

                    {/* Group Page Routing Start */}
                    <Route
                        path="/createnewgroup"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreateGroupPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/createnewgroup/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <CreateGroupPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/invitemember/:groupId"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <InviteMemberPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    {/* Group Page Routing End */}

                    {/* Help Page  start */}

                    <Route
                        path="/help"
                        element={
                            <WebLayout>
                                <HelpMain />
                            </WebLayout>
                        }
                    />
                    <Route
                        path="/get-questions/:id"
                        element={
                            <WebLayout>
                                <Questions />
                            </WebLayout>
                        }
                    />
                    <Route
                        path="/readmore-answer"
                        element={
                            <WebLayout>
                                <ReadMoreAnswers />
                            </WebLayout>
                        }
                    />
                    {/* <Route path="/help-web" element={<HelpMain isFullScreen={true} />} /> */}
                    {/* <Route
                        path="/help/questions/:id"
                        element={<Questions isFullScreen={true} />}
                    />
                    <Route
                        path="/help/readmore-answer"
                        element={<ReadMoreAnswers isFullScreen={true} />}
                    /> */}

                    {/* Help page end */}
                    <Route
                        path="/contact-us"
                        element={
                            <WebLayout>
                                <ContactUsMain />
                            </WebLayout>
                        }
                    />
                    <Route
                        path="/contacts-us"
                        element={
                            <PublicRoute>
                                <ContactUsMain isWeb={true} />
                            </PublicRoute>
                        }
                    />
                    {/* Cookies page */}
                    <Route
                        path="/cookies"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Cookies />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route path="/cookie" element={<Cookies />} />
                    {/* media page */}
                    <Route
                        path="/media"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Media />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    {/* me page */}
                    <Route
                        path="/privacy-policy"
                        element={
                            <WebLayout>
                                <PrivacyPolicy />
                            </WebLayout>
                        }
                    />
                    <Route path="/privacy-policys" element={<PrivacyPolicy />} />
                    {/* terms of use page page */}
                    <Route
                        path="/terms-of-use"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <TermsOfUse />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route path="/terms-of-uses" element={<TermsOfUse />} />

                    {/* Trending Videos page */}
                    <Route
                        path="/trending-videos"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <TrendingMain />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    {/* settings page start */}
                    <Route path="/setting" element={<SettingsPage />} />
                    <Route
                        path="/settings"
                        element={
                            <WebLayout>
                                <SettingsPage />
                            </WebLayout>
                        }
                    />

                    <Route
                        path="/settings/account"
                        element={
                            <WebLayout>
                                <AccountPageSettings />
                            </WebLayout>
                        }
                    />
                    <Route
                        path="/settings/security"
                        element={
                            <WebLayout>
                                <SecurityPageSettings isMobile={true} />
                            </WebLayout>
                        }
                    />
                    <Route
                        path="/settings/privacy"
                        element={
                            <WebLayout>
                                <PrivacyPage isMobile={true} />
                            </WebLayout>
                        }
                    />
                    <Route
                        path="/settings/preferences"
                        element={
                            <WebLayout>
                                <PreferencesPage />
                            </WebLayout>
                        }
                    />

                    <Route
                        path="/settings/notifications"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NotificationsPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/notification"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Notification />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/settings/whatsnew"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <WhatsNewPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/settings/display"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <DisplayPage />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    {/* settings page end */}

                    <Route
                        path="/resetpassword"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <LostPassword />
                                </WebLayout>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/changepassword"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <ChangePassword />
                                </WebLayout>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/enterotp"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <EnterOTP />
                                </WebLayout>
                            </PublicRoute>
                        }
                    />
                    <Route path="/commingsoon" element={<CommingSoonPage />} />
                    <Route
                        path="/entrypage"
                        element={
                            <PublicRoute>
                                <WebLayout>
                                    <AfterInstallation />
                                </WebLayout>
                            </PublicRoute>
                        }
                    />
                    <Route path="/Error400" element={<Error400 />} />
                    <Route path="/Error401" element={<Error401 />} />
                    <Route path="/Error403" element={<Error403 />} />
                    <Route path="/Error404" element={<Error404 />} />
                    <Route path="/Error408" element={<Error408 />} />
                    <Route path="/Error503" element={<Error503 />} />
                    <Route path="/Error500" element={<Error500 />} />
                    <Route path="/Error501" element={<Error501 />} />
                    <Route path="/Error502" element={<Error502 />} />

                    {/* neighborhood section */}
                    <Route
                        path="/neighborhood"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <Neighborhood />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/pinboard/create"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <PostCreate />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/news/create"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NewsCreate />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/news/:id"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NewsDetails />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/saved"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NeighborSaved />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/posted"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <YourNeighborPost />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/invite"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NeighborInvite />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/your-neighbor"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <YourNeighbor />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/location"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NeighborLocation />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/neighborhood/setting"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <NeighborSettings />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user/recommended"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <RecommendedUser />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user/suggestion"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <FollowSuggestion />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user/suggestion/friendship"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <FriendshipSuggestion />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user/suggestion/content-based"
                        element={
                            <PrivateRoute>
                                <WebLayout>
                                    <ContentBasedUser />
                                </WebLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<h1>PAGE NOT FOUND</h1>} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
