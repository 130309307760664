export const neighborhoodNewsData = [
   {
      id: 1,
      firstName: "Sara",
      lastName: "Lee",
      userName: "Sara Lee",
      profileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Please help, I lost my dog!!",
      description: "This is my dog, his name is Max. He is a 2 year old golden retriever. He went missing yesterday around 5pm. If you see him, please contact me at 323-942-7890. Thank you!",
      postImage: "https://images.unsplash.com/photo-1422565096762-bdb997a56a84?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      time: "45 mins ago",
      location: "Downtown, Springfield",
      likes: 852,
      comments: 93,
      shares: 50
   },
   {
      id: 2,
      firstName: "John",
      lastName: "Smith",
      userName: "John Smith",
      profileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Community Cleanup Drive",
      description: "Join us for a community cleanup drive this Saturday. Let's work together to keep our neighborhood clean and green.",
      postImage: "https://images.prismic.io/pittsburgh-rushstreetgaming/f5c54e1c-0e23-4f66-8cd6-0e3212b2c439_rivers-gives-3.jpg?auto=compress,format",
      time: "5 hours ago",
      location: "Central Park",
      likes: 120,
      comments: 45,
      shares: 30
   },
   {
      id: 3,
      firstName: "Emily",
      lastName: "Johnson",
      userName: "Emily Johnson",
      profileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Neighborhood Watch Meeting",
      description: "Join us for a neighborhood watch meeting to discuss safety and security measures in our community.",
      postImage: "https://th.bing.com/th/id/R.88c80db9156f532d5f344c6db7132f62?rik=CKoXLH6%2f47KgnQ&riu=http%3a%2f%2fkrtnradio.com%2fwp%2fwp-content%2fuploads%2f2016%2f01%2fI-25-Neighborhood-Watch-Mtg-1-14-16_7286.jpg&ehk=KOiIRU%2blmTf4fLvO23Tqa30aKjTARtGv43n3kAqp%2b2E%3d&risl=&pid=ImgRaw&r=0",
      time: "2 days ago",
      location: "Community Center",
      likes: 85,
      comments: 30,
      shares: 25
   },
   {
      id: 4,
      firstName: "Michael",
      lastName: "Brown",
      userName: "Michael Brown",
      profileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Garden Workshop this Weekend",
      description: "Learn how to grow your own vegetables and herbs at our community garden workshop this weekend.",
      postImage: "https://esc-alsace.org/wp-content/uploads/2016/09/ESC-Picnique-B.jpg",
      time: "5 days ago",
      location: "Community Garden",
      likes: 95,
      comments: 40,
      shares: 35
   },
   {
      id: 5,
      firstName: "Lisa",
      lastName: "Wong",
      userName: "Lisa Wong",
      profileImage: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Hi guys, Library Book Sale!!",
      description: "Don't miss the community library book sale this weekend. Find great deals on a wide selection of books.",
      postImage: "https://www.cedarparktexas.gov/ImageRepository/Document?documentId=2309",
      time: "1 week ago",
      location: "Community Library",
      likes: 110,
      comments: 55,
      shares: 50
   },
   {
      id: 6,
      firstName: "Daniel",
      lastName: "Kim",
      userName: "Daniel Kim",
      profileImage: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Health Fair this Saturday",
      description: "Join us for a community health fair this Saturday. Get free health screenings, fitness tips, and more.",
      postImage: "https://assets.mnstatefair.org/_core/img/database/locations/health-fair-11/featured.2000x1125.jpg?v=20210629.224423",
      time: "2 weeks ago",
      location: "Community Center",
      likes: 75,
      comments: 35,
      shares: 30
   }
];

export const pinboardPost = [
   {
      id: 1,
      title: "Hey guys, BBQ at my house tomorrow",
      userName: "Lisa Wong",
      profileImage: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      desc: "Join us for a fun BBQ party at my place. We'll have lots of food, drinks, and games. Everyone is welcome!",
      postimage: "https://xyuandbeyond.com/wp-content/uploads/2022/05/bbq-1024x684.jpg"
   },
   {
      id: 2,
      title: "Looking for a babysitter this weekend",
      userName: "Daniel Kim",
      profileImage: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      desc: "I need a reliable babysitter for my 2 kids this weekend. They are 4 and 7 years old. Please contact me if you're available.",
      postimage: "https://cdn2.stylecraze.com/wp-content/uploads/2023/10/1066576061.png"
   },
   {
      id: 3,
      title: "Lost dog, please help find him",
      userName: "Sara Lee",
      profileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      desc: "Our beloved dog, Max, went missing yesterday in the downtown area. He's a brown Labrador with a blue collar. Please let us know if you see him.",
      postimage: "https://images.unsplash.com/photo-1422565096762-bdb997a56a84?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
   },
   {
      id: 4,
      title: "Community garage sale next Saturday",
      userName: "Michael Brown",
      profileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      desc: "We're organizing a community garage sale next Saturday. Come and find great deals on various items. Everyone is welcome to participate.",
      postimage: "https://101daysoforganization.org/wp-content/uploads/2021/02/shutterstock_1787043014.jpg"
   },
   {
      id: 5,
      title: "Yoga class in the park on Sunday",
      userName: "Emily Johnson",
      profileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      desc: "Join us for a free yoga class in the park this Sunday morning. All levels are welcome. Bring your own mat and water bottle.",
      postimage: "https://media.timeout.com/images/101855207/image.jpg"
   },
   {
      id: 6,
      title: "Anyone interested in a book club?",
      userName: "John Smith",
      profileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      desc: "I'm looking to start a book club in the neighborhood. If you're interested in reading and discussing books, please get in touch.",
      postimage: "https://i.ytimg.com/vi/4LXYI1V6EtM/maxresdefault.jpg"
   },
];

export const commentsData = [
   {
      id: 1,
      userName: "John Doe",
      userProfileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      timeAgo: "2 min ago",
      body: "I’m so sorry, Sara. I’ll check around my area and let you know if I see Max.",
      likes: 14,
      replies: [
         {
            id: 1,
            userName: "Sara Lee",
            userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "1 min ago",
            body: "Thank you, John! I really appreciate your help.",
         },
         {
            id: 2,
            userName: "Alice Smith",
            userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "Just now",
            body: "I’ll share this with my neighbors. Hoping for the best!",
         },
         {
            id: 3,
            userName: "Michael Brown",
            userProfileImage: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "Just now",
            body: "Let me know if there’s anything else I can do to help!",
         },
      ],
   },
   {
      id: 2,
      userName: "Emily Johnson",
      userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      timeAgo: "5 min ago",
      body: "Sara, I think I saw a golden retriever near Elm Street around 6pm yesterday. Could that be Max?",
      likes: 28,
      replies: [
         {
            id: 1,
            userName: "Sara Lee",
            userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "3 min ago",
            body: "That’s close to where he went missing! I’ll head there to check. Thank you, Emily!",
         },
         {
            id: 2,
            userName: "Jessica Lee",
            userProfileImage: "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "3 min ago",
            body: "That’s helpful, Emily. Let’s hope Sara finds him soon.",
         },
         {
            id: 3,
            userName: "Michael Brown",
            userProfileImage: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "2 min ago",
            body: "I’ll check Elm Street as well. It’s not far from me.",
         },
         {
            id: 4,
            userName: "Alice Smith",
            userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "1 min ago",
            body: "Elm Street is a good lead. Let’s keep looking there!",
         },
      ],
   },
   {
      id: 3,
      userName: "Alice Smith",
      userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      timeAgo: "10 min ago",
      body: "Have you checked the local shelters? Sometimes lost pets are taken there.",
      likes: 10,
      replies: [
         {
            id: 1,
            userName: "Sara Lee",
            userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "8 min ago",
            body: "Thanks, Alice. I’ve called a few shelters already, but I’ll keep checking.",
         },
         {
            id: 2,
            userName: "Emily Johnson",
            userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "5 min ago",
            body: "Great suggestion, Alice. Let’s keep the shelters informed.",
         },
      ],
   },

];

export const notificationsData = [
   {
      id: 1,
      name: "John Doe",
      category: "like",
      time: "2 hours ago",
      message: null,
      image: "https://cdn.pixabay.com/photo/2024/06/13/05/31/men-8826710_1280.jpg",
      postImage: "https://cdn.pixabay.com/photo/2024/12/07/17/47/autumn-9251331_960_720.jpg"
   },
   {
      id: 2,
      name: "Emily Johnson",
      category: "comment",
      time: "5 hours ago",
      message: "Great photo! I love the colors.",
      image: "https://cdn.pixabay.com/photo/2023/11/06/06/24/woman-8368918_1280.jpg",
      postImage: "https://cdn.pixabay.com/photo/2024/02/27/16/17/cat-8600417_960_720.jpg"
   },
   {
      id: 3,
      name: "Alice Smith",
      category: "like",
      time: "10 hours ago",
      message: null,
      image: "https://cdn.pixabay.com/photo/2018/01/22/07/31/portrait-3098319_1280.jpg",
      postImage: "https://cdn.pixabay.com/photo/2023/01/26/02/15/books-7744938_960_720.jpg"
   },
];

export const hashtagData = [
   { id: "1", hashtag: "#Technology", count: 1200 },
   { id: "2", hashtag: "#Health", count: 950 },
   { id: "3", hashtag: "#Entertainment", count: 2300 },
   { id: "4", hashtag: "#Automotive", count: 780 },
   { id: "5", hashtag: "#Fitness", count: 1100 },
   { id: "6", hashtag: "#Food", count: 1800 },
   { id: "7", hashtag: "#Travel", count: 1450 },
   { id: "8", hashtag: "#Fashion", count: 1650 },
   { id: "9", hashtag: "#Business", count: 890 },
   { id: "10", hashtag: "#Education", count: 720 }
]

export const homePagecommentsData = [
   {
      id: 1,
      userName: "John Doe",
      userProfileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      timeAgo: "2 min ago",
      body: "I’m so sorry, Sara. I’ll check around my area and let you know if I see Max.",
      likes: 14,
      replies: [
         {
            id: 1,
            userName: "Sara Lee",
            userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "1 min ago",
            body: "Thank you, John! I really appreciate your help.",
         },
         {
            id: 2,
            userName: "Alice Smith",
            userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "Just now",
            body: "I’ll share this with my neighbors. Hoping for the best!",
         },
         {
            id: 3,
            userName: "Michael Brown",
            userProfileImage: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "Just now",
            body: "Let me know if there’s anything else I can do to help!",
         },
      ],
   },
   {
      id: 2,
      userName: "Emily Johnson",
      userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      timeAgo: "5 min ago",
      body: "Sara, I think I saw a golden retriever near Elm Street around 6pm yesterday. Could that be Max?",
      likes: 28,
      replies: [
         {
            id: 1,
            userName: "Sara Lee",
            userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "3 min ago",
            body: "That’s close to where he went missing! I’ll head there to check. Thank you, Emily!",
         },
         {
            id: 2,
            userName: "Jessica Lee",
            userProfileImage: "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "3 min ago",
            body: "That’s helpful, Emily. Let’s hope Sara finds him soon.",
         },
         {
            id: 3,
            userName: "Michael Brown",
            userProfileImage: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "2 min ago",
            body: "I’ll check Elm Street as well. It’s not far from me.",
         },
         {
            id: 4,
            userName: "Alice Smith",
            userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "1 min ago",
            body: "Elm Street is a good lead. Let’s keep looking there!",
         },
      ],
   },
   {
      id: 3,
      userName: "Alice Smith",
      userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      timeAgo: "10 min ago",
      body: "Have you checked the local shelters? Sometimes lost pets are taken there.",
      likes: 10,
      replies: [
         {
            id: 1,
            userName: "Sara Lee",
            userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "8 min ago",
            body: "Thanks, Alice. I’ve called a few shelters already, but I’ll keep checking.",
         },
         {
            id: 2,
            userName: "Emily Johnson",
            userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            timeAgo: "5 min ago",
            body: "Great suggestion, Alice. Let’s keep the shelters informed.",
         },
      ],
   },

];