import React, { Fragment } from "react";
import FilterSideBar from "./FilterSideBar";
import { useNavigate } from "react-router-dom";

const InputButton = () => {
   const navigate = useNavigate();
   return (
      <Fragment>
         <div className="px-2 grid grid-cols-10 mt-4 mb-3">
            <div className="col-span-8">
               <input
                  placeholder="Search for products here…"
                  className="dark:bg-[#fff] bg-gray-200 rounded-3xl text-[#000] w-full py-1 px-3 focus:outline-none placeholder:text-[14px]"
               />
            </div>
            <div className="col-span-2 flex justify-end ">
               <FilterSideBar className="dark:text-[#fff] text-black" />
            </div>
         </div>
         <div className="flex items-center justify-between px-2 gap-2">
            <button className="w-[35%] bg-[#959494] dark:text-[#ffffff] text-[13px] px-5 md:px-2 py-1 rounded-full">
               Popular
            </button>
            <button className="w-[35%] bg-[#2e2e2e] dark:bg-[#ffffff] text-[#fff] dark:text-black text-[13px] px-5 md:px-2 py-1 rounded-full">
               Discounted
            </button>
            <button className="w-[35%] bg-[#2e2e2e] dark:bg-[#ffffff] text-[#fff] dark:text-black text-[13px] px-5 md:px-2 py-1 rounded-full">
               Favorites
            </button>
            <button
               onClick={() => navigate("/order")}
               className="w-[35%] bg-[#2e2e2e] dark:bg-[#ffffff] text-[#fff] dark:text-black text-[13px] px-5 md:px-2 py-1 rounded-full"
            >
               Orders
            </button>
         </div>
      </Fragment>
   );
};

export default InputButton;
