import React from "react";
import { groupSearchMedia } from "../../../Datas/Datas";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Media = ({
   MediaData, groupId
}

) => {
   const navigate = useNavigate();

   return (
      <div className="overflow-y-scroll">
         <div className="grid grid-cols-3">
            {MediaData?.map((data) => (
               <div className="col-span-1 p-1">
                  <div className="border border-gray-800 p-1" onclick={() => navigate(`/group/${groupId}/post/${data.id}`)}>
                     <p className="text-white leading-[13px] text-[13px] pb-1">{data.caption}</p>

                     {data.media.image_url && <img
                        alt="img"
                        className="h-16 w-full rounded object-cover"
                        src={data.media.image_url}
                     />}
                     <div className="w-full h-16"
                     >
                        {data.media.video_url && <ReactPlayer
                           width={"100%"}
                           height={"100%"}
                           controls={false}
                           muted

                           url={data.media.video_url}
                           className=" w-full h-full rounded object-cover cursor-pointer"

                        />}
                     </div>
                  </div>
                  <div className="py-1 flex gap-1 items-center">
                     <img
                        className="h-5 w-5 rounded-full object-cover"
                        src={data.user_details.profile_image}
                     />
                     <p className="text-white leading-[13px] text-[12px]">{data?.user_details?.first_name} {data?.user_details?.last_name}</p>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default Media