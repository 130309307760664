import React, { useCallback, useEffect, useState } from "react";
import HomePagePannel from "../Components/Panels/HomePagePanel";
import { VideoCardFooterIcons } from "../Datas/Datas";
import { useDispatch, useSelector } from "react-redux";
import {
    getHomePageVideo,
    setCurrentIndexfn,
    setPageNumber,
} from "../Redux/ApiSlice/HomePageVideoSlice";
import PostModal from "../Component/videopage/PostModal";
import { useNavigate, useParams } from "react-router-dom";
import ProfileAccountModal from "../Component/Modals/HomePageModal/ProfileAccountModal";
import SecretSauceButton from "../Component/SecretSauceButton/SecretSauceButton";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import Footer from "../Component/Footer/Footer";

export default function HomeMainPage() {
    const params = useParams();
    const { id } = params;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getAllPost = useSelector((state) => state.HomePageVideo);
    const { videoData, homePageVideos } = getAllPost;
    const [videoDataList, setVideoDataList] = useState([]);
    const [newUser, setNewUser] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(1);
    const [setLikeLikeEmoji] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [openBottom, setOpenBottom] = useState(false);
    const [openAccountBottom, setOpenAccountBottom] = useState(false);

    const openDrawerBottom = () => setOpenBottom(true);
    const closeDrawerBottom = () => setOpenBottom(false);
    const openDrawerAccountModal = () => setOpenAccountBottom(!openAccountBottom);

    useEffect(() => {
        setNewUser(videoData?.new_user);
    }, [homePageVideos]);

    // Detect screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        handleResize(); // Check on initial render
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Fetch initial data on mount
    useEffect(() => {

        if (Boolean(videoData.next) || getAllPost.currentIndex !== 0) {
            setCurrentIndex(getAllPost.currentIndex);
            setVideoDataList(homePageVideos);
            setPageCount(getAllPost.pageNumber);
        }
        else {
            getDataForPaginated(pageCount);
        }
    }, []); // Empty dependency array ensures this runs only once on mount

    // Fetch data for paginated pages
    const getDataForPaginated = useCallback(async (count) => {
        console.log("Fetching data for page:", count);
        setLoading(true);
        try {
            const response = await dispatch(getHomePageVideo(count));
            if (Array.isArray(response?.payload?.post)) {
                setVideoDataList((prev) => [...prev, ...response.payload.post] || []);
            }
            return response.payload.post;
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    // Handle next video
    console.log(videoDataList, "videoDataList")
    const handleNext = async () => {
        if (currentIndex === videoDataList.length - 1) {
            // If it's the last video and there's a next page, fetch more data
            if (Boolean(videoData.next)) {
                const newData = await getDataForPaginated(pageCount + 1);
                if (newData.length > 0) {
                    setPageCount((prev) => prev + 1);
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                    dispatch(setPageNumber(pageCount + 1))
                }
            }
        } else {
            // Move to the next video in the list
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
        setDirection(1);
    };

    // Handle previous video
    const handlePrev = () => {
        if (currentIndex === 0) return;
        setCurrentIndex((prevIndex) => prevIndex - 1);
        setDirection(-1);
    };

    const handleNavigate = (value) => {
        const replaceValue = value.replaceAll(" ", "");
        switch (replaceValue) {
            case "Messages":
                return navigate(`/mobileMessages`);
            case "Createvideo":
                return openDrawerBottom();
            case "Home":
                const handleRefresh = () => {
                    setPageCount(1);
                    dispatch(setPageNumber(1))
                    setVideoDataList([]);
                    getDataForPaginated(1);
                    setCurrentIndex(0);
                };
                return handleRefresh();
            case "Neighborhood":
                return navigate(`/neighborhood`);
            case "Account":
                // return openDrawerAccountModal();
                return navigate(`/account`);
            case "YourFriends":
                return reloadPage();
            case "YourCC":
                return navigate(`/commingsoon`);
            case "Helmet1":
                return navigate(`/commingsoon`);
            case "Groups":
                return navigate(`/othergroup/list/`);
            default:
                // navigate(`/${replaceValue}`);
                break;
        }
    };

    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
                handlePrev();
            } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
                if (loading) return;
                handleNext();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        dispatch(setCurrentIndexfn(currentIndex));

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentIndex, videoDataList, loading]);

    return (
        <>
            <div className="hidden md:flex h-full text-center items-center md:w-[10%] mr-4">
                <button
                    disabled={currentIndex === 0}
                    onClick={handlePrev}
                    className="w-20 top-2/4 h-16 bg-blue-gray-200 hover:bg-[#b3acac] rounded-full items-center text-center justify-center flex"
                >
                    <BiSolidLeftArrow />
                </button>
            </div>

            <div className="md:w-[53%] md:h-[88vh] w-full bg-black border-black md:border-y-[1px] relative md:rounded-[20px] md:border-x-[1px] overflow-hidden">
                <div className="h-[85%] rounded-none relative">
                    <HomePagePannel
                        AllPosts={videoDataList}
                        id={id}
                        newUser={newUser}
                        setCurrentIndex={setCurrentIndex}
                        currentIndex={currentIndex}
                        setDirection={setDirection}
                        direction={direction}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        isMobile={isMobile}
                    />
                </div>

                <SecretSauceButton />

                <div className="h-[5.5vh] bg-black dark:bg-black">
                    <Footer
                        isFixedDark={true}
                        handleNavigate={handleNavigate}
                        openDrawerBottom={openDrawerBottom}
                        VideoCardHeaderIcons={VideoCardFooterIcons}
                    />
                </div>

                <PostModal
                    openBottom={openBottom}
                    openDrawerBottom={openDrawerBottom}
                    closeDrawerBottom={closeDrawerBottom}
                />

                <ProfileAccountModal
                    openBottom={openAccountBottom}
                    openDrawerBottom={openDrawerAccountModal}
                    closeDrawerBottom={openDrawerAccountModal}
                />
            </div>

            <div className="hidden md:flex h-full text-center items-center md:w-[10%] ml-4">
                <button
                    disabled={loading}
                    className="w-20 top-2/4 h-16 right-0 bg-blue-gray-200 hover:bg-[#b3acac] rounded-full items-center text-center justify-center flex"
                    onClick={handleNext}
                >
                    <BiSolidRightArrow />
                </button>
            </div>
        </>
    );
}