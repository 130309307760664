import React, { useEffect, useRef, useState } from "react";
import { LuDot } from "react-icons/lu";
import { RiEditFill } from "react-icons/ri";
import { MdDeleteSweep } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { Drawer } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { IoSend, IoCloseCircle } from "react-icons/io5";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import { BiSolidMessage, BiSolidReport } from "react-icons/bi";
import { HomepageVideoReplayCommentDelete, } from "../../Redux/ApiSlice/HomePageVideoSlice";

export default function Comments(props) {
    const {
        openBottomComments,
        closeDrawerBottomCommments,
        // comments=[],
        handleChangeComment,
        commentPrev,
        handleChangeReply,
        menuCommentId,
        emptyComments,
        menuRef,
        created_by,
        handleChangeEditComment,
        toggleMenu,
        handleChangeDeleteComment,
        setMenuCommentId,
        originalURL,
        createCommet,
        handleSubmitComment,
        handleChangeRepleyComment,
        repleyitemId,
        handleOpenreplay,
        replay,
        setReplay,
        setReportModal,
        handleSubmitReplay,
        createReplay,
        handleCloseReply,
        EditComment,
        closeEditComment
    } = props;

    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const post_created_by = created_by;
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const comments = useSelector((state) => state.comments);
    const [commentsData, setCommentsData] = useState([])
    const [menuReplyId, setMenuReplyId] = useState(null);

    const { User_profile } = ProfileDetails;

    const handleDeleteComment = async (Id) => {
        await dispatch(HomepageVideoReplayCommentDelete(Id));
    };

    const focusInput = () => {
        inputRef.current.focus();
    };

    const handleGoAccount = (UserId) => {
        if (User_profile.id === UserId) {
            navigate(`/account`)
        }
        else {
            navigate(`/otheruseraccount/${UserId}`)
        }
    }

    const reportComment = () => {
        closeDrawerBottomCommments()
        setReportModal(true)
    }

    function timeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);

        const intervals = {
            year: 31536000, // 60 * 60 * 24 * 365
            month: 2592000, // 60 * 60 * 24 * 30
            day: 86400,     // 60 * 60 * 24
            hour: 3600,     // 60 * 60
            minute: 60,
            second: 1
        };

        for (const key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval}${key[0]} ago`;
            }
        }

        return "just now";  // In case it's less than a second ago
    }

    useEffect(() => {
        setCommentsData(comments.postCommants)
    }, [comments])

    useEffect(() => {
        if (replay) {
            focusInput();
        }
        if (menuCommentId) {
            window.scrollTo(0, 0);
        }
        // return () => setReplay(false);
    }, [replay, menuCommentId, setReplay]);

    // NEW DESIGN FOR COMMENTS
    const [likedComments, setLikedComments] = useState([]);

    const handleLike = (commentId) => {
        setLikedComments((prev) =>
            prev.includes(commentId) ? prev.filter(id => id !== commentId) : [...prev, commentId]
        );
    };

    const [activeCommentId, setActiveCommentId] = useState(null);

    const toggleMenus = (commentId) => {
        setActiveCommentId(activeCommentId === commentId ? null : commentId);
    };

    const toggleReplyMenu = (replyId) => {
        setMenuReplyId(menuReplyId === replyId ? null : replyId);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuReplyId(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <Drawer
            placement="bottom"
            open={openBottomComments}
            onClose={closeDrawerBottomCommments}
            size={window.width < 500 ? 320 : 300}
            className={`absolute w-full ${!openBottomComments && "hidden"}`}
            style={{ zIndex: 1 }}
        >
            <div className="bg-white dark:bg-black h-full w-full pb-10 relative">
                <div className="bg-white dark:bg-black dark:text-white pt-0 w-full h-full overflow-y-auto no-scrollbar">
                    {commentsData?.length !== 0 &&
                        <div className="h-full w-full">
                            {commentsData?.map((data) => {
                                return (
                                    <div key={data?.id}>
                                        <div className="py-2 px-2 border-b border-gray-300 dark:border-gray-800 relative">
                                            <div className="flex items-start">
                                                <div className="h-7 w-10">
                                                    <img
                                                        alt="profile"
                                                        src={data?.created_by?.avatar_url}
                                                        onClick={() => handleGoAccount(data?.created_by?.id)}
                                                        className="h-7 w-7 rounded-full object-cover bg-gray-300 dark:bg-gray-800 cursor-pointer"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <div className="flex items-start justify-between">
                                                        <div className="flex items-start gap-x-2 relative">
                                                            <div className="flex flex-col">
                                                                <p
                                                                    onClick={() => handleGoAccount(data?.created_by?.id)}
                                                                    className="text-[11px] font-bold line-clamp-1 cursor-pointer"
                                                                >
                                                                    {data?.created_by?.first_name} {data?.created_by?.last_name}
                                                                </p>
                                                                <div className="flex items-center gap-x-[1px]">
                                                                    <p className="text-[8px] text-gray-500 line-clamp-1">
                                                                        {timeAgo(data?.created_at)}
                                                                    </p>
                                                                    {post_created_by?.id === data?.created_by?.id &&
                                                                        <LuDot className="text-[10px] text-gray-500" />
                                                                    }
                                                                    <p className="text-[8px] text-gray-500 line-clamp-1">
                                                                        {post_created_by?.id === data?.created_by?.id && "Author"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <HiDotsVertical
                                                                onClick={() => toggleMenu(data?.id)}
                                                                className="text-[12px] cursor-pointer text-gray-600 mt-0.5"
                                                            />
                                                            {menuCommentId === data?.id && (
                                                                <div
                                                                    ref={menuRef}
                                                                    className="absolute top-0 -right-14 w-auto bg-white border border-gray-400 overflow-hidden rounded shadow-lg"
                                                                >
                                                                    <p
                                                                        onClick={() => handleChangeRepleyComment(data)}
                                                                        className="px-1.5 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer flex items-center gap-x-1 whitespace-nowrap"
                                                                    >
                                                                        <BiSolidMessage className="mt-[2px]" /> Reply
                                                                    </p>
                                                                    {User_profile.id !== data.created_by.id && (
                                                                        <p
                                                                            onClick={() => reportComment()}
                                                                            className="px-1.5 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer flex items-center gap-x-1 whitespace-nowrap"
                                                                        >
                                                                            <BiSolidReport className="mt-[-0.5px]" /> Report
                                                                        </p>
                                                                    )}
                                                                    {User_profile.id === data.created_by.id &&
                                                                        <>
                                                                            <p
                                                                                onClick={() => handleChangeEditComment(data)}
                                                                                className="px-1.5 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer flex items-center gap-x-1 whitespace-nowrap"
                                                                            >
                                                                                <RiEditFill /> Edit
                                                                            </p>
                                                                            <p
                                                                                onClick={() => handleChangeDeleteComment(data?.id)}
                                                                                className="px-1.5 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer flex items-center gap-x-1 whitespace-nowrap"
                                                                            >
                                                                                <MdDeleteSweep className="mt-[-1px]" /> Delete
                                                                            </p>
                                                                        </>
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="flex items-center gap-x-3 mr-[1.5px] mt-[3px]">
                                                            <div className="flex flex-col items-center mr-0.5">
                                                                <FaRegHeart className="text-[10px] text-gray-600 cursor-pointer" />
                                                                <p className="text-[8px] text-gray-500">0</p>
                                                            </div>
                                                            <div className="flex flex-col items-center">
                                                                <FaRegComment
                                                                    onClick={() => handleChangeRepleyComment(data)}
                                                                    className="text-[10px] text-gray-600 cursor-pointer"
                                                                />
                                                                <p className="text-[8px] text-gray-500">{data?.replies?.length}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="text-[10px] mt-[3px] break-words leading-[14px]">
                                                        {data?.post_comment_box}
                                                    </p>

                                                    {data?.replies?.length > 0 &&
                                                        <div className="h-[0.5px] bg-gray-300 dark:bg-gray-800 w-[86%] mt-1.5" />
                                                    }

                                                    {data?.replies.map((reply) => (
                                                        <div key={reply.id} className="flex w-full mt-1.5">
                                                            <div className="h-7 w-10">
                                                                <img
                                                                    alt="User"
                                                                    src={reply.created_by.avatar_url}
                                                                    onClick={() => handleGoAccount(reply.created_by.id)}
                                                                    className="h-7 w-7 object-cover rounded-full cursor-pointer bg-gray-300 dark:bg-gray-800"
                                                                />
                                                            </div>
                                                            <div className="flex items-start justify-between w-full">
                                                                <div className="flex flex-col w-fit">
                                                                    <div className="flex items-start gap-x-2 relative">
                                                                        <p className="text-[11px] font-bold line-clamp-1">
                                                                            {reply.created_by.first_name} {reply.created_by.last_name}
                                                                        </p>
                                                                        <HiDotsVertical
                                                                            onClick={() => toggleReplyMenu(reply?.id)}
                                                                            className="text-[12px] cursor-pointer text-gray-600 mt-0.5"
                                                                        />
                                                                        {menuReplyId === reply?.id && (
                                                                            <div
                                                                                ref={menuRef}
                                                                                className="absolute top-0 -right-12 w-auto bg-white border border-gray-400 overflow-hidden rounded shadow-lg"
                                                                            >
                                                                                <p className="px-1.5 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer flex items-center gap-x-1 whitespace-nowrap">
                                                                                    <BiSolidMessage className="mt-[2px]" /> Reply
                                                                                </p>
                                                                                {reply.created_by.id === data?.created_by?.id &&
                                                                                    <p className="px-1.5 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer flex items-center gap-x-1 whitespace-nowrap">
                                                                                        <BiSolidReport className="mt-[-0.5px]" /> Report
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex items-center gap-x-[1px]">
                                                                        <p className="text-[8px] mt-[-2px] text-gray-500 line-clamp-1">
                                                                            {timeAgo(reply.created_at)}
                                                                        </p>
                                                                        {reply.created_by.id === data?.created_by?.id &&
                                                                            <LuDot className="text-[10px] text-gray-500" />
                                                                        }
                                                                        <p className="text-[8px] mt-[-2px] text-gray-500 line-clamp-1">
                                                                            {reply.created_by.id === data?.created_by?.id && "Commenter"}
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-[10px] mt-[3px] break-words leading-[14px]">
                                                                        {reply.post_comment_box}
                                                                    </p>
                                                                </div>
                                                                <div className="flex items-center gap-x-3 mr-[1.5px] mt-[3px]">
                                                                    <div className="flex flex-col items-center mr-0.5">
                                                                        <FaRegHeart className="text-[10px] text-gray-600 cursor-pointer" />
                                                                        <p className="text-[8px] text-gray-500">0</p>
                                                                    </div>
                                                                    <div className="flex flex-col items-center">
                                                                        <FaRegComment className="text-[10px] text-gray-600 cursor-pointer" />
                                                                        <p className="text-[8px] text-gray-500">0</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {commentsData?.length === 0 && (
                        <div className="flex flex-col justify-center items-center">
                            <p className="text-[12px] text-gray-300 dark:text-gray-400 mt-3">
                                No comments yet. Be the first to comment
                            </p>
                            <img alt="profile" src={emptyComments} className="h-[13rem] mt-3" />
                        </div>
                    )}
                </div>

                {!repleyitemId ?
                    EditComment ?
                        <div className="px-2 absolute bottom-[4px] z-50 flex flex-col bg-white dark:bg-black w-full pt-1 border-t border-gray-300 dark:border-gray-800">
                            <p className="text-[10px] text-blue-700 font-bold">Edit your Comment</p>
                            <div className="py-2 w-full  relative">
                                <div className="flex items-start">
                                    <div className="h-7 w-10">
                                        <img
                                            alt="profile"
                                            src={commentPrev?.created_by?.avatar_url}
                                            onClick={() => handleGoAccount(commentPrev?.created_by?.id)}
                                            className="h-7 w-7 rounded-full object-cover bg-gray-300 dark:bg-gray-800"
                                        />
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="flex items-start justify-between">
                                            <div className="flex flex-col items-start gap-x-2 relative w-full">
                                                <div className="flex flex-col w-full">
                                                    <div className="flex justify-between items-center w-full" >
                                                        <p
                                                            onClick={() => handleGoAccount(commentPrev?.created_by?.id)}
                                                            className="text-[11px] font-bold line-clamp-1 cursor-pointer text-black dark:text-white"
                                                        >
                                                            {commentPrev?.created_by?.first_name} {commentPrev?.created_by?.last_name}
                                                        </p>
                                                        <IoCloseCircle 
                                                            onClick={closeEditComment}
                                                            className="text-red-500 cursor-pointer text-[18px] hover:text-red-700"
                                                        />
                                                    </div>
                                                    <p className="text-[8px] text-gray-500 mt-[-2px] line-clamp-1">
                                                        {timeAgo(commentPrev.created_at)}
                                                    </p>
                                                </div>
                                                <p className="text-[10px] mt-[3px] break-words leading-[14px] text-black dark:text-white">
                                                    {commentPrev?.post_comment_box}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="w-full z-50  flex">
                                <div className="h-8 w-11">
                                    <img
                                        alt="profile"
                                        src={originalURL}
                                        className="h-8 w-8 rounded-full object-cover bg-gray-300 dark:bg-gray-800"
                                    />
                                </div>
                                <div className="flex justify-start w-full relative">
                                    <input
                                        type="text"
                                        ref={inputRef}
                                        value={createCommet.body}
                                        onChange={handleChangeComment}
                                        placeholder="your Reply"
                                        className="text-[14px] bg-gray-200 w-full text-[#000] pl-2 pr-7 py-1 outline-none rounded placeholder:text-[14px]"
                                    />
                                    <IoSend
                                        onClick={handleSubmitComment}
                                        className="absolute right-1 top-[6px] text-blue-600 hover:text-blue-700 text-[18px] cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div> 
                        :
                        <div className="px-2 absolute bottom-[4px] z-50 flex items-center w-full pt-1 border-t border-gray-300 dark:border-gray-800">
                            <div className="h-8 w-11">
                                <img
                                    alt="profile"
                                    src={originalURL}
                                    className="h-8 w-8 rounded-full object-cover bg-gray-300 dark:bg-gray-800"
                                />
                            </div>
                            <div className="flex justify-start w-full relative">
                                <input
                                    type="text"
                                    ref={inputRef}
                                    value={createCommet.body}
                                    onChange={handleChangeComment}
                                    placeholder="Type your comment"
                                    className="text-[14px] bg-gray-200 w-full text-[#000] pl-2 pr-7 py-1 outline-none rounded placeholder:text-[14px]"
                                />
                                <IoSend
                                    onClick={handleSubmitComment}
                                    className="absolute right-1 top-[6px] text-blue-600 hover:text-blue-700 text-[18px] cursor-pointer"
                                />
                            </div>
                        </div>
                    :
                    <div className="px-2 absolute bottom-[4px] z-50 flex flex-col bg-white dark:bg-black w-full pt-1.5 border-t border-gray-300 dark:border-gray-800">
                        <div className="pb-2 w-full  relative">
                            <div className="flex items-start">
                                <div className="h-7 w-10">
                                    <img
                                        alt="profile"
                                        src={commentPrev?.created_by?.avatar_url}
                                        onClick={() => handleGoAccount(commentPrev?.created_by?.id)}
                                        className="h-7 w-7 rounded-full object-cover bg-gray-300 dark:bg-gray-800"
                                    />
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col items-start gap-x-2 relative w-full">
                                            <div className="flex flex-col w-full">
                                                <div className="flex justify-between items-center w-full" >
                                                    <p
                                                        onClick={() => handleGoAccount(commentPrev?.created_by?.id)}
                                                        className="text-[11px] font-bold line-clamp-1 cursor-pointer text-black dark:text-white"
                                                    >
                                                        {commentPrev?.created_by?.first_name} {commentPrev?.created_by?.last_name}
                                                    </p>
                                                    <IoCloseCircle
                                                        onClick={handleCloseReply}
                                                        className="text-red-500 cursor-pointer text-[18px] hover:text-red-700"
                                                    />
                                                </div>
                                                <p className="text-[8px] text-gray-500 mt-[-2px] line-clamp-1">
                                                    {timeAgo(commentPrev.created_at)}
                                                </p>
                                            </div>
                                            <p className="text-[10px] mt-[3px] break-words leading-[14px] text-black dark:text-white">
                                                {commentPrev?.post_comment_box}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full z-50  flex">
                            <div className="h-8 w-11">
                                <img
                                    alt="profile"
                                    src={originalURL}
                                    className="h-8 w-8 rounded-full object-cover bg-gray-300 dark:bg-gray-800"
                                />
                            </div>
                            <div className="flex justify-start w-full relative">
                                <input
                                    type="text"
                                    ref={inputRef}
                                    value={createReplay.body}
                                    onChange={handleChangeReply}
                                    placeholder="Type your Reply"
                                    className="text-[14px] bg-gray-200 w-full text-[#000] pl-2 pr-7 py-1 outline-none rounded placeholder:text-[14px]"
                                />
                                <IoSend
                                    onClick={handleSubmitReplay}
                                    className="absolute right-1 top-[6px] text-blue-600 hover:text-blue-700 text-[18px] cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Drawer>
    );
}