import {
   Tabs,
   TabsHeader,
   TabsBody,
   Tab,
   TabPanel,
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
   Typography,
} from "@material-tailwind/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   AccountProfile,
   AccountProfileNameEdit,
} from "../../../Redux/ApiSlice/AccountProfileSlice";

const AccountNameEditModal = ({
   theme,
   open,
   handleOpen,
   User_profile,
   loading,
   ProfileName,
   setProfileName,
}) => {
   const dispatch = useDispatch();
   const handleChange = (e) => {
      setProfileName((prev) => ({ ...prev, [e.target.name]: e.target.value }));
   };
   const handleSubmit = async () => {
      await dispatch(AccountProfileNameEdit(ProfileName, User_profile.id));
      dispatch(AccountProfile());
      handleOpen();
   };

   return (
      <Dialog
         theme={
            window.innerWidth < 500
               ? theme.dialog.styles.sizes.sm
               : theme.dialog.styles.sizes.md
         }
         open={open}
         handler={handleOpen}
         className=" h-50% absolute top-[8%]"
      >
         <DialogHeader className="flex justify-center flex-col gap-3">
            <Typography className=" md:w-2/4 rounded-md flex justify-center flex-col ">
               <label className="text-[14px] font-bold text-[#000]">
                  First Name
               </label>
               <input
                  type="text"
                  value={ProfileName.first_name}
                  name="first_name"
                  onChange={handleChange}
                  className={"border rounded-md p-2 focus:outline-none focus:border-gray-800 border-gray-400"}
               />
            </Typography>
            <Typography className=" md:w-2/4 rounded-md flex justify-center flex-col">
               <label className="text-[14px] font-bold text-[#000]">Last Name</label>
               <input
                  type="text"
                  value={ProfileName.last_name}
                  name="last_name"
                  onChange={handleChange}
                  class="border rounded-md p-2 focus:outline-none focus:border-gray-800 border-gray-400"
               />
            </Typography>
         </DialogHeader>

         <DialogFooter className="flex justify-center">
            <div className="justify-around flex w-full md:w-2/4">
               <Button
                  variant="text"
                  color="red"
                  onClick={handleSubmit}
                  className="bg-black w-[30%] text-[#fff] p-3 hover:bg-[#141414]"
               >
                  Save
               </Button>
               <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="bg-black w-[30%] p-3 text-[#fff] hover:bg-[#e93f3f]"
               >
                  Cancel
               </Button>
            </div>
         </DialogFooter>
         <div className="flex justify-center flex-col items-center w-full">
            {/* <span className="h-full text-[#ff3b3b] font-bold text-[10px] flex items-center">
               CC EDIT ACCOUNT NAME EDIT - FE - V2 - JUNE 26, 2024
            </span> */}
            <p className="text-base font-bold text-[#ff3b3b] text-[12px]">
            Copyright © 2025 Galileo One Inc
            </p>
         </div>
      </Dialog>
   );
};

export default AccountNameEditModal;
