import React, { Fragment, useState, useRef, useEffect } from "react";
import {
    RiCloseCircleLine,
    RiVideoUploadFill,
    RiVideoAddFill,
    RiAdvertisementLine,
} from "react-icons/ri";
import { useDispatch } from "react-redux";
import { CiStreamOn } from "react-icons/ci";
import Carousel from "react-multi-carousel";
import { MdGroupAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RiFolderVideoFill } from "react-icons/ri";
import { Drawer, Typography } from "@material-tailwind/react";
import { Uploadvideo } from "../../Redux/ApiSlice/UploadVideoSlice";
import { UploadVideoWaringModal } from "../Modals/UploadVideo/UploadVideoWaringModal";

const PostModal = ({ openDrawerBottom, closeDrawerBottom, openBottom }) => {
    const dispatch = useDispatch();
    const [selectedImg, setSelectedImg] = useState(null);
    const [selectVideo, setSelectVideo] = useState(null);
    const [waringOpen, setWaringOpen] = useState(false);

    const navigate = useNavigate();
    const fileRef = useRef(null);

    const handleOpen = () => setWaringOpen(!waringOpen);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;
        const fileUrl = URL.createObjectURL(file);

        if (file.size > 100e6) {
            return handleOpen();
        } else {
            setSelectedImg(fileUrl);
            setSelectVideo(file);
            dispatch(Uploadvideo({ fileURL: fileUrl, file }));
        }
    };

    // const handleNavigate = (value) => {
    //     navigate(`${value}`);
    //     closeDrawerBottom();
    // };

    const handleNavigate = (value) => {
        if (value.startsWith("http")) {
            window.open(value, "_blank");
        } else {
            navigate(`${value}`);
        }
        closeDrawerBottom();
    };

    useEffect(() => {
        if (selectVideo) {
            navigate("/uploadvideo");
            closeDrawerBottom();
        }
        return () => {
            closeDrawerBottom();
        };
    }, [selectVideo]);


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1
        }
    };

    return (
        <Fragment>
            {openBottom && (
                <Drawer
                    placement="bottom"
                    open={openBottom}
                    style={{ zIndex: 1000 }}
                    onClose={closeDrawerBottom}
                    size={window.screen.width > 500 ? 220 : 250}
                    className="px-0 py-3 w-full  max-h-20 md:max-h-20 absolute bg-white dark:bg-black border-t border-gray-200 dark:border-gray-900"
                >
                    <div className="mb-4 md:mb-4 px-4 flex items-center  justify-center">
                        <Typography variant="h5" className="text-black dark:text-white">
                            Create
                        </Typography>
                        <RiCloseCircleLine
                            onClick={closeDrawerBottom}
                            className="text-[23px] absolute right-2 cursor-pointer text-black dark:text-white"
                        />
                    </div>
                    <Carousel
                        ssr={true}
                        showDots={false}
                        infinite={false}
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                    >
                        <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
                            <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                                <RiFolderVideoFill
                                    onClick={() => handleNavigate("/account/saved")}
                                    className="text-[3rem] md:text-[2rem] text-black dark:text-white"
                                />
                                <div
                                    onClick={() => handleNavigate("/account/saved")}
                                    className="text-[11px] md:text-[9px] text-center text-black dark:text-white"
                                >
                                    My Drafts
                                </div>
                            </div>
                            <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                                <MdGroupAdd
                                    onClick={() => handleNavigate("/createnewgroup")}
                                    className="text-[3rem] md:text-[2rem] text-black dark:text-white"
                                />
                                <div
                                    onClick={() => handleNavigate("/createnewgroup")}
                                    className="text-[11px] md:text-[9px] text-center text-black dark:text-white"
                                >
                                    Create Group
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
                            <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                                <RiVideoAddFill
                                    onClick={() => handleNavigate("/createvideo")}
                                    className="text-[3rem] md:text-[2rem] text-black dark:text-white"
                                />
                                <div
                                    onClick={() => handleNavigate("/createvideo")}
                                    className="text-[11px] md:text-[9px] text-center text-black dark:text-white"
                                >
                                    Create Video
                                </div>
                            </div>

                            <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                                <RiVideoUploadFill
                                    onClick={() => { fileRef.current?.click() }}
                                    className="text-[3rem] md:text-[2rem] text-black dark:text-white"
                                />
                                <div className="text-[11px] md:text-[9px] text-center text-black dark:text-white">
                                    Upload Video
                                </div>
                                <input
                                    type="file"
                                    ref={fileRef}
                                    accept="video/*"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
                            <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                                <CiStreamOn
                                    onClick={() => handleNavigate("/createLiveStream")}
                                    className="text-[3rem] md:text-[2rem] text-black dark:text-white"
                                />
                                <div
                                    onClick={() => handleNavigate("/createLiveStream")}
                                    className="text-[11px] md:text-[9px] text-center text-black dark:text-white"
                                >
                                    Live Stream
                                </div>
                            </div>
                            <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                                <RiAdvertisementLine
                                    onClick={() => handleNavigate("https://spartanadvertisingnetwork.com")}
                                    className="text-[3rem] md:text-[2rem] text-black dark:text-white"
                                />
                                <div
                                    onClick={() => handleNavigate("https://spartanadvertisingnetwork.com")}
                                    className="text-[11px] md:text-[9px] text-center text-black dark:text-white"
                                >
                                    Create Advert
                                </div>
                            </div>
                        </div>
                    </Carousel>

                    <div className="mt-8 flex justify-center flex-col">
                        <h2 className="text-[red] font-bold text-[10px] text-center">
                            Copyright © 2025 Galileo One Inc
                        </h2>
                    </div>
                </Drawer>
            )}

            {waringOpen && (
                <UploadVideoWaringModal
                    message={"Please upload a file smaller than 100 MB"}
                    open={waringOpen}
                    handleOpen={handleOpen}
                />
            )}
        </Fragment>
    );
};

export default PostModal;
