import React, { useEffect, useRef, useState } from "react";
import { FaEye, FaSearch } from "react-icons/fa";
import { TbDotsVertical } from "react-icons/tb";
import { BsEmojiSmileFill } from "react-icons/bs";
import LazyLoad from 'react-lazyload';
import emptyPosts from "../../../src/Assets/YourAccountIcons/emptyPosts.png";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";

export function OtherUserPosts({ User_posts }) {
   const menuRef = useRef(null);
   const [postId, setPostId] = useState(null);
   const selectVideo = useSelectVideo();

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setPostId(null); // Close menu if clicked outside
      }
   };

   const handleDeletePost = async (id) => {
      //   await dispatch(AccountProfilePostDelete(id));
      //   await dispatch(AccountProfilePosts(User_profile?.id));
      //   dispatch(AccountProfile());
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const captureVideoPoster = (videoElement) => {
      videoElement.currentTime = 3;
      videoElement.addEventListener('seeked', function () {
         const canvas = document.createElement('canvas');
         canvas.width = videoElement.videoWidth;
         canvas.height = videoElement.videoHeight;
         canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
         const posterUrl = canvas.toDataURL();
         videoElement.setAttribute('poster', posterUrl);
      }, { once: true });
   };

   const handleSelectVideo = async (id) => {
      selectVideo(id, User_posts);
   };
   return (
      <>
         {User_posts?.length > 0 ? (
            <div className={`grid grid-cols-2 gap-2 overflow-y-scroll no-scrollbar px-2 py-2 z-10 `}>
               {User_posts?.map((item) => (
                  <VideoCard
                     id={item.id}
                     belowDecs={true}
                     threeDots={false}
                     body={item.post_description}
                     views={item.views}
                     video={item.video_url}
                     view_count={item.view_count}
                     likes_count={item.likes_count}
                     selectVideo={handleSelectVideo}
                     avatar_url={item.created_by.avatar_url}
                  />
               ))}
            </div>
         )
            : (
               <div className="flex justify-center items-center flex-col h-[50%]">
                  <img src={emptyPosts} alt="" className="w-32 h-32" />
                  <p className="text-[10px] text-[#c0b8b8] font-semibold">
                     No Posts yet
                  </p>
               </div>
            )}
      </>
   );
}
