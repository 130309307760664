import React from "react";
import { IoMdPhotos } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BiSolidVideos } from "react-icons/bi";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MdAccountCircle, MdOutlineMarkChatUnread } from "react-icons/md";
import { Typography, List, ListItem, ListItemPrefix, Drawer, Card } from "@material-tailwind/react";

const GroupChatSideBar = ({ isDrawerOpen, closeDrawer }) => {
   const navigate = useNavigate();

   return (
      <>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            placement="right"
            className="absolute overflow-y-scroll w-4/5 bg-white"
            style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
         >
            <Card
               color="transparent"
               shadow={false}
               className="overflow-scroll no-scrollbar z-50"
               style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
            >
               <List>
                  <div className="p-2 mt-4 relative">
                     <MagnifyingGlassIcon className="h-5 w-5 absolute top-[17px] left-[12px] cursor-pointer" />
                     <input
                        type="text"
                        placeholder="Search"
                        className="border border-[#d9d9d9] w-full pl-7 pr-2 py-[0.4rem] focus:outline-none rounded-[5px]"
                     />
                  </div>
                  <ListItem onClick={() => navigate("/invitemember")}>
                     <ListItemPrefix>
                        <MdAccountCircle className="h-5 w-5" />
                     </ListItemPrefix>
                     Add Members
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <IoMdPhotos className="h-5 w-5" />
                     </ListItemPrefix>
                     Photos
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <BiSolidVideos className="h-5 w-5" />
                     </ListItemPrefix>
                     Videos
                  </ListItem>
               </List>
            </Card>

            <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center uppercase">
            Copyright © 2025 Galileo One Inc
            </p>
            {/* <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center uppercase">
               CC GroupChatSideBar, FE, V3, june 22, 2024
            </p> */}
         </Drawer>
      </>
   );
};

export default GroupChatSideBar;
