import React from "react";
import { homeVideoFootage } from "../../Datas/Datas";

export const HashtagRecentviews = () => {
   return (
      <div className="grid grid-cols-12 gap-2 px-2 overflow-y-scroll no-scrollbar">
         {homeVideoFootage.map((item) => (
            <div className="col-span-6 mb-2">
               <div className="h-[26vh] w-full relative rounded-md border border-gray-400 dark:border-gray-800">
                  <video
                     autoPlay
                     src={`${item.footage}`}
                     className="h-full w-full object-cover rounded-md"
                  />
               </div>
               <div className="col-span-12 flex justify-between items-center mt-0.5 px-0.5">
                  <p className="text-black dark:text-white text-[10px] cursor-pointer overflow-hidden w-[50%] line-clamp-1">
                     @{item.name}
                  </p>
                  <p className="text-gray-700 dark:text-gray-800 text-[10px] cursor-pointer">
                     {item.time} ago
                  </p>
               </div>
            </div>
         ))}
      </div>
   );
};
