import React, { useCallback, useEffect, useState } from "react";
import { IoSettings,IoVideocam } from "react-icons/io5";
import { MdPrivacyTip } from "react-icons/md";
import { PiFolderUser } from "react-icons/pi";
import toast, { Toaster } from "react-hot-toast";
import { HiMiniClipboard } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PowerIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { FaUserCheck, FaRegUser, FaCookieBite, FaRegNewspaper, FaImages } from "react-icons/fa6";
import { RiUserAddFill, RiCustomerService2Line ,RiGitRepositoryPrivateLine} from "react-icons/ri";
import { getPublicPrivateToggle, postPublicPrivateToggle } from "../../Redux/ApiSlice/UserStatusSlice";
import { List, ListItem, ListItemPrefix, ListItemSuffix, Drawer, Card, Switch } from "@material-tailwind/react";

const AccountSidebar = ({ isDrawerOpen, setIsDrawerOpen, openDrawer, closeDrawer }) => {
    const dispatch = useDispatch();
    const [toggleChecked, setToggleChecked] = useState({ is_private: false });
    const User_Status = useSelector((state) => state.UserPrivate_Status);
    const { User_PrivateStatus } = User_Status;
    const { user_data } = User_PrivateStatus;

    const navigate = useNavigate();
    const location = useLocation();

    const keepLogin = localStorage.getItem("keepMeLogin");
    const handleLogOut = () => {
        if (keepLogin) {
            localStorage.removeItem("security_login");
            localStorage.removeItem("accessToken");
        } else {
            localStorage.clear();
        }
        navigate("/");
    };

    const handleChangeToggle = async (isChecked) => {
        setToggleChecked({ is_private: isChecked });
        await dispatch(postPublicPrivateToggle({ is_private: isChecked }));
        dispatch(getPublicPrivateToggle());

        if (isChecked) {
            toast.success("Your account is now private.");
        } else {
            toast.success("Your account is now public.");
        }
    };

    const handleNavigate = (path) => {
        if (location.pathname === path) {
            return null;
        } else {
            navigate(path);
        }
    }

    const handleSelected = useCallback(() => {
        setToggleChecked({ is_private: user_data?.is_private });
    }, [user_data])

    useEffect(() => {
        handleSelected()
    }, [handleSelected]);

    return (
        <>
            <Toaster position="top-right" />
            <Drawer
                open={isDrawerOpen}
                onClose={closeDrawer}
                placement="right"
                className="absolute dark:bg-black overflow-y-scroll w-4/5"
                style={{ zIndex: 1, height: "100vh", overflow: "hidden" }}
            >
                <Card
                    color="transparent"
                    shadow={false}
                    style={{ zIndex: 1, height: "100vh" }}
                    className="overflow-y-scroll  overflow-x-hidden"
                >
                    <p className="text-center text-red-800 text-[12px] font-bold">
                        Copyright © 2025 Galileo One Inc
                    </p>
                    <List>
                        <ListItem className="dark:text-[#c9c8c8] active:text-red-700  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/user/recommended")}>
                            <ListItemPrefix>
                                <FaUserCheck className="h-5 w-5" />
                            </ListItemPrefix>
                            Recommended Friends
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8] active:text-red-700  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/user/suggestion")}>
                            <ListItemPrefix>
                                <RiUserAddFill className="h-5 w-5" />
                            </ListItemPrefix>
                            Follow Suggestion
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8] active:text-red-700  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/user/suggestion/friendship")}>
                            <ListItemPrefix>
                                <FaRegUser className="h-5 w-5" />
                            </ListItemPrefix>
                            Friendship Suggestion
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8] active:text-red-700  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/user/suggestion/content-based")}>
                            <ListItemPrefix>
                                <PiFolderUser className="h-5 w-5" />
                            </ListItemPrefix>
                            Content Based Friends
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8] active:text-red-700  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/settings")}>
                            <ListItemPrefix>
                                <IoSettings className="h-5 w-5" />
                            </ListItemPrefix>
                            Settings
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleChangeToggle(toggleChecked.is_private)}>
                            <ListItemPrefix>
                                <RiGitRepositoryPrivateLine className="h-5 w-5" />
                            </ListItemPrefix>
                            Private
                            <ListItemSuffix>
                                <Switch
                                    checked={toggleChecked.is_private}
                                    onChange={() => handleChangeToggle(!toggleChecked.is_private)}
                                />
                            </ListItemSuffix>
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/account/private/empty")}>
                            <ListItemPrefix>
                                <IoVideocam className="h-5 w-5" />
                            </ListItemPrefix>
                            Hidden Videos
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/terms-of-use")}>
                            <ListItemPrefix>
                                <HiMiniClipboard className="h-5 w-5" />
                            </ListItemPrefix>
                            Terms & Conditions
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/settings/whatsnew")}>
                            <ListItemPrefix>
                                <FaRegNewspaper className="h-5 w-5" />
                            </ListItemPrefix>
                            What's New
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/privacy-policy")}>
                            <ListItemPrefix>
                                <MdPrivacyTip className="h-5 w-5" />
                            </ListItemPrefix>
                            Privacy Policy
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/cookies")}>
                            <ListItemPrefix>
                                <FaCookieBite className="h-5 w-5" />
                            </ListItemPrefix>
                            Cookies Policy
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/contact-us")}>
                            <ListItemPrefix>
                                <RiCustomerService2Line className="h-5 w-5" />
                            </ListItemPrefix>
                            Contact Us
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/media")}>
                            <ListItemPrefix>
                                <FaImages className="h-5 w-5" />
                            </ListItemPrefix>
                            Media
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={() => handleNavigate("/help")}>
                            <ListItemPrefix>
                                <QuestionMarkCircleIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            Help
                        </ListItem>
                        <ListItem className="dark:text-[#c9c8c8]  dark:hover:bg-[#373737]" onClick={handleLogOut}>
                            <ListItemPrefix>
                                <PowerIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            Log Out
                        </ListItem>
                    </List>
                </Card>
            </Drawer>
        </>
    );
};

export default AccountSidebar;