import React, { Fragment } from "react";
import Mediaimg from "../Assets/common/media.svg";
import { Input, Button, Typography, Card, CardBody } from "@material-tailwind/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RiDownload2Line } from "react-icons/ri";
import { ccLogo } from "../Datas/Assests";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { mediaInquiry } from "../Redux/ApiSlice/ContactUs";

export default function Media() {
   const dispatch = useDispatch();

   const [mediaFrom, setMediaFrom] = React.useState({
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      country: "",
      media: "",
      reason: ""
   });

   const handleInputChange = (e) => {
      setMediaFrom({ ...mediaFrom, [e.target.name]: e.target.value });
   }

   const handleSendMedia = async () => {
      if (mediaFrom.first_name === "" || mediaFrom.last_name === "" || mediaFrom.email === "" || mediaFrom.contact === "" || mediaFrom.country === "" || mediaFrom.media === "" || mediaFrom.reason === "") {
         return toast.error("Please fill all the fields");
      }
      try {
         await dispatch(mediaInquiry(mediaFrom));
         toast.success("Media Inquiry submitted successfully");

      } catch (error) {
         toast.error("Something went wrong");
      }
   }

   return (
      <Fragment>
         <div className="grid grid-cols-12 dark:bg-black bg-white">
            <div className="absolute top-0 w-full">
               <p className="text-red-800 bg-[#ffffff93] px-2 py-[1px] font-bold text-[10px] text-center">
                  <img src={ccLogo} alt="cc" className="h-12 w-12" />
               </p>
            </div>

            <div className="col-span-12">
               <img src={Mediaimg} alt="cookies" className="w-full mx-auto" />
            </div>

            <div className={`col-span-12 px-2`}>
               <div className={`col-span-12 flex justify-center items-center mt-3`}>
                  <p className={`text-black dark:text-gray-200 rounded-none font-semibold  text-center text-[12px]`}>
                     If you are a member of Media and would like to talk, please submit
                     the form below. Only media queries will receive a response, for
                     everything else, please
                     <span className="text-blue-700 underline mt-1"> Contact Support</span>
                  </p>
               </div>

               <div className="col-span-12 px-3">
                  <form className="mt-8 mb-1 w-full px-2">
                     <div className="mb-1 flex flex-col gap-1">
                        <Typography variant="h6" className="text-black dark:text-gray-200">
                           First Name
                        </Typography>
                        <Input
                           size="sm"
                           onChange={handleInputChange}
                           value={mediaFrom?.first_name}
                           name="first_name"
                           placeholder="Enter your first name"
                           className=" dark:text-gray-100 !border-t-blue-gray-200 focus:!border-t-gray-900 rounded-none"
                           labelProps={{
                              className: "before:content-none after:content-none",
                           }}
                        />
                        <Typography variant="h6" className="text-black dark:text-gray-200">
                           Last Name
                        </Typography>
                        <Input
                           size="sm"
                           onChange={handleInputChange}
                           value={mediaFrom?.last_name}
                           name="last_name"
                           placeholder="Enter your last name"
                           className="dark:text-gray-100 !border-t-blue-gray-200 focus:!border-t-gray-900 rounded-none"
                           labelProps={{
                              className: "before:content-none after:content-none",
                           }}
                        />
                        <Typography variant="h6" className="text-black dark:text-gray-200">
                           Country
                        </Typography>
                        <select
                           onChange={handleInputChange}
                           value={mediaFrom?.country}
                           name="country"

                           id="countries_disabled"
                           class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#252121] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-none"
                        >
                           <option value="Choose a country">Choose a country</option>
                           <option value="US">United States</option>
                           <option value="CA">Canada</option>
                           <option value="FR">France</option>
                           <option value="DE">Germany</option>
                        </select>
                        <Typography variant="h6" className="text-black dark:text-gray-200">
                           Media
                        </Typography>
                        <Input
                           type="text"
                           onChange={handleInputChange}
                           value={mediaFrom?.media}
                           name="media"
                           size="sm"
                           placeholder="Enter Your Media House Name"
                           className=" !border-t-blue-gray-200 focus:!border-t-gray-900 rounded-none"
                           labelProps={{
                              className: "before:content-none after:content-none",
                           }}
                        />
                        <Typography variant="h6" className="text-black dark:text-gray-200">
                           Email
                        </Typography>
                        <Input
                           onChange={handleInputChange}
                           value={mediaFrom?.email}
                           name="email"
                           size="sm"
                           placeholder="Enter your Email"
                           className=" !border-t-blue-gray-200 focus:!border-t-gray-900 rounded-none"
                           labelProps={{
                              className: "before:content-none after:content-none",
                           }}
                        />

                        <div className=" w-full">
                           <Typography variant="h6" className="text-black dark:text-gray-200">
                              Contact
                           </Typography>
                           <PhoneInput

                              inputStyle={{ width: "100%", borderRadius: 0 }}
                              className="border-t-blue-gray-200 focus:!border-t-gray-900 rounded-none w-[25%]"
                              country={"us"}
                              value={mediaFrom?.contact}
                              name="contact"
                              onChange={handleInputChange}
                           />
                        </div>
                        <div className=" w-full">
                           <Typography variant="h6" className="text-black dark:text-gray-200">
                              Reason to get in touch
                           </Typography>

                           <textarea
                              onChange={handleInputChange}
                              value={mediaFrom?.reason}
                              name="reason"
                              id="message"
                              rows="2"
                              class="block p-2.5 w-full text-sm dark:bg-[#252121] text-gray-900 bg-gray-50  border border-gray-400 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-none"
                              placeholder="Enter your message"
                           ></textarea>
                        </div>
                     </div>
                     <div className=" w-full flex justify-center items-center">
                        <Button className="mt-6 rounded-full" onClick={handleSendMedia}>Submit</Button>
                     </div>
                  </form>
               </div>
               <div className="col-span-12 mt-4">
                  <p className="text-center px-2 text-[12px] dark:text-gray-200 font-semibold">
                     Learn about our branding guidelines and how you can use our logos
                     and icons for your project. For detailed instructions, download
                     our <span className="text-blue-700">Guideline document. </span>
                  </p>
               </div>
               <div className="col-span-12 mt-6 flex flex-wrap justify-between gap-2 px-2">
                  <div className="col-span-4 w-[30%] ">
                     <Card className="w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 shadow-blue-gray-500">
                        <CardBody className="flex justify-center items-center flex-col p-1">
                           <Typography
                              variant="h1"
                              color="blue-gray"
                              className="mb-1 text-blue-700 font-bold text-[30px]"
                           >

                              <img src={ccLogo} alt="cc" className="h-12 w-12" />

                           </Typography>
                           <Button className="bg-white border-[1px] p-1 border-gray-700">
                              <RiDownload2Line className="text-black" />
                           </Button>
                        </CardBody>
                     </Card>
                  </div>
                  <div className="col-span-4 w-[30%] ">
                     <Card className="w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 shadow-blue-gray-500">
                        <CardBody className="flex justify-center items-center flex-col p-1">
                           <Typography
                              variant="h1"
                              color="blue-gray"
                              className="mb-1 text-blue-700 font-bold text-[30px]"
                           >

                              <img src={ccLogo} alt="cc" className="h-12 w-12" />

                           </Typography>
                           <Button className="bg-white border-[1px] p-1 border-gray-700">
                              <RiDownload2Line className="text-black" />
                           </Button>
                        </CardBody>
                     </Card>
                  </div>
                  <div className="col-span-4 w-[30%] ">
                     <Card className="w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 shadow-blue-gray-500">
                        <CardBody className="flex justify-center items-center flex-col p-1">
                           <Typography
                              variant="h1"
                              color="blue-gray"
                              className="mb-1 text-blue-700 font-bold text-[30px]"
                           >

                              <img src={ccLogo} alt="cc" className="h-12 w-12" />

                           </Typography>
                           <Button className="bg-white border-[1px] p-1 border-gray-700">
                              <RiDownload2Line className="text-black" />
                           </Button>
                        </CardBody>
                     </Card>
                  </div>
                  <div className="col-span-4 w-[30%] ">
                     <Card className="w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 shadow-blue-gray-500">
                        <CardBody className="flex justify-center items-center flex-col p-1">
                           <Typography
                              variant="h1"
                              color="blue-gray"
                              className="mb-1 text-blue-700 font-bold text-[30px]"
                           >

                              <img src={ccLogo} alt="cc" className="h-12 w-12" />

                           </Typography>
                           <Button className="bg-white border-[1px] p-1 border-gray-700">
                              <RiDownload2Line className="text-black" />
                           </Button>
                        </CardBody>
                     </Card>
                  </div>
                  <div className="col-span-4 w-[30%] ">
                     <Card className="w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 shadow-blue-gray-500">
                        <CardBody className="flex justify-center items-center flex-col p-1">
                           <Typography
                              variant="h1"
                              color="blue-gray"
                              className="mb-1 text-blue-700 font-bold text-[30px]"
                           >

                              <img src={ccLogo} alt="cc" className="h-12 w-12" />

                           </Typography>
                           <Button className="bg-white border-[1px] p-1 border-gray-700">
                              <RiDownload2Line className="text-black" />
                           </Button>
                        </CardBody>
                     </Card>
                  </div>
                  <div className="col-span-4 w-[30%] ">
                     <Card className="w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 shadow-blue-gray-500 ">
                        <CardBody className="flex justify-center items-center flex-col p-1">
                           <Typography
                              variant="h1"
                              color="blue-gray"
                              className="mb-1 text-blue-700 font-bold text-[30px]"
                           >

                              <img src={ccLogo} alt="cc" className="h-12 w-12" />

                           </Typography>
                           <Button className="bg-white border-[1px] p-1 border-gray-700">
                              <RiDownload2Line className="text-black" />
                           </Button>
                        </CardBody>
                     </Card>
                  </div>
               </div>
               <div className="col-span-12 mt-6 mb-4">
                  <p className={`text-[12px] text-center dark:text-gray-200 font-bold px-2`}>
                     Our Media kit provides ready to use versions of our logo for
                     digital media. Please do not edit, change, distort, recolor, or
                     reconfigure our logos.
                  </p>
               </div>
            </div>
         </div>
      </Fragment>
   );
}
