import React, { useState } from "react";
import { Drawer } from "@material-tailwind/react";
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { BiRepost } from "react-icons/bi";
import { FaRegCopy } from "react-icons/fa6";
import { SocialIcon } from "react-social-icons";
import { IoSpeedometerSharp, IoSearchOutline } from "react-icons/io5";
import { MdDownloading, MdOutlineDownloadDone } from "react-icons/md";

const socialMediaButtons = [
    { Component: FacebookShareButton, network: "facebook", url: "https://thtsusa.com/api/post/folders/", quote: "title" },
    { Component: WhatsappShareButton, network: "whatsapp", url: "https://thtsusa.com/api/post/folders/", quote: "title" },
    { Component: InstapaperShareButton, network: "instagram", url: "https://thtsusa.com/api/post/folders/", quote: "title" },
    { Component: TwitterShareButton, network: "x", url: "https://thtsusa.com/api/post/folders/", title: "title" },
    { Component: LinkedinShareButton, network: "linkedin", url: "https://thtsusa.com/api/post/folders/" },
    { Component: TelegramShareButton, network: "telegram", url: "https://thtsusa.com/api/post/folders/", title: "https://thtsusa.com/api/post/folders/" },
    { Component: EmailShareButton, network: "email", url: "https://thtsusa.com/api/post/folders/" },
    { Component: TumblrShareButton, network: "tumblr", url: "https://thtsusa.com/api/post/folders/" },
];

const images = [
    "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1500336624523-d727130c3328?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1603988089669-c041ac2fe196?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
];

export default function Share({ closeDrawerBottom, openBottom, videoUrl }) {
    const [like, setLike] = useState(false);
    const [downloaded, setDownloaded] = useState(false);

    const downloadVideo = async (videoUrl) => {
        try {
            const response = await fetch(videoUrl);
            if (!response.ok) throw new Error("Network response was not ok");

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "video.mp4";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloaded(true);
        } catch (error) {
            console.error("Error downloading the video:", error);
        }
    };

    return (
        <>
            <Drawer
                size={270}
                placement="bottom"
                open={openBottom}
                style={{ zIndex: 1 }}
                onClose={closeDrawerBottom}
                className={`absolute dark:bg-black w-full ${!openBottom && "hidden"}`}
            >
                <div className="grid grid-cols-6">
                    <div className="col-span-6 border-b border-gray-300 py-2 px-2">
                        <div className="flex w-full items-center gap-x-2">
                            <p className="text-[13px] w-[85%] py-2 px-3 overflow-x-scroll dark:text-white dark:bg-[#272626] bg-gray-300 rounded-md">
                                https://thtsusa.com/api/post/folders/
                            </p>
                            <div className="w-[15%] flex justify-center items-center py-2 dark:bg-[#272626]  bg-black rounded-md cursor-pointer">
                                <FaRegCopy className="text-white   text-[20px]" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 px-2 h-[13.5rem] overflow-y-scroll border-r border-gray-300">
                        <div className="flex items-center gap-x-3 gap-y-2 flex-wrap mt-2 pb-2 border-b border-gray-300">
                            {socialMediaButtons.map(({ Component, network, url, quote, title }, index) => (
                                <div key={index}>
                                    <Component url={url} quote={quote} title={title}>
                                        <SocialIcon network={network} url="#" style={{ height: "40px", width: "40px" }} />
                                    </Component>
                                </div>
                            ))}
                        </div>
                        <div className="relative my-2">
                            <input
                                type="text"
                                placeholder="Search for friends..."
                                className="w-full py-2 pl-2 pr-10 bg-gray-200 rounded-md focus:outline-none text-[13px] placeholder:text-[13px]"
                            />
                            <IoSearchOutline className="absolute top-1/2 right-[5px] transform -translate-y-1/2 text-gray-600 text-[20px]" />
                        </div>
                        <div className="flex items-center gap-x-3 gap-y-2 flex-wrap mt-2">
                            {images.map((src, index) => (
                                <div key={index}>
                                    <img
                                        src={src}
                                        alt={`img${index}`}
                                        className="h-[40px] w-[40px] object-cover rounded-full cursor-pointer"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="flex flex-col justify-center items-center gap-y-3 mt-2">
                            <div className="flex items-center justify-center bg-gray-900 rounded-full w-[40px] h-[40px] cursor-pointer">
                                {downloaded ? (
                                    <MdOutlineDownloadDone
                                        className="text-[22px] text-white"
                                    />
                                ) : (
                                    <MdDownloading
                                        onClick={() => downloadVideo(videoUrl)}
                                        className="text-[22px] text-white"
                                    />
                                )}
                            </div>
                            <div className="flex items-center justify-center bg-gray-900 rounded-full w-[40px] h-[40px] cursor-pointer">
                                <IoSpeedometerSharp className="text-[20px] text-white" />
                            </div>
                            <div className="flex items-center justify-center bg-gray-900 rounded-full w-[40px] h-[40px] cursor-pointer">
                                <BiRepost className="text-[22px] text-white" />
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
}