import React, { useCallback, useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import useSelectVideo from "../../Hooks/useSelectVideo";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HomepageVideo } from "../../Redux/ApiSlice/HomePageVideoSlice";

export default function TrendingMain() {
    const selectVideo = useSelectVideo();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filterCriteria, setFilterCriteria] = useState('');

    const getAllPost = useSelector((state) => state.HomePageVideo);
    const { AllPosts } = getAllPost;

    let count = AllPosts.length;
    const handleSelectVideo = async (id) => {
        selectVideo(id, AllPosts);
    };

    const handleFilterChange = (e) => {
        setFilterCriteria(e.target.value);
    };

    const filteredPosts = AllPosts.filter(post => {
        if (!filterCriteria) return true;
        return post.body.toLowerCase().includes(filterCriteria.toLowerCase());
    });

    const getDataForPaginated = useCallback(async () => {
        await dispatch(HomepageVideo());
    }, [dispatch])

    useEffect(() => {
        getDataForPaginated();
    }, [getDataForPaginated])

    return (
        <div className="bg-black h-full pb-5 overflow-y-scroll">
            <div className="sticky top-0 bg-black z-10">
                <div className="grid grid-cols-12 px-2 pt-1 items-center">
                    <div className="col-span-12 pb-[2px]">
                        {/* <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                            CC TRENDING VIDEOS - FE - V1 - JUNE 21, 2024
                        </p> */}
                        <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                            Copyright © 2025 Galileo One Inc
                        </p>
                    </div>
                    <div className="col-span-2">
                        <IoArrowBackCircle color="white" className="text-[1.5rem] cursor-pointer" onClick={() => navigate(-1)} />
                    </div>
                    <div className="col-span-8 text-center">
                        <p className="text-white text-[14px]">TRENDING VIDEOS</p>
                    </div>
                </div>
                <hr className="w-full my-[3px] h-[0.3px] bg-gray-800 border-0" />
            </div>
            <div>
                <div className="mx-3 mt-[4px] relative">
                    <input
                        type="text"
                        placeholder="search..."
                        onChange={handleFilterChange}
                        className="w-full focus:outline-none border border-gray-700 py-1 pl-8 pr-2 rounded-full"
                    />
                    <IoSearchSharp className="absolute top-[7px] left-[7px] text-[20px] cursor-pointer" />
                </div>
            </div>

            <div className="grid grid-cols-12 gap-2 items-center pt-2 pb-2 px-2 cursor-pointer">
                {(filteredPosts || [])?.map((data) => {
                    count--;
                    return (
                        <div className="col-span-6" key={data.id}>
                            <div className="relative">
                                <video
                                    disablePictureInPicture
                                    src={`${data.video_url}`}
                                    onClick={() => handleSelectVideo(data.id)}
                                    className="h-[13rem] w-full object-cover rounded"
                                />
                                <div className="absolute top-1 left-1">
                                    <div 
                                        onClick={() => navigate(`/otheruseraccount/${data.created_by.id}`)}
                                        className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl">
                                        <img
                                            alt="avatar"
                                            disablePictureInPicture
                                            className="h-3 w-3 rounded-full object-cover"
                                            src={data.created_by.avatar_url}
                                        />
                                        <p className="text-[10px] text-white">
                                            {data.author}
                                            {data.created_by.first_name}
                                        </p>
                                    </div>
                                </div>
                                <div className="absolute bottom-1 right-1">
                                    <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[8px] py-[2px] rounded-2xl">
                                        <p className="text-[10px] text-white">1.{count}M</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
