import React, { useState, useCallback, useEffect } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { LiaSearchSolid } from "react-icons/lia";
import { TbStarHalfFilled } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    getGroupByUserId,
    groupJoin,
} from "../../Redux/ApiSlice/Group/GroupSlice";
import { ThreeDots } from "react-loader-spinner";
import { FaLock } from "react-icons/fa6";

export default function OtherUserGroupList() {
    const navigate = useNavigate();
    const params = useParams();
    const { userId } = params;
    const dispatch = useDispatch();
    const groupData = useSelector((state) => state.group);
    const { data, loading } = groupData;
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        setGroupList(data);
    }, [data]);

    const [searchTerm, setSearchTerm] = useState("");

    console.log(data, "testing");

    const fetchData = useCallback(async () => {
        try {
            await dispatch(getGroupByUserId(userId));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, userId]);

    const handleJoinGroup = async (groupId) => {
        try {
            await dispatch(groupJoin(groupId));
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filteredUsers = Array.isArray(groupList)
        ? groupList?.filter(
            (user) =>
                user.group_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];
    console.log(filteredUsers, "filteredUsers");
    return (
        <div className="h-full w-full bg-white overflow-y-scroll overflow--hidden">
            <div className="flex justify-between items-center w-full h-14 gap-x-4 bg-black sticky top-0 px-4 pt-2">
                <div>
                    <IoArrowBackCircle
                        className="text-[24px] text-white cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="relative w-full">
                    <input
                        type="text"
                        color="blue"
                        placeholder="Search....."
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="rounded-md h-8 outline-none bg-white px-4 pr-6 placeholder:text-[black] w-full"
                    />
                    {searchTerm === "" && (
                        <LiaSearchSolid className="absolute right-2 top-2" />
                    )}
                </div>
                {/* <p className="absolute top-0 text-red-700 text-center  uppercase text-[10px] w-full font-bold">
          CC / OTHER USER GROUP LIST PAGE / V1 / oct 11 / 2024
        </p> */}
                <p className="absolute top-0 text-red-700 text-center  uppercase text-[10px] w-full font-bold">
                    Copyright © 2025 Galileo One Inc
                </p>
            </div>
            {filteredUsers?.map((data) => (
                <>
                    <div
                        className="grid grid-cols-12 items-center pt-2 pb-2 px-4 hover:bg-[#9a989844] text-black cursor-pointer"
                        key={data.id}
                    >
                        <div className="col-span-2">
                            <div className="relative flex items-center justify-center border shadow-[1px_1px_2px_1px_#f687b3]  rounded-md h-8 w-8 p-[1px]">
                                <img
                                    alt={data?.group_name}
                                    src={data?.group_profile_picture}
                                    onClick={() =>
                                        data.user_joined
                                            ? navigate(`/createdgroup/${data.id}`)
                                            : navigate(`/othergroup/${data.id}`)
                                    }
                                    className="h-7 w-7 rounded-md object-cover"
                                />
                                {data.group_type === "private" && (
                                    <p className="absolute -right-3 -top-2 text-[black] text-[14px] rounded-xl">
                                        <FaLock />
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-span-7  ml-[-2px]">
                            <div className="grid grid-cols-12 w-full">
                                <div className="col-span-12 flex justify-between items-center pr-4">
                                    <div className="flex gap-1 items-center">
                                        <p className="text-[14px] md:text-[12px] font-bold first-letter:uppercase line-clamp-2">
                                            {data?.group_name}
                                        </p>
                                        <p>
                                            <TbStarHalfFilled className="text-blue-600 text-[12px]" />
                                        </p>
                                    </div>
                                    <div class="flex -space-x-1 overflow-hidden">
                                        {data.members.map((member, index) => (
                                            <>
                                                {index < 3 && (
                                                    <img
                                                        alt="img"
                                                        class="inline-block h-5 w-5 rounded-full"
                                                        src={member?.profile_pic}
                                                    />
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-span-12">
                                    <p className="text-[10px] line-clamp-1">
                                        {data?.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3 flex justify-end items-center">
                            {/* {data.user_joined ?
                <button className="bg-green-600 text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold" onClick={() => handleJoinGroup(data.id)}>
                  Joined
                </button> : <button className="bg-[#f86045] text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold" onClick={() => handleJoinGroup(data.id)}>
                  Join
                </button>} */}

                            {data.join_request_status !== "pending" ? (
                                data?.user_joined ? (
                                    <button
                                        className="bg-green-600 text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold"
                                        onClick={() => navigate(`/createdgroup/${data.id}`)}
                                    >
                                        Joined
                                    </button>
                                ) : (
                                    <button
                                        className="bg-[#f86045] text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold"
                                        onClick={() => handleJoinGroup(data.id)}
                                    >
                                        Join
                                    </button>
                                )
                            ) : (
                                <button
                                    className="bg-[#f86045] text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold"
                                    onClick={() => navigate(`/othergroup/${data.id}`)}
                                >
                                    Requested
                                </button>
                            )}
                        </div>
                    </div>
                    <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-700 border-0" />
                </>
            ))}

            {loading && (
                <div className="h-[25vh] flex justify-center items-center">
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#5e378b"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}

            {!loading && filteredUsers?.length === 0 && (
                <div className="flex justify-center items-center">
                    <p className="text-[14px] text-gray-800 font-bold">No Group Found</p>
                </div>
            )}
        </div>
    );
}
