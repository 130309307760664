import React from 'react'
import { Button } from "@material-tailwind/react";


export default function PassWordButton({ value, onClick }) {
    return (
      <Button
        color="lightBlue"
        buttonType="filled"
        size="md"
        rounded={true}
        block={true}
        ripple="light"
        onClick={() => onClick(value)}
        className='px-8 py-6 md:px-6 md:py-3 dark:bg-[#ffffff] dark:text-black ' 
      >
        {value}
      </Button>
    );
  }
