import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { FaRegTrashCan, FaCcVisa, FaCcMastercard, FaCcPaypal } from "react-icons/fa6";

const PaymentDetails = () => {
    const navigate = useNavigate();
    const [showCardForm, setShowCardForm] = useState(false);
    const [showPayPalForm, setShowPayPalForm] = useState(false);
    const [cardDetails, setCardDetails] = useState({ cardNumber: "", expiryDate: "", cvv: "" });
    const [savedCards, setSavedCards] = useState([
        { id: 1, name: "JPMorgan Chase", cardNumber: "1234 5678 9012 3456", expiryDate: "12/23", type: "visa" },
        { id: 2, name: "Bank of America", cardNumber: "9876 5432 1098 7654", expiryDate: "11/24", type: "mastercard" }
    ]);
    const [savedPayPal, setSavedPayPal] = useState([
        { id: 1, email: "user1@paypal.com" },
        { id: 2, email: "user2@paypal.com" }
    ]);
    const [selectedCard, setSelectedCard] = useState(savedCards[0]?.id || null);
    const [selectedPayPal, setSelectedPayPal] = useState(savedPayPal[0]?.id || null);

    const handleCardFormToggle = () => setShowCardForm(!showCardForm);
    const handlePayPalFormToggle = () => setShowPayPalForm(!showPayPalForm);

    const handleCardNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        if (value.length <= 16) {
            const formattedValue = value.replace(/(.{4})/g, "$1 ").trim();
            setCardDetails({ ...cardDetails, cardNumber: formattedValue });
        }
    };

    const handleExpiryDateChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        if (value.length <= 4) {
            const formattedValue = value.replace(/(\d{2})(\d{1,2})/, "$1/$2");
            setCardDetails({ ...cardDetails, expiryDate: formattedValue });
        }
    };

    const handleCVVChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        if (value.length <= 3) {
            setCardDetails({ ...cardDetails, cvv: value });
        }
    };

    const handleCardSelect = (id) => {
        setSelectedCard(id);
    };

    const handlePayPalSelect = (id) => {
        setSelectedPayPal(id);
    };

    const handleCardDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this card?")) {
            setSavedCards(savedCards.filter(card => card.id !== id));
            if (selectedCard === id) {
                setSelectedCard(savedCards[0]?.id || null);
            }
        }
    };

    const handlePayPalDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this PayPal account?")) {
            setSavedPayPal(savedPayPal.filter(account => account.id !== id));
            if (selectedPayPal === id) {
                setSelectedPayPal(savedPayPal[0]?.id || null);
            }
        }
    };

    const getCardIcon = (type) => {
        switch (type) {
            case "visa":
                return <FaCcVisa className="text-blue-600" />;
            case "mastercard":
                return <FaCcMastercard className="text-red-600" />;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div className="sticky top-0 grid grid-cols-12 px-4 py-2 bg-black items-center z-10">
                <div className="col-span-2 flex justify-start">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[20px] cursor-pointer text-white"
                    />
                </div>
                <div className="col-span-8 flex justify-center">
                    <p className="text-center text-[14px] text-white font-semibold">Payment Details</p>
                </div>
            </div>
            <div className="mt-2 px-3">
                <div className="mb-8 relative">
                    <p className="text-[13px] text-black dark:text-white font-semibold mb-2">Your Credit/Debit Card</p>
                    {savedCards.map(card => (
                        <div key={card.id} className="flex items-start justify-between mb-2 p-2 border border-gray-300 rounded">
                            <div className="flex items-start">
                                <input
                                    type="radio"
                                    name="savedCard"
                                    checked={selectedCard === card.id}
                                    onChange={() => handleCardSelect(card.id)}
                                    className="mr-2"
                                />
                                <div className="flex flex-col mt-[-3px]">
                                    <p className="text-[13px] text-black dark:text-white">{card.name}</p>
                                    <div className="flex items-center">
                                        {getCardIcon(card.type)}
                                        <p className="text-[13px] text-black dark:text-white ml-2">**** **** **** {card.cardNumber.slice(-4)}</p>
                                    </div>
                                </div>
                            </div>
                            <FaRegTrashCan
                                className="text-[12px] text-gray-800 hover:text-red-700 cursor-pointer"
                                onClick={() => handleCardDelete(card.id)}
                            />
                        </div>
                    ))}
                    {!showCardForm && (
                        <button
                            onClick={handleCardFormToggle}
                            className="w-full bg-[#126C02] hover:opacity-95 text-white text-[14px] py-2 mt-2 rounded"
                        >
                            Add Card Details
                        </button>
                    )}
                    {showCardForm && (
                        <div className="absolute top-0 right-0 cursor-pointer text-[14px] font-semibold text-[#126C02]" onClick={handleCardFormToggle}>
                            Hide
                        </div>
                    )}
                    {showCardForm && (
                        <div className="mt-4">
                            <div className="mb-4">
                                <label className="block text-[13px] text-black dark:text-white">Card Number</label>
                                <input
                                    type="text"
                                    className="w-full border border-gray-300 p-2 rounded focus:border-[#126C02] focus:outline-none"
                                    placeholder="1234 5678 9012 3456"
                                    value={cardDetails.cardNumber}
                                    onChange={handleCardNumberChange}
                                />
                            </div>
                            <div className="flex mb-4">
                                <div className="w-1/2 pr-2">
                                    <label className="block text-[13px] text-black dark:text-white">Expiry Date</label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 p-2 rounded focus:border-[#126C02] focus:outline-none"
                                        placeholder="MM/YY"
                                        value={cardDetails.expiryDate}
                                        onChange={handleExpiryDateChange}
                                    />
                                </div>
                                <div className="w-1/2 pl-2">
                                    <label className="block text-[13px] text-black dark:text-white">CVV</label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 p-2 rounded focus:border-[#126C02] focus:outline-none cursor-pointer accent-[#222020]"
                                        placeholder="123"
                                        value={cardDetails.cvv}
                                        onChange={handleCVVChange}
                                    />
                                </div>
                            </div>
                            <button className="w-full bg-[#126C02] hover:opacity-95 text-white text-[14px] py-2 rounded">
                                Save Card Details
                            </button>
                        </div>
                    )}
                </div>
                <div className="mb-8 relative">
                    <p className="text-[13px] text-black dark:text-white font-semibold mb-2">Your PayPal Accounts</p>
                    {savedPayPal.map(account => (
                        <div key={account.id} className="flex items-start justify-between mb-2 p-2 border border-gray-300 rounded">
                            <div className="flex items-start">
                                <input
                                    type="radio"
                                    name="savedPayPal"
                                    checked={selectedPayPal === account.id}
                                    onChange={() => handlePayPalSelect(account.id)}
                                    className="mr-2 cursor-pointer accent-[#222020]"
                                />
                                <div className="flex items-center">
                                    <FaCcPaypal className="text-blue-800" />
                                    <p className="text-[13px] text-black dark:text-white ml-2">{account.email}</p>
                                </div>
                            </div>
                            <FaRegTrashCan
                                className="text-[12px] text-gray-800 hover:text-red-700 cursor-pointer"
                                onClick={() => handlePayPalDelete(account.id)}
                            />
                        </div>
                    ))}
                    {!showPayPalForm && (
                        <button
                            onClick={handlePayPalFormToggle}
                            className="w-full bg-[#00529E] hover:opacity-95 text-white text-[14px] py-2 mt-2 rounded"
                        >
                            Add PayPal Details
                        </button>
                    )}
                    {showPayPalForm && (
                        <div className="absolute top-0 right-0 cursor-pointer text-[14px] font-semibold text-[#00529E]" onClick={handlePayPalFormToggle}>
                            Hide
                        </div>
                    )}
                    {showPayPalForm && (
                        <div className="mt-4">
                            <div className="mb-4">
                                <label className="block text-[13px] text-black dark:text-white">PayPal Email</label>
                                <input
                                    type="email"
                                    placeholder="example@paypal.com"
                                    className="w-full border border-gray-300 p-2 rounded focus:border-[#00529E] focus:outline-none"
                                />
                            </div>
                            <button className="w-full bg-[#00529E] hover:opacity-95 text-white text-[14px] py-2 rounded">
                                Save PayPal Details
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default PaymentDetails;