import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { RiDraftFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { TbFilterSearch } from "react-icons/tb";
import ReguestTab from "../Component/MobileMessagesTabs/ReguestTab";
import MessagesGroup from "../Component/MobileMessagesTabs/MessagesGroup";
import UnreadMessages from "../Component/MobileMessagesTabs/UnreadMessages";
import MessagesRecent from "../Component/MobileMessagesTabs/MessagesRecent";
import { Button } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function MessagesPageMobile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("Recent");
    const [isOpen, setIsOpen] = useState(false);
    const [isNestedOpen, setIsNestedOpen] = useState(false);
    const [isNestedOpenDate, setIsNestedOpenDate] = useState(false);
    const filterRef = useRef(null);
    const [search, setSearch] = useState("")
    const MessagesData = useSelector((state) => state.messages);

    const [selectedDateText, setSelectedDateText] = useState("Date");
    const [selectedContentText, setSelectedContentText] = useState("Content");

    const handleDateOptionClick = (text) => {
        setSelectedDateText(text);
        setIsNestedOpenDate(false);
    };

    const handleContentOptionClick = (text) => {
        setSelectedContentText(text);
        setIsNestedOpen(false);
    };

    const { messagesList, loading } = MessagesData

    const handleClickTab = (value) => {
        setActiveTab(value);
        setSearch("")
    };
    const handleDateSelect = () => {
        setIsNestedOpenDate(!isNestedOpenDate)
        setIsNestedOpen(false)
    }
    const handleConentSelect = () => {
        setIsNestedOpen(!isNestedOpen)
        setIsNestedOpenDate(false)
    }
    const handleRecentMessages = () => {
        setIsOpen(!isOpen)
        setActiveTab("Recent")
        setIsNestedOpen(false)
        setIsNestedOpenDate(false)
    }

    const MessagesList = [
        {
            id: 1,
            name: "John Smith",
            dec: "Stay ",
            lastmessageTime: "15 min ago",
            notification: 2,
            img: "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 2,
            name: "Joseph",
            dec: "Run",
            lastmessageTime: "43 min ago",
            notification: 3,
            img: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 3,
            name: "Rock",
            dec: "Hope me",
            lastmessageTime: "45 min ago",
            notification: 1,
            img: "https://images.unsplash.com/photo-1527980965255-d3b416303d12?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 4,
            name: "Warner",
            dec: "You Are The Winner",
            lastmessageTime: "50 min ago",
            notification: 5,
            img: "https://images.unsplash.com/photo-1543610892-0b1f7e6d8ac1?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 5,
            name: "David",
            dec: "React me",
            lastmessageTime: "1 hour ago",
            notification: 2,
            img: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 6,
            name: "Merry Devis",
            dec: "Read  me",
            lastmessageTime: "3 hour ago",
            notification: 3,
            img: "https://images.unsplash.com/photo-1532074565847-ae9c3c0bfbf2?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 7,
            name: "William",
            dec: "Replay me",
            lastmessageTime: "8 hour ago",
            notification: 1,
            img: "https://images.unsplash.com/photo-1543132220-3ec99c6094dc?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 8,
            name: "Robert",
            dec: "Nice to meet you",
            lastmessageTime: "12 hour ago",
            notification: 4,
            img: "https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 9,
            name: "Messy",
            dec: "Thank you",
            lastmessageTime: "16 hour ago",
            notification: 2,
            img: "https://images.unsplash.com/photo-1537511446984-935f663eb1f4?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 10,
            name: "Christopher",
            dec: "Hi",
            lastmessageTime: "1 day ago",
            notification: 7,
            img: "https://plus.unsplash.com/premium_photo-1674777843430-3c0413584110?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ];

    const MessagesGroupList = [
        {
            id: 1,
            name: "Birthday party",
            dec: "Stay ",
            lastmessageTime: "1 hour ago",
            img: "https://th.bing.com/th/id/OIP.F3AuA8BKxIGCquoBQ7re1gHaD4?w=269&h=187&c=7&r=0&o=5&dpr=1.2&pid=1.7",
        },
        {
            id: 2,
            name: "Ferrari",
            dec: "Run",
            lastmessageTime: "1 hour ago",
            img: "https://images.unsplash.com/photo-1614200187524-dc4b892acf16?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ];


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsOpen(false);
                setIsNestedOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <section className="bg-white dark:bg-black h-full overflow-hidden ">
            <div className="flex justify-between items-center px-3 pt-2 pb-1 border-b border-gray-400 dark:border-gray-800">
                <div className="text-black dark:text-white cursor-pointer">
                    <TbFilterSearch
                        onClick={() => setIsOpen(!isOpen)}
                        className="text-[1.3rem] text-black dark:text-white"
                    />
                    {isOpen && (
                        <div
                            className="origin-top-right absolute left-0 mt-2 w-[7rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                            ref={filterRef}
                        >
                            <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                <div className="relative">
                                    <button
                                        onClick={() => handleDateSelect()}
                                        className="w-full text-left px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 flex justify-between items-center"
                                    >
                                        {selectedDateText}
                                        <MdKeyboardArrowDown
                                            className={`h-4 w-4 ml-2 inline-block transform transition-transform ${isNestedOpenDate ? "rotate-180" : ""}`}
                                        />
                                    </button>
                                    {isNestedOpenDate && (
                                        <div className="absolute left-[7.2rem] origin-bottom-right -bottom-24 mt-2 w-24 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                            <div
                                                className="py-1"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="options-menu"
                                            >
                                                <div
                                                    onClick={() => handleDateOptionClick("Today")}
                                                    className="block px-2 w-full py-2 text-sm text-gray-700 hover:bg-gray-100"

                                                >
                                                    Today
                                                </div>
                                                <div
                                                    onClick={() => handleDateOptionClick("Last 7 days")}
                                                    className="block px-2 w-full py-2 text-sm text-gray-700 hover:bg-gray-100"

                                                >
                                                    last 7 days
                                                </div>
                                                <div
                                                    onClick={() => handleDateOptionClick("Last Month")}
                                                    className="block px-2 w-full py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    last Month
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <button
                                        onClick={() => handleConentSelect()}
                                        className="w-full text-left px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 flex justify-between items-center"

                                    >
                                        {selectedContentText}
                                        <MdKeyboardArrowDown
                                            className={`h-4 w-4 ml-2 inline-block transform transition-transform ${isNestedOpen ? "rotate-180" : ""}`}
                                        />
                                    </button>
                                    {isNestedOpen && (
                                        <div className="absolute left-[7.2rem]  -bottom-24 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                            <div
                                                className="py-1"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="options-menu"
                                            >
                                                <div
                                                    onClick={() => { navigate("/mobileMessages/achived"); handleContentOptionClick("Archived Messages"); }}
                                                    className="block px-2 w-full py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    Archived Messages
                                                </div>
                                                <div
                                                    onClick={() => { handleRecentMessages(); handleContentOptionClick("Recent Messages"); }}
                                                    className="block px-2 w-full py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    Recent Messages
                                                </div>
                                                <div
                                                    onClick={() => handleContentOptionClick("Old Messages")}
                                                    className="block px-2 w-full py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    Old Messages
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="text-black dark:text-white font-bold">Messages</div>
                <div className="cursor-pointer">
                    <RiDraftFill
                        className="text-[1.3rem] text-black dark:text-white"
                        onClick={() => navigate("/mobileMessages/draft")}
                    />
                </div>
            </div>

            <div className="w-full flex justify-center mt-1">
                <div className="w-4/5 relative">
                    <FaSearch className="absolute cursor-pointer top-2 left-[8px] text-black" />
                    <input
                        value={search}
                        type="text"
                        placeholder="Search Messages"
                        className="rounded-full w-full py-1 pr-2 pl-8 bg-gray-200 outline-none"
                        onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>

            <div className="grid grid-cols-12 gap-x-[1px] mt-1">
                <Button
                    className={`mobileMessages-tab-text focus:border-none col-span-4 lg:text-[14px] p-[6px] rounded-none ${activeTab === "Recent"
                        ? "bg-blue-600 text-center text-white"
                        : "bg-black dark:bg-gray-800 text-white"
                        }`}
                    onClick={() => handleClickTab("Recent")}
                >
                    <p className="text-[12px] md:text-[10px]  font-semibold">Recent</p>
                </Button>
                <Button
                    className={`mobileMessages-tab-text focus:border-none col-span-4 lg:text-[14px] p-[6px] rounded-none ${activeTab === "Groups"
                        ? "bg-blue-600 text-center text-white"
                        : "bg-black dark:bg-gray-800 text-white"
                        }`}
                    onClick={() => handleClickTab("Groups")}
                >
                    <p className="text-[12px] md:text-[10px]  font-semibold">Group Chats</p>
                </Button>
                <Button
                    className={`mobileMessages-tab-text focus:border-none col-span-4 lg:text-[14px] p-[6px] rounded-none ${activeTab === "Requests"
                        ? "bg-blue-600 text-center text-white"
                        : "bg-black dark:bg-gray-800 text-white"
                        }`}
                    onClick={() => handleClickTab("Requests")}
                >
                    <p className="text-[12px] md:text-[10px]  font-semibold">Requests</p>
                </Button>
            </div>
            <div className="mt-1 h-[-webkit-fill-available] absolute w-full overflow-y-scroll no-scrollbar">
                {activeTab === "Recent" &&
                    <MessagesRecent search={search} />
                }
                {activeTab === "Groups" &&
                    <MessagesGroup MessagesList={MessagesGroupList} search={search} />
                }
                {activeTab === "Requests" &&
                    <ReguestTab MessagesList={MessagesList} search={search} />
                }
                {activeTab === "Unread" &&
                    <UnreadMessages MessagesList={MessagesList} search={search} />
                }
            </div>
        </section>
    );
}