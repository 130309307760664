import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { orderedProducts } from "../../Datas/Datas";
import SideBar from "./SideBar";

const Order = () => {
    const navigate = useNavigate();
    const [showCancelModal, SetShowCancelModal] = useState(false);
    const handleShowModal = () => SetShowCancelModal(!showCancelModal);

    return (
        <div className="px-2 pt-2">
            <div className="grid grid-cols-12 items-center mb-4">
                <div className="col-span-2"></div>
                <div className="col-span-8 flex items-center justify-center">
                    <p className="text-[#000] dark:text-white font-bold text-[20px]">
                        Orders
                    </p>
                </div>
                <div className="col-span-2 flex items-center justify-end">
                    <div className="rounded-lg  px-2 pt-1">
                        <SideBar />
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center mb-3">
                <p className="text-[12px] font-semibold dark:text-white">Purchased on Feb 21, 2024</p>
                <div className="text-[#000] dark:text-white ">
                    <Button
                        className="bg-green-300 text-[10px] text-[#000] dark:text-white px-2 py-1 shadow-none"
                        onClick={() => navigate("/orderdetails")}
                    >
                        Track All Orders
                    </Button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-[6px]">
                {orderedProducts.map((data) => (
                    <div className="mb-5" key={data.id}>
                        <img
                            src={data.imgUrl}
                            alt={data.name}
                            onClick={() => navigate("/orderdetails")}
                            className="img-shadow rounded-2xl h-[10rem] w-full object-cover bg-[#fff]"
                        />
                        <div className="text-[#000] dark:text-white mt-2 px-1 text-start">
                            <p className="line-clamp-1 text-[13px] font-semibold">
                                {data.name}
                            </p>
                            <p className="line-clamp-2 leading-3 text-[12px]">{data.description}</p>
                            <p className="text-[10px] text-gray-700 dark:text-gray-600 font-bold py-[2px]">
                                <span className="font-bold text-black dark:text-white">Ordered Date: </span>
                                {data.orderDate}
                            </p>

                            <p className="text-[10px] font-bold text-yellow-800">{data.reviews} Total Reviews</p>

                            <p className="text-[10px] leading-3 font-bold text-green-700 dark:text-green-500">
                                <p className="font-bold text-black dark:text-white">Expected Delivery:</p>
                                {data.expectedDeliverDate}
                            </p>

                            <p className="line-clamp-1 text-[10px] py-[2px] font-bold">Ordered Quantity: {data.qty}</p>

                            <p className="text-[10px] leading-3 font-bold">Manufacturer: <p className="font-normal">{data.manufacturer}</p></p>

                            <div className="flex justify-between gap-4 items-center mt-[5px]">
                                <Button
                                    className="bg-gray-300 text-[9px] text-[#000] px-2 py-1 shadow-none"
                                    onClick={() => navigate("/orderdetails")}
                                >
                                    Track
                                </Button>
                                <Button
                                    className="bg-gray-300 w-full text-[9px] text-[#000] px-1 py-1 shadow-none"
                                    onClick={() => handleShowModal()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {showCancelModal && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
                    <div className="bg-white border border-gray-400 dark:bg-black p-4 rounded-md m-4">
                        <p className="text-center  dark:text-white mb-4 text-[12px]">Are you sure you want to cancel the order?</p>
                        <div className="flex justify-around">
                            <button
                                onClick={handleShowModal}
                                className="bg-red-500 hover:bg-red-600 text-white text-[12px] px-4 py-1 rounded"
                            >
                                Yes
                            </button>
                            <button
                                onClick={handleShowModal}
                                className="bg-gray-300 hover:bg-gray-400 text-black text-[12px] px-4 py-1 rounded"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Order;
