import React, { useEffect, useState } from "react"
import CreatedGroupProfileSection from "./CreatedGroupProfileSection"
import { useDispatch, useSelector } from "react-redux"
import { groupStatusChange } from "../../Redux/ApiSlice/Group/GroupSlice"
import { LockConfirmationModal } from "../Modals/UserCreatedGroup/LockConfirmationModal"
import toast from "react-hot-toast"

const CreatedGroupInfo = () => {
    const dispatch = useDispatch()
    const groupDetails = useSelector((state) => state.group)

    const [openTool, setOpenTool] = useState(false);
    const [groupLock, setGroupLock] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpenTool = () => setOpenTool(!openTool);

    const handleOpen = (value) => {
        if (value) {
            setGroupLock(value);
        } else {
            setOpen(value);
        }
    };

    const handleChangeStatusOfGroup = async () => {
        try {
            const responce = await dispatch(groupStatusChange(groupDetails?.data.id))
            handleOpen(false)
            toast.success(responce?.payload?.detail)
            setGroupLock(!groupLock)
        } catch (error) {
            console.log("error")
        }
    }

    const formatDate = (isoString) => {
        const date = new Date(isoString);

        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-US', options).toUpperCase();
    };

    useEffect(() => {
        setGroupLock(groupDetails?.data?.group_type === "private" ? true : false)
    }, [groupDetails])

    return (
        <div className="bg-white dark:bg-black h-full">
            <div className="w-full flex justify-center items-center absolute top-0">
                <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                    Copyright © 2025 Galileo One Inc
                </p>
                {/* <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                    CC GROUP OWNER INFO PAGE - FE - V4 / JUNE 25, 2024
                </p> */}
            </div>
            <CreatedGroupProfileSection
                setOpen={setOpen}
                handleOpenTool={handleOpenTool}
                groupLock={groupLock}
                groupId={groupDetails?.data.id}
                groupDetails={groupDetails?.data}
            />

            <div className="mt-2 px-4 pt-2 border-t border-gray-300 dark:border-gray-700">
                <p className="text-black dark:text-white text-[18px] font-bold pb-1">Rules</p>
                <p className="text-black dark:text-white text-[13px]">{groupDetails?.data?.rules}</p>
                {/* <p className="text-black dark:text-white text-[13px]">1. No Swearing</p>
                <p className="text-black dark:text-white text-[13px]">2. Only one post per user per day</p>
                <p className="text-black dark:text-white text-[13px]">3. No spams</p>
                <p className="text-black dark:text-white text-[13px]">4. Don't pretend to be someone else</p> */}
            </div>

            <div className="mt-3 px-4">
                <p className="text-black dark:text-white text-[18px] font-bold pb-1">Disclaimers</p>
                <p className="text-black dark:text-white text-[13px]">1. We do not represent ferrari</p>
                <p className="text-black dark:text-white text-[13px]">2. We do not conduct car meet-ups</p>
            </div>

            <div className="mt-3 px-4">
                <p className="text-black dark:text-white text-[17px] font-bold">Date Created : {formatDate(groupDetails?.data?.created_at)}</p>
            </div>

            <LockConfirmationModal
                open={open}
                handleOpen={handleOpen}
                groupLock={groupLock}
                handleChangeStatusOfGroup={handleChangeStatusOfGroup}
            />
        </div>
    )
}

export default CreatedGroupInfo