import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const search = createAsyncThunk("search", async (query) => {
    const response = await axiosInstance.get(`/search/?q=${query}`, {});
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const searchOnlyUsers = createAsyncThunk("searchOnlyUsers", async (query) => {
    const response = await axiosInstance.get(`/search_users/?q=${query}`, {});
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});
export const searchTrendingUsers = createAsyncThunk("searchTrendingUsers", async () => {
    const response = await axiosInstance.get(`/trending-users/`);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});


export const groupSearch = createAsyncThunk("groupSearch", async (query) => {
    const response = await axiosInstance.get(`/group/groups/search/?q=${query}`, {});
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const userSearch = createAsyncThunk("userSearch", async (query) => {
    const response = await axiosInstance.get(`/group/groups/search/?q=${query}`, {});
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const recentSearch = createAsyncThunk("recentSearch", async (query) => {
    const response = await axiosInstance.get(`/search/recent/`);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const deleteAllRecentSearch = createAsyncThunk("deleteAllRecentSearch", async () => {
    const response = await axiosInstance.delete(`/search/recent/clear/`);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const deleteIndividualRecentSearch = createAsyncThunk("deleteIndividualRecentSearch", async ({ searchId }) => {
    const response = await axiosInstance.delete(`/search/recent/${searchId}/delete/`);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});


export const SearchSlice = createSlice({
    name: "SearchSlice",
    initialState: {
        SearchResult: [],
        recentSearch: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        // HomepageVideo action promises
        builder.addCase(search.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(search.fulfilled, (state, action) => {
            state.SearchResult = action.payload;
            state.loading = false;
        });
        builder.addCase(search.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //user search user list
        builder.addCase(searchOnlyUsers.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(searchOnlyUsers.fulfilled, (state, action) => {
            state.SearchResult = action.payload;
            state.loading = false;
        });
        builder.addCase(searchOnlyUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //trending user list
        builder.addCase(searchTrendingUsers.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(searchTrendingUsers.fulfilled, (state, action) => {
            state.SearchResult = action.payload;
            state.loading = false;
        });
        builder.addCase(searchTrendingUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(groupSearch.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(groupSearch.fulfilled, (state, action) => {
            state.SearchResult = action.payload;
            state.loading = false;
        });
        builder.addCase(groupSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(userSearch.pending, (state) => {
            state.loading = true;
            state.SearchResult = [];
        });
        builder.addCase(userSearch.fulfilled, (state, action) => {
            state.SearchResult = action.payload;
            state.loading = false;
        });
        builder.addCase(userSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(recentSearch.pending, (state) => {
            state.loading = true;
            state.recentSearch = [];
        });
        builder.addCase(recentSearch.fulfilled, (state, action) => {
            state.recentSearch = action.payload;
            state.loading = false;
        });
        builder.addCase(recentSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteAllRecentSearch.pending, (state) => {
            state.loading = true;
            state.recentSearch = [];
        });
        builder.addCase(deleteAllRecentSearch.fulfilled, (state) => {
            state.recentSearch = [];
            state.loading = false;
        });
        builder.addCase(deleteAllRecentSearch.rejected, (state, action) => {
            state.loading = false;
            state.recentSearch = [];
            state.error = action.payload;
        });
        builder.addCase(deleteIndividualRecentSearch.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteIndividualRecentSearch.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteIndividualRecentSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default SearchSlice.reducer;
