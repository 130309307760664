import React, { Fragment, useEffect, useRef, useState } from "react";
import VideoCard from "../Common/VideoCard";
import useSelectVideo from "../Hooks/useSelectVideo";
import { useDispatch, useSelector } from "react-redux";
import AccountTab from "../Component/AccountPage/AccountTab";
import AccountSidebar from "../Component/AccountPage/AccountSidebar";
import AccountProfileSection from "../Component/AccountPage/AccountProfileSection";
import { AccountProfile, AccountProfilePosts, UserAccountFollowerlist, UserAccountFollowinglist } from "../Redux/ApiSlice/AccountProfileSlice";
import AccountTabModal from "../Component/AccountPage/AccountTabModal";
import { useOutsideClick } from "../Hooks/useOutsideClick";

export default function AccountPage() {
    const dispatch = useDispatch();
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile, User_posts } = ProfileDetails;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const ref = useRef(null);

    const handleClickOutside = () => {
        setShow(null);
        setActiveTab("");
    };
    useOutsideClick(ref, handleClickOutside);


    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const selectVideo = useSelectVideo();

    const handleSelectVideo = async (id) => {
        selectVideo(id, User_posts);
    };

    useEffect(() => {
        dispatch(AccountProfile());
        dispatch(AccountProfilePosts(User_profile.id));
        dispatch(UserAccountFollowerlist());
        dispatch(UserAccountFollowinglist());
    }, [dispatch, User_profile.id]);

    return (
        <Fragment>

            <div className={`bg-white dark:bg-black dark:text-white h-full ${isDrawerOpen ? "" : "overflow-y-scroll"} overflow-hidden relative`}>
                <p className="text-[#ff3b3b] text-center w-full mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    Copyright © 2025 Galileo One Inc
                </p>
                {/* <p className="text-[#ff3b3b] text-center w-full text-[12px] font-[700] flex justify-center z-50 lgtext-[10px] uppercase">
                    CC / ACCOUNT PAGE / FE / V12 / Oct 11 /2024
                </p> */}

                <AccountProfileSection
                    ref={ref}
                    show={show}
                    setShow={setShow}
                    setActiveTab={setActiveTab}
                    isDrawerOpen={isDrawerOpen}
                    handleOpenSideBar={openDrawer}
                    handleCloseSideBar={closeDrawer}
                />

                <AccountTab activeTab={""} />

                <div className="mb-2">
                    {User_posts?.length > 0 && (
                        <div className="grid grid-cols-2 gap-2 px-2 w-full h-full">
                            {User_posts?.map((item) => (
                                <VideoCard
                                    id={item.id}
                                    body={item.body}
                                    belowDecs={true}
                                    threeDots={false}
                                    video={item.video_url}
                                    likes_count={item.likes_count}
                                    view_count={item.view_count}
                                    selectVideo={handleSelectVideo}
                                    avatar_url={item.created_by.avatar_url}
                                />
                            ))}
                        </div>
                    )}
                </div>

                {User_posts?.length === 0 && (
                    <div className="flex justify-center items-start w-full">
                        <p className="text-[#a9a8a8] text-[12px] font-bold mt-5">
                            No Posts Yet
                        </p>
                    </div>
                )}

                <div className="grid grid-cols-12 overflow-hidden">
                    <div className="col-span-12">
                        <AccountSidebar
                            isDrawerOpen={isDrawerOpen}
                            setIsDrawerOpen={setIsDrawerOpen}
                            openDrawer={openDrawer}
                            closeDrawer={closeDrawer}
                        />
                    </div>
                </div>
            </div>

            {show && (
                <div className={"absolute h-full w-full top-0 overflow-hidden"}>
                    <div
                        className="absolute h-screen w-full top-0 opacity-100 bg-opacity-70 bg-black z-50"
                        onClick={handleClickOutside}
                    />
                    <div
                        ref={ref}
                        className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
                    >
                        <AccountTabModal
                            ref={ref}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
}
