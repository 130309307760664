import React, { useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function SetPassword() {
   const navigate = useNavigate();
   const [password, setPassword] = useState("");
   const [error, setError] = useState("");


   const handleInputChange = (e) => {
      setPassword(e.target.value);
   }

   const handleSubmit = () => {
      if (password.length !== 6) {
        setError("Password must be exactly 6 digits.");
        alert("Password must be exactly 6 digits.");
      } else {
         localStorage.setItem("Privatepassword", password);
         navigate("/account/private");
        setError("");
        alert("Password is valid!");
        // Proceed with further logic (e.g., form submission)
      }
    };
   return (
      <div className=" w-full h-full p-2">
         <div className="flex justify-start text-start">
            <IoArrowBackCircle
               onClick={() => navigate(-1)}
               className="text-[1.5rem] dark:text-white cursor-pointer"
            />
         </div>
         <div className="flex flex-col justify-center items-center mt-3">
            <h1 className="dark:text-white font-bold text-[1.5rem] mb-5">
               Set New Password
            </h1>
            <div className="mb-4 w-full px-4">
               <label
                  htmlFor="username"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
               >
                  New Password
               </label>
               <input
                  type="text"
                  value={password}
                  onChange={handleInputChange}
                  maxLength={6}
                  placeholder="Enter 6-digit password"
          
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <button
            onClick={handleSubmit}
               className="bg-orange-700 hover:bg-orange-800 dark:bg-gray-700 dark:hover:bg-[#545353] text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
            >
               Save
            </button>
         </div>
      </div>
   );
}
