import React from "react";
import { useNavigate } from "react-router-dom";
import { groupSearchComment } from "../../Datas/Datas";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";

export default function DraftNewMessages() {
    const navigate = useNavigate();

    return (
        <section className="h-full w-full bg-white dark:bg-black overflow-y-scroll pt-4">
            {/* <p className="uppercase font-bold text-[10px] text-red-700 w-full text-center absolute top-0">
                CC Draft Message / FE / V3 / June 10, 2024
            </p> */}
            <p className="uppercase font-bold text-[10px] text-red-700 w-full text-center absolute top-0">
                Copyright © 2025 Galileo One Inc
            </p>

            <div className="grid grid-cols-12 px-2 pb-2 border-b border-gray-400 dark:border-gray-800">
                <div className="col-span-2 flex items-center justify-start">
                    <FaArrowAltCircleLeft
                        onClick={() => navigate(-1)}
                        className="text-black text-[17px] cursor-pointer"
                    />
                </div>
                <div className="col-span-8 flex items-center justify-center">
                    <p className="text-[16px] text-black dark:text-white font-bold">New Message</p>
                </div>
            </div>

            <div className="flex items-center gap-x-2 px-2 py-1.5 border-b border-gray-400 dark:border-gray-800">
                <div className="w-full flex justify-start relative">
                    <FaSearch className="absolute top-2 left-2 cursor-pointer text-black" />
                    <input
                        type="text"
                        placeholder="Search for people"
                        className="rounded-full w-full p-1 pl-[30px] pr-2 outline-none bg-gray-100 placeholder:text-[14px]"
                    />
                </div>
                <button
                    onClick={() => navigate("/mobileMessages")}
                    className="w-[8rem] text-white bg-black dark:bg-gray-900 rounded text-[12px] px-2 py-1.5 font-semibold h-full"
                >
                    Group Chat
                </button>
            </div>

            <div className="overflow-y-scroll no-scrollbar mb-2 h-[-webkit-fill-available] absolute w-full">
                <p className="text-[12px] font-bold text-gray-600 dark:text-gray-500 pt-2 px-2">Recommended</p>
                {groupSearchComment.map((data) => (
                    <div className="flex items-center gap-x-1 border-b border-gray-300 dark:border-gray-800 px-2 py-1.5" key={data.id}>
                        <img
                            alt={data.userName}
                            src={data.profileUrl}
                            className="w-9 h-9 rounded-full object-cover cursor-pointer bg-gray-200"
                        />
                        <p className="text-[14px] text-black dark:text-white ml-2 cursor-pointer line-clamp-1">{data.userName}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}
