import React, { Fragment, useState } from "react";
import { HiMiniBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import SliderMenu from "./SliderMenu";
import News from "./News";

const NeighborSaved = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("news");

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Fragment>
            <div className="sticky top-0 bg-white dark:bg-black pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] dark:text-gray-200 cursor-pointer"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px] dark:text-gray-200">
                            Your Neighborhood
                        </p>
                        <p className="flex items-center text-[10px] text-gray-500">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            Downtown, Springfield
                        </p>
                    </div>
                    <HiMiniBars3
                        onClick={toggleMenu}
                        className="text-[24px] dark:text-gray-400 cursor-pointer"
                    /> 
                </div>
            </div>

            <div className="p-2 flex items-center justify-between gap-x-2 border-b border-gray-400">
                <div
                    onClick={() => setActiveTab("news")}
                    className={`flex items-center justify-center text-white 
                            h-[30px] w-full cursor-pointer rounded-full transition-colors duration-300 
                            ${activeTab === "news" ? "bg-[#8a8686]" : "bg-black dark:bg-[#2b2b2b] hover:bg-[#FF9900]"}
                        `}
                >
                    <p className="text-[12px]">
                        Saved News
                    </p>
                </div>
                <div
                    onClick={() => setActiveTab("pinboard")}
                    className={`flex items-center justify-center text-white 
                            h-[30px] w-full cursor-pointer rounded-full transition-colors duration-300 
                            ${activeTab === "pinboard" ? "bg-[#8a8686]" : "bg-black dark:bg-[#2b2b2b] hover:bg-[#FF9900]"}
                        `}
                >
                    <p className="text-[12px]">
                        Saved PinBoard
                    </p>
                </div>
            </div>

            {activeTab === "news" &&
                <div className="mt-2">
                    <News />
                </div>
            }

            {activeTab === "pinboard" &&
                <div className="mx-2 mt-10">
                    <p className="text-center text-gray-300 text-[14px]">
                        No saved pinboard yet
                    </p>
                </div>
            }

            {menuOpen &&
                <SliderMenu
                    toggleMenu={toggleMenu}
                    onClose={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    )
}

export default NeighborSaved