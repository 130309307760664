import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getMessagesChatList } from "../../Redux/ApiSlice/MessagesSlice";
import Skeleton from "react-loading-skeleton";

export default function MessagesRecent({ search }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [recentData, setRecentData] = useState([])
    const MessagesData = useSelector((state) => state.messages);
    const { messagesList, loading } = MessagesData

    useEffect(() => {
        if (search) {
            const filteredData = messagesList.filter((item) => {
                return (item.users[0]?.first_name.toLowerCase().includes(search.toLowerCase()) || item.users[0]?.last_name.toLowerCase().includes(search.toLowerCase()))
            })
            setRecentData(filteredData)
        } else {
            setRecentData(messagesList)
        }
    }, [search, messagesList])

    const fetchData = useCallback(async () => {
        await dispatch(getMessagesChatList())
    }, [dispatch])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Fragment>
            {loading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                <div className="grid grid-cols-12 p-2 py-0 mt-1" key={item}>
                    <div className="col-span-2">
                        <Skeleton baseColor="#202020" highlightColor="#444" height={30} width={30} circle={true} />
                    </div>
                    <div className="col-span-6 flex flex-col  items-start">
                        <div>
                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={150} />
                        </div>
                        <div>
                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={100} />
                        </div>
                    </div>
                    <div className="col-span-4 flex justify-end items-center">
                        <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={30} />
                    </div>
                </div>
            ))}

            {!loading && recentData?.map((item, index) => (
                <div
                    key={index}
                    className="px-2 py-1.5 bg-white dark:bg-gray-900 border-b border-gray-300 dark:border-black relative cursor-pointer transition-colors duration-150"
                >
                    <div className="flex flex-row w-full items-start">
                        <div className="w-16 h-11">
                            <Avatar
                                alt="avatar"
                                src={item.users[0]?.avatar_url}
                                onClick={() => navigate(`/otheruseraccount/${item.users[0]?.id}`)}
                                className="h-11 w-11 shadow-2xl object-cover bg-gray-200"
                            />
                        </div>
                        <div
                            className="flex flex-col mt-1 w-full"
                            onClick={() => navigate(`/mobileMessages/userChat/${item.id}`)}
                        >
                            <div className="flex items-start justify-between">
                                <p className="font-semibold text-[13px] text-black dark:text-white">
                                    {item.users[0]?.first_name} {item.users[0]?.last_name}
                                </p>
                                <p className="text-[10px] line-clamp-1 text-gray-500 dark:text-gray-700">
                                    {item?.latest_message?.sent_time ? item?.latest_message?.sent_time : "N/A"}
                                </p>
                            </div>
                            <p className="text-[10px] line-clamp-1 text-gray-700 dark:text-gray-400">
                                {item?.latest_message?.body ? item?.latest_message?.body : "No Messages"}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
            {recentData?.length === 0 && !loading && <div className="flex justify-center items-center h-[50vh]">
                <p className="text-[14px] text-gray-400 dark:text-white">No Messages Found</p>
            </div>}
        </Fragment>
    );
}
