import React from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";

const NewsCreate = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-gray-100 dark:dark:bg-black h-full overflow-y-scroll">
            {/* Header */}
            <div className="sticky top-0 bg-gray-100 dark:bg-black">
                <div className="flex items-center justify-center border-b border-gray-400 dark:border-gray-800 p-2 relative">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] dark:text-white cursor-pointer absolute left-2"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px] dark:text-white">
                            Create Your News
                        </p>
                    </div>
                </div>
            </div>

            {/* Form */}
            <div className="max-w-lg mt-1 px-4 mb-5">
                {/* Header */}
                <div className="mb-3">
                    <label htmlFor="title" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        Header
                    </label>
                    <input
                        type="text"
                        id="header"
                        name="header"
                        placeholder="Enter post header"
                        className="mt-1 block w-full placeholder:text-[13px] dark:bg-[#2b2b2b] dark:text-gray-200 border border-gray-300 dark:border-gray-800 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Title */}
                <div className="mb-3">
                    <label htmlFor="title" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        News Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="This is what will show on the news"
                        className="mt-1 block w-full placeholder:text-[13px] dark:bg-[#2b2b2b] dark:text-gray-200 border border-gray-300 dark:border-gray-800 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Description */}
                <div className="mb-3">
                    <label htmlFor="description" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Write a description..."
                        className="mt-1 block w-full placeholder:text-[13px] dark:bg-[#2b2b2b] dark:text-gray-200 border border-gray-300 dark:border-gray-800 rounded-md shadow-sm px-2 py-1 resize-none focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Post Image */}
                <div className="mb-3">
                    <label htmlFor="postImage" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        Post Image
                    </label>
                    <input
                        type="file"
                        id="postImage"
                        name="postImage"
                        className="mt-1 block w-full placeholder:text-[13px]  text-sm text-gray-500 file:mr-4 file:py-[2px] file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-black dark:file:bg-[#2b2b2b] file:text-white hover:file:bg-gray-800 hover:file:cursor-pointer"
                    />
                </div>

                {/* Location */}
                <div className="mb-3">
                    <label htmlFor="location" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        Location
                    </label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Enter location"
                        className="mt-1 block w-full placeholder:text-[13px] dark:bg-[#2b2b2b] dark:text-gray-200 border border-gray-300 dark:border-gray-800 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Tag */}
                <div className="mb-3">
                    <label htmlFor="tag" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        Tag
                    </label>
                    <input
                        type="text"
                        id="tag"
                        name="tag"
                        placeholder="Notify neighbors"
                        className="mt-1 block w-full placeholder:text-[13px] dark:bg-[#2b2b2b] dark:text-gray-200 border border-gray-300 dark:border-gray-800 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Responses */}
                <div className="mb-3">
                    <label htmlFor="tag" className="block text-[14px] font-medium dark:text-gray-300 text-black">
                        Allow Responses
                    </label>
                    <div className="mt-1 flex items-center space-x-2">
                        <input
                            id="yes"
                            value="yes"
                            type="radio"
                            name="responses"
                            className="text-cyan-500 focus:ring-cyan-500"
                        />
                        <label htmlFor="yes" className="text-black dark:text-gray-300">
                            Yes
                        </label>
                        <input
                            id="no"
                            value="no"
                            type="radio"
                            name="responses"
                            className="text-cyan-500 focus:ring-cyan-500"
                        />
                        <label htmlFor="no" className="text-black dark:text-gray-300">
                            No
                        </label>
                    </div>
                </div>

                {/* Submit Button */}
                <button className="w-full bg-black dark:bg-[#2b2b2b] text-white font-semibold py-2 px-4 rounded-md shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    Post
                </button>
            </div>
        </div>
    )
}

export default NewsCreate