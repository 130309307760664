import React from "react";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";

export function PostedTab({ User_posts }) {
    const selectVideo = useSelectVideo();
    const handleSelectVideo = async (id) => {
        selectVideo(id, User_posts);
    };

    return (
        <>
            {User_posts.length ? (
                <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar">
                    {User_posts?.map((item) => (
                        <VideoCard
                            id={item.id}
                            body={item.post_description}
                            belowDecs={true}
                            threeDots={false}
                            video={item.video_url}
                            views={item.views}
                            // videosList={hashTagList}
                            likes_count={item.likes_count}
                            selectVideo={handleSelectVideo}
                            // handleDeletePost={handleDeletePost}
                            avatar_url={item.created_by.avatar_url}
                        />
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-start w-full">
                    <p className="text-[#a9a8a8] text-[12px] font-bold mt-5">
                        No Posts Yet
                    </p>
                </div>
            )}
        </>
    );
}
