import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";
import RepostEmpty from "../../Assets/YourAccountIcons/RepostEmptyData.png";
import { getReshare } from "../../Redux/ApiSlice/ResharedSlice";
import Skeleton from "react-loading-skeleton";

export function RepostTab() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [reposts, setReposts] = useState([]);
    const selectVideo = useSelectVideo();

    const handleSelectVideo = async (id) => {
        const repostData = reposts.map((item) => item?.original_post);
        selectVideo(id, repostData);
    };

    const fetchReSharedData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await dispatch(getReshare());
            if (response?.payload?.results?.reposts) {
                setReposts(response?.payload?.results?.reposts);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchReSharedData();
    }, [fetchReSharedData]);

    return (
        <Fragment>
            {reposts.length !== 0 ? (
                <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar">
                    {reposts?.map((item) => (
                        <VideoCard
                            id={item?.original_post.id}
                            body={item?.original_post.post_description}
                            belowDecs={true}
                            threeDots={false}
                            views={item?.original_post.views}
                            video={item?.original_post.video_url}
                            likes_count={item?.original_post.likes_count}
                            selectVideo={handleSelectVideo}
                            avatar_url={item?.original_post.created_by.avatar_url}
                            view_count={item?.original_post.view_count}
                        />
                    ))}
                </div>
            ) : loading ? (
                <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar">
                    {[1, 2, 3, 4, 5, 6].map((data) => (
                        <div key={data} className="col-span-1">
                            <Skeleton
                                height={170}
                                width={150}
                                loading={loading}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-center flex-col">
                    <img src={RepostEmpty} alt="img" className="h-32 w-32" />
                    <p className="text-[10px] text-[#c0b8b8] font-semibold">
                        No Repost yet
                    </p>
                </div>
            )}
        </Fragment>
    );
}
