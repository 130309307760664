import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { IoCaretBackCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { suggestedMyFriendship } from "../../Redux/ApiSlice/RecommendationSlice";

const FriendshipSuggestion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, myFriendshipSuggestion, error } = useSelector((state) => state.recommendationUser);

    useEffect(() => {
        dispatch(suggestedMyFriendship());
    }, [dispatch]);

    const handleFollowFun = async (id) => {
        try {
            const response = await dispatch(UserAccountFollow(id));
            if (response.meta.requestStatus === 'fulfilled') {
                // Update the state or perform any other actions after a successful follow
                dispatch(suggestedMyFriendship());
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="bg-white dark:bg-black">
            <div className="sticky top-0">
                <div className="flex items-center justify-center bg-black relative p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[18px] text-white absolute top-1/2 left-2 transform -translate-y-1/2 cursor-pointer"
                    />
                    <p className="text-[14px] font-semibold text-white">
                        Friendship Suggestion
                    </p>
                </div>
            </div>

            {loading &&
                <div className="flex justify-center items-center h-[70vh]">
                    <ClipLoader
                        size={40}
                        color={"#123abc"}
                        loading={loading}
                    />
                </div>
            }

            {error &&
                <div className="flex justify-center items-center h-[70vh]">
                    <p className="text-[10px] text-red-500">Error: {error}</p>
                </div>
            }

            {!loading && !error && myFriendshipSuggestion.length === 0 && (
                <div className="flex justify-center items-center h-[69vh]">
                    <p className="text-[12px] text-gray-500">
                        No Friendship Suggestion Found.
                    </p>
                </div>
            )}

            {myFriendshipSuggestion.map((data) => (
                <div key={data.id} className="p-2 border-b border-gray-300 dark:border-gray-700 flex items-start w-full">
                    <div className="h-10 w-14">
                        <img
                            alt={data.name}
                            src={data.suggested_user.avatar_url}
                            className="h-10 w-10 rounded-full object-cover cursor-pointer border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800"
                        />
                    </div>
                    <div className="flex justify-between w-full">
                        <div className="flex flex-col mt-[2px]">
                            <p className="text-[13px] font-semibold line-clamp-1 cursor-pointer text-black dark:text-white">
                                {data.suggested_user.first_name} {data.suggested_user.last_name}
                            </p>
                            <div className={`flex items-center gap-x-3 ${data.mutual_friends.length > 0 ? "mt-0" : "mt-0.5"}`}>
                                <p className="text-[10px] text-gray-500">
                                    Followers <span className="font-semibold">{data.suggested_user.followers_count}</span>
                                </p>
                                <p className="text-[10px] text-gray-500">
                                    Following <span className="font-semibold">{data.suggested_user.following_count}</span>
                                </p>
                            </div>
                            <div className="flex items-center gap-x-2 mt-1">
                                {data.mutual_friends.length > 0 && (
                                    <>
                                        <div className="flex -space-x-2 overflow-hidden">
                                            {data.mutual_friends.map((friends, index) => (
                                                <>
                                                    {index < 3 && (
                                                        <img
                                                            alt="img"
                                                            src={friends?.avatar_url}
                                                            className="inline-block h-5 w-5 rounded-full object-cover cursor-pointer"
                                                        />
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                        <p className="text-[10px] text-gray-400 cursor-pointer">
                                            {data.mutual_friends.length > 3
                                                ? `and ${data.mutual_friends.length - 3} others`
                                                : data.mutual_friends.length}{" "}
                                            mutual
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex items-start">
                            <button
                                onClick={() => handleFollowFun(data.recommended_user.id)}
                                className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full text-[12px]"
                            >
                                Follow <FaUserPlus />
                            </button>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default FriendshipSuggestion