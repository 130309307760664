import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle, IoCloseCircle } from "react-icons/io5";
import { sugestedProducts } from "../../Datas/Datas";

const Favorites = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");

    const filteredProducts = sugestedProducts.filter(product =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Fragment>
            <div className="sticky top-0 z-10">
                <div className="grid grid-cols-12 px-4 py-2 bg-black  items-center">
                    <div className="col-span-2 flex justify-start">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="text-[20px] cursor-pointer  text-white"
                        />
                    </div>
                    <div className="col-span-8 flex justify-center">
                        <p className="text-center text-[14px] text-white font-semibold">Your Favorites ({sugestedProducts.length})</p>
                    </div>
                </div>
                <div className="px-3 pt-3 pb-1 bg-white dark:bg-black relative">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search for products"
                        className="w-full bg-[#fff] pl-3 pr-8 py-[6px] text-[14px] rounded-full focus:outline-none border border-gray-400"
                    />
                    {searchQuery && (
                        <IoCloseCircle
                            onClick={() => setSearchQuery('')}
                            className="absolute right-4 top-[19px] text-[20px] cursor-pointer text-gray-500"
                        />
                    )}
                </div>
            </div>

            <div className="grid grid-cols-12 gap-3 mt-3 px-3 dark:bg-black dark:text-white">
                {filteredProducts.length > 0 ? (
                    filteredProducts.map((data) => (
                        <div className="col-span-6 mb-5" key={data.id}>
                            <img
                                alt={data.name}
                                src={data.imgUrl}
                                className="img-shadow rounded-md object-cover bg-[#fff] cursor-pointer h-[9rem] p-3 w-full"
                            />
                            <div className="flex items-center justify-between gap-x-5 mt-2">
                                <p className="text-[11px] line-clamp-1 cursor-pointer">
                                    {data.name}
                                </p>
                                <p className="text-[11px] font-semibold cursor-pointer">
                                    ${data.price}
                                </p>
                            </div>
                            <div className="flex items-center justify-between gap-x-2 mt-1">
                                <button className="w-full text-[11px] text-white bg-[#000000] dark:bg-white dark:text-black hover:opacity-95 p-1 rounded-sm">
                                    Remove
                                </button>
                                <button className="w-full text-[11px] text-white bg-[#126C02] hover:opacity-95 p-1 rounded-sm">
                                    Buy
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-span-12 text-center text-[14px] text-gray-500">
                        No results found
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default Favorites;