import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";


export const GetComment = createAsyncThunk(
    "GetComment",
    async (id) => {
        const response = await axiosInstance.get(`/post/${id}/comments/`);
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const CreateComment = createAsyncThunk(
    "CreateComment",
    async (data) => {
        const response = await axiosInstance.post(`post/comment/${data.key}/`, {
            post_comment_box: data.body,
        });
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const DeleteComment = createAsyncThunk(
    "DeleteComment",
    async (data) => {
        const response = await axiosInstance.delete(`post/delete_comment/${data}/`);
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const UpdateComment = createAsyncThunk(
    "UpdateComment",
    async (data) => {
        const response = await axiosInstance.patch(
            `/post/update_comment/${data.key}/`,
            {
                post_comment_box: data.body,
            }
        );
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);

export const ReplayComment = createAsyncThunk(
    "ReplayComment",
    async (data) => {
        const response = await axiosInstance.post(
            `post/comment/${data.key}/replies/`,
            {
                post_comment_box: data.body,
            }
        );
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const CommentDelete = createAsyncThunk(
    "CommentDelete",
    async (data) => {
        const response = await axiosInstance.delete(
            `post/comment/${data}/delete_reply/`
        );
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const CommentLike = createAsyncThunk(
    "CommentLike",
    async (id) => {
        const response = await axiosInstance.post(
            `/group/comment/${id}/like/`
        );
        try {
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);




export const commentsSlice = createSlice({
    name: "commentsSlice",
    initialState: {
        postCommants: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        /* HomeVideoFootage comments */
        builder.addCase(GetComment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetComment.fulfilled, (state, action) => {
            state.postCommants = action.payload;
            state.loading = false;
        });
        builder.addCase(GetComment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        /* ReplayComment comments */
        builder.addCase(ReplayComment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(ReplayComment.fulfilled, (state, action) => {
            // state.postCommants = action.payload;
            state.loading = false;
        });
        builder.addCase(ReplayComment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        /* CommentDelete comments */
        builder.addCase(CommentDelete.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            CommentDelete.fulfilled,
            (state, action) => {
                // state.postCommants = action.payload;
                state.loading = false;
            }
        );
        builder.addCase(
            CommentDelete.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }
        );
    },
});

export default commentsSlice.reducer;
