import React, { useEffect, useRef, useState } from "react";
import ContactUsCard from "../../Component/ContactUs.jsx/ContactUsCard";
import FeedBackFromCard from "../../Component/ContactUs.jsx/FeedBackFromCard";
import helpHandImage from "../../../src/Assets/HelpPage/help 1.svg";
import newsReport from "../../../src/Assets/HelpPage/news-report 1.svg";
import MessageImg from "../../Assets/HelpPage/questions 1.svg";
import contentMarketing from "../../Assets/HelpPage/content-marketing 1.svg";
import { useNavigate } from "react-router-dom";
import { ccLogo } from "../../Datas/Assests";
import { createFeedback } from "../../Redux/ApiSlice/ContactUs";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

export default function ContactUsMain({ isWeb }) {
    const navigate = useNavigate();
    const [showFeedBack, setShowFeedBack] = useState(false);
    const cardRef = useRef(null);
    const dispatch = useDispatch();
    const [feedbackData, setFeedbackData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        feedback: ""
    });

    const handleInputChange = (e) => {
        setFeedbackData({ ...feedbackData, [e.target.name]: e.target.value });
    }

    const handleFeedback = async () => {
        if (feedbackData.first_name === "" || feedbackData.last_name === "" || feedbackData.email === "" || feedbackData.feedback === "") {
            return toast.error("Please fill all the fields");
        }
        try {
            dispatch(createFeedback(feedbackData));
            toast.success("Feedback submitted successfully");
            setShowFeedBack(!showFeedBack)
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setShowFeedBack(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <section>
            <Toaster position="top-right" />

            <p className="text-[red] text-[10px] w-full text-center font-bold">
                Copyright © 2025 Galileo One Inc
            </p>

            <div className="sticky top-0 z-50">
                <div className="flex justify-center items-center gap-x-4 px-2 py-3 relative bg-white dark:bg-black border-b border-gray-400 dark:border-gray-800 shadow-md">
                    <img
                        src={ccLogo}
                        onClick={() => navigate("/home")}
                        className="h-12 w-12 absolute top-1/2 left-2 transform -translate-y-1/2 cursor-pointer"
                    />
                    <p className="text-[20px] text-[#7e22ce] dark:text-[#b668fa] font-extrabold text-center uppercase">
                        Contact Us
                    </p>
                </div>
            </div>

            <div className={`justify-center items-center gap-4 w-full ${isWeb ? "md:px-40 grid grid-cols-12" : "px-3"}`}>
                <div className={`${isWeb ? "col-span-12 md:col-span-6  w-full p-2" : "w-full"} mt-4 p-2 px-3`}>
                    <ContactUsCard
                        isWeb={isWeb}
                        buttonName={"Visit FAQs"}
                        image={helpHandImage}
                        navigatePath="/contact-us"
                        description={"Have any questions? We're here to assist you. We’ve put together some commonly asked questions to give you more information about CC and how to use it."}
                    />
                </div>
                <div className={`${isWeb ? "col-span-12 md:col-span-6  w-full p-2" : "w-full"} mt-6 p-2 px-3`}>
                    <ContactUsCard
                        isWeb={isWeb}
                        buttonName={"Visit Media"}
                        image={newsReport}
                        navigatePath="/media"
                        description={"Are you working on a 6644 story? If you have any queries, we have answers. On our media page, you will find the media enquiry form as well as our media kit."}
                    />
                </div>
                <div className={`mt-6 p-2 px-3 relative ${isWeb ? "col-span-12 md:col-span-6 w-full p-3" : "w-full"}`}>
                    <ContactUsCard
                        isWeb={isWeb}
                        buttonName={"Give Feedback"}
                        image={MessageImg}
                        handleClick={() => setShowFeedBack(!showFeedBack)}
                        description={"Do you have more questions or any tips to share? Please send us your comments or report any problems you experienced. We read all feedback and address them as soon as possible."}
                    />
                    {showFeedBack && (
                        <div className="absolute top-0 -left-0.5 z-20" ref={cardRef}>
                            <FeedBackFromCard
                                handleInputChange={handleInputChange}
                                handleCancel={() => setShowFeedBack(!showFeedBack)}
                                handleFeedback={handleFeedback}
                            />
                        </div>
                    )}
                </div>
                <div className={`${isWeb ? "col-span-12 md:col-span-6 w-full p-2" : "w-full"} mt-6 p-2 px-3`}>
                    <ContactUsCard
                        isWeb={isWeb}
                        buttonName={"Give Feedback"}
                        image={contentMarketing}
                        subcription={true}
                        description={"Want to receive a monthly email in your inbox with awesome tips on how to use CC resources and more?"}
                    />
                </div>
            </div>
        </section>
    );
}
