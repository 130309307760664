import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";


export const postReshare = createAsyncThunk("postReshare", async ({postId}) => {
  const response = await axiosInstance.post(`/post/repost/${postId}/`, {});
  try {
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});

export const getReshare = createAsyncThunk("getReshare", async () => {
  const response = await axiosInstance.get(`/post/reposts/`);
  try {
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});


export const ReSharedSlice = createSlice({
  name: "ReSharedSlice",
  initialState: {
    reSharedList: [],
      loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postReshare.pending, (state) => {
      state.loading = true;
      state.reSharedList = [];
    });
    builder.addCase(postReshare.fulfilled, (state, action) => {
      state.reSharedList = action.payload;
      state.loading = false;
    });
    builder.addCase(postReshare.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getReshare.pending, (state) => {
      state.loading = true;
      state.reSharedList = [];
    });
    builder.addCase(getReshare.fulfilled, (state, action) => {
      state.reSharedList = action.payload;
      state.loading = false;
    });
    builder.addCase(getReshare.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
   },
});

export default ReSharedSlice.reducer;
