import React, { useCallback, useEffect, useRef, useState } from "react";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
// import { IoCheckbox } from "react-icons/io5";
import {
    HomepageVideoCreateComment,
    HomepageVideoDeleteComment,
    HomepageVideoLike,
    HomepageVideoUpdateComment,
    removeVideoLike,
    setVideoLike,
} from "../../Redux/ApiSlice/HomePageVideoSlice";
import SaveModal from "../../Component/Modals/HomePageModal/saveModal";
import emptyComments from "../../Assets/HomePageIcons/comments.svg";
import Comments from "../../Component/Home/Comments";
import { videoFootageSideIcon } from "../../Datas/Datas";
import Share from "./Share";
import { TiUserAddOutline } from "react-icons/ti";
import { BiCheckDouble } from "react-icons/bi";
import { IoNotifications } from "react-icons/io5";
import { Button } from "@material-tailwind/react";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import ReportModal from "../Modals/ReportModal";
import WelcomeModal from "../Modals/WelcomeModal";
import { GetComment, ReplayComment } from "../../Redux/ApiSlice/CommentsSlice";
import { FaBasketShopping } from "react-icons/fa6";
import { MdGroups } from "react-icons/md";
import { CiStreamOn } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { GiThreeFriends } from "react-icons/gi";
import { videoView } from "../../Redux/ApiSlice/VideoViewSlice";
import { SavedVideosById } from "../../Redux/ApiSlice/SavedVideosSlice";

const HomeVideoView = ({
    id,
    src,
    created_by,
    comments,
    dec,
    fetchData,
    handleReshared,
    user_liked,
    user_saved,
    userreshared,
    newUser,
    handleReadUserStatus,
    resharedPostId,
    rehsaredMesaage,
    setResharedPostId,
    userfollowing,
    handleFollowFun,
    itsNotHome,
    handleSliderStart,
    handleSliderEnd,
    isFollow
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getAllPost = useSelector((state) => state.HomePageVideo);
    const { homePageVideos, currentIndex } = getAllPost;
    const [isSaved, setIsSaved] = useState(false);
    const [likeEmoji, setLikeLikeEmoji] = useState(false);
    const [isLikeAnimating, setIsLikeAnimating] = useState(false);
    const [isRepostAnimating, setIsRepostAnimating] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [commentCount, setCommentCount] = useState(0);
    const [openBottom, setOpenBottom] = useState(false);
    const openDrawerBottom = () => setOpenBottom(true);
    const closeDrawerBottom = () => setOpenBottom(false);
    const [openBottomComments, setOpenBottomComments] = useState(false);
    const [openSaveDrawer, setOpenSaveDrawer] = useState(false);
    const [createCommet, setCreateComment] = useState({ key: id, body: "" });
    const [createReplay, setCreateReplay] = useState({ key: id, body: "" });

    const [menuCommentId, setMenuCommentId] = useState(null);
    const menuRef = useRef(null);
    const saveRef = useRef(null);
    const [EditComment, setEditComment] = useState(false);
    const [replay, setReplay] = useState(false);
    const videoRef = useRef(null);
    const [repleyitemId, setRepleyItemId] = useState(null);
    const [commentPrev, setCommentPrev] = useState({});

    // Show More Desc For Video
    const [showMore, setShowMore] = useState(false);
    const textRef = useRef(null);
    const [reportModal, setReportModal] = useState(false);
    const [showWelcome, setShowWelcome] = useState(false);
    const [viewAPITriggered, setViewAPITriggered] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    console.log("userfollowing", userfollowing);

    useEffect(() => {
        setLikeCount(homePageVideos[currentIndex]?.likes_count);
        setCommentCount(homePageVideos[currentIndex]?.comments_count);

        if (user_liked) {
            setLikeLikeEmoji(!likeEmoji);
        }
    }, [homePageVideos, currentIndex, user_liked]);

    useEffect(() => {
        setShowWelcome(homePageVideos[currentIndex]?.new_user);
    }, [homePageVideos, currentIndex]);


    const text = dec;
    const hashtags = text?.split(/\s+#/).slice(1); // Split by one or more spaces followed by #
    // Remove '#' and everything after it
    let description = text?.replace(/#\S+/g, "");
    const openDrawerBottomComments = async () => {
        await dispatch(GetComment(id));
        setOpenBottomComments(true);
    };
    const maxLength = 60;

    const isLongText = description?.length > maxLength;

    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;

    const closeDrawerBottomCommments = () => {
        setOpenBottomComments(false)
        setRepleyItemId(null);
        setReplay(false);
        setCreateReplay({ key: id, body: "" });
        setEditComment(false);
        setCreateComment({ key: id, body: "" });
    };

    // const handleOpenSavedModal = () => setOpenSaveDrawer(!openSaveDrawer);
    // useOutsideClick(saveRef, () => handleOpenSavedModal());

    const handleOpenreplay = (value) => {
        setRepleyItemId(value);
    };

    let originalURL = User_profile.avatar;

    const toggleMenu = (commentId) => {
        if (menuCommentId === commentId) {
            setMenuCommentId(null); // Close menu if already open
        } else {
            setMenuCommentId(commentId); // Open menu for this comment
        }
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuCommentId(null); // Close menu if clicked outside
        }
    };

    const handleLikeVideo = useCallback(async () => {
        setIsLikeAnimating(true);

        // Set a timeout to reset animation state
        setTimeout(() => {
            setIsLikeAnimating(false);
        }, 500);

        const responce = await dispatch(HomepageVideoLike(id));
        if (responce.payload?.message === "like removed") {
            setLikeLikeEmoji(false);
            dispatch(removeVideoLike(id));
        } else {
            setLikeLikeEmoji(true);
            dispatch(setVideoLike(id));
        }
    }, [id, dispatch]);

    const handleRepostWithAnimation = (id) => {
        setIsRepostAnimating(true);

        // Reset animation after it completes
        setTimeout(() => {
            setIsRepostAnimating(false);
        }, 800);

        // Call the original repost handler
        handleReshared(id);
    };

    const handleSetPage = (value) => {
        switch (value) {
            case "Repost":
                return handleRepostWithAnimation(id);
            case "Comment":
                return openDrawerBottomComments();
            case "Share":
                return openDrawerBottom();
            case "Like":
                return handleLikeVideo();
            case "Save":
                return handleSaveVideo(id);
            default:
                break;
        }
    };

    const handleSaveVideo = async (id) => {
        try {
            const response = await dispatch(SavedVideosById(id));
            if (response.payload?.is_saved !== undefined) {
                setIsSaved(response.payload.is_saved); // Update state based on API response
            } else {
                setIsSaved(prev => !prev); // Fallback toggle if response doesn't include is_saved
            }
        } catch (error) {
            console.error("Error saving video:", error);
        }
    };

    useEffect(() => {
        setIsSaved(homePageVideos[currentIndex]?.is_saved);

        if (user_saved) {
            setIsSaved(true);
        }
    }, [homePageVideos, currentIndex, user_saved]);

    const handleChangeComment = (e) => {
        setCreateComment((prev) => ({ ...prev, body: e.target.value }));
    };

    const handleChangeReply = (e) => {
        setCreateReplay((prev) => ({ ...prev, body: e.target.value }));
    };

    const handleSubmitComment = async () => {
        if (EditComment) {
            await dispatch(HomepageVideoUpdateComment(createCommet));
            dispatch(GetComment(id));
            setEditComment((prev) => !prev);
        }
        // else if (replay) {
        //     await dispatch(ReplayComment(createCommet));
        //     dispatch(GetComment(id));
        //     setReplay((prev) => !prev);
        // } 
        else {
            if (createCommet.body.length > 0) {
                await dispatch(HomepageVideoCreateComment({ key: id, body: createCommet.body }));
                dispatch(GetComment(id));
            }
        }
        setCreateComment({ key: id, body: "" });
    };

    const handleSubmitReplay = async () => {
        if (createReplay.body.length > 0) {
            await dispatch(ReplayComment(createReplay));
            dispatch(GetComment(id));
            setCreateReplay({ key: id, body: "" });
            setRepleyItemId(null);
        }
    }
    const closeEditComment = () => {
        setEditComment((prev) => !prev);
        setCreateComment({ key: null, body: "" });
    }

    const handleCloseReply = () => {
        setRepleyItemId(null);
        setReplay(false);
        setCreateReplay({ key: null, body: "" });
    }


    const handleChangeEditComment = (data) => {
        setCommentPrev(data);
        setCreateComment(() => ({
            key: data.id, body: data.post_comment_box
        }));
        setEditComment((prev) => !prev);
        setMenuCommentId(null);
    };

    const handleChangeDeleteComment = async (CommentId) => {
        await dispatch(HomepageVideoDeleteComment(CommentId));
        setMenuCommentId(null);
        dispatch(GetComment(id));
    };
    const handleChangeRepleyComment = (data) => {
        setCommentPrev(data);
        setCreateReplay(() => ({ key: data.id, body: "" }));
        setReplay(true);
        setRepleyItemId(data.id);
        setMenuCommentId(null);
    };

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };

    const handleViewVideo = async () => {
        try {
            await dispatch(videoView(id));
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const playVideo = (video) => {
        return new Promise((resolve, reject) => {
            if (video?.paused) {
                video
                    .play()
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                resolve(); // Already playing, resolve immediately
            }
        });
    };

    const pauseVideo = (video) => {
        return new Promise((resolve, reject) => {
            if (!video?.paused) {
                video.pause();
                resolve();
            } else {
                resolve(); // Already paused, resolve immediately
            }
        });
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const videoElement = document.getElementById("video");
    playVideo(videoElement)
        .then(() => { })
        .catch((error) => {
            console.error("Error occurred while trying to play video:", error);
        });

    useEffect(() => {
        const video = document.getElementById(`video-${id}`);
        const postMainElement = document.getElementById(`post-main-${id}`);

        if (video && postMainElement) {
            let observer = new IntersectionObserver(
                (entries) => {
                    if (entries && entries[0]) {
                        if (entries[0].isIntersecting && !showWelcome) {
                            try {
                                playVideo(video)
                                    .then(() => {
                                        console.log("Video started playing successfully");
                                        setMenuCommentId(null);
                                        toggleMenu();
                                    })
                                    .catch((error) => {
                                        console.error(
                                            "Error occurred while trying to play video:",
                                            error
                                        );
                                    });
                            } catch (error) {
                                console.error(
                                    "Error occurred while trying to play video:",
                                    error
                                );
                            }
                        } else {
                            try {
                                pauseVideo(video)
                                    .then(() => {
                                        console.log("Video paused successfully");
                                        closeDrawerBottomCommments();
                                        closeDrawerBottom();
                                    })
                                    .catch((error) => {
                                        console.error(
                                            "Error occurred while trying to pause video:",
                                            error
                                        );
                                    });
                            } catch (error) {
                                console.error(
                                    "Error occurred while trying to pause video:",
                                    error
                                );
                            }
                        }
                    }
                },
                { threshold: [0.6] }
            );
            observer.observe(postMainElement);
            return () => {
                observer.disconnect(); // Cleanup observer on component unmount
            };
        }
    }, [showWelcome]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textRef.current && !textRef.current.contains(event.target)) {
                setShowMore(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const handleVideoEnd = () => video.play();
            video.addEventListener("ended", handleVideoEnd);

            return () => video.removeEventListener("ended", handleVideoEnd);
        }
    }, [videoRef]);

    const handleTimeUpdate = () => {
        const video = videoRef.current;
        if (!isDragging) {
            setProgress(videoRef.current.currentTime);
        }
        setDuration(videoRef.current.duration);
        if (video && !viewAPITriggered) {
            const requiredWatchTime = 4; // Fixed time to watch (in seconds)

            if (video.currentTime >= requiredWatchTime) {
                // Call the view API here
                handleViewVideo();
                setViewAPITriggered(true);
            }
        }
    };

    // Handle seek bar change
    const HeaderIcons = [
        {
            icon: <FaBasketShopping color="white" className="h-[20px] w-[20px]" />,
            placeholder: "Shop",
            path: "shop",
        },
        {
            icon: <IoNotifications color="white" className="h-[20px] w-[20px]" />,
            placeholder: "Notifications",
            path: "notification",
        },
        {
            icon: <GiThreeFriends color="white" className="h-[20px] w-[20px]" />,
            placeholder: "Your Friends",
            path: "yourfriends",
        },
        {
            icon: <CiStreamOn color="white" className="h-[20px] w-[20px]" />,
            placeholder: "Live Stream",
            path: "livestream",
        },
        {
            icon: <IoIosSearch color="white" className="h-[20px] w-[20px]" />,
            placeholder: "Search",
            path: "search",
        },
        {
            icon: <MdGroups color="white" className="h-[20px] w-[20px]" />,
            placeholder: "Groups",
            path: "groups",
        },
    ];

    const reloadPage = () => {
        window.location.reload();
    };

    const handleNavigateFun = (value) => {
        const replaceValue = value.replaceAll(" ", "");
        switch (replaceValue) {
            case "yourfeed":
                return reloadPage();
            case "yourfriends":
                return reloadPage();
            case "groups":
                return navigate(`/group/list-view`)
            default:
                navigate(`/${replaceValue}`);
                break;
        }
    };

    useEffect(() => {
        if (resharedPostId) {
            setTimeout(() => {
                setResharedPostId(null);
            }, 3000);
        }
    }, [resharedPostId]);

    const [isLoading, setIsLoading] = useState(true);

    const handleWaiting = () => setIsLoading(true);
    const handleCanPlay = () => setIsLoading(false);
    const handlePlaying = () => setIsLoading(false);
    // Format time in MM:SS
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };


    // Handle user manually changing progress
    const handleSeek = (e) => {
        const newTime = e.target.value;
        setProgress(newTime);
        if (videoRef.current) {
            videoRef.current.currentTime = newTime;
        }
        if (!isDragging) {
            videoRef.current.currentTime = newTime;
        }
    };

    return (
        <div
            aria-hidden="true"
            id={`post-main-${id}`}
            className="h-[85vh] md:h-[100%] w-full relative overflow-hidden">
            <div className="pt-[2px] absolute z-20 w-full px-3 py-[2px] bg-gradient-to-b from-black bg-opacity-40">
                <div className="w-full text-center pb-[3px]">
                    <p className="text-[#ff3b3b] text-[10px] font-bold mb-[2px] uppercase">
                        Copyright © 2025 Galileo One Inc
                    </p>
                    {/* <p className="text-[#ff3b3b] text-[10px] font-bold mb-[2px] uppercase">
                        CC HOME / V40 / Oct 11 / 2024
                    </p> */}
                </div>

                <div className="flex justify-between items-center">
                    {HeaderIcons.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center pb-[2px] group"
                            onClick={() => handleNavigateFun(`${item.path}`)}
                        >
                            <div className="cursor-pointer opacity-100 group-hover:scale-110 group-hover:text-blue-500 transition-transform duration-300 ease-in-out">
                                {item.icon}
                            </div>
                            <p className="text-white cursor-pointer font-semibold text-[7px] mt-[1px]  group-hover:scale-110 transition-transform duration-300 ease-in-out">
                                {item.placeholder}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            {showWelcome && !itsNotHome && (
                <div className="absolute w-[100%]  h-[100%]  z-50">
                    <WelcomeModal onClose={() => handleReadUserStatus()} />
                </div>
            )}
            {/* SAVE MODAL FOR SAVE THE VIDEO INTO OUR SAVED FOLDER */}
            {openSaveDrawer && (
                <div className="h-full w-full  relative ">
                    <div className="absolute top-0 h-full w-full flex items-center justify-center ">
                        <div className="absolute bg-gray-200 h-full w-full z-40 opacity-10"></div>
                        <SaveModal
                            saveRef={saveRef}
                            open={openSaveDrawer}
                            handleClose={handleOpenSavedModal}
                            videoId={id}
                            fetchData={fetchData}
                        />
                    </div>
                </div>
            )}

            <video
                key={src}
                ref={videoRef}
                onTimeUpdate={handleTimeUpdate}
                controls
                onWaiting={handleWaiting}
                onCanPlay={handleCanPlay}
                onPlaying={handlePlaying}
                id={`video-${id}`}
                style={{ maxWidth: "100%" }}
                type={"video/mp4"}
                aria-hidden
                disablePictureInPicture
                disablePlayback
                disableRemotePlayback
                controlslist="nodownload noplaybackrate"
                className="w-full h-full object-cover hide-video-controls"
                loading="false"
                preload="auto"
                muted
            >
                <source src={`${src}`} type="video/mp4" />
            </video>
            {/* Custom Timeline Bar */}
            <div
                className="absolute bottom-[3px] h-1 w-full rounded-full bg-gray-400"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {/* Progress Bar */}
                <div
                    className="absolute h-1 rounded-full bg-[#fff] transition-all duration-200"
                    style={{ width: `${(progress / duration) * 100}%` }}
                ></div>

                {/* Draggable Circle with Tooltip */}
                <div
                    className="absolute z-10 bottom-0 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer rounded-full  bg-white shadow-lg"
                    style={{ left: `${(progress / duration) * 100}%`, top: "2px" }}
                >
                    {/* Tooltip */}
                    {isHovered && formatTime(progress) !== formatTime(duration) && (
                        <div className={`absolute bottom-4 ${(progress / duration) * 100 === 0 ? "left-12" : "left-1/2"}  z-50 -translate-x-1/2 transform rounded bg-gray-800 px-2 py-1 text-[10px] text-white shadow`}>
                            {formatTime(progress)}/{formatTime(duration)}
                        </div>
                    )}
                </div>

                {/* Range Input */}
                <input
                    type="range"
                    min="0"
                    max={duration}
                    value={progress}
                    onInput={handleSeek} // Use onInput for smoother updates
                    className="absolute w-full cursor-pointer opacity-0"
                    onMouseDown={handleSliderStart}
                    onMouseUp={handleSliderEnd}
                    onTouchStart={handleSliderStart}
                    onTouchEnd={handleSliderEnd}
                />
            </div>
            {isLoading && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <div className="bg-black bg-opacity-80 w-full h-full flex items-center justify-center">
                        <ClipLoader color={"#fff"} size={50} loading={isLoading} />
                    </div>
                </div>
            )}
            {/* share pop */}
            <Share
                openBottom={openBottom}
                closeDrawerBottom={closeDrawerBottom}
                videoUrl={`${src}`}
            />
            {/* comments pop */}
            <Comments
                openBottomComments={openBottomComments}
                closeDrawerBottomCommments={closeDrawerBottomCommments}
                comments={comments}
                handleChangeComment={handleChangeComment}
                handleChangeReply={handleChangeReply}
                menuCommentId={menuCommentId}
                emptyComments={emptyComments}
                menuRef={menuRef}
                created_by={created_by}
                handleChangeEditComment={handleChangeEditComment}
                toggleMenu={toggleMenu}
                handleChangeDeleteComment={handleChangeDeleteComment}
                setMenuCommentId={setMenuCommentId}
                handleSubmitComment={handleSubmitComment}
                createCommet={createCommet}
                createReplay={createReplay}
                handleChangeRepleyComment={handleChangeRepleyComment}
                handleOpenreplay={handleOpenreplay}
                repleyitemId={repleyitemId}
                originalURL={originalURL}
                replay={replay}
                setReplay={setReplay}
                setReportModal={setReportModal}
                postId={id}
                handleSubmitReplay={handleSubmitReplay}
                commentPrev={commentPrev}
                handleCloseReply={handleCloseReply}
                closeEditComment={closeEditComment}
                EditComment={EditComment}
            />

            <div className="absolute bottom-2 flex justify-between px-2 w-full">
                {videoFootageSideIcon?.map((item) => (
                    <div className="text-white  items-end flex justify-center w-10">
                        <div className={`flex fle-start flex-col items-center`}>
                            <p
                                onClick={() => handleSetPage(item?.text)}
                                className="text-[#ffff] text-[10px] font-semibold"
                            >
                                {item?.text === "Like" && (
                                    <span className="pr-[2px]">{likeCount}</span>
                                )}
                                {item?.text === "Comment" && (
                                    <span className="pr-[2px]">
                                        {/* {commentCount.length > 0 && commentCount} */}
                                        {commentCount}
                                    </span>
                                )}
                            </p>
                            <div className="pb-1">
                                {item?.icon && item?.text === "Like" ? (
                                    <p
                                        onClick={() => handleSetPage(item?.text)}
                                        className={`cursor-pointer text-[1.2rem] transition-all 
                                        ${likeEmoji ? "text-cyan-500" : "text-white"}
                                        ${isLikeAnimating ? "animate-like-button" : ""}`
                                        }
                                    >
                                        {item?.icon}
                                    </p>
                                ) : (
                                    <p
                                        onClick={() => item?.text === "Repost" ? handleRepostWithAnimation(id) : handleSetPage(item?.text)}
                                        className={`text-[1.2rem] cursor-pointer transition-all
                                            ${item.text === "Save" ? (isSaved ? "text-cyan-500" : "text-white") : ""}
                                            ${item.text === "Repost" ? (userreshared ? "text-cyan-500" : "text-white") : ""}
                                            ${item.text === "Repost" && isRepostAnimating ? "animate-repost-button" : ""}`
                                        }
                                    >
                                        {item?.icon}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="absolute w-full bottom-[3rem]">
                <div className="flex items-start justify-between px-2">
                    <div className="w-[80%]">
                        <p
                            onClick={() => handleNavigate(created_by?.id)}
                            className="text-white font-bold text-[14px] cursor-pointer"
                        >
                            {created_by?.first_name} {created_by?.last_name}
                        </p>
                        <p
                            ref={textRef}
                            onClick={handleShowMore}
                            className={`text-[12px] pt-1 text-white w-[90%] ${showMore ? "" : "line-clamp-2"}`}
                        >
                            {showMore
                                ? description
                                : description?.slice(0, maxLength) + (isLongText ? "..." : "")}
                            {isLongText && (
                                <span
                                    onClick={handleShowMore}
                                    className="text-[12px] cursor-pointer font-bold text-cyan-500 hover:text-cyan-400"
                                >
                                    {showMore ? "Less" : "More"}
                                </span>
                            )}
                        </p>
                        <>
                            {hashtags?.map((item, index) => (
                                <>
                                    {index < 3 && (
                                        <a
                                            onClick={() => navigate(`/hashtag/#${item}`)}
                                            className="underline text-sm pt-1 text-white w-[80%] cursor-pointer px-[2px] "
                                        >
                                            #{item}
                                        </a>
                                    )}
                                </>
                            ))}
                        </>
                        {resharedPostId === id && (
                            <p className="absolute right-14 top-[1.3rem] flex items-center text-[10px] font-bold text-black text-center bg-white px-[5px] py-[2px] rounded-md">
                                {rehsaredMesaage}
                            </p>
                        )}
                    </div>

                    <div className="flex flex-col relative items-center">
                        {isFollow &&
                            <div className="flex items-center justify-center absolute top-[-20px] bg-gray-800 py-[2px] px-[4px] rounded-md">
                                <p className="text-[8px] text-white">
                                    Followed
                                </p>
                            </div>
                        }

                        <img
                            alt="image"
                            src={`${created_by?.avatar_url}`}
                            className="object-cover w-10 h-10 rounded-md bg-gray-500"
                            onClick={() => handleNavigate(created_by?.id)}
                        />
                        {created_by?.id !== User_profile.id && (
                            <div className="absolute -top-2 -right-2.5 h-[25px] w-[25px] cursor-pointer">
                                {!userfollowing &&
                                    <Button
                                        className={`w-[20px] h-[20px] rounded-full flex justify-center items-center p-0
                                        ${userfollowing ? "bg-gray-700" : "bg-black"}`}
                                    >
                                        <TiUserAddOutline
                                            onClick={() => handleFollowFun(created_by?.id)}
                                            className="w-[15px] h-[15px] text-white"
                                        />

                                    </Button>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {reportModal && (
                <ReportModal setReportPost={() => setReportModal(false)} />
            )}
        </div>
    );
};

export default HomeVideoView;
