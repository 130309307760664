import React, { Fragment, useEffect, useRef, useState } from "react";
import { TbDotsVertical } from "react-icons/tb";
import { SavedModal } from "../Modals/AccountPageModal/SavedTab";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FolderImage from "../../Assets/common/SavedFolder.png"
import NoSavedVideo from "../../Assets/YourAccountIcons/NoSavedVideo.png";
import { deleteSavedFolders, getSavedFolders } from "../../Redux/ApiSlice/SavedVideosSlice.js";
import PulseLoader from "react-spinners/PulseLoader";

export function SavedTab({ SavedFolderList, loading, handleOpenEditModal, handleOpenDeleteModal, handleCreateModal }) {
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;
    const [savedId, setSavedId] = useState(null);
    const [show, setShow] = useState(false);
    const [showtext, setsShowText] = useState("");


    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setSavedId(null); // Close menu if clicked outside
        }
    };

    const handleOpen = (val) => {
        setShow(!show);
        setsShowText(val);
    };

    const handleNavigate = (value) => {
        if (value) navigate(`/account/saved/folder/${value}`);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    return (
        <Fragment>
            {loading &&
                <div className="h-[25vh] flex justify-center items-center">
                    <PulseLoader
                        size={15}
                        color="#6dd6f3"
                    />
                </div>
            }

            {!loading &&
                <div className="h-full overflow-hidden">
                    <div className="flex justify-between px-2 pb-1 sticky top-0 bg-white border-b dark:bg-black border-gray-200 dark:border-gray-800">
                        <p
                            onClick={handleCreateModal}
                            className="text-[12px] text-blue-700 font-semibold cursor-pointer"
                        >
                            Create Folder
                        </p>
                        <p className="text-[12px] text-gray-500 dark:text-gray-700 font-semibold">
                            {SavedFolderList?.data?.length} Folders
                        </p>
                    </div>

                    {SavedFolderList?.data?.length ? (
                        <div className="h-[90%] md:h-[86%] p-2 overflow-scroll no-scrollbar mt-1">
                            <div className="grid grid-cols-6 gap-x-4">
                                {SavedFolderList?.data?.map((item) => (
                                    <div className="col-span-2 h-[7rem]" key={item.id}>
                                        <div className="h-[5rem] relative ">
                                            <img
                                                src={FolderImage}
                                                onClick={() => handleNavigate(item.id)}
                                                className="object-cover h-[5rem] cursor-pointer"
                                            />
                                            <div className="flex justify-center items-center w-4 h-4 bg-red-900 bg-opacity-50 absolute rounded-full top-0 left-0">
                                                <p className="text-black dark:text-white text-[10px] font-bold line-clamp-1" >
                                                    {item?.videos?.length}
                                                </p>
                                            </div>
                                            <TbDotsVertical
                                                onClick={() => setSavedId(item.id)}
                                                className="absolute -right-[6px] top-2 font-bold cursor-pointer z-30 text-black dark:text-white"
                                            />
                                            {savedId === item.id && (
                                                <div
                                                    ref={menuRef}
                                                    className="absolute right-2 top-6 bg-white border border-gray-400 z-30 overflow-hidden rounded-md shadow-md"
                                                >
                                                    <p
                                                        onClick={() => handleOpenDeleteModal(item.id)}
                                                        className="flex items-center text-[10px] text-black cursor-pointer px-2 py-1 hover:bg-gray-200 border-b border-gray-400"
                                                    >
                                                        Delete
                                                    </p>
                                                    <p
                                                        onClick={() => handleOpenEditModal(item.id)}
                                                        className="flex items-center text-[10px] text-black cursor-pointer px-2 py-1 hover:bg-gray-200"
                                                    >
                                                        Rename
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <p className="text-black dark:text-white text-[10px] mt-[-5px] ml-[3px] font-bold line-clamp-1">
                                            {item.name}
                                        </p>
                                    </div>
                                ))}

                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center flex-col p-2">
                            <img src={NoSavedVideo} alt="" className="w-32 h-32" />
                            <p className="text-[10px] text-gray-400 dark:text-gray-500 font-semibold">
                                No saved video yet
                            </p>
                        </div>
                    )}
                    <SavedModal
                        size={show}
                        handleOpen={handleOpen}
                        showtext={showtext}
                    />
                </div>
            }
        </Fragment>
    );
}