import React, { useEffect } from "react";
import { groupSearchUser } from "../../../Datas/Datas";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const User = ({ userData, searchItem
}) => {
    const navigate = useNavigate();

    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };

    const filterData = userData.filter((data) => data.first_name.toLowerCase().includes(searchItem.toLowerCase()) || data.last_name.toLowerCase().includes(searchItem.toLowerCase()));

    function timeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);

        const intervals = {
            year: 31536000, // 60 * 60 * 24 * 365
            month: 2592000, // 60 * 60 * 24 * 30
            day: 86400,     // 60 * 60 * 24
            hour: 3600,     // 60 * 60
            minute: 60,
            second: 1
        };

        for (const key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval}${key[0]} ago`;
            }
        }

        return "just now";  // In case it's less than a second ago
    }

    return (
        <div className="overflow-y-scroll">
            {filterData?.map((data) => (
                <div className="grid grid-cols-12 items-center border-b border-gray-400 dark:border-gray-800 py-2 overflow-y-scroll" key={data?.id}>
                    <div className="col-span-7 flex justify-start items-center gap-1 border-r border-gray-400 dark:border-gray-800 pl-2">
                        <div className="grid grid-cols-12 items-center w-full">
                            <div className="col-span-3">
                                <img
                                    alt="img"
                                    src={data?.avatar_url}
                                    className="h-11 w-11 md:h-8 md:w-8 rounded-full object-cover"
                                    onClick={() => handleNavigate(data?.id)}
                                />
                            </div>
                            <div className="col-span-9 -ml-2 md:-ml-1">
                                <p className="text-black dark:text-white text-[12px] ml-2 md:ml-0" onClick={() => handleNavigate(data?.id)}>@{data?.first_name} {data?.last_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 pr-2">
                        <p className="text-black dark:text-white text-[12px] ml-2">
                            <span className="font-bold">Joined:</span> {timeAgo(data?.joined_at)}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default User