import React, { useEffect, useState } from "react";
import HomeVideoView from "./HomeVideoView";
import "react-multi-carousel/lib/styles.css";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from 'react-swipeable';

const VideoCarousel = ({
    videos = [],
    setPreButton,
    setNextButton,
    spilThashTags,
    summary,
    fetchData,
    id,
    handleReshared,
    setPageCount,
    itsNotHome,
    handleReadUserStatus,
    newUser,
    resharedPostId,
    rehsaredMesaage,
    setResharedPostId,
    handleFollowFun,
    totalVideos,
    currentIndex,
    direction,
    handleNext,
    handlePrev,
    isMobile,
    isFollow
}) => {
    const [isSliderActive, setIsSliderActive] = useState(false);

    const handleSliderStart = () => setIsSliderActive(true);
    const handleSliderEnd = () => setIsSliderActive(false);

    const videoDataList = videos || [];

    const swipeHandlers = useSwipeable({
        onSwipedLeft: !isMobile && isSliderActive ? handleNext : undefined, // Left swipe for web
        onSwipedRight: !isMobile && isSliderActive ? handlePrev : undefined, // Right swipe for web
        onSwipedUp: isMobile  ? handleNext : undefined, // Up swipe for mobile
        onSwipedDown: isMobile ? handlePrev : undefined, // Down swipe for mobile
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div className="relative flex items-center justify-center w-full h-full overflow-hidden">
            {/* Swipeable area for mobile */}
            <div
                {...swipeHandlers}
                className={`w-full h-full flex ${isMobile ? 'flex-col overflow-y-auto' : 'flex-row overflow-x-auto'}`}
            >
                <AnimatePresence mode="wait" custom={direction}>
                    <motion.div
                        key={currentIndex}
                        custom={direction}
                        initial={{
                            opacity: 0,
                            x: !isMobile ? (direction === 1 ? 100 : -100) : 0, // Horizontal for web
                            y: isMobile ? (direction === 1 ? 100 : -100) : 0, // Vertical for mobile
                        }}
                        animate={{ opacity: 1, x: 0, y: 0 }} // Reset to center
                        exit={{
                            opacity: 0,
                            x: !isMobile ? (direction === 1 ? -100 : 100) : 0, // Horizontal for web
                            y: isMobile ? (direction === 1 ? -100 : 100) : 0, // Vertical for mobile
                        }}
                        transition={{ duration: 0.2 }}
                        className="w-full h-full flex items-center justify-center"
                    >
                        <HomeVideoView
                            id={videoDataList[currentIndex]?.id}
                            src={videoDataList[currentIndex]?.video_url}
                            summary={summary}
                            comments={videoDataList[currentIndex]?.comments}
                            spilThashTags={spilThashTags}
                            created_by={videoDataList[currentIndex]?.created_by}
                            dec={videoDataList[currentIndex]?.post_description}
                            like_count={videoDataList[currentIndex]?.likes_count}
                            comments_count={videoDataList[currentIndex]?.comments_count}
                            fetchData={fetchData}
                            handleReshared={handleReshared}
                            userreshared={videoDataList[currentIndex]?.userreshared}
                            handleReadUserStatus={handleReadUserStatus}
                            newUser={newUser}
                            resharedPostId={resharedPostId}
                            rehsaredMesaage={rehsaredMesaage}
                            setResharedPostId={setResharedPostId}
                            userfollowing={videoDataList[currentIndex]?.userfollowing}
                            handleFollowFun={handleFollowFun}
                            user_liked={videoDataList[currentIndex]?.userliked}
                            user_saved={videoDataList[currentIndex]?.usersaved}
                            handleSliderStart={handleSliderStart}
                            handleSliderEnd={handleSliderEnd}
                            isFollow={isFollow}
                        />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

export default VideoCarousel;
