import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHashtag } from "../Redux/ApiSlice/HashTagSlice";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import VideoCard from "../Common/VideoCard";
import TitleSection from "../Component/HashTag/TitleSection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useSelectVideo from "../Hooks/useSelectVideo";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1,
    },
};

export default function HashTagPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const carouselRef = useRef(null);
    const slelectVideo = useSelectVideo();

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const hashTag = useSelector((state) => state.HashTag);
    const { hashTagList } = hashTag;
    let hash = window.location.hash;
    // Remove the '#' symbol if needed
    hash = hash.replace("#", "");
    const handleSelectVideo = (id) => {
        slelectVideo(id, hashTagList);
    };

    useEffect(() => {
        dispatch(getHashtag(hash));
    }, [dispatch, hash]);

    return (
        <section className="h-full bg-black relative">
            <p className="absolute top-0 w- w-full flex justify-center z-10 text-[red] font-bold text-[8px] uppercase">
                Copyright © 2025 Galileo One Inc
            </p>
            {/* <p className="absolute top-0 w- w-full flex justify-center z-10 text-[red] font-bold text-[8px] uppercase">
                CC HASHTAG / V7 / FE / OCT 11/ 2024
            </p> */}
            <TitleSection hash={hash} />
            <div className="flex justify-between px-2 gap-1">
                <button className="bg-[#191919] hover:bg-[#2b2b2b] p-1 rounded" onClick={handlePrevious}>
                    <BiSolidLeftArrow color="red" />
                </button>
                <div className="w-80 md:w-[14rem] relative">
                    <Carousel
                        ref={carouselRef}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        infinite={false}
                        arrows={false}
                        renderButtonGroupOutside={true}
                        renderDotsOutside={false}
                        className="bg-black rounded-sm"
                    >
                        <div className="flex flex-col justify-center items-center bg-[#191919] hover:bg-[#2b2b2b] border-r-2 border-[#2b2b2b]">
                            <button
                                onClick={() => navigate("/hashtag/store")}
                                className="text-white text-[11px] text-center p-2"
                            >
                                Store
                            </button>
                        </div>
                        <div className="flex flex-col justify-center items-center bg-[#191919] hover:bg-[#2b2b2b] border-r-2 border-[#2b2b2b]">
                            <button
                                onClick={() => navigate("/hashtag/trending")}
                                className=" text-white text-[11px] text-center p-2 "
                            >
                                Trending Videos
                            </button>
                        </div>
                        <div className="flex flex-col justify-center items-center bg-[#191919] hover:bg-[#2b2b2b] border-r-2 border-[#2b2b2b]">
                            <div>
                                <button
                                    className=" text-white text-[11px] text-center p-2"
                                    onClick={() => navigate("/hashtag/otheruser")}
                                >
                                    Other Users
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center bg-[#191919] hover:bg-[#2b2b2b] border-r-2 border-[#2b2b2b]">
                            <div>
                                <button
                                    className=" text-white text-[11px] text-center p-2"
                                    onClick={() => navigate("/hashtag/popular")}
                                >
                                    Popular
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center bg-[#191919] hover:bg-[#2b2b2b] border-r-2 border-[#2b2b2b]">
                            <div>
                                <button
                                    className=" text-white text-[11px] text-center p-2"
                                    onClick={() => navigate("/hashtag/recent")}
                                >
                                    Recent
                                </button>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <button className="bg-[#191919] hover:bg-[#2b2b2b] p-1 rounded" onClick={handleNext}>
                    <BiSolidRightArrow color="green" />
                </button>
            </div>
            <div className="absolute h-[-webkit-fill-available] overflow-auto no-scrollbar mt-2 w-full">
                <div className="flex flex-wrap gap-3 overflow-scroll no-scrollbar">
                    <div className="grid grid-cols-2 gap-3 overflow-scroll no-scrollbar w-full px-2">
                        {hashTagList?.map((item) => (
                            <VideoCard
                                // videosList={hashTagList}
                                threeDots={false}
                                id={item.id}
                                video={item.video_url}
                                likes_count={item.likes_count}
                                avatar_url={item.created_by.avatar_url}
                                body={item.body}
                                views={item.views}
                                selectVideo={handleSelectVideo}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
