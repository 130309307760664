import React, { useState, useEffect } from "react";

export default function Footer(props) {
    const { VideoCardHeaderIcons, isFixedDark, handleNavigate } = props;
    const [activeIcon, setActiveIcon] = useState("Home"); // Default active icon

    // Check URL to set initial active icon based on current page
    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("mobileMessages")) setActiveIcon("Messages");
        else if (path.includes("neighborhood")) setActiveIcon("Neighborhood");
        else if (path.includes("account")) setActiveIcon("Account");
        else if (path.includes("othergroup")) setActiveIcon("Your Friends");
        else setActiveIcon("Home"); // Default
    }, []);

    const handleIconClick = (placeholder) => {
        setActiveIcon(placeholder);
        handleNavigate(placeholder);
    };

    return (
        <div className={`w-full flex justify-between items-center h-full px-3`}>
            {VideoCardHeaderIcons?.map((item, index) => {
                const isActive = item.placeholder === activeIcon;

                return (
                    <div
                        key={index}
                        onClick={() => handleIconClick(item.placeholder)}
                        className="videoCard-content w-10 flex flex-col justify-evenly items-center mt-1 relative"
                    >
                        <div
                            className={`cursor-pointer ${isActive
                                    ? "text-blue-500"
                                    : isFixedDark
                                        ? "text-white dark:text-white"
                                        : "text-black dark:text-white"
                                } transition-colors`}
                        >
                            {item.icon && item.icon}
                            {isActive && (
                                <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-1 h-1 bg-blue-500 rounded-full"></div>
                            )}
                        </div>
                        <p
                            className={`cursor-pointer text-[7px] ${isActive
                                    ? "text-blue-500 font-bold"
                                    : isFixedDark
                                        ? "text-white dark:text-white"
                                        : "text-black dark:text-white"
                                } transition-colors`}
                        >
                            {item.placeholder}
                        </p>
                    </div>
                );
            })}
        </div>
    );
}