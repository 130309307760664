import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Media = ({ MediaData, groupId
}) => {
    const navigate = useNavigate();
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };
    return (
        <div className="overflow-y-scroll">
            <div className="grid grid-cols-3">
                {MediaData?.map((data) => (
                    <div className="col-span-1 p-1 overflow-hidden" key={data.id}>
                        <div className="border border-gray-800 p-1"
                            onClick={() => navigate(`/group/${groupId}/post/${data.id}`)}
                        >
                            <p className="text-black dark:text-white text-[10px] cursor-pointer line-clamp-1">
                                {data.caption}
                            </p>
                            {data?.media?.image_url && <img
                                alt="img"
                                className="h-16 w-full rounded object-cover"
                                src={data?.media?.image_url}
                                onClick={() => navigate(`/group/${groupId}/post/${data.id}`)}
                            />}
                            {data?.media?.video_url && <div className="w-full h-16"
                                onClick={() => navigate(`/group/${groupId}/post/${data.id}`)}
                            >
                                <ReactPlayer
                                    muted
                                    width={"100%"}
                                    height={"100%"}
                                    controls={false}
                                    url={data?.media?.video_url}
                                    className="w-full h-full rounded object-cover cursor-pointer"

                                />
                            </div>}
                        </div>
                        <div className="flex items-center py-1">
                            <div className="h-5 w-6">
                                <img
                                    src={data?.user_details?.profile_image}
                                    onClick={() => handleNavigate(data?.user_details.id)}
                                    className="h-5 w-5 rounded-full object-cover cursor-pointer"
                                />
                            </div>
                            <p className="text-[10px] text-black dark:text-white leading-[13px] cursor-pointer line-clamp-1">
                                {data?.user_details?.first_name} {data?.user_details?.last_name}
                            </p>
                        </div>
                    </div>
                ))}
                {MediaData?.length === 0 &&
                    <div className="col-span-3 flex justify-center items-center h-[25vh]">
                        <p className="text-black dark:text-white text-[14px]">No Media Found</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default Media