import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";

export const DeleteSavedFolderModal = ({ handleOpen, open, deleteCreatedFolder, selectFolder }) => {
   const [folderName, setFolderName] = useState("");

   const handleSubmit = () => {
      deleteCreatedFolder(selectFolder?.id);
   };

   useEffect(() => {
      setFolderName(selectFolder?.name);
   }, [selectFolder]);

   if(!open) return null;

   return (
      <div className="absolute h-full top-0 inset-0 p-5 flex items-center justify-center bg-black bg-opacity-50 z-50">
         <div className="bg-white border-2 dark:bg-black rounded-lg shadow-lg w-96  border-gray-200 dark:border-gray-700">
            <div className="p-2">
               <h2 className="text-lg text-center font-semibold mb-2 text-black dark:text-gray-400">Delete Folder</h2>
               <p className="dark:text-gray-300 text-center text-[13px]"> Are you sure that you want to Delete ? </p>
               <p className="text-center text-[14px] font-bold"><span className="text-blue-600">
               {`"${folderName}"`}</span></p>
            </div>
            <div className="flex justify-center gap-4 p-2">
               <button
                  onClick={() => handleSubmit()}
                  className="px-4 py-1 bg-black text-[13px] dark:bg-cyan-500 text-white rounded-lg hover:bg-gray-800 hover:dark:bg-cyan-600"
               >
                  Yes
               </button>
               <button
                  onClick={() => handleOpen(null)}
                  className="px-4 py-1 bg-red-500 text-[13px] text-white rounded-lg hover:bg-red-600"
               >
                  Cancel
               </button>
            </div>
         </div>
      </div>

      // <Dialog open={open} handler={handleOpen} size="sm" className="rounded-lg">
      //    <DialogBody className="text-center text-base font-semibold">
      //       Are you sure that you want to Delete
      //       <p>"{`${folderName}`}"</p>
      //    </DialogBody>

      //    <DialogFooter className="w-full flex gap-5 justify-center items-center">
      //       <Button
      //          color="black"
      //          onClick={() => handleSubmit()}
      //       >
      //          <span>YES</span>
      //       </Button>
      //       <Button
      //          color="red"
      //          onClick={() => handleOpen(null)}
      //       >
      //          <span>NO</span>
      //       </Button>
      //    </DialogFooter>
      // </Dialog>
   );
};
