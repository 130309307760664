import React, { useState, useEffect } from "react";
import { GoPin } from "react-icons/go";
import Draggable from "react-draggable";
import { pinboardPost } from "../../data";
import { useNavigate } from "react-router-dom";
import dust from "../../Assets/dust-particles.png";
import PinboardPopup from "./PinboardPopup";

const Pinboard = () => {
    const navigate = useNavigate();
    const [positions, setPositions] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedPin, setSelectedPin] = useState(null);

    useEffect(() => {
        const savedPositions = JSON.parse(localStorage.getItem("pinboardPositions")) || {};
        setPositions(savedPositions);
    }, []);

    const handleStop = (e, data, id) => {
        const newPositions = { ...positions, [id]: { x: data.x, y: data.y } };
        setPositions(newPositions);
        localStorage.setItem("pinboardPositions", JSON.stringify(newPositions));
    };

    const handlePinClick = (pin) => {
        setSelectedPin(pin);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedPin(null);
    };

    if (positions === null) return null;

    return (
        <div className="px-2 pb-2 relative">
            <p className="font-bold text-[18px] text-center">Pinboard</p>
            <div
                className="w-full h-[40vh] bg-[#635151] border-[10px] border-[#2e2222] rounded-lg p-2 overflow-hidden"
                style={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${dust})`,
                }}
            >
                <div className="grid grid-cols-12 gap-2">
                    {pinboardPost.map((data) => (
                        <Draggable
                            key={data.id}
                            position={positions[data.id] || { x: 0, y: 0 }}
                            onStop={(e, dragData) => handleStop(e, dragData, data.id)}
                        >
                            <div
                                className="col-span-4 bg-white text-black hover:bg-[#49bfd4] hover:text-white w-full h-[68px] overflow-y-scroll relative rounded-sm cursor-pointer"
                                onClick={() => handlePinClick(data)}
                            >
                                <GoPin className="sticky top-0 text-[12px] text-[#FF9900] bg-black p-[2px]" />
                                <p className="text-[10px] text-center p-1">{data.title}</p>
                            </div>
                        </Draggable>
                    ))}
                </div>
            </div>

            <div className="flex justify-center items-center w-full">
                <button
                    onClick={() => navigate("/neighborhood/pinboard/create")}
                    className="w-[80%] bg-[#3d3d3b] hover:bg-[#252524] text-white py-2 rounded-lg mt-[2rem] transition-colors duration-300"
                >
                    Post To Pinboard
                </button>
            </div>

            {isPopupOpen && (
                <PinboardPopup pinPost={selectedPin} onClose={closePopup} />
            )}
        </div>
    );
};

export default Pinboard;