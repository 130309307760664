import React from "react";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@material-tailwind/react";
import {
    otherUserFollowersList, otherUserFollowingList,
    removeFollowers, UserAccountFollow,
    UserAccountFollowerlist, UserAccountFollowinglist,
} from "../../Redux/ApiSlice/AccountProfileSlice";

export const FollowersTab = ({ datalist, otheruser_id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFollowFun = async (userId) => {
        console.log("userId", userId);
        await dispatch(UserAccountFollow(userId));
        if (otheruser_id) {
            await dispatch(otherUserFollowersList(otheruser_id));
            await dispatch(otherUserFollowingList(otheruser_id));
        } else {
            await dispatch(UserAccountFollowerlist());
            await dispatch(UserAccountFollowinglist());
        }
    };

    const deleteFollowFun = async (userId) => {
        try {
            await dispatch(removeFollowers(userId));
            await dispatch(UserAccountFollowerlist());
        } catch (error) {
            console.error("Error unfollowing user:", error);
        }
    }

    return (
        <section className="h-full">
            <div className="flex h-[5%] justify-center items-center w-full pb-2 border-b border-gray-400 dark:border-gray-800">
                <div className="flex w-[94%] bg-cyan-600 hover:bg-cyan-700 justify-center items-center rounded-md overflow-hidden">
                    <div className="w-[10%] flex">
                        <FaSearch className="pl-2 w-[20px] h-[20px] cursor-pointer text-white" />
                    </div>
                    <div className="w-[100%] h-[85%]">
                        <input
                            type="text"
                            placeholder={`Find Your Followers`}
                            className="w-full bg-gray-100 placeholder:text-sm font-medium outline-none pl-1 pr-2 py-1"
                        />
                    </div>
                </div>
            </div>
            {datalist?.length ? (
                <>
                    <div className="h-[90%] pb-10 overflow-scroll no-scrollbar">
                        {datalist?.map((item) => (
                            <div className="flex items-center py-2 border-b border-gray-400 dark:border-gray-800">
                                <div className="grid grid-cols-12  justify-between w-full px-2" key={item.id}>
                                    <div className="col-span-7 flex items-start gap-x-1.5 rounded-full">
                                        <Avatar
                                            size="xs"
                                            alt="avatar"
                                            onClick={() => navigate(`/otheruseraccount/${item.follower_id}`)}
                                            src={item.follower_avatar_url ? `${item.follower_avatar_url}` : "https://thtsusa.com/user_data/media/user_icon.png"}
                                            className="h-10 w-10 rounded-full object-cover cursor-pointer border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800"
                                        />
                                        <div className="flex flex-col">
                                            <p className="text-[11px] first-letter:uppercase whitespace-nowrap font-bold dark:text-white text-gray-600">
                                                {item.follower_first_name} {item.follower_last_name}
                                            </p>

                                            <div className={`flex items-center gap-x-1 ${item?.mutual_friends?.length !== 0 && "mt-1"}`}>
                                                <div className="flex -space-x-2 overflow-hidden">
                                                    {item?.mutual_friends?.map((friends, index) => (
                                                        <>
                                                            {index < 3 && (
                                                                <img
                                                                    alt="img"
                                                                    src={friends?.avatar_url}
                                                                    className="inline-block h-4 w-4 rounded-full object-cover cursor-pointer"
                                                                />
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                                <p className={`text-[10px] text-gray-400 dark:text-gray-600 cursor-pointer whitespace-nowrap ${item?.mutual_friends?.length === 0 && "-ml-1"}`}>
                                                    {item?.mutual_friends?.length > 0
                                                        ? `${item.mutual_friends.length} mutual`
                                                        : "No mutual"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-5 flex items-center justify-end gap-x-1">
                                        <button
                                            onClick={() => handleFollowFun(item?.follower_id)}
                                            className="w-[4.2rem] bg-cyan-500 hover:bg-cyan-600 text-white text-[10px] font-semibold py-1 rounded-md whitespace-nowrap"
                                        >
                                            {item.is_following ? "Unfollow" : "Follow Back"}
                                        </button>
                                        {!otheruser_id && <button onClick={() => deleteFollowFun(item.follower_id)} className="bg-gray-800 hover:opacity-70 text-white text-[10px] p-1 rounded-md">
                                            <IoClose className="text-[16px]" />
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="flex justify-center items-center h-[50%] w-full">
                    <p className="text-[10px] text-[#c0b8b8] font-semibold">
                        No Followers yet
                    </p>
                </div>
            )}
        </section>
    );
};
