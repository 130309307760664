import React, { Fragment, useEffect, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

export default function ReguestTab({ MessagesList, search }) {
    const [requestData, setRequestData] = useState([])

    const handleAccept = (id) => {
        setRequestData((prev) => (
            prev.filter((item) => item.id !== id)
        ))
    }

    useEffect(() => {
        if (search) {
            const filteredData = MessagesList.filter((item) => {
                return (item.name.toLowerCase().includes(search.toLowerCase()))
            })
            setRequestData(filteredData)
        } else {
            setRequestData(MessagesList)
        }
    }, [search])

    return (
        <Fragment>
            {requestData.map((item) => (
                <div className="grid grid-cols-12 px-2 py-1.5 bg-white dark:bg-gray-900 border-b border-gray-300 dark:border-black cursor-pointer">
                    <div className="grid grid-cols-7 col-span-7">
                        <div className="col-span-2 flex items-center justify-start">
                            <Avatar
                                alt={item.name}
                                src={item.img}
                                className="h-9 w-9 shadow-2xl object-cover"
                            />
                        </div>
                        <div className="col-span-5 flex items-center">
                            <p className="text-black dark:text-white text-[14px] line-clamp-1 -ml-1">{item.name}</p>
                        </div>
                    </div>
                    <div className="col-span-5 flex justify-end items-center gap-[5px]">
                        <button
                            type="button"
                            onClick={() => handleAccept(item.id)}
                            className="bg-black hover:bg-gray-900 text-white p-[5px] rounded-md"
                        >
                            <FiCheck className="h-[14px] w-[14px]" />
                        </button>
                        <button
                            onClick={() => handleAccept(item.id)}
                            type="button"
                            className="bg-black hover:bg-gray-900 text-white p-[5px] rounded-md"
                        >
                            <AiOutlineClose className="h-[14px] w-[14px]" />
                        </button>
                    </div>
                </div>
            ))}

            {requestData?.length === 0 && (
                <div className="grid grid-cols-12 p-2 py-0 mt-1">
                    <div className="col-span-12 flex justify-center items-center">
                        <p className="text-white dark:text-black font-semibold text-[12px]">No Request Found</p>
                    </div>
                </div>
            )
            }
        </Fragment>
    );
}
