import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const recommendedFriends = createAsyncThunk("recommendedFriends", async (data) => {
    const response = await axiosInstance.get(`/recommended_users/`, data);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const suggestedFriends = createAsyncThunk("suggestedFriends", async (data) => {
    const response = await axiosInstance.get(`/follow_suggestions/`, data);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const suggestedMyFriendship = createAsyncThunk("suggestedMyFriendship", async (data) => {
    const response = await axiosInstance.get(`/my_friendship_suggestions/`, data);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const suggestedContentBasedUser = createAsyncThunk("suggestedContentBasedUser", async (data) => {
    const response = await axiosInstance.get(`/similar-user-suggest/`, data);
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const RecommendationSlice = createSlice({
    name: "Recommendation",
    initialState: {
        loading: false,
        error: null,
        recommendUserData: [],
        suggestedUserData: [],
        myFriendshipSuggestion: [],
        contentBasedUser: [],
    },
    extraReducers: (builder) => {
        // Recommended Friends
        builder.addCase(recommendedFriends.pending, (state) => {
            state.loading = true;
            state.recommendUserData = [];
        });
        builder.addCase(recommendedFriends.fulfilled, (state, action) => {
            state.loading = false;
            state.recommendUserData = action.payload;
        });
        builder.addCase(recommendedFriends.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Follow Suggestion
        builder.addCase(suggestedFriends.pending, (state) => {
            state.loading = true;
            state.suggestedUserData = [];
        });
        builder.addCase(suggestedFriends.fulfilled, (state, action) => {
            state.loading = false;
            state.suggestedUserData = action.payload;
        });
        builder.addCase(suggestedFriends.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // My Friendship Suggestion
        builder.addCase(suggestedMyFriendship.pending, (state) => {
            state.loading = true;
            state.myFriendshipSuggestion = [];
        });
        builder.addCase(suggestedMyFriendship.fulfilled, (state, action) => {
            state.loading = false;
            state.myFriendshipSuggestion = action.payload;
        });
        builder.addCase(suggestedMyFriendship.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Content Based User
        builder.addCase(suggestedContentBasedUser.pending, (state) => {
            state.loading = true;
            state.contentBasedUser = [];
        });
        builder.addCase(suggestedContentBasedUser.fulfilled, (state, action) => {
            state.loading = false;
            state.contentBasedUser = action.payload;
        });
        builder.addCase(suggestedContentBasedUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default RecommendationSlice.reducer;