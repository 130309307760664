import { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
    slidesToSlide: 1,
  },
};

export default function SearchSection({
  SearchItem,
  handleSearchBar,
  navigate,
  setActiveTab,
  activeTab,
}) {


  const carouselRef = useRef(null);
  const scrollbarRef = useRef(null);
  const scrollThumbRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 6,
  //     partialVisibilityGutter: 40
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 4,
  //     partialVisibilityGutter: 30
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 3,
  //     partialVisibilityGutter: 20
  //   }
  // };

  // Update scrollbar position when carousel slides
  useEffect(() => {
    if (!carouselRef.current) return;

    const updateScrollbar = () => {
      const carousel = carouselRef.current;
      if (!carousel || !scrollThumbRef.current) return;

      const totalSlides = carousel.state.totalItems - 1;
      const currentSlide = carousel.state.currentSlide;
      const scrollPercentage = totalSlides >= 0 ? currentSlide / totalSlides : 0;

      scrollThumbRef.current.style.transform = `translateX(${scrollPercentage * 100}%)`;
    };

    // Initial update
    updateScrollbar();

    // Subscribe to carousel changes
    const interval = setInterval(updateScrollbar, 100);
    return () => clearInterval(interval);
  }, []);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.clientX - scrollbarRef.current.getBoundingClientRect().left;
    scrollLeft.current = scrollThumbRef.current.getBoundingClientRect().left -
      scrollbarRef.current.getBoundingClientRect().left;
    document.body.style.cursor = 'grabbing';
    document.body.style.userSelect = 'none';
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current || !carouselRef.current) return;

    const carousel = carouselRef.current;
    const totalSlides = carousel.state.totalItems - 1;
    if (totalSlides <= 0) return;

    const scrollbarRect = scrollbarRef.current.getBoundingClientRect();
    const scrollbarWidth = scrollbarRect.width;
    const thumbWidth = scrollThumbRef.current.offsetWidth;

    let newX = e.clientX - scrollbarRect.left - startX.current;
    newX = Math.max(0, Math.min(newX, scrollbarWidth - thumbWidth));

    const scrollPercentage = newX / (scrollbarWidth - thumbWidth);
    const targetSlide = Math.round(scrollPercentage * totalSlides);

    // Move carousel to the target slide
    if (targetSlide !== carousel.state.currentSlide) {
      carousel.goToSlide(targetSlide);
    }
  };

  const handleMouseUp = () => {
    if (!isDragging.current) return;
    isDragging.current = false;
    document.body.style.cursor = '';
    document.body.style.userSelect = '';
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };


  const handleNavigate = (data) => {
    switch (data) {
      case "Group":
        setActiveTab("Group");
        break;
      case "Shop":
        navigate("/Shop");
        break;
      case "Trending":
        setActiveTab("Trending");
        // navigate("/trending-videos");
        break;
      case "Hashtag":
        setActiveTab("Hashtag");

        // navigate("/Hashtag/trending");
        break;
      case "Users":
        setActiveTab("Users");
        break;
      case "Posts":
        setActiveTab("Posts");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="w-full flex justify-between items-center mt-1 px-1 gap-x-2">
        <IoArrowBackCircleSharp
          onClick={() => navigate(-1)}
          className="text-[30px] cursor-pointer dark:text-white "
        />
        <div className="relative w-full">
          <input
            placeholder="Search..."
            onChange={handleSearchBar}
            className="rounded-full p-1 pl-8 pr-3 w-[100%] bg-gray-300 text-black placeholder-black outline-none"
          />
          <FaSearch color="black" className="absolute left-2 top-2" />
        </div>
      </div>
      <div className="w-full relative py-2 group">
        <Carousel
          ref={carouselRef}
          ssr
          swipeable
          draggable
          showDots={false}
          infinite={false}
          responsive={responsive}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          containerClass="gap-1 overflow-x-auto scrollbar-hide"
          itemClass="min-w-fit px-1"
        // partialVisible
        >
          {["Group", "Trending", "Posts", "Hashtag", "Users", "Shop"].map((data) => (
            <div key={data} className="flex h-full py-2 justify-center items-center">
              <button
                onClick={() => handleNavigate(data)}
                className={`
                ${activeTab === data
                    ? "bg-green-700 text-white hover:bg-green-600"
                    : "bg-[#3a3a3a] hover:bg-[#1d1d1d] text-white"}
                text-xs rounded-md w-full p-2 whitespace-nowrap transition-colors
              `}
              >
                {data}
              </button>
            </div>
          ))}
        </Carousel>

        {/* Interactive Scrollbar Track */}
        <div
          ref={scrollbarRef}
          className="absolute bottom-1 left-0 mx-2 right-0 h-2 bg-gray-200"
        >
          <div
            ref={scrollThumbRef}
            className="h-full bg-black  absolute top-0 left-0 w-32  cursor-grab active:cursor-grabbing"
            onMouseDown={handleMouseDown}
          />
        </div>
      </div>
    </>
  );
}



const ScrollControlledCarousel = ({ activeTab, handleNavigate }) => {


  return (<>


  </>
  );
};

