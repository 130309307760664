import React, { useEffect, useState } from "react";

import CreatedGroupProfileSection from "./CreatedGroupProfileSection";
import CreatedGroupTab from "./CreatedGroupTab";
import { useDispatch, useSelector } from "react-redux";
import CreatedGroupSliderMenu from "./CreatedGroupSliderMenu";
import { useNavigate, useParams } from "react-router-dom";
import { LockConfirmationModal } from "../Modals/UserCreatedGroup/LockConfirmationModal";
import { groupLeave, groupStatusChange } from "../../Redux/ApiSlice/Group/GroupSlice";
import toast, { Toaster } from "react-hot-toast";

export const CreatedGroupSetting = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { groupId } = params
    const dispatch = useDispatch()
    const groupDetails = useSelector((state) => state.group)
    const [open, setOpen] = useState(false);
    const [groupLock, setGroupLock] = useState(false);
    const [openTool, setOpenTool] = useState(false);
    const [GroupData, seGroupData] = useState({})
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);



    useEffect(() => {
        seGroupData(groupDetails)
    }, [groupDetails])

    const handleLeaveGroup = async () => {
        try {
            const response = await dispatch(groupLeave(groupId))
            console.log(response, "response")
            seGroupData({ ...GroupData, data: { ...GroupData?.data, user_joined: !GroupData?.data?.user_joined } })
            // navigate("/groups")
            toast.success(response?.payload?.message)
            setTimeout(() => {
                navigate("/account/group")
            }, [2000])
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeStatusOfGroup = async () => {
        try {
            const responce = await dispatch(groupStatusChange(groupId))
            handleOpen(false)
            toast.success(responce?.payload?.detail)
            setGroupLock(!groupLock)
        } catch (error) {
            console.log("error")
        }
    }

    const handleOpen = (value) => {
        if (value) {
            setGroupLock(value);
        } else {
            setOpen(value);
        }

    };

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const handleOpenTool = () => setOpenTool(!openTool);

    return (
        <section className="overflow-hidden h-full relative">
            <Toaster position="top-right" reverseOrder={false} />
            <CreatedGroupProfileSection
                setOpen={setOpen}
                handleOpenTool={handleOpenTool}
                groupLock={groupLock}
                groupId={groupId}
                groupDetails={GroupData?.data}
                isDrawerOpen={isDrawerOpen}
                openDrawer={openDrawer}
                handleLeaveGroup={handleLeaveGroup}
            />
            <CreatedGroupTab tabName={"Settings"} />
            <span className="absolute top-0 flex justify-center text-[#ff3b3b] font-bold text-[10px] w-full">
                Copyright © 2025 Galileo One Inc
            </span>
            {/* <span className="absolute top-0 flex justify-center text-[#ff3b3b] font-bold text-[10px] w-full">
                CC CREATED GROUP SETTINGS - FE - V1 - JUNE 25, 2024
            </span> */}
            <div className="bg-white dark:bg-black p-4 pt-1 overflow-scroll  absolute w-full h-[-webkit-fill-available] no-scrollbar">
                <div>
                    <h2 className="text-black dark:text-white font-semibold">Allow Members</h2>
                    {[
                        "Join group directly",
                        "Message as admin",
                        "Create or post as admin",
                        "Share the current group",
                        "Show group members",
                    ].map((item, index) => (
                        <div className="flex justify-between px-4 w-full pt-3">
                            <p className="text-gray-600 dark:text-white text-[11px] ">{item}</p>
                            <label class="inline-flex items-center  cursor-pointer">
                                <input type="checkbox" value="" class="sr-only peer" />
                                <div class="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#fff] after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                        </div>
                    ))}
                </div>
                <div className="pt-3">
                    <h2 className="text-black dark:text-white font-semibold">Group Updates</h2>
                    {[
                        "Member added invitation",
                        "Updates from admin",
                        "Group updates",
                        "Group updates",
                    ].map((item, index) => (
                        <div key={index}>
                            <div className="flex justify-between px-4 w-full pt-3">
                                <p className="text-gray-600 dark:text-white text-[11px] ">{item}</p>
                                <label class="inline-flex items-center  cursor-pointer">
                                    <input type="checkbox" value="" class="sr-only peer" />
                                    <div class="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#fff] after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                            </div>
                            <div className="grid grid-cols-6 px-6 pt-2">
                                <div className="col-span-2 flex gap-1">
                                    <div class=" flex items-center ">
                                        <input
                                            id="green-radio"
                                            type="radio"
                                            value=""
                                            name="colored-radio"
                                            class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300  dark:border-gray-600"
                                        />
                                    </div>
                                    <p className="text-gray-600 dark:text-white text-[11px] md:text-[10px]">
                                        In App
                                    </p>
                                </div>

                                <div className="col-span-2 flex gap-1">
                                    <div class=" flex items-center ">
                                        <input
                                            id="green-radio"
                                            type="radio"
                                            value=""
                                            name="colored-radio"
                                            class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300  dark:border-gray-600"
                                        />
                                    </div>
                                    <p className="text-gray-600 dark:text-white text-[11px] md:text-[10px]">
                                        Email
                                    </p>
                                </div>

                                <div className="col-span-2 flex gap-1">
                                    <div class="flex items-center">
                                        <input
                                            id="green-radio"
                                            type="radio"
                                            value=""
                                            name="colored-radio"
                                            class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 dark:border-gray-600"
                                        />
                                    </div>
                                    <p className="text-gray-600 dark:text-white whitespace-nowrap text-[11px] md:text-[10px]">
                                        Push Updates
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <LockConfirmationModal
                open={open}
                handleOpen={handleOpen}
                groupLock={groupLock}
                handleChangeStatusOfGroup={handleChangeStatusOfGroup}
            />
            <CreatedGroupSliderMenu groupId={groupId} isDrawerOpen={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />

        </section>
    );
}
export default React.memo(CreatedGroupSetting);