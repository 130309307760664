import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";


export const saveGroupPost = createAsyncThunk("saveGroupPost", async ({ groupId, postId }) => {
    try {
        const response = await axiosInstance.post(
            `/group/groups/${groupId}/posts/${postId}/save/`, {}
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});

export const getSaveGroupPost = createAsyncThunk("getSaveGroupPost", async ({ groupId }) => {
    try {
        const response = await axiosInstance.get(
            `/group/groups/${groupId}/saved-posts`
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});


export const GroupPostSaveSlice = createSlice({
    name: "GroupPostSaveSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(getSaveGroupPost.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSaveGroupPost.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getSaveGroupPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default GroupPostSaveSlice.reducer;
