import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const selectVideo = createAsyncThunk("selectVideo", async (data) => {
  try {
    const response = data;
    return response;
  } catch (error) {
    return error;
  }
});


export const videoView = createAsyncThunk(
  "groupStatusChange", async (postId) => {
    try {
      const response = await axiosInstance.get(`/post/view/${postId}/`);
      const result = response.data;
      return result;
    } catch (error) {
      console.error("Error:", error.response.data);
    }
  }
);


export const SelectVideoView = createSlice({
  name: "SelectVideoView",
  initialState: {
    SelectedVideo: {},
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // AccountProfile action promises
    builder.addCase(selectVideo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(selectVideo.fulfilled, (state, action) => {
      state.SelectedVideo = action.payload;
      state.loading = false;
    });
    builder.addCase(selectVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default SelectVideoView.reducer;
