import React from "react";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import {useSelector} from "react-redux"
import { FollowingTab } from "../../Component/AccountPage/FollowingTab";

export default function Following() {
  const followerlist = useSelector((state)=>state.AccountProfile)
  const { User_followingList } = followerlist;
  return (
    <div className="bg-[#000] overflow-hidden h-full relative ">
      {/* <p className="text-[#ff3b3b] text-center w-full  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
        CC ACCOUNT FOLLOWING PAGE-FE - V10 /MAR 25,2024
      </p> */}
      <p className="text-[#ff3b3b] text-center w-full  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
      Copyright © 2025 Galileo One Inc
      </p>
      <div className="">
        <AccountProfileSection />
      </div>
      <div className="p-0 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
        <FollowingTab datalist={User_followingList} />
      </div>
    </div>
  );
}
