import React, { useState } from "react";
import { Stepper, Step } from "@material-tailwind/react";
import { wishlistProducts } from "../../Datas/Datas";
import { useNavigate } from "react-router-dom";
import { IoStar, IoStarOutline } from "react-icons/io5";

const OrderDetails = () => {
   const [activeStep, setActiveStep] = useState(2);
   const [showConfirm, setShowConfirm] = useState(false);
   const navigate = useNavigate();

   const handleCancelClick = () => {
      setShowConfirm(true);
   };

   const handleConfirmYes = () => {
      setShowConfirm(false);
      navigate("/ordercancel");
   };

   const handleConfirmNo = () => {
      setShowConfirm(false);
   };

   return (
      <div className="px-2 dark:text-white">
         <p className="text-center text-[#000] dark:text-white font-bold text-[20px] mb-2">Order Details</p>
         <p className="text-[13px] font-semibold mb-2">Purchased on Apr 28, 2024</p>
         {wishlistProducts.map((data) => (
            <div className="grid grid-cols-12 gap-2 mb-4 border-b border-gray-300 pb-4" key={data.id}>
               <div className="col-span-4">
                  <img
                     alt="img"
                     src={data.imgUrl}
                     onClick={() => navigate("/shopdetails")}
                     className="img-shadow rounded-2xl h-[5.9rem] w-full object-cover bg-[#fff]"
                  />
                  <p className="line-clamp-1 text-[12px] font-bold mt-[4px]" onClick={() => navigate("/shopdetails")}>{data.name}</p>
                  <div className="flex items-center justify-start text-yellow-800 text-[12px] mb-[3px]" onClick={() => navigate("/shopdetails")}>
                     <IoStar className="text-yellow-800 text-[12px]" />
                     <IoStar className="text-yellow-800 text-[12px]" />
                     <IoStar className="text-yellow-800 text-[12px]" />
                     <IoStar className="text-yellow-800 text-[12px]" />
                     <IoStarOutline className="text-yellow-800 text-[12px]" />
                  </div>
                  <p className="line-clamp-2 text-[10px] leading-3" onClick={() => navigate("/shopdetails")}>{data.description}</p>
               </div>
               <div className="col-span-8">
                  <div className="w-full py-4 px-5 pt-4 pb-6">
                     <Stepper
                        activeStep={activeStep}
                        lineClassName="bg-gray-600"
                        activeLineClassName="bg-gray-600"
                     >
                        <Step
                           className="h-3 w-3 !bg-gray-400 text-[#000] dark:text-white  dark:text-white cursor-pointer"
                           activeClassName="ring-0 !bg-red-500 text-[#fff]"
                           completedClassName="!bg-green-500 text-[#fff]"
                        >
                           <div className="absolute -bottom-[1.3rem] w-max text-center text-[10px]">
                              <p className=" text-[#000] dark:text-white">Shipped</p>
                           </div>
                        </Step>
                        <Step
                           className="h-3 w-3 !bg-gray-400 text-[#000] dark:text-white cursor-pointer"
                           activeClassName="ring-0 !bg-red-500 text-[#000] dark:text-white dark:text-white"
                           completedClassName="!bg-green-500 text-[#fff]"
                        >
                           <div className="absolute -bottom-[1.3rem] w-max text-center text-[10px]">
                              <p className=" text-[#000] dark:text-white">Transit</p>
                           </div>
                        </Step>
                        <Step
                           className="h-3 w-3 !bg-gray-400 text-[#000] dark:text-white cursor-pointer"
                           activeClassName="ring-0 !bg-red-500 text-[#fff]"
                           completedClassName="!bg-green-500 text-[#fff]"
                        >
                           <div className="absolute -bottom-[1.3rem] w-max text-center text-[10px]">
                              <p className=" text-[#000] dark:text-white ">Delivered</p>
                           </div>
                        </Step>
                     </Stepper>
                  </div>
                  {activeStep === 2 && <p className="text-center text-[10px]">Current Status - Transit in Canada</p>}
                  <div className={`${activeStep === 2 ? "mt-[10px]" : "mt-[25px]"} px-2 leading-[13px] relative`}>
                     <div className="flex flex-col">
                        <p className="text-[10px] font-bold dark:text-white">Price: <span className="text-gray-800 dark:text-gray-500">${data.price}</span></p>
                        <p className="text-[10px] font-bold dark:text-white">QTY: <span className="text-gray-800 dark:text-gray-500">{data.qty}</span></p>
                        <p className="text-[10px] font-bold dark:text-white">Manufacture: <span className="text-gray-800 dark:text-gray-500">{data.manufacture}</span></p>
                        <p className="text-[10px] font-bold dark:text-white text-yellow-900">485 Rating</p>
                     </div>
                     <div className="flex justify-between">
                        <p className="font-bold text-[#000] dark:text-white text-[10px]">Excepted Delivery</p>
                        <p className="font-bold text-green-500 text-[10px]">29, FEB 2024</p>
                     </div>
                     <button
                        onClick={handleCancelClick}
                        className="absolute text-[10px] right-2 top-0 bg-gray-300 hover:bg-red-700 text-black hover:text-white hover:shadow-2xl px-[6px] py-[3px] rounded-sm">
                        Cancel
                     </button>
                  </div>
               </div>
            </div>
         ))}
         {showConfirm && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
               <div className="bg-white border border-gray-400 dark:bg-black p-4 rounded-md m-4">
                  <p className="text-center  dark:text-white mb-4 text-[12px]">Are you sure you want to cancel the order?</p>
                  <div className="flex justify-around">
                     <button
                        onClick={handleConfirmYes}
                        className="bg-red-500 hover:bg-red-600 text-white text-[12px] px-4 py-1 rounded"
                     >
                        Yes
                     </button>
                     <button
                        onClick={handleConfirmNo}
                        className="bg-gray-300 hover:bg-gray-400 text-black text-[12px] px-4 py-1 rounded"
                     >
                        No
                     </button>
                  </div>
               </div>
            </div>
         )}
      </div>
   )
}

export default OrderDetails