import React from "react";
import { Button } from "@material-tailwind/react";

export default function InviteCall({ handleInvite }) {
  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-4 relative  h-full w-full ">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="flex flex-col gap-4 justify-center mb-4 items-center">
            <img
              id="zoom-in-zoom-out"
              src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="invite"
              className="h-14 w-14 rounded-full object-cover  m-3"
            />
            <div>
              <p className="text-[1.5rem] text-white font-bold">James Wilson</p>
              {false ? (
                <p className="text-[10px] text-green-800 font-bold text-center">
                  online
                </p>
              ) : (
                <p className="text-[10px] text-red-800 font-bold text-center">
                  Offline
                </p>
              )}
            </div>
          </div>
          <Button className="bg-red-700 w-2/4" onClick={handleInvite}>
            Cancel{" "}
          </Button>
        </div>
        <p className="text-[10px] text-red-900 font-bold  absolute top-0 w-full text-center">Copyright © 2025 Galileo One Inc</p>
        {/* <p className="text-[10px] text-red-900 font-bold  absolute top-0 w-full text-center">CC INVITE CALL , V1 , MAY 26 2024  </p> */}
      </div>

    </React.Fragment>
  );
}
