import React from "react"
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react"
import { wishlistProducts } from "../../Datas/Datas";
import { IoStar, IoStarOutline } from "react-icons/io5";

const MyWishlist = () => {
   const navigate = useNavigate();

   return (
      <div className="px-3 pt-5">
         <div className="grid grid-cols-12 items-center mb-4">
            <div className="col-span-2"></div>
            <div className="col-span-8 flex items-center justify-center">
               <p className="text-[#000] dark:text-white font-bold text-[20px]">
                  My Wishlist ({wishlistProducts.length})
               </p>
            </div>
            <div className="col-span-2 flex items-center justify-end">
               <SideBar />
            </div>
         </div>

         <div className="grid grid-cols-2 lg:grid-cols-2 gap-x-[10px] mb-5">
            {wishlistProducts.map((data) => (
               <div className="mb-5" key={data.id}>
                  <img
                     src={data.imgUrl}
                     alt={data.name}
                     onClick={() => navigate("/shopdetails")}
                     className="img-shadow rounded-2xl h-[9rem] w-full object-cover bg-[#fff]"
                  />
                  <div className="text-[#000] dark:text-white mt-2 px-1 text-start">
                     <p className="line-clamp-1 text-[13px] font-semibold" onClick={() => navigate("/shopdetails")}>{data.name}</p>
                     <p className="line-clamp-1 text-[11px]" onClick={() => navigate("/shopdetails")}>{data.description}</p>
                     <div className="flex items-center gap-x-2 justify-between py-[2px]">
                        <div className="flex items-center justify-start text-yellow-800 text-[12px]" onClick={() => navigate("/shopdetails")}>
                           <IoStar className="text-yellow-800 text-[12px]" />
                           <IoStar className="text-yellow-800 text-[12px]" />
                           <IoStar className="text-yellow-800 text-[12px]" />
                           <IoStar className="text-yellow-800 text-[12px]" />
                           <IoStarOutline className="text-yellow-800 text-[12px]" />
                        </div>
                        <p className="text-[8px] text-gray-600">400+ Ratings</p>
                     </div>
                     <div className="flex justify-between items-center mt-1">
                        <p className="text-[18px] md:text-[12px] font-bold" onClick={() => navigate("/shopdetails")}>${data.price}</p>
                        <select className="bg-[#f8000080] dark:text-black rounded-lg h-[20px] px-[2px] text-[10px] focus:outline-none">
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={1}>Qty - 1</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={2}>Qty - 2</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={3}>Qty - 3</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={4}>Qty - 4</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={5}>Qty - 5</option>
                        </select>
                     </div>
                     <div className="flex justify-between items-center mt-[5px]">
                        <Button className="bg-gray-300 text-[9px] md:text-[7px]  text-[#000] px-2 py-2 shadow-none">Remove</Button>
                        <Button className="bg-gray-300 text-[9px] md:text-[7px] text-[#000] px-2 py-2 shadow-none" onClick={() => navigate("/basket")}>Move to Basket</Button>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default MyWishlist