import React, { useRef, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { TbDotsVertical } from "react-icons/tb";
import { useOutsideClick } from "../Hooks/useOutsideClick";
import { HiMiniSpeakerWave, HiMiniSpeakerXMark } from "react-icons/hi2";

export default function VideoCard({
    id,
    likes_count,
    body,
    video,
    avatar_url,
    handleDeletePost,
    handleMoveVideo,
    threeDots,
    belowDecs,
    selectVideo,
    view_count,
    isSavedView,
}) {
    const menuRef = useRef(null);
    const savedPopupRef = useRef(null);
    const [postId, setpostId] = useState(null);
    const [sound, setSound] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [showSavedPopup, setShowSavedPopup] = useState(false);

    const videoRef = useRef();

    const handleMouseEnter = () => {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
            playPromise.catch((error) => {
                // Auto-play was prevented
                // Show a UI element to let the user manually start playback
            });
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    const handleClickOutside = () => {
        setpostId(null);
        setShowSavedPopup(false);
    };

    useOutsideClick(menuRef, handleClickOutside);
    useOutsideClick(savedPopupRef, handleClickOutside);

    return (
        <div
            key={id}
            className={`col-span-1 ${!belowDecs ? "flex flex-col-reverse gap-1" : ""}`}
        >
            <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className="h-[27vh] w-[100%] relative rounded-md overflow-hidden"
            >
                <div
                    className="w-full h-full rounded-md"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <video
                        muted={sound}
                        ref={videoRef}
                        src={`${video}`}
                        autoPlay={hovered}
                        onClick={() => selectVideo(id)}
                        controlsList="nodownload noremoteplayback"
                        className="h-full w-full object-cover rounded-md cursor-pointer border border-gray-400 dark:border-gray-800"
                    />
                    {video && threeDots && !isSavedView && (
                        <TbDotsVertical
                            onClick={() => setpostId(id)}
                            className="absolute -right-1 bottom-1 font-bold cursor-pointer z-30 w-6 h-86 text-white"
                        />
                    )}
                    {postId === id && !isSavedView && (
                        <div
                            ref={menuRef}
                            className="absolute text-[#000] font-bold bg-white px-1 py-[2px] text-[12px] right-2 bottom-6 z-50 rounded-sm"
                        >
                            <p
                                className="flex items-center text-[10px] cursor-pointer"
                                onClick={() => handleDeletePost(id)}
                            >
                                Delete
                            </p>
                        </div>
                    )}
                    {isSavedView &&
                        <div className="absolute -right-0.5 bottom-1 cursor-pointer">
                            <div className="relative">
                                <TbDotsVertical
                                    onClick={() => setShowSavedPopup(!showSavedPopup)}
                                    className="text-[16px] text-white"
                                />
                                {showSavedPopup &&
                                    <div
                                        ref={savedPopupRef}
                                        className="absolute bottom-0 right-3 bg-white rounded-md border border-gray-400 overflow-hidden"
                                    >
                                        <p
                                            onClick={() => handleMoveVideo && handleMoveVideo(id)}
                                            className="text-[9px] px-1.5 py-0.5 border-b border-gray-400 hover:bg-gray-200"
                                        >
                                            Move
                                        </p>
                                        <p
                                            onClick={() => handleDeletePost(id)}
                                            className="text-[9px] px-1.5 py-0.5 hover:bg-gray-200"
                                        >
                                            Remove
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className="absolute top-1 right-1">
                    <div className="flex items-center justify-end gap-x-1.5 right-0">
                        <p className="text-white text-[10px] mt-[1px]">{view_count}</p>
                        <FaRegEye className="text-[12px] text-white" />
                    </div>
                    <div className="flex justify-end gap-x-1.5">
                        <p className="text-white text-[10px]">{likes_count}</p>
                        <FaThumbsUp className="text-[12px] text-white" />
                    </div>
                </div>
                <div className="flex justify-between absolute top-2 left-0 w-full px-2">
                    <div
                        className={`rounded-full p-[2px] cursor-pointer flex justify-center items-center 
                            ${hovered ? "bg-white dark:bg-black dark:text-white" : ""} opacity-75 hover:opacity-90
                        `}
                    >
                        {!sound ? (
                            <HiMiniSpeakerWave
                                className="text-[15px]"
                                onClick={() => setSound(!sound)}
                            />
                        ) : (
                            <HiMiniSpeakerXMark
                                className="text-[15px]"
                                onClick={() => setSound(!sound)}
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* {body && (
                <div className="grid grid-cols-12 items-center mt-1">
                    <Avatar src={`${avatar_url}`} className="h-5 w-5 col-span-2" />
                    <p className="font-medium text-[8px] col-span-10 text-black dark:text-white line-clamp-1 ml-1">
                        {body}
                    </p>
                </div>
            )} */}
        </div>
    );
}
