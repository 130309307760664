import React, { useState } from "react";
import { IconButton, Typography, List, ListItem, ListItemPrefix, ListItemSuffix, Chip, Accordion, AccordionHeader, AccordionBody, Drawer, Card } from "@material-tailwind/react";
import { PresentationChartBarIcon, ShoppingBagIcon, UserCircleIcon, Cog6ToothIcon, InboxIcon, PowerIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { PiBasketFill } from "react-icons/pi";
import { GiEternalLove } from "react-icons/gi";
import { MdFavorite } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";
import { IoIosHelpCircle } from "react-icons/io";

const SideBar = () => {
   const [open, setOpen] = useState(0);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [search, setSearch] = useState("");
   const navigate = useNavigate();
   const location = useLocation();

   const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
   };

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   const handleSearchChange = (e) => {
      setSearch(e.target.value);
   };

   return (
      <>
         <button onClick={openDrawer}>
            {isDrawerOpen ? (
               <XMarkIcon className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "dark:text-[#fff] text-[#000]" : "dark:text-[#fff] text-black"}`} />
            ) : (
               <Bars3Icon className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "dark:text-[#fff] text-[#000]" : "dark:text-[#fff] text-black"}`} />
            )}
         </button>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            placement="right"
            className="absolute overflow-y-scroll w-4/5 dark:bg-black "
            style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
         >
            <Card
               color="transparent"
               shadow={false}
               className="overflow-scroll no-scrollbar"
               style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
            >
               <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
                  Copyright © 2025 Galileo One Inc
               </p>
               <div className="mt-2 flex items-center gap-4 p-4 overflow-scroll no-scrollbar">
                  <Typography variant="h4"  className="dark:text-gray-300">
                     Setting
                  </Typography>
               </div>
               <div className="px-4 relative">
                  <MagnifyingGlassIcon className="h-5 w-5 absolute top-[10px] left-[20px] cursor-pointer" />
                  <input
                     type="text"
                     placeholder="Search"
                     value={search}
                     onChange={handleSearchChange}
                     className="border border-[#d9d9d9] w-full pl-7 pr-2 py-[0.4rem] focus:outline-none rounded-[5px]"
                  />
               </div>
               <List>
                  {search ? (
                     <>
                        <ListItem>
                           <ListItemPrefix>
                              <Cog6ToothIcon className="h-5 w-5" />
                           </ListItemPrefix>
                           Settings
                        </ListItem>
                     </>
                  ) : (
                     <>
                        <Accordion
                           open={open === 1}
                           icon={
                              <ChevronDownIcon
                                 strokeWidth={2.5}
                                 className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                              />
                           }
                        >
                           <ListItem className="p-0" selected={open === 1}>
                              <AccordionHeader
                                 onClick={() => handleOpen(1)}
                                 className="border-b-0 p-3"
                              >
                                 <ListItemPrefix>
                                    <PresentationChartBarIcon className="h-5 w-5 dark:text-gray-300" />
                                 </ListItemPrefix>
                                 <Typography   className="mr-auto font-normal dark:text-gray-300">
                                    History
                                 </Typography>
                              </AccordionHeader>
                           </ListItem>
                           <AccordionBody className="py-1">
                              <List className="p-0">
                                 <ListItem className="dark:text-gray-300">
                                    <ListItemPrefix>
                                       <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    Order History
                                 </ListItem>
                                 <ListItem className="dark:text-gray-300">
                                    <ListItemPrefix>
                                       <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    Recent Viewed
                                 </ListItem>
                              </List>
                           </AccordionBody>
                        </Accordion>
                        <ListItem className="dark:text-gray-300" onClick={() => navigate("/shop/sell-products")}>
                           <ListItemPrefix>
                              <PiBasketFill className="h-5 w-5" />
                           </ListItemPrefix>
                           Sell Items Locally
                        </ListItem>
                        <hr className="my-2 border-blue-gray-50" />
                        <ListItem className="dark:text-gray-300" onClick={() => navigate("/basket")}>
                           <ListItemPrefix>
                              <PiBasketFill className="h-5 w-5" />
                           </ListItemPrefix>
                           My Basket
                        </ListItem>
                        <ListItem className="dark:text-gray-300" onClick={() => navigate("/wishlist")}>
                           <ListItemPrefix>
                              <GiEternalLove className="h-5 w-5" />
                           </ListItemPrefix>
                           My Wishlist
                        </ListItem>
                        <ListItem className="dark:text-gray-300" onClick={() => navigate("/shop/favorites")}>
                           <ListItemPrefix>
                              <MdFavorite className="h-5 w-5" />
                           </ListItemPrefix>
                           Favorites
                        </ListItem>
                        <ListItem className="dark:text-gray-300">
                           <ListItemPrefix>
                              <IoIosSave className="h-5 w-5" />
                           </ListItemPrefix>
                           Shipping Address
                        </ListItem>
                        <ListItem className="dark:text-gray-300" onClick={() => navigate("/shop/payment-details")}>
                           <ListItemPrefix>
                              <MdOutlinePayment className="h-5 w-5" />
                           </ListItemPrefix>
                           Payment Details
                        </ListItem>
                     </>
                  )}
               </List>
            </Card>
         </Drawer>
      </>
   );
};

export default SideBar;