import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Drawer,
  Card,
  Switch,
} from "@material-tailwind/react";

import { MdOutlineBrowserUpdated } from "react-icons/md";
import { FaSave } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { MdReportProblem } from "react-icons/md";
import { BiSolidVolumeMute } from "react-icons/bi";
import { SiApostrophe } from "react-icons/si";
import { HiSpeakerWave } from "react-icons/hi2";
import { MdOutlineHideImage } from "react-icons/md";
import { IoPersonAdd, IoSettings } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { deleteGroup } from "../../Redux/ApiSlice/Group/GroupSlice";
import { FaTrashCan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { IoMdExit } from "react-icons/io";

const CreatedGroupSliderMenu = ({
  groupId,
  isDrawerOpen,
  closeDrawer,
  groupDetails,
  handleLeaveGroup,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [autoMute, setAutoMute] = useState(false);

  const handleChangeAutoMute = () => setAutoMute(!autoMute);

  const handleDeleteGroup = async () => {
    try {
      await dispatch(deleteGroup(groupId));
      navigate("/account/group");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={closeDrawer}
      placement="right"
      className="absolute overflow-hidden dark:bg-black  bg-white overflow-y-scroll  w-[75%]"
    >
      <Card
        color="transparent"
        shadow={false}
        className="overflow-hidden  overflow-y-scroll no-scrollbar"
        style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
      >
        <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
          Copyright © 2025 Galileo One Inc
        </p>
        {/* <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
                        CC GROUP OWNER SLIDER MENU / FE / V3 / JULY 08, 2024
                    </p> */}
        <List className="mt-1">
          {groupDetails?.current_user_role !== "member" && (
            <ListItem className="dark:text-white"
              onClick={() => navigate(`/createdgroup/request/${groupId}`)}
            >
              <ListItemPrefix>
                <FaUserCheck className="h-5 w-5" />
              </ListItemPrefix>
              Request
            </ListItem>
          )}
          <ListItem className="dark:text-white" onClick={() => navigate(`/createnewgroup/${groupId}`)}>
            <ListItemPrefix>
              <MdOutlineBrowserUpdated className="h-5 w-5" />
            </ListItemPrefix>
            Update Group
          </ListItem>
          <ListItem className="dark:text-white" onClick={() => navigate(`/invitemember/${groupId}`)}>
            <ListItemPrefix>
              <IoPersonAdd className="h-5 w-5" />
            </ListItemPrefix>
            Invite
          </ListItem>
          <ListItem className="dark:text-white">
            <ListItemPrefix>
              <SiApostrophe className="h-5 w-5" />
            </ListItemPrefix>
            Posts
          </ListItem>
          <ListItem className="dark:text-white" onClick={() => navigate(`/createdgroup/${groupId}/saved/`)}>
            <ListItemPrefix>
              <FaSave className="h-5 w-5" />
            </ListItemPrefix>
            Saved Posts
          </ListItem>
          <ListItem className="dark:text-white"
            onClick={() => navigate(`/createdgroup/${groupId}/hidden/`)}
          >
            <ListItemPrefix>
              <MdOutlineHideImage className="h-5 w-5" />
            </ListItemPrefix>
            Hidden Posts
          </ListItem>
          <ListItem className="dark:text-white">
            <ListItemPrefix>
              <MdReportProblem className="h-5 w-5" />
            </ListItemPrefix>
            Report
          </ListItem>
          <ListItem className="dark:text-white">
            <ListItemPrefix>
              {autoMute ? (
                <BiSolidVolumeMute className="h-5 w-5" />
              ) : (
                <HiSpeakerWave className="h-5 w-5" />
              )}
            </ListItemPrefix>
            Mute Notification
            <ListItemSuffix>
              <Switch checked={autoMute} onChange={handleChangeAutoMute} />
            </ListItemSuffix>
          </ListItem>
          {groupDetails?.current_user_role !== "member" && (
            <ListItem className="dark:text-white" onClick={handleDeleteGroup}>
              <ListItemPrefix>
                <FaTrashCan className="h-5 w-5" />
              </ListItemPrefix>
              Delete Group
            </ListItem>
          )}
          <ListItem className="dark:text-white"
            onClick={() => navigate(`/created/group/${groupId}/settings/`)}
          >
            <ListItemPrefix>
              <IoSettings className="h-5 w-5" />
            </ListItemPrefix>
            Settings
          </ListItem>
          {groupDetails?.user_joined && (
            <ListItem className="dark:text-white" onClick={() => handleLeaveGroup()}>
              <ListItemPrefix>
                <IoMdExit className="h-5 w-5" />
              </ListItemPrefix>
              Leave Group
            </ListItem>
          )}
        </List>
      </Card>
    </Drawer>
  );
};

export default CreatedGroupSliderMenu;
