import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getGroupPostsList } from "../../Redux/ApiSlice/Group/GroupPostsSlice";
import { reportGroupPost } from "../../Redux/ApiSlice/Group/GroupPostReportSlice";
import toast, { Toaster } from "react-hot-toast";

export default function GroupPostReport() {
    const params = useParams();
    const { groupId, postId } = params;
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const groupPosts = useSelector((state) => state.groupPosts)
    const post = groupPosts?.data?.results?.find((post) => post.id === postId);
    const [reason, setReason] = useState("");
    const [reasonText, setReasonText] = useState("");

    const handleChange = (event) => {
        const inputText = event.target.value;
        if (inputText.length <= 200) {
            setCount(inputText.length);
            setReasonText(inputText);
        }
    };

    const getGroupPostData = useCallback(async () => {
        try {
            await dispatch(getGroupPostsList(groupId))
        } catch (error) {
            console.log(error)
        }
    }, [dispatch, groupId])

    const handleSubmitReport = async () => {
        if (!reason) {
            toast.error("Please select a reason to report");
            return;
        }
        try {
            const toastId = toast.loading("Reporting Post...");
            const form_data = new FormData();
            form_data.append("reason", reason);
            form_data.append("additional_reason", reasonText);
            await dispatch(reportGroupPost({ groupId, postId, form_data }));
            toast.dismiss(toastId);
            navigate(-1);
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        getGroupPostData()
    }, [getGroupPostData])

    const reasonData = [{
        id: 1,
        reason: "Break Rules"
    },
    {
        id: 2,
        reason: "Harassment"
    },
    {
        id: 3,
        reason: "Hate"
    },
    {
        id: 4,
        reason: "Violence"
    },
    {
        id: 5,
        reason: "Abuse"
    },
    {
        id: 6,
        reason: "Spam"
    }]

    return (
        <div className="bg-white dark:bg-black h-full overflow-scroll px-3 pt-2">
            <Toaster position="top-right" />
            {/* <p className="w-full text-center text-[8px] -mt-[5px] text-red-900 font-bold">
            CC USER CREATED GROUP POST REPORT PAGE / FE / V2 / JULY 06, 2024
         </p> */}
            <p className="w-full text-center text-[8px] -mt-[5px] text-red-900 font-bold">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="grid grid-cols-12  pb-2">
                <div className="sticky top-0 col-span-12 grid grid-cols-12 w-full bg-white dark:bg-black">
                    <div className="col-span-2 mb-3 flex  items-center">
                        <IoArrowBackCircleSharp className="text-[1.2rem] text-black dark:text-white cursor-pointer" onClick={() => navigate(-1)} />
                    </div>
                    <div className="col-span-8 mb-3">
                        <h2 className="font-bold text-black dark:text-white text-[24px] md:text-[20px] text-center underline underline-offset-[5px]">
                            Report Page
                        </h2>
                    </div>
                    <div className="col-span-2 mb-3">
                    </div>
                </div>

                <div className="col-span-2 mb-2">
                    <p className="text-black dark:text-white text-[18px] md:text-[13px]">User:</p>
                </div>
                <div className="col-span-9 mb-2">
                    <div className="flex gap-3">
                        <p className="text-black dark:text-white text-[18px] md:text-[13px] font-bold">{post?.created_by?.first_name}</p>
                    </div>
                </div>
                <div className="col-span-2">
                    <p className="text-black dark:text-white text-[18px] md:text-[13px]">Post:</p>
                </div>
                <div className="col-span-9">
                    <img
                        alt="postimg"
                        className="w-full object-cover mt-2 rounded-[10px]"
                        src={post?.image_url}
                    />
                </div>
            </div>
            <div className="grid grid-cols-12 pt-3 pb-2">
                <div className="col-span-12 mb-[5px]">
                    <p className="text-black dark:text-white text-[18px] md:text-[14px] font-bold underline underline-offset-[5px]">
                        Reason For Report
                    </p>
                </div>
                {reasonData.map((data) => (
                    <div className="col-span-6 py-2 flex items-center gap-2" key={data.id}>
                        <div class="inline-flex items-center">
                            <label
                                class="relative flex items-center p-0 rounded-full cursor-pointer"
                                htmlFor="vertical-list-react"
                            >
                                <input
                                    gap-3
                                    checked={reason === data.reason}
                                    id="vertical-list-react"
                                    type="checkbox"
                                    name="reason"
                                    value={data.reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#FF6600] checked:before:bg-[#FF6600]  hover:before:opacity-0"
                                />
                                <span class="absolute text-black dark:text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        stroke-width="1"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                            </label>
                        </div>
                        <label className="text-black dark:text-white text-[15px] md:text-[13px]">{data.reason}</label>
                    </div>
                ))}
                <div className="relative col-span-12 md:mt-4 mt-6 lg:mt-4 xl:mt-4 h-[100px] bg-gray-200 dark:bg-gray-900 text-black dark:text-white rounded-[10px]">
                    <textarea
                        required
                        rows="4"
                        maxLength={200}
                        onChange={handleChange}
                        placeholder="Write your reason here"
                        className="w-full h-[80px] bg-gray-200 dark:bg-gray-900 text-black dark:text-white p-2 rounded-[10px] focus:outline-none resize-none "
                    />
                    <p className="absolute bottom-1 right-2 text-gray-500 text-[14px] -mt-6">
                        {count}/200
                    </p>
                </div>
                <div className="col-span-12 flex justify-center items-center mt-2">
                    <button onClick={() => handleSubmitReport()} className="py-1 px-5 text-[18px] bg-red-600 text-white font-semibold rounded-md">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}
