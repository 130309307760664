import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
// import Cropper from "react-easy-crop";
import { TbPhotoEdit } from "react-icons/tb";
import toast, { Toaster } from "react-hot-toast";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dialog, DialogFooter, Avatar, } from "@material-tailwind/react";
import AccountProfileImageCrop from "../Modals/AccountPageModal/AccountProfileImageCrop";
import { createGroup, getGroupDetails, updateGroup } from "../../Redux/ApiSlice/Group/GroupSlice";

const CreateGroup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const params = useParams()
    const { groupId } = params;
    const fileInputRef = useRef(null);
    const [showToast, setShowToast] = useState(false);

    const [count, setCount] = useState(0);
    const [rulesCount, setRulesCount] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [open, setOpen] = useState(false);

    const [group_name, setGroup_name] = useState("");
    const [description, setDescription] = useState("");
    const [rules, setRules] = useState("");
    const [motto, setMotto] = useState("");
    const [selectedImage, setSelectedImage] = useState(null); // State to store selected image
    const [selectedImageFile, setSelectedImageFile] = useState(null); // State to store selected image

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    // const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    // const [zoom, setZoom] = React.useState(1);
    const [showImage, setShowImage] = useState(null);
    // const onCropChange = (crop) => {
    //    setCrop(crop);
    // };

    const handleOpen = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
        setShowImage(true);
    }
    const handleFileInputChange = (event) => {
        const selectedFile = event.target.files[0];
        // Store selected image in state
        const file = event.target.files[0];
        if (!file) return;
        const fileUrl = URL.createObjectURL(file);
        setSelectedImage(fileUrl);
        setSelectedImageFile(file);
        setOpen(!open);
    };

    // const onCropComplete = (croppedArea, croppedAreaPixels) => {
    // };

    // const onZoomChange = (zoom) => {
    //    setZoom(zoom);
    // };

    // 50 textarea character
    const handleChange = (event) => {
        const inputText = event.target.value;
        setDescription(inputText);
        if (inputText.length <= 200) {
            setCount(inputText.length);
        }
    };
    const handleChangeRules = (event) => {
        const inputText = event.target.value;
        setRules(inputText);
        if (inputText.length <= 200) {
            setRulesCount(inputText.length);
        }
    };

    // checkbox toggle button
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleCreateGroup = async () => {
        setShowToast(true)
        const toastId = toast.loading("Creating group...");
        if (group_name &&
            description &&
            motto &&
            rules &&
            selectedImageFile) {
            const form_data = new FormData();
            form_data.append("group_name", group_name);
            form_data.append("group_profile_picture", selectedImageFile);
            form_data.append("description", description);
            form_data.append("motto", motto);
            form_data.append("rules", rules);
            form_data.append("group_type", isChecked ? "private" : "public");

            try {
                const response = await dispatch(createGroup(form_data));
                console.log(response?.payload?.id, "response1234");

                toast.success("Group created successfully!", { id: toastId });
                setTimeout(() => {
                    navigate(`/invitemember/${response?.payload?.id}`)
                }, [2000])
            } catch (error) {
                toast.error("Failed to create group.", { id: toastId });
            }
        } else {
            toast.error("All fields are required")
            toast.dismiss(toastId);
        }
    }

    const handleUpdate = async () => {
        setShowToast(true)
        const toastId = toast.loading("updating group...");
        if (group_name &&
            description &&
            motto &&
            rules) {
            const form_data = new FormData();
            form_data.append("group_name", group_name);
            if (selectedImageFile) {
                form_data.append("group_profile_picture", selectedImageFile);
            }
            form_data.append("description", description);
            form_data.append("motto", motto);
            form_data.append("rules", rules);
            form_data.append("group_type", isChecked ? "private" : "public");

            try {
                const response = await dispatch(updateGroup({ groupId, form_data }));
                console.log(response?.payload?.id, "response1234");

                toast.success("Group updated successfully!", { id: toastId });
                setTimeout(() => {
                    navigate(`/createdgroup/${groupId}`)
                }, [2000])
            } catch (error) {
                toast.error("Failed to update group.", { id: toastId });
            }
        } else {
            toast.error("All fields are required")
            toast.dismiss(toastId);
        }
    }

    const groupDetailsData = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails(groupId))
            console.log(response, "response")
            setGroup_name(response?.payload?.group_name)
            setDescription(response?.payload?.description)
            setMotto(response?.payload?.motto)
            setRules(response?.payload?.rules)
            setIsChecked(response?.payload?.group_type === "private" ? true : false)
            setSelectedImage(response?.payload?.group_profile_picture)
        } catch (error) {
            console.log(error)
        }

    }, [groupId])

    useEffect(() => {
        if (groupId) {
            groupDetailsData()
        }
        return () => {
            setShowToast(false)
        }
    }, [groupDetailsData])


    return (
        <div className="p-3 bg-white dark:bg-black">
            {showToast && <Toaster position="top-right" />}
            <div className="flex justify-center items-center w-full relative mb-2">
                <div className=" absolute left-0 top-1">
                    <FaArrowAltCircleLeft
                        onClick={() => navigate(-1)}
                        className="text-black dark:text-white text-[18px] cursor-pointer"
                    />
                </div>
                <p className="text-black dark:text-white text-center text-[22px] font-semibold md:text-[18px]">
                    Create Group
                </p>
            </div>
            <form className="mt-2">
                <div className="flex flex-col">
                    <label className="text-black dark:text-white mb-1 text-[18px] md:text-[13px]">
                        Group Name
                    </label>
                    <input
                        required
                        type="text"
                        value={group_name}
                        id="grid-last-name"
                        placeholder="Group Name"
                        onChange={(e) => setGroup_name(e.target.value)}
                        className="text-[14px] appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4  md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                </div>

                <div className="flex justify-center items-center mt-5 mb-3">
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        className="hidden"
                        required
                        onChange={handleFileInputChange}
                    />
                    {!showImage && <div className="flex justify-center items-center rounded-full h-[8rem] w-[8rem] bg-gray-200 md:h-[6rem] md:w-[6rem]">
                        <FaRegPlusSquare
                            onClick={handleIconClick}
                            className="text-[#1d1d1d] text-[23px] cursor-pointer"
                        />
                    </div>}
                    {showImage &&
                        <div className="rounded-[50%] flex flex-col">
                            <div className="relative group">
                                <Avatar src={selectedImage} className="h-[8rem] w-[8rem] md:h-[6rem] md:w-[6rem]" />
                                <div className="absolute inset-0 z-10 items-center justify-center hidden h-[8rem] w-[8rem] md:h-[6rem] md:w-[6rem] rounded-[50%] object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                                    <button className="flex items-center justify-center  h-2 w-2 text-center rounded-full cursor-pointer ring-1  ring-transparent ">
                                        <TbPhotoEdit
                                            color="white"
                                            onClick={handleIconClick}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>}
                    <Dialog
                        open={open}
                        size={300}
                        handler={handleOpen}
                        className={`${selectedImage ? "h-[50%]" : ""}  bg-0 absolute top-[20%] border-0`}
                    >
                        <AccountProfileImageCrop
                            selectedImg={selectedImage}
                            handleOpen={handleOpen}
                            handleSubmitProfileImage={handleOpen}
                            isCreateGroup={true}
                        />
                        <DialogFooter>
                            <div className="w-full flex justify-center h-10 gap-10 ">
                                <Button
                                    className="bg-[hsl(0,0%,60%)] w-[25%] flex justify-center items-center rounded-3xl text-[#000] font-bold text-[18px] cursor-pointer"
                                    onClick={() => handleClose()}
                                >
                                    Save
                                </Button>

                                <Button
                                    className="bg-[hsl(0,0%,60%)] w-[25%] flex justify-center items-center text-[#000] text-[18px] font-bold rounded-3xl cursor-pointer"
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogFooter>
                    </Dialog>
                </div>

                <div className="flex flex-col mb-2">
                    <label className="text-black dark:text-white mb-1 text-[18px] md:text-[13px]">
                        Group Description
                    </label>
                    <div className="relative">
                        <textarea
                            rows="5"
                            maxLength={200}
                            value={description}
                            onChange={handleChange}
                            placeholder="Type Something"
                            required
                            className="text-[14px] resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4 md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"

                        />
                        <p className="absolute text-black text-[12px] bottom-1 right-2">
                            {count}/200
                        </p>
                    </div>
                </div>

                <div className="flex flex-col mb-3">
                    <label className="text-black dark:text-white mb-1 text-[18px] md:text-[13px]">
                        Group Motto
                    </label>
                    <input
                        type="text"
                        value={motto}
                        placeholder="Type Something"
                        required
                        onChange={(e) => setMotto(e.target.value)}
                        className="text-[14px] resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4 md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                </div>

                <div className="flex flex-col mb-2">
                    <label className="text-black dark:text-white mb-1 text-[18px] md:text-[13px]">
                        Rules to follow
                    </label>
                    <div className="relative">
                        <textarea
                            rows="5"
                            maxLength={200}
                            required
                            value={rules}
                            onChange={handleChangeRules}
                            placeholder="Type Something"
                            className="text-[14px] resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4 md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        />
                        <p className="absolute text-black text-[12px] bottom-1 right-2">
                            {rulesCount}/200
                        </p>
                    </div>
                </div>

                <div className="flex flex-col mb-3">
                    <label className="text-black dark:text-white mb-1 text-[18px] md:text-[13px]">
                        Disclaimer
                    </label>
                    <input
                        required
                        type="text"
                        placeholder="Type Disclaimer 1 here"
                        className="mb-1.5 text-[14px] resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4 md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    <input
                        required
                        type="text"
                        placeholder="Type Disclaimer 2 here"
                        className="mb-1.5 text-[14px] resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4 md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    <input
                        required
                        type="text"
                        placeholder="Type Disclaimer 3 here"
                        className="mb-1.5 text-[14px] resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-[8px] py-2 px-4 md:px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                </div>

                <div className="flex justify-between mb-2">
                    <p className="text-black dark:text-white text-[18px] md:text-[13px]">
                        Private
                    </p>
                    <label className="relative inline-flex cursor-pointer select-none items-center">
                        <input
                            type="checkbox"
                            name="autoSaver"
                            className="sr-only"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <span className={`slider flex h-[26px] w-[50px] md:h-[20px] md:w-[45px] items-center rounded-full p-1 duration-200 ${isChecked ? "bg-[#3ccc2f]" : "bg-[#CCCCCE] "}`}>
                            <span className={`dot h-[18px] w-[14px] md:w-[12x] md:h-[14px] rounded-full duration-200 ${isChecked ? "translate-x-6 bg-[#D9D9D9]" : "bg-black"}`}></span>
                        </span>
                    </label>
                </div>
            </form>
            <div className="flex justify-between mt-5 pb-5">
                <button
                    onClick={() => groupId ? handleUpdate() : handleCreateGroup()}
                    className="bg-black dark:bg-white text-white dark:text-black hover:font-bold rounded-full w-[30%] py-1 text-[12px]"
                >
                    {groupId ? "Update" : "Next"}
                </button>
                <button className="bg-black dark:bg-white text-white dark:text-black hover:font-bold rounded-full w-[30%] py-1 text-[12px]" onClick={() => navigate(-1)}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default CreateGroup;
