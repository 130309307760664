import React, { useEffect, useState } from "react";
import { dummyComment } from "../../Datas/Datas";
import { useNavigate, useParams } from "react-router-dom"
import { IoCaretBackCircle } from "react-icons/io5";
import { inviteMembers, searchMembers } from "../../Redux/ApiSlice/Group/GroupInviteMemberSlice";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

const InviteMembers = () => {
    const params = useParams();
    const { groupId } = params;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [individualChecked, setIndividualChecked] = useState({});
    const [search, setSearch] = useState("")
    const [searchResults, setSearchResults] = useState([])

    const handleSelectAllChange = (event) => {
        const { checked } = event.target;
        setSelectAllChecked(checked);
        const updatedIndividualChecked = {};
        searchResults.forEach((data) => {
            updatedIndividualChecked[data.user_id] = checked;
        });
        setIndividualChecked(updatedIndividualChecked);
    };

    const handleIndividualChange = (event, id) => {
        const { checked } = event.target;
        setIndividualChecked((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleInvite = (async () => {
        const keysArray = Object.keys(individualChecked);
        if (keysArray.length === 0) return toast.error("Please select atleast one member to invite");
        try {
            const response = await dispatch(inviteMembers({ groupId, data: { user_ids: keysArray } }));
            if (response.type === "inviteMembers/fulfilled") {
                toast.success(response?.payload?.message)
                setTimeout(() => {
                    navigate(`/createdgroup/${groupId}`)
                }, 3000);
            }
        } catch (error) {
            toast.error("Error inviting members")
        }
    })


    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                const response = await dispatch(searchMembers({ groupId, query: search }));
                setSearchResults(response?.payload?.search_results);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };
        fetchSearchResults();
    }, [search]);


    return (
        <div className="px-3 pb-28 h-full w-full relative">
            <Toaster position="top-right" reverseOrder={false} />
            <div className="grid grid-cols-12">
                <div className="col-span-2 flex items-center ">
                    <IoCaretBackCircle className="text-black dark:text-white text-[1.3rem] cursor-pointer" onClick={() => navigate(-1)} />
                </div>
                <p className="col-span-8 text-black dark:text-white text-center text-[18px] font-semibold">
                    Invite Members
                </p>
                <div className="col-span-2 flex items-center ">
                </div>
            </div>
            <form className="mt-2 h-full overflow-y-scroll w-full">
                <input
                    type="text"
                    value={search}
                    onChange={handleSearch}
                    placeholder="Search members"
                    className="focus:outline-none bg-gray-100 dark:bg-gray-300 rounded-2xl py-2 px-[14px] md:py-1 md:px-[12px] w-full"
                />
                <div className="flex justify-end items-center mt-[2px] gap-1 w-full">
                    <p className="text-[#00A3FF] text-[12px]">Select all</p>
                    <div class="inline-flex items-center">
                        <label
                            htmlFor="check"
                            class="relative flex items-center p-3 rounded-full cursor-pointer"
                        >
                            <input
                                id="check"
                                type="checkbox"
                                onChange={handleSelectAllChange}
                                checked={selectAllChecked}
                                class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-gray-500 dark:border-gray-600 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-100 before:opacity-0 before:transition-opacity checked:border-none checked:bg-gray-300 checked:before:bg-white"
                            />
                            <span class="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-3.5 w-3.5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    strokeWidth="1"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </label>
                    </div>
                </div>
                {searchResults.map((data) => (
                    <div className="mt-1" key={data?.user_id}>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-3">
                                <img
                                    src={data?.profile_pic}
                                    alt="img"
                                    className="h-8 w-8 rounded-full object-cover"
                                />
                                <p className="text-black dark:text-white text-[13px]">{data.first_name} {data.last_name}</p>
                            </div>
                            {data.status === "not_member" &&
                                <div class="inline-flex items-center">
                                    <label
                                        class="relative flex items-center p-3 rounded-full cursor-pointer"
                                        htmlFor={`check-${data.user_id}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id={`check-${data.user_id}`}
                                            checked={individualChecked[data.user_id] || false}
                                            onChange={(e) => handleIndividualChange(e, data.user_id)}
                                            class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-gray-500 dark:border-gray-600 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-100 before:opacity-0 before:transition-opacity checked:border-none checked:bg-gray-300 checked:before:bg-white"
                                        />
                                        <span class="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-3.5 w-3.5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                stroke="currentColor"
                                                strokeWidth="1"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                    </label>
                                </div>
                            }

                            {data.status !== "not_member" &&
                                <p className="text-[#00A3FF] text-[12px]">Joined</p>
                            }
                        </div>
                    </div>
                ))}
            </form>
            <div className="sticky z-30 py-2 -bottom-5 bg-white dark:bg-black flex justify-between gap-2 w-full">
                <button
                    className="bg-[#D9D9D9] text-black rounded-full text-[14px] hover:font-bold py-[5px] px-6  cursor-pointer"
                    onClick={() => navigate(`/createdgroup/${groupId}`)}
                >
                    Skip
                </button>
                <button onClick={() => handleInvite()} className="bg-[#D9D9D9] text-black rounded-full text-[14px] hover:font-bold py-[5px] px-6  cursor-pointer">
                    Invite
                </button>
            </div>
        </div>
    );
};

export default InviteMembers;
