import React, { useState } from "react";
import { Button, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFolder,
  SavedTheVideoInsideFolder,
} from "../../../Redux/ApiSlice/SavedVideosSlice";
import toast, { Toaster } from "react-hot-toast";

export default function SaveModal(props) {
  const {
    handleClose,
    videoId,
    fetchData,
    saveRef,
  } = props
  const dispatch = useDispatch();
  const [createFolderShow, setCreateFolderShow] = useState(false);
  const [createFolderName, setCreateFolderName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectId, setSelectId] = useState(null);
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const savedVideo = useSelector((state) => state.savedVideos);
  const { SavedFolderList } = savedVideo;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");


  const handleChange = (e) => {
    setCreateFolderName(e.target?.value);
  };
  const handleSelect = (e) => {
    console.log(e.target?.value);
    if (e.target?.value) {
      setSelectedFolder(e.target?.value);
      setError(null)
    }
  };

  const handleCreate = () => {
    setCreateFolderShow(!createFolderShow);
    setError(null)
  }

  const handleCreateFolder = async (value) => {
    if (value) {
      try {
        await dispatch(createFolder({ name: value, userId: ProfileDetails?.User_profile?.id }));
        fetchData();
        setCreateFolderName("");
        setSuccess("Folder created successfully")

        setTimeout(() => {
          setCreateFolderShow(false);
          setSuccess("")
        }, 2000)

      } catch (error) {
        toast.error("Folder already exist")
      }
    } else {
      return null;
    }
  };


  const handleSubmit = async () => {
    if (createFolderShow) {
      handleCreateFolder(createFolderName);
    } else {
      if (selectId) {
        const responce = await dispatch(SavedTheVideoInsideFolder({ folderId: selectId, videoId }));
        if (responce.error) {
          setError("Already exist");
        } else {
          setSuccess("Video saved successfully")
          setTimeout(() => {
            handleClose();
            setSuccess("")
          }, 2000)
        }
      }
    }
  };

  return (
    <div
      className={`${error ? "h-44" : "h-40"} w-72 bg bg-white dark:bg-black dark:border-white border px-6 py-2 z-50 rounded-md relative`}
      ref={saveRef}
    >
      <Toaster position="top-right" />

      {/* <p className="text-[12px] text-red-800 font-bold absolute uppercase top-0 w-full text-start">
        CC /SAVE VIDEO POPUP /FE / V1 / oct 11 , 2024
      </p> */}
      <div className="flex justify-between mt-2">
        <p className="font-bold text-[12px] md:text-[14px] dark:text-white">
          {" "}
          {createFolderShow ? "Enter Folder Name" : "Select Folder"}
        </p>
        <p
          className="text-[#00A3FF] text-[12px] md:text-[14px] font-bold cursor-pointer"
          onClick={() => handleCreate()}
        >
          {createFolderShow ? (
            <span className="text-[12px] text-red-700 font-bold cursor-pointer">
              Cancel
            </span>
          ) : (
            "Create Folder"
          )}
        </p>
      </div>
      {success !== "" && <p className="text-[12px] font-bold text-green-700 text-end">
        <span className="px-1"> ✅</span>  {success}
      </p>}
      {
        error && <p className="text-red-500 text-[12px] font-bold text-end">
          <span className="px-1"> ❌</span>
          {error}</p>
      }
      <div className="pb-0 relative mt-2">
        {createFolderShow ? (
          <input
            placeholder=" Enter Folder Name"
            className="outline-none bg-[#D9D9D9] dark:bg-white px-2 py-1 w-full rounded-md"
            onChange={handleChange}

            value={createFolderName}
          />

        ) : (
          <select
            value={selectedFolder}
            onChange={(e) => {
              handleSelect(e);
              const selectedItem = SavedFolderList?.data?.find(item => item.name === e.target.value);
              if (selectedItem) {
                setSelectId(selectedItem.id);
              }
            }}
            id="underline_select"
            className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="" disabled>Select Folder</option>
            {(SavedFolderList?.data || [])?.map((item) => (
              <option
                className="font-bold text-[12px] text-black px-2"
                key={item.id}
                value={item.name}
              >
                {item.name}
              </option>
            ))}

            {SavedFolderList?.data?.length === 0 && (
              <option className="font-bold text-base">
                No folder found
              </option>
            )}
          </select>

        )}
      </div>
      <div className="flex justify-around mt-4">
        <Button
          onClick={() => handleSubmit()}
          className="bg-[#D9D9D9] text-[black] p-2 w-2/5"
        >
          <span>Save</span>
        </Button>
        <Button
          className="bg-[#D9D9D9] text-[black] p-2 w-2/5"
          onClick={() => handleClose()}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </div>
  );
}
