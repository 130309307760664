import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IoCaretBackCircle,
  IoMenu,
  IoCheckmark,
  IoClose,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptRequest,
  getAllRequest,
} from "../../Redux/ApiSlice/Group/GroupSlice";
import Skeleton from "react-loading-skeleton";

const CreatedGroupRequest = () => {
  const params = useParams();
  const { groupId } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const groupData = useSelector((state) => state.group);
  const { loading } = groupData;
  console.log(groupData, "groupData");

  const [requestedUsers, setRequestedUsers] = useState([]);

  const getRequestedGroup = useCallback(async () => {
    try {
      const response = await dispatch(getAllRequest({ groupId }));
      if (response.type === "getAllRequest/fulfilled") {
        setRequestedUsers(response?.payload);
      } else {
        setRequestedUsers([]);
      }

      return result;
    } catch (error) {
      return error;
    }
  }, [dispatch, groupId]);

  const handleAcceptRequest = async (requestedId) => {
    try {
      const response = await dispatch(
        acceptRequest({ groupId, requestedId, data: { action: "approve" } })
      );
      if (response.type === "acceptRequest/fulfilled") {
        setRequestedUsers(
          requestedUsers.filter((user) => user.id !== requestedId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRejectRequest = async (requestedId) => {
    try {
      const response = await dispatch(
        acceptRequest({ groupId, requestedId, data: { action: "reject" } })
      );
      if (response.type === "acceptRequest/fulfilled") {
        setRequestedUsers(
          requestedUsers.filter((user) => user.id !== requestedId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  function timeAgo(timestamp) {
    const now = new Date();
    const past = new Date(timestamp);
    const seconds = Math.floor((now - past) / 1000);

    const intervals = {
      year: 31536000, // 60 * 60 * 24 * 365
      month: 2592000, // 60 * 60 * 24 * 30
      day: 86400, // 60 * 60 * 24
      hour: 3600, // 60 * 60
      minute: 60,
      second: 1,
    };

    for (const key in intervals) {
      const interval = Math.floor(seconds / intervals[key]);
      if (interval >= 1) {
        return `${interval}${key[0]} ago`;
      }
    }

    return "just now"; // In case it's less than a second ago
  }

  useEffect(() => {
    getRequestedGroup();
  }, [getRequestedGroup]);

  return (
    <div className="h-full bg-black">
      <div className=" static top-0 p-2 flex items-center justify-between bg-black text-white border-b border-gray-800">
        <IoCaretBackCircle
          onClick={() => navigate(-1)}
          className="text-[20px] cursor-pointer"
        />
        <p className="text-[14px]">Group Request</p>
        <IoMenu className="text-[22px] cursor-pointer" />
      </div>
      <div className="h-[calc(100%-64px)] overflow-y-auto">
        {loading
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15].map((data) => (
              <div className="grid grid-cols-12  px-2 w-full" key={data}>
                <div className="col-span-1">
                  <Skeleton
                    baseColor="#202020"
                    highlightColor="#444"
                    height={40}
                    width={40}
                    circle={true}
                  />
                </div>
                <div className="col-span-8 px-4">
                  <Skeleton
                    baseColor="#202020"
                    highlightColor="#444"
                    height={15}
                    width={150}
                  />
                  <Skeleton
                    baseColor="#202020"
                    highlightColor="#444"
                    height={15}
                    width={50}
                  />
                </div>
                <div className="col-span-3 gap-2 flex justify-end">
                  <Skeleton
                    baseColor="#202020"
                    highlightColor="#444"
                    height={25}
                    width={25}
                  />
                  <Skeleton
                    baseColor="#202020"
                    highlightColor="#444"
                    height={25}
                    width={25}
                  />
                </div>
              </div>
            ))
          : requestedUsers?.map((data) => (
              <div className="border-b border-gray-800 px-2 py-2" key={data.id}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-2">
                    <img
                      alt="user"
                      src={data.user.avatar_url}
                      className="w-[40px] h-[40px] rounded-full object-cover border border-gray-900"
                    />
                    <div className="flex flex-col leading-5">
                      <p className="text-[14px] font-semibold text-white line-clamp-1">
                        {data.user.first_name} {data.user.last_name}
                      </p>
                      <p className="text-[12px] text-gray-600">
                        {timeAgo(data.requested_at)}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <button
                      onClick={() => handleAcceptRequest(data?.id)}
                      className="px-1 py-1 bg-gray-800 hover:bg-gray-900 text-white hover:text-[#52e772] hover:border hover:border-[#52e772] rounded-md"
                    >
                      <IoCheckmark className="text-[18px]" />
                    </button>
                    <button
                      onClick={() => handleRejectRequest(data?.id)}
                      className="px-1 py-1 bg-gray-800 hover:bg-gray-900 text-white hover:text-[#f15454] hover:border hover:border-[#f15454] rounded-md"
                    >
                      <IoClose className="text-[18px]" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

        {requestedUsers.length === 0 && !loading && (
          <p className="text-white text-center mt-5">No Request Found</p>
        )}
      </div>
    </div>
  );
};

export default CreatedGroupRequest;
