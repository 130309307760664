import React from "react";
import { FaTrashCan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const FinalProduct = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 gap-3 border border-gray-300 rounded-lg p-[6px]">
            <div className="col-span-3">
                <img
                    alt="product"
                    onClick={() => navigate("/shopdetails")}
                    src="https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg"
                    className="border border-gray-300 bg-white rounded-lg cursor-pointer"
                />
            </div>
            <div className="col-span-9">
                <p className="text-[12px] text-gray-700">IPhone 15 Pro</p>
                <p className="text-[10px] text-gray-500 line-clamp-1">FORGED IN TITANIUM — iPhone 15 Pro has a strong and light aerospace-grade titanium design with a textured matte-glass back. It also features a Ceramic Shield front thats tougher than any smartphone glass. And its splash, water, and dust resistant.</p>
                <p className="text-[12px] text-gray-900 font-bold">$1541.55</p>
                <div className="flex items-center justify-between">
                    <p className="text-[10px] text-gray-500">Quantity - 1</p>
                    <FaTrashCan className="text-[12px] text-gray-700 cursor-pointer" />
                </div>
            </div>
        </div>
    )
}

export default FinalProduct