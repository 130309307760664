import React, { Fragment } from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdStarRate } from "react-icons/md";
import { useCountries } from "use-react-countries";
import { Stepper, Step } from "@material-tailwind/react";

const Address = () => {
   const navigate = useNavigate();
   const { countries } = useCountries();

   return (
      <Fragment>
         <div className="px-2">
            <p className="text-[14px] font-bold text-center mt-4 dark:text-white">Payment Purchase</p>
         </div>
         <div className="w-full pb-4 pt-3 px-8">
            <Stepper activeStep={0}>
               <Step className="">1
                  <div className="absolute -bottom-[1.3rem] w-max text-center">
                     <p className="text-[#e2b5b5] dark:text-white text-[10px]">Address</p>
                  </div>
               </Step>
               <Step className="!bg-[#0F9B36]">2
                  <div className="absolute -bottom-[1.3rem] w-max text-center">
                     <p className="text-[#000] dark:text-white text-[10px]">Paymeny Details</p>
                  </div>
               </Step>
               <Step>3
                  <div className="absolute -bottom-[1.3rem] w-max text-center">
                     <p className="text-[#000] dark:text-white text-[10px]">Complete Purchase</p>
                  </div>
               </Step>
            </Stepper>
         </div>
         <div className="px-2 mt-5">
            <div className="flex justify-between items-center">
               <FaArrowAltCircleLeft className="text-[#000] dark:text-white text-[20px] cursor-pointer" onClick={() => navigate(-1)} />
               <p className="font-bold text-[20px] md:text-[16px]">Enter Your Contact Address</p>
            </div>
            <form className="mt-5">
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">Your Name <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                  />
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">Your Address <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <textarea
                     rows="4"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md resize-none"
                  />
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">Country <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <select
                     placeholder="USA"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[8px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                     labelProps={{
                        className: "before:content-none after:content-none",
                     }}
                     menuProps={{ className: "h-48" }}
                  >
                     {countries.map(({ name, flags }) => (
                        <option key={name} value={name}>
                           <div className="flex items-center gap-x-2">
                              <img
                                 src={flags.svg}
                                 alt={name}
                                 className="h-4 w-4 rounded-full object-cover"
                              />
                              {name}
                           </div>
                        </option>
                     ))}
                  </select>
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">City <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                  />
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">Zip Code <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                  />
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">State <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                  />
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">Phone Number <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                  />
               </div>
               <div className="flex items-center justify-between mb-3">
                  <p className="flex text-[12px] dark:text-gray-400 font-semibold">Email <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 dark:bg-gray-300 border-[1px] py-[5px] px-[8px] w-[16rem] md:w-[11rem] focus:outline-none rounded-md"
                  />
               </div>
               <button type="submit" className="w-full mt-5 bg-black  text-[#fff] py-2 font-semibold md:mb-5 dark:bg-gray-700" onClick={() => navigate("/shoppayment")}>Proceed to Payment</button>
            </form>
         </div>
      </Fragment>
   )
}

export default Address