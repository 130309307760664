import React, { Fragment } from "react";
import { FaLock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { TbStarHalfFilled } from "react-icons/tb";

export default function Groups({ groupSearchData, handleJoinGroup, loading }) {
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="w-full dark:bg-black  h-[100%] overflow-y-scroll">
                {groupSearchData?.length > 0 &&
                    groupSearchData?.map((data) => (
                        <>
                            <div
                                className="grid px-2 grid-cols-12 items-center pt-2 pb-2  hover:bg-[#9a989844] z-50 dark:text-white cursor-pointer"
                                key={data.id}
                            >
                                <div className="col-span-2">
                                    <div className="relative flex items-center justify-center border shadow-[1px_1px_2px_1px_#f687b3]  rounded-md h-8 w-8 p-[1px]">
                                        <img
                                            alt={data?.group_name}
                                            src={data?.group_profile_picture}
                                            className="h-7 w-7 rounded-md object-cover"
                                            onClick={() =>
                                                data?.user_joined
                                                    ? navigate(`/createdgroup/${data.id}`)
                                                    : navigate(`/othergroup/${data.id}`)
                                            }
                                        />

                                        {data.group_type === "private" && (
                                            <p className="absolute -right-3 -top-2 dark:text-white text-[14px] rounded-xl">
                                                <FaLock />
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-7  ml-[-2px]">
                                    <div className="grid grid-cols-12 w-full">
                                        <div className="col-span-12 flex justify-between items-center">
                                            <div className="flex gap-1 items-center">
                                                <p
                                                    className="text-[14px] md:text-[12px] dark:text-white  font-bold first-letter:uppercase line-clamp-2"
                                                    onClick={() =>
                                                        data?.user_joined
                                                            ? navigate(`/createdgroup/${data.id}`)
                                                            : navigate(`/othergroup/${data.id}`)
                                                    }
                                                >
                                                    {data?.group_name}
                                                </p>
                                                <p>
                                                    <TbStarHalfFilled className="text-blue-600 text-[12px]" />
                                                </p>
                                            </div>
                                            <div class="flex -space-x-1 overflow-hidden">
                                                {data?.members.map((member, index) => (
                                                    <>
                                                        {index < 3 && (
                                                            <img
                                                                alt="img"
                                                                class="inline-block h-5 w-5 rounded-full object-cover"
                                                                src={member?.profile_pic}
                                                            />
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <p className="text-[10px] line-clamp-1 text-[#333333] dark:text-[white] ">
                                                {data?.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3 flex justify-end items-center px-2">
                                    {data.join_request_status !== "pending" ? (
                                        data?.user_joined ? (
                                            <button
                                                className="bg-green-600 text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold"
                                                onClick={() => navigate(`/createdgroup/${data.id}`)}
                                            >
                                                Joined
                                            </button>
                                        ) : (
                                            <button
                                                className="bg-[#f86045] text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold"
                                                onClick={() => handleJoinGroup(data.id)}
                                            >
                                                Join
                                            </button>
                                        )
                                    ) : (
                                        <button
                                            className="bg-[#f86045] text-white rounded-sm text-[10px]  px-1 py-[1px] w-full font-bold"
                                            onClick={() => navigate(`/othergroup/${data.id}`)}
                                        >
                                            Requested
                                        </button>
                                    )}
                                </div>
                            </div>
                            <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-700 border-0" />
                        </>
                    ))}

                {groupSearchData?.length === 0 && !loading && (
                    <div className="text-gray-600 text-[13px] text-center mt-5">
                        No Group Found
                    </div>
                )}
            </div>
        </Fragment>
    );
}
