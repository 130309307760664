import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Stepper, Step } from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";

const OrderPlaced = () => {
   const navigate = useNavigate();

   return (
      <Fragment>
         <div className="w-full py-4 px-8 mt-3">
            <Stepper activeStep={2} >
               <Step className="!bg-[#0F9B36]">1
                  <div className="absolute -bottom-[1.5rem] w-max text-center">
                     <p className="text-[#000] text-[12px] dark:text-white">Address</p>
                  </div>
               </Step>
               <Step className="!bg-[#0F9B36]">2
                  <div className="absolute -bottom-[1.5rem] w-max text-center">
                     <p className="text-[#000] text-[12px] dark:text-white">Paymeny</p>
                  </div>
               </Step>
               <Step className="!bg-[#0F9B36]">3
                  <div className="absolute -bottom-[1.5rem] w-max text-center">
                     <p className="text-[#000] text-[12px] dark:text-white">Ordered</p>
                  </div>
               </Step>
            </Stepper>
         </div>
         <div className="px-4 mt-5">
            <div className="flex flex-col justify-center items-center">
               <p className="font-bold text-[24px] md:text-[16px] mb-16 dark:text-white">Order Placed</p>
               <FaCheckCircle className="text-[#0f9b37e2] text-[8rem] mb-5 md:text-[6rem]"  />
               <p className="text-[15px] md:text-[14px] text-center mb-3 dark:text-white">Congrats, your order has been placed!</p>
               <p className="cursor-pointer text-[#0085FF] font-semibold text-[16px]" onClick={() => navigate("/shop")}>Continue Shopping</p>
               <button className="bg-black dark:bg-[#535151] text-white px-4 py-1 rounded text-[14px] mt-4" onClick={() => navigate("/order")}>
                  See order details
               </button>
            </div>
         </div>
      </Fragment>
   )
}

export default OrderPlaced