import React, { Fragment } from "react";
import { TbStarHalfFilled } from "react-icons/tb";

export default function Users({ usersData, handleNavigate }) {

  console.log(usersData , "usersData")
  return (
    <Fragment>
      <div className="w-full h-[100%] px-2 overflow-y-scroll pb-40">
        {usersData?.map((data) => (
          <>
            <div
              className="grid grid-cols-12 items-center pt-2 pb-2 px-1 hover:bg-[#9a989844] z-50 text-black cursor-pointer"
              key={data.user.id}
            >
              <div className="col-span-2">
                <div className="relative flex items-center justify-center border shadow-[1px_1px_2px_1px_#f687b3]  rounded-md h-8 w-8 p-[1px]">
                  <img
                    alt={data?.user.first_name}
                    src={data?.user.avatar_url}
                    className="h-7 w-7 rounded-md object-cover"
                    onClick={() => handleNavigate(data.user.id)}
                  />
                </div>
              </div>
              <div className="col-span-8  ml-[-2px]">
                <div className="grid grid-cols-12 w-full">
                  <div className="col-span-12 flex justify-between items-center">
                    <div className="flex gap-1 items-center">
                      <p
                        className="text-[14px] md:text-[12px] dark:text-[white]   font-bold first-letter:uppercase line-clamp-2"
                        onClick={() => handleNavigate(data?.user.id)}
                      >
                        {data?.user?.first_name} {data?.user?.last_name}
                      </p>
                      <p>
                        <TbStarHalfFilled className="text-blue-600 text-[12px]" />
                      </p>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <p className="text-[10px] line-clamp-1 dark:text-[white]  ">
                      {data?.user?.username}
                      <span className="text-gray-600 dark:text-gray-400">
                        {data?.mutual_friends?.length > 0 ?
                          `.Followed ${data.mutual_friends[0]?.username} 
    ${data?.mutual_friends?.length > 1 ? `+${data?.mutual_friends?.length - 1} others` : ""}`
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-300 dark:bg-gray-700 border-0" />
          </>
        ))}
        {
          !usersData?.length && (
            <div className="text-gray-600 text-[13px] text-center mt-5">
              No users found
            </div>
          )
        }
      </div>
    </Fragment>
  );
}
