import React, { Fragment } from "react";
import Address from "../../Component/shoppage/Address";

const AddressPage = () => {
   return (
      <Fragment>
         <div className="grid grid-cols-1">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               Copyright © 2025 Galileo One Inc
            </p>
            <Address />
         </div>
      </Fragment>
   );
};

export default AddressPage;
