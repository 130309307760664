import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdPersonAddAlt1 } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import { neighborhoodNewsData } from "../../data";
import { BsPersonCheckFill } from "react-icons/bs";
import { IoCaretBackCircle } from "react-icons/io5";

const CreatedGroupInvite = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [invitedUsers, setInvitedUsers] = useState([]);

    const handleInvite = (userName) => {
        setInvitedUsers([...invitedUsers, userName]);
        toast.success(`${userName} has been invited!`);
    };

    const filteredFriends = neighborhoodNewsData.filter((data) =>
        data.userName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="h-full bg-black">
            <Toaster 
                position="top-right"
                reverseOrder={false}
            />
            <p className="text-[10px] text-red-600 text-center py-1 font-bold">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="flex items-center gap-x-2 px-2 pb-2">
                <IoCaretBackCircle 
                    onClick={() => navigate(-1)}
                    className="text-white text-3xl cursor-pointer" 
                />
                <input 
                    type="text" 
                    placeholder="Search for friends to invite" 
                    value={searchTerm}
                    className="w-full bg-gray-800 text-white px-3 py-1 placeholder:text-[13px] rounded-full focus:outline-none"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {filteredFriends.map((data, index) => (
                <div className="flex items-center justify-between py-2 px-2 border-b border-gray-900" key={index}>
                    <div className="flex items-center gap-x-2">
                        <img 
                            alt="profile" 
                            src={data.profileImage} 
                            className="w-10 h-10 rounded-full object-cover border border-gray-900" 
                        />
                        <div>
                            <p className="text-white text-[14px] font-bold">{data.userName}</p>
                            <p className="text-gray-500 text-[12px]">{data.location}</p>
                        </div>
                    </div>
                    <div className="flex items-center">
                        {invitedUsers.includes(data.userName) ? (
                            <BsPersonCheckFill className="text-gray-700 text-[18px]" />
                        ) : (
                            <MdPersonAddAlt1 
                                className="text-white text-[20px] cursor-pointer" 
                                onClick={() => handleInvite(data.userName)}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default CreatedGroupInvite;