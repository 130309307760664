import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import { FaArrowsRotate } from "react-icons/fa6";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";
import { FaShare, FaUserCheck } from "react-icons/fa";
import { BsMailbox2 } from "react-icons/bs";
import { HiOutlineDotsVertical } from "react-icons/hi";
import BeatLoader from "react-spinners/BeatLoader";

export default function ProfileSection({
    userId,
    setCount,
    count,
    handleFollowFun,
    otherUserFollowingYou,
    handleStartChat,
    handleShare,
    loading
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [openPopup, setOpenPopup] = useState(false);
    const [reportUser, setReportUser] = useState(false);
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { otherUser_profile } = ProfileDetails || {};
    const {
        first_name,
        last_name,
        about_us,
        avatar,
        post_count,
        following_count,
        followers_count,
        is_private,
        is_following,
    } = otherUser_profile || {}
    const [follow, setFollow] = useState(false);

    const handleNavigate = (value) => {
        if ((is_private && is_following && otherUserFollowingYou) || !is_private) {
            setCount(`${value}`);
        }
    };

    const handleBack = () => {
        if (count === "allPosts") {
            navigate(-1);
        } else {
            setCount("allPosts");
        }
    };

    const handleReportUser = () => {
        setReportUser(!reportUser);
        setOpenPopup(false);
    }

    const path = location?.pathname?.split("/");
    const label = path[path.length - 2];

    useEffect(() => {
        setFollow(is_following)
    }, [is_following])

    return (
        <div className="relative">
            <p className="text-[#ff3b3b] text-center w-full text-[8px] mb-1 font-[700] flex justify-center z-50 lg:text-[8px] uppercase">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="grid grid-cols-12 items-center px-2 pb-1 border-b border-gray-400 dark:border-gray-800">
                <div className="col-span-3">
                    <IoArrowBackCircle
                        onClick={() => handleBack()}
                        className="text-[23px] text-black dark:text-[#ffff] cursor-pointer"
                    />
                </div>
                <div className="col-span-6" />
                <div className="col-span-3 flex items-center justify-between relative">
                    <FaShare
                        onClick={handleShare}
                        className="text-[16px] text-black dark:text-[#ffff] cursor-pointer"
                    />
                    <FaArrowsRotate
                        className="text-[16px] text-black dark:text-[#ffff] cursor-pointer"
                    />
                    <HiOutlineDotsVertical
                        onClick={() => setOpenPopup(!openPopup)}
                        className="text-[19px] text-black dark:text-[#ffff] cursor-pointer"
                    />
                    {openPopup &&
                        <div className="absolute top-[22px] right-0 bg-white border border-gray-400  z-10 rounded-lg whitespace-nowrap shadow-lg overflow-hidden">
                            <p
                                onClick={() => setOpenPopup(false)}
                                className="text-[12px] px-2 py-1 text-gray-900 hover:text-black hover:bg-gray-200 border-b border-gray-400 cursor-pointer"
                            >
                                Block User
                            </p>
                            <p
                                onClick={handleReportUser}
                                className="text-[12px] px-2 py-1 text-gray-900 hover:text-black hover:bg-gray-200 cursor-pointer"
                            >
                                Report User
                            </p>
                        </div>
                    }
                </div>
            </div>

            <div className="grid grid-cols-12 px-2 mt-2 relative">
                <div className="col-span-6 h-full">
                    <div className="rounded-[50%] flex flex-col">
                        <div className="relative">
                            <LazyLoad height={200} offset={100}>
                                <img
                                    src={avatar}
                                    alt={first_name}
                                    className="h-[4.8rem] w-[4.8rem] rounded-full object-cover"
                                />
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col col-span-6">
                    <div className="flex flex-col justify-center items-center">
                        <div className="relative flex justify-center gap-8">
                            <div
                                onClick={() => handleNavigate("Followers")}
                                className="w-auto h-10  text-[8px] font-bold  text-black dark:text-[#ffff] justify-center items-center flex flex-col"
                            >
                                <p className="flex items-center justify-center relative w-full cursor-pointer">
                                    <p className="absolute left-[4px] top-[0.3px] text-[10px]">
                                        {followers_count}
                                    </p>
                                    <FaUserGroup className="text-[13px]" />
                                </p>
                                <p className="text-[10px] font-bold text-black dark:text-[#ffff] cursor-pointer">
                                    followers
                                </p>
                            </div>
                            <div
                                onClick={() => handleNavigate("Following")}
                                className="w-auto h-10 text-[8px] font-bold text-black dark:text-[#ffff] justify-center items-center flex flex-col"
                            >
                                <p className="flex items-center justify-center relative w-full cursor-pointer">
                                    <p className="absolute left-[4px] top-[0.3px] text-[10px]">
                                        {following_count}
                                    </p>
                                    <FaUserCheck className="text-[13px]" />
                                </p>
                                <p className="text-[10px] font-bold text-black dark:text-[#ffff] cursor-pointer">
                                    following
                                </p>
                            </div>
                        </div>
                        <div className="w-full h-10 items-center justify-center flex ">
                            <div className="h-10 text-[8px] font-bold text-black dark:text-[#ffff] justify-center items-center flex flex-col w-10">
                                <div className="flex items-center justify-center gap-x-1 relative w-full">
                                    <p
                                        onClick={() => handleNavigate("Posted")}
                                        className="text-[10px] mt-0.5 cursor-pointer"
                                    >
                                        {post_count}
                                    </p>
                                    <BsMailbox2
                                        onClick={() => handleNavigate("Posted")}
                                        className="text-[14px] cursor-pointer"
                                    />
                                </div>
                                <p className="text-[10px] font-bold  text-black dark:text-[#ffff] cursor-pointer">
                                    posts
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12">
                    <p className="text-black dark:text-[#ffff] font-bold text-sm flex">
                        {first_name} {last_name}
                    </p>
                    <p className="text-black dark:text-white hover:text-blue-700 cursor-pointer text-[10px]">
                        https://www.example.com
                    </p>
                    <p className="text-black dark:text-white font-medium text-[12px] mt-1 line-clamp-2 leading-[14px] cursor-pointer">
                        {about_us}
                    </p>
                </div>
            </div>

            <div className="px-2 pb-2 mt-2 flex items-center justify-center gap-2 border-b border-gray-400 dark:border-gray-800">
                <Button
                    size="sm"
                    onClick={handleFollowFun}
                    className={`rounded-lg w-full ${follow
                        ? "bg-[#1b1b1b] dark:bg-gray-800 hover:bg-[#0f0f0f]"
                        : "bg-blue-500 hover:bg-blue-600"
                        } text-white`}
                >
                    {follow ? "UnFollow" : "Follow"}
                </Button>
                <Button
                    size="sm"
                    onClick={() => handleStartChat()}
                    className="rounded-lg w-full bg-[#1b1b1b] dark:bg-gray-800 hover:bg-[#0f0f0f] text-white"
                >
                    {loading ? (
                        <BeatLoader
                            size={15}
                            color={"#fff"}
                            loading={loading}
                        />
                    ) : (
                        "Message"
                    )}

                </Button>
            </div>

            {reportUser && (
                <div className="absolute h-[74.6vh] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white shadow-lg rounded-lg w-[300px] h-[160px] flex flex-col items-center justify-center">
                        <p className="text-[24px] font-bold text-black">
                            Report User
                        </p>
                        <p className="text-[12px] text-black text-center px-4 mt-2">
                            Are you sure you want to report this user?
                        </p>
                        <div className="flex gap-2 mt-4">
                            <Button
                                onClick={handleReportUser}
                                size="sm"
                                className="bg-[#1b1b1b] dark:bg-gray-800 hover:bg-[#0f0f0f] text-white"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleReportUser}
                                size="sm"
                                className="bg-red-500 hover:bg-red-600 text-white"
                            >
                                Report
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
