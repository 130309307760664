import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from "@material-tailwind/react";

export const CreateSavedFolderModal = ({ handleOpen, open, handleCreateFolder, folderName, setFolderName }) => {
   const handleChange = (e) => {
      setFolderName(e.target.value);
   };

   if (!open) return null;

   return (
      <div className="absolute top-0 inset-0 p-5 flex items-center justify-center bg-black bg-opacity-50 z-50">
         <div className="bg-white px-2 py-1 border-2 dark:bg-black rounded-lg shadow-lg w-96  border-gray-200 dark:border-gray-700">
            <div className="">
               <h2 className="text-lg font-semibold mb-2 text-black dark:text-white">Create Folder</h2>
               <label className="text-center text-[13px] dark:text-gray-400">
                  Folder Name
               </label>
               <input
                  type="text"
                  value={folderName}
                  onChange={handleChange}
                  placeholder="Create Folder Name"
                  className="w-full px-3 py-1 border placeholder:text-[12px] border-gray-300 dark:border-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
               />
            </div>
            <div className="flex justify-center gap-4 p-2">
               <button
                  onClick={() => handleOpen(null)}
                  className="px-4 py-1 text-[13px] bg-red-500 text-white rounded-lg hover:bg-red-600"
               >
                  Cancel
               </button>
               <button
                  onClick={()=>handleCreateFolder(folderName)}
                  className="px-4 py-1 text-[13px] bg-black dark:bg-cyan-500 text-white rounded-lg hover:bg-gray-800 hover:dark:bg-cyan-600"
               >
                  Create
               </button>
            </div>
         </div>
      </div>
      // <Dialog
      //    open={open}
      //    handler={handleOpen}
      //    className="rounded-xl dark:bg-black"
      //    size="sm"
      //    style={{ width: "100px" }}
      // >
      //    <DialogHeader className="flex justify-center items-center text-xl font-bold  dark:text-[#fff] ">
      //       Create Folder
      //    </DialogHeader>

      //    <DialogBody className="flex flex-col items-start justify-start">
      //       <Typography className="text-center text-base font-semibold dark:text-[#fff]">
      //          Folder Name
      //       </Typography>
      //       <Typography className="w-full mt-1">
      //          <input
      //             placeholder=""
      //             className="rounded-md px-3 outline-none dark:text-[#000] bg-[#D9D9D9] p-2 w-full" 
      //             onChange={handleChange}
      //             value={folderName}
      //          />
      //       </Typography>
      //    </DialogBody>

      //    <DialogFooter className="w-full flex justify-center">
      //       <Button
      //          variant="text"
      //          onClick={() => handleCreateFolder(folderName)}
      //          className="mr-8 bg-black text-[#fff] dark:bg-[#fff]  dark:text-[#000]"
      //       >
      //          <span>Save</span>
      //       </Button>
      //       <Button
      //          variant="gradient"
      //          className=" bg-black text-[#fff] "
      //          onClick={() => handleOpen(null)}
      //       >
      //          <span>Cancel</span>
      //       </Button>
      //    </DialogFooter>
      // </Dialog>

   );
};
