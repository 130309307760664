import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Comments = ({ commentsData }) => {

    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;
    const navigate = useNavigate()

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };


    function timeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);

        const intervals = {
            year: 31536000, // 60 * 60 * 24 * 365
            month: 2592000, // 60 * 60 * 24 * 30
            day: 86400,     // 60 * 60 * 24
            hour: 3600,     // 60 * 60
            minute: 60,
            second: 1
        };

        for (const key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval}${key[0]} ago`;
            }
        }

        return "just now";  // In case it's less than a second ago
    }

    return (
        <div className="overflow-y-scroll">
            {commentsData?.map((data) => (
                <div className="border-b border-gray-400 dark:border-gray-700 py-1" key={data.id}>
                    <div className="grid grid-cols-12 items-center px-2">
                        <p className="col-span-12 text-black dark:text-white text-[14px] md:text-[12px] font-bold">{timeAgo(data?.created_at)} ago</p>
                        <div className="col-span-2">
                            <img
                                alt="img"
                                src={data?.user_details?.avatar_url}
                                onClick={() => handleNavigate(data?.user_details?.id)}
                                className="h-11 w-11 md:h-9 md:w-9 rounded-full object-cover cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10 leading-4 -ml-2 md:-ml-1">
                            <p className="text-black dark:text-white text-[16px] md:text-[14px] overflow-hidden"><span className="font-bold">{data.user_details.first_name}{data.user_details.last_name} : </span> "{data.content}"</p>
                        </div>
                    </div>
                </div>
            ))}
            {
                commentsData?.length === 0 &&
                <div className="col-span-12 flex justify-center items-center h-[25vh]">
                    <p className="text-black dark:text-white text-[14px]">No Comments Found</p>
                </div>
            }
        </div>
    )
}

export default Comments