import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";
import {
    AccountProfile,
    AccountProfilePosts,
    AccountProfilePostDelete,
} from "../../Redux/ApiSlice/AccountProfileSlice";

export function PostsTab({ User_posts, User_profile, activeTab }) {
    const dispatch = useDispatch();
    const selectVideo = useSelectVideo();
    const [userPostData, setUserPostData] = useState(null);
    const [search, setSearch] = useState("");


    const handleDeletePost = async (id) => {
        await dispatch(AccountProfilePostDelete(id));
        dispatch(AccountProfilePosts(User_profile?.id));
        dispatch(AccountProfile());
    };

    const handleChangeSearch = (event) => {
        const inputValue = event.target.value;
        setSearch(inputValue);

        // Filter data based on search query
        const filtered = User_posts.filter((item) =>
            item.body.toLowerCase().includes(inputValue.toLowerCase())
        );
        setUserPostData(filtered);
    };

    const handleSelectVideo = async (id) => {
        selectVideo(id, userPostData);
    };

    useEffect(() => {
        setUserPostData(User_posts)
    }, [User_posts])

    return (
        <>
            <div className="flex h-[5%] justify-center items-center w-full mb-2">
                <div className="flex w-[94%] bg-cyan-600 hover:bg-cyan-700 justify-center items-center rounded-md overflow-hidden">
                    <div className="w-[10%] flex">
                        <FaSearch className="pl-2 w-[20px] h-[20px] cursor-pointer text-white" />
                    </div>
                    <div className="w-[100%] h-[85%]">
                        <input
                            type="text"
                            value={search}
                            onChange={handleChangeSearch}
                            placeholder={`Find Your Posts`}
                            className="w-full bg-gray-100 placeholder:text-sm font-medium outline-none pl-1 pr-2 py-1"
                        />
                    </div>
                </div>
            </div>

            {userPostData?.length > 0 ? (
                <div className={`grid grid-cols-2 gap-2 overflow-y-scroll no-scrollbar px-2 z-10 ${activeTab === "posts" ? "" : "hidden"}`}>
                    {userPostData?.map((item) => (
                        <VideoCard
                            id={item.id}
                            belowDecs={true}
                            threeDots={true}
                            body={item.post_description}
                            views={item.views}
                            video={item.video_url}
                            likes_count={item.likes_count}
                            selectVideo={handleSelectVideo}
                            handleDeletePost={handleDeletePost}
                            avatar_url={item.created_by.avatar_url}
                        />
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-center h-[50%] w-full">
                    <p className="text-[10px] text-[#c0b8b8] font-semibold">
                        No Posts yet
                    </p>
                </div>
            )}
        </>
    );
}
