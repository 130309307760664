import React, { Fragment } from "react";
import Order from "../../Component/shoppage/Order";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";
import DeliveredProducts from "../../Component/shoppage/DeliveredProducts";

const OrderPage = () => {
   return (
      <Fragment>
         <div className="pt-0">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               Copyright © 2025 Galileo One Inc
            </p>
            <InputButtonBlack />
            <Order />
            <DeliveredProducts />
         </div>
      </Fragment>
   );
};

export default OrderPage;
