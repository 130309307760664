import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const postPublicPrivateToggle = createAsyncThunk(
  "postPublicPrivateToggle",
  async (data) => {
    let form_data = new FormData();
    form_data.append("is_private", !data.is_private ? "True" : "False");
    try {
      const response = await axiosInstance.post(`/toggle-private/`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const getPublicPrivateToggle = createAsyncThunk(
  "getPublicPrivateToggle",
  async () => {
    const response = await axiosInstance.get(`/toggle-private/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const userStatusChangeOld = createAsyncThunk(
  "userStatusChangeOld",
  async () => {
    try {
      const response = await axiosInstance.post(`/post/toggle_new_user_status/`, {});
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const UserStatusSlice = createSlice({
  name: "UserStatusSlice",
  initialState: {
    User_PrivateStatus: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // getPublicPrivateToggle action promises
    builder.addCase(getPublicPrivateToggle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPublicPrivateToggle.fulfilled, (state, action) => {
      state.User_PrivateStatus = action.payload;
      state.loading = false;
    });
    builder.addCase(getPublicPrivateToggle.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default UserStatusSlice.reducer;
