import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";

/* get the group list */
export const reportGroupPost = createAsyncThunk(
    "reportGroupPost",
    async ({ groupId, postId, form_data }) => {
        try {
            const response = await axiosInstance.post(
                `/group/report/group/${groupId}/post/${postId}/`,
                form_data,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }
            );
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);



export const GroupPostReportSlice = createSlice({
    name: "GroupPostReportSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        // get group list action promises
        builder.addCase(reportGroupPost.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(reportGroupPost.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(reportGroupPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export default GroupPostReportSlice.reducer;
