import React, { useEffect, useState } from "react";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import { UpdatesTab } from "../../Component/AccountPage/UpdatesTab";
import { useDispatch } from "react-redux";
import { getNotification } from "../../Redux/ApiSlice/NotificationSlice";
import AccountSidebar from "../../Component/AccountPage/AccountSidebar";

export default function Updates() {
    const dispatch = useDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    useEffect(() => {
        dispatch(getNotification());
    }, []);

    return (
        <div className={`bg-[#fff] overflow-hidden h-full relative ${isDrawerOpen ? "" : "overflow-y-scroll"}`}>
            {/* <p className="text-[#ff3b3b] text-center w-full mb-2 text-[10px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                CC ACCOUNT UPDATES PAGE-FE - V11 / JUNE 29, 2024
            </p> */}
            <p className="text-[#ff3b3b] text-center w-full mb-2 text-[10px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="">
                <AccountProfileSection
                    handleOpenSideBar={openDrawer}
                    handleCloseSideBar={closeDrawer}
                    isDrawerOpen={isDrawerOpen}
                    activeTab={"Updates"}
                />
            </div>
            <div className="sticky top-0 z-30">
                <AccountTab activeTab={"Updates"} />
            </div>
            <UpdatesTab />
            <div className="grid grid-cols-12 overflow-hidden">
                <div className="col-span-12">
                    <AccountSidebar
                        isDrawerOpen={isDrawerOpen}
                        setIsDrawerOpen={setIsDrawerOpen}
                        openDrawer={openDrawer}
                        closeDrawer={closeDrawer}
                    />
                </div>
            </div>
        </div>
    );
}
