import React, { useCallback, useEffect, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteAllRecentSearch,
    deleteIndividualRecentSearch,
    groupSearch,
    recentSearch,
    search,
    searchOnlyUsers,
    searchTrendingUsers,
} from "../Redux/ApiSlice/SearchSlice";
import { useNavigate } from "react-router-dom";
import SearchSection from "../Component/SearchResult/SearchSection";
import { MdManageSearch } from "react-icons/md";
import { groupJoin } from "../Redux/ApiSlice/Group/GroupSlice";
import { ThreeDots } from "react-loader-spinner";
import HashTag from "../Component/SearchResult/HashTag";
import Posts from "../Component/SearchResult/Posts";
import Users from "../Component/SearchResult/Users";
import Groups from "../Component/SearchResult/Groups";
import Trending from "../Component/SearchResult/Trending";

export default function SearchResultPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const SearchItems = useSelector((state) => state.SearchItems);
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;

    const [SearchItem, setSearchItem] = useState("");
    const [activeTab, setActiveTab] = useState("");
    const [groupSearchData, setGroupSearchData] = useState([]);
    const [recentSearchData, setRecentSearchData] = useState([]);
    console.log(SearchItems, "SearchItems");

    const handleSearchBar = (e) => {
        setSearchItem(e.target.value);
    };

    const handleJoinGroup = async (groupId) => {
        try {
            const responce = await dispatch(groupJoin(groupId));
            if (
                responce?.playload?.message ===
                "Join request has been sent to the group owner"
            ) {
                setGroupSearchData(
                    groupSearchData.map((data) => {
                        if (data.id === groupId) {
                            return { ...data, join_request_status: "pending" };
                        }
                        return data;
                    })
                );
            } else {
                navigate(`/createdgroup/${groupId}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearchGroup = useCallback(async () => {
        try {
            await dispatch(groupSearch(SearchItem));
        } catch (error) {
            console.log(error);
        }
    }, [SearchItem, dispatch]);

    const handleSearchUsers = useCallback(async () => {
        try {
            await dispatch(search(SearchItem));
        } catch (error) {
            console.log(error);
        }
    }, [SearchItem, dispatch]);

    const handleSearchOnlyUsers = useCallback(async () => {
        try {
            await dispatch(searchOnlyUsers(SearchItem));
        } catch (error) {
            console.log(error);
        }
    }, [SearchItem, dispatch]);

    const handleSearchTrendingUsers = useCallback(async () => {
        try {
            await dispatch(searchTrendingUsers());
        } catch (error) {
            console.log(error);
        }
    }, [SearchItem, dispatch]);

    const handleRecentSearch = useCallback(async () => {
        try {
            await dispatch(recentSearch());
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    const handleClearAllSearch = async () => {
        try {
            await dispatch(deleteAllRecentSearch());
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteIndividualSearch = async (searchId) => {
        try {
            await dispatch(deleteIndividualRecentSearch({ searchId }));
            handleRecentSearch();
        } catch (error) {
            console.log(error);
        }
    };

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };

    useEffect(() => {
        if (SearchItems?.recentSearch?.length !== 0) {
            setRecentSearchData(SearchItems?.recentSearch);
        }
        setGroupSearchData(SearchItems?.SearchResult);
    }, [SearchItems]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (activeTab === "Group") {
                handleSearchGroup();
            } else if (
                activeTab === "Posts" ||
                activeTab === "Hashtag" ||
                activeTab === ""
            ) {
                handleSearchUsers();
            } else if (activeTab === "Users" || activeTab === "") {
                handleSearchOnlyUsers();
            }
            else if (activeTab === "Trending" || activeTab === "") {
                handleSearchTrendingUsers();
            }
            else {
                handleRecentSearch();
            }
            // Trigger search only if query exists
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [
        activeTab,
        handleSearchGroup,
        handleSearchUsers,
        handleRecentSearch,
        SearchItem,
    ]);

    return (
        <div className="dark:bg-black bg-[#fff] h-full relative overflow-y-scroll">
            <p className="text-red-700 uppercase font-bold text-[10px] w-full text-center">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="h-full">
                <SearchSection
                    SearchItem={SearchItem}
                    handleSearchBar={handleSearchBar}
                    navigate={navigate}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                />
                {SearchItems.loading && (
                    <div className="flex  justify-center items-center h-[25vh]">
                        <ThreeDots
                            visible={true}
                            height="90"
                            width="90"
                            color="#656665"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                )}
                {SearchItem === "" &&
                    activeTab !== "Group" &&
                    activeTab !== "Users" &&
                    activeTab !== "Trending" &&
                    activeTab !== "Hashtag" &&
                    activeTab !== "Posts" &&
                    !SearchItems.loading && (
                        <div className="grid grid-cols-1 overflow-scroll no-scrollbar mb-2 h-[-webkit-fill-available] absolute w-full">
                            <div className="overflow-scroll no-scrollbar px-2">
                                <div className="w-full bg-gray-900 px-2 bg-opacity-50 rounded">
                                    {SearchItems?.recentSearch?.length !== 0 ? (
                                        <div className="mt-1 pt-1">
                                            <div className="text-[16px] text-[#fff] font-bold cursor-pointer">
                                                <div className="flex justify-between items-center gap-x-1">
                                                    <div className="flex items-center gap-x-1">
                                                        <MdManageSearch className="text-[1.5rem]" />
                                                        <p className="text-[#fff]">Recent Searches</p>
                                                    </div>
                                                    <p
                                                        className="text-[12px] text-blue-800"
                                                        onClick={() => handleClearAllSearch()}
                                                    >
                                                        clear all
                                                    </p>
                                                </div>
                                                <div className="bg-[#ffffff] h-[1px] rounded-full" />
                                            </div>
                                            {recentSearchData?.map((sub) => (
                                                <div className="flex justify-between py-1 w-[100%] mt-2 rounded-sm">
                                                    <p className="text-[14px] md:text-[12px] line-clamp-1 text-white">
                                                        {sub.query}
                                                    </p>
                                                    <CgCloseO
                                                        className="cursor-pointer text-red-500 hover:text-red-700"
                                                        onClick={() =>
                                                            handleDeleteIndividualSearch(sub?.id)
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="flex justify-center items-center h-[25vh]">
                                            <p className="text-[#fff] text-[14px]">
                                                No Recent Searches
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="w-full"></div>
                            </div>
                        </div>
                    )}
                {activeTab === "Users" && !SearchItems.loading && (
                    <Users
                        usersData={SearchItems?.SearchResult?.search_results}
                        handleNavigate={handleNavigate}
                    />
                )}
                {activeTab === "Group" && !SearchItems.loading && (
                    <Groups
                        loading={SearchItems.loading}
                        groupSearchData={groupSearchData}
                        handleJoinGroup={handleJoinGroup}
                    />
                )}

                {activeTab === "Hashtag" && !SearchItems.loading && (
                    <HashTag hashtagData={SearchItems?.SearchResult?.trending_hashtags} />
                )}
                {(activeTab === "Posts") && !SearchItems.loading && (
                    <Posts
                        posts={SearchItems?.SearchResult?.posts}
                        loading={SearchItems.loading}
                    />
                )}
                {SearchItem !== "" &&
                    activeTab !== "Group" &&
                    activeTab !== "Users" &&
                    activeTab !== "Trending" &&
                    activeTab !== "Hashtag" &&
                    activeTab !== "Posts" &&
                    !SearchItems.loading && (
                        <Posts
                            posts={SearchItems?.SearchResult?.posts}
                            loading={SearchItems.loading}
                        />
                    )}

                {activeTab === "Trending" && SearchItems.loading === false && (
                    <Trending
                        usersData={SearchItems?.SearchResult}
                        handleNavigate={handleNavigate}
                        loading={SearchItems.loading}
                        SearchItem={SearchItem}
                    />
                )}
            </div>
        </div>
    );
}
