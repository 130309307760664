import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";

/* get the group list */
export const getGroupMembersList = createAsyncThunk(
    "getGroupMembersList",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/group/${groupId}/members/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);
export const deleteGroupMembersList = createAsyncThunk(
    "deleteGroupMembersList",
    async ({groupId , data}) => {
        try {
            const response = await axiosInstance.delete(`/group/groups/${groupId}/remove_members/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);



export const GroupMemberSlice = createSlice({
    name: "GroupMemberSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
    
        // get group list action promises
        builder.addCase(getGroupMembersList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getGroupMembersList.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getGroupMembersList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        
    },
});

export default GroupMemberSlice.reducer;
