import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { IoMenu, IoClose } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { LuDot } from "react-icons/lu";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import { IoIosCloseCircle } from "react-icons/io";
import {
    getMessages,
    sendMessages,
} from "../../Redux/ApiSlice/MessagesSlice";
import { useDispatch, useSelector } from "react-redux";
import MoonLoader from "react-spinners/MoonLoader";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import ReportModal from "../../Component/Modals/ReportModal";
import BlockModal from "../../Component/Modals/BlockModal";
import MuteNotificationModal from "../../Component/Modals/MuteNotificationModal";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const UserChat = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const messagesChatData = useSelector((state) => state.messages);
    const accountProfile = useSelector((state) => state.AccountProfile);
    const { User_profile } = accountProfile;
    const {
        individualMessages,
        // messagesList,
        loading,
        post_upload_loader,
    } = messagesChatData;
    const { id } = useParams();
    const menuRef = useRef(null);
    const fileRef = useRef(null);
    const bottomRef = useRef(null);
    const [inputValue, setInputValue] = useState("");

    const [showEmoji4, setShowEmoji4] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [showReplyPopup, setShowReplyPopup] = useState(false);
    const [showReplyPopup1, setShowReplyPopup1] = useState(false);
    const [selectImage, setSelectImage] = useState(null);
    const [image, setImage] = useState(null);
    const [emojiId, setEmojiId] = useState(null);
    const [replyId, setReplyId] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const menuRef1 = useRef(null);
    const [showBlockUserModal, setShowBlockUserModal] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showMuteNotificationsModal, setShowMuteNotificationsModal] = useState(false);

    // const selectedPerson = messagesList?.find((item) => item.id == id);
    // const { users } = selectedPerson;
    const { users } = individualMessages;

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        // setIsInputFocused(false);
    };
    const handleChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;
        const fileUrl = URL.createObjectURL(file);
        if (file.size > 100e6) {
            return null;
        } else {
            setSelectImage(fileUrl);
            setImage(file);
        }
    };
    const handleSelectEmoji = (value) => {
        setInputValue((prev) => prev + value);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowReplyPopup(null);
            setShowReplyPopup1(null);
            setReplyId(null);
        }
    };
    const fetchChats = useCallback(async () => {
        await dispatch(getMessages(id));
    }, [dispatch, id])

    const postMessage = async (event) => {
        event.preventDefault();

        if (inputValue || image) {
            await dispatch(
                sendMessages({
                    id: id,
                    img: image,
                    text: inputValue,
                })
            ).then(() => {
                setInputValue("");
                setSelectImage(null);
                setImage(null);
                setShowEmoji4(null);
                fetchChats();
                // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
            })
        }
    };

    useOutsideClick(menuRef, () => setShowMenu(null));

    useEffect(() => {
        fetchChats();
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("mousedown", (event) => {
            if (menuRef1.current && !menuRef1.current.contains(event.target)) {
                setShowMenu(false);
            }
        });

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("mousedown", (event) => {
                if (menuRef1.current && !menuRef1.current.contains(event.target)) {
                    setShowMenu(false);
                }
            });
        };
    }, [fetchChats]);

    return (
        <Fragment>
            <div className="h-full" >
                <div className="sticky top-0 z-50 bg-white dark:bg-black">
                    {/* <p className="text-[9px] text-red-600 font-bold uppercase text-center">
                        CC USER MESSAGING CHAT / FE / V3 / MAY 03, 2024
                    </p> */}
                    {/* <p className="text-[9px] text-red-600 font-bold uppercase text-center">
                        Copyright © 2025 Galileo One Inc
                    </p> */}
                    <div className="grid grid-cols-12 items-center px-2 py-1.5 bg-black border-b border-gray-400 dark:border-gray-800">
                        <div className="col-span-2 flex items-center justify-start">
                            <IoArrowBackCircle
                                onClick={() => navigate(-1)}
                                className="text-[22px] text-white cursor-pointer"
                            />
                        </div>

                        {loading &&
                            <div className="col-span-8 flex items-center justify-center">
                                <Skeleton width={120} height={18} />
                            </div>
                        }

                        {!loading &&
                            <div className="col-span-8">
                                {users?.map((item) => (
                                    <div
                                        key={item.id}
                                        className="flex items-center justify-center gap-x-2"
                                    >
                                        <img
                                            alt="profile"
                                            src={item.avatar_url}
                                            onClick={() => navigate(`/mobileMessages/user-detail/${id}`)}
                                            className="h-6 w-6 rounded-full object-cover cursor-pointer"
                                        />
                                        <p
                                            onClick={() => navigate(`/mobileMessages/user-detail/${id}`)}
                                            className="text-[14px] font-normal text-white cursor-pointer"
                                        >
                                            {item.first_name} {item.last_name}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        }

                        <div className="col-span-2 flex items-center justify-end">
                            {showMenu ?
                                <IoClose
                                    onClick={() => setShowMenu(!showMenu)}
                                    className="text-[25px] text-white cursor-pointer"
                                />
                                :
                                <IoMenu
                                    onClick={() => setShowMenu(!showMenu)}
                                    className="text-[25px] text-white cursor-pointer"
                                />
                            }

                            {showMenu && (
                                <div
                                    ref={menuRef1}
                                    className="absolute top-11 right-2 bg-white border border-gray-400 rounded-lg shadow-lg w-fit overflow-hidden"
                                >
                                    <p
                                        className="text-black text-[12px] font-medium border-b border-gray-400 hover:bg-gray-100 p-1.5 px-2 cursor-pointer"
                                    >
                                        Delete Chat
                                    </p>
                                    <p
                                        onClick={() => setShowBlockUserModal(true)}
                                        className="text-black text-[12px] font-medium border-b border-gray-400 hover:bg-gray-100 p-1.5 px-2 cursor-pointer"
                                    >
                                        Block User
                                    </p>
                                    <p
                                        onClick={() => setShowReport(true)}
                                        className="text-black text-[12px] font-medium border-b border-gray-400 hover:bg-gray-100 p-1.5 px-2 cursor-pointer"
                                    >
                                        Report
                                    </p>
                                    <p
                                        onClick={() => setShowMuteNotificationsModal(true)}
                                        className="text-black text-[12px] font-medium border-b border-gray-400 hover:bg-gray-100 p-1.5 px-2 cursor-pointer"
                                    >
                                        Mute Notifications
                                    </p>
                                    <p
                                        onClick={() => setShowMenu(false)}
                                        className="text-black text-[12px] font-medium border-b border-gray-400 hover:bg-gray-100 p-1.5 px-2 cursor-pointer"
                                    >
                                        Mark as Unread
                                    </p>
                                    <p
                                        onClick={() => navigate("/mobileMessages/achived")}
                                        className="text-black text-[12px] font-medium hover:bg-gray-100 p-1.5 px-2 cursor-pointer"
                                    >
                                        Archive Chat
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {loading &&
                    <div ref={bottomRef} className="px-2 h-full overflow-scroll no-scrollbar">
                        {Array(5)
                            .fill()
                            .map((_, i) => (
                                <div key={i} className="flex w-full flex-col space-y-4 px-2">
                                    {/* Receiver Skeleton */}
                                    <div className="flex w-auto justify-start mt-2">
                                        <div className="bg-gray-200 p-2 rounded-lg  w-56">
                                            <Skeleton width={200} height={20} />
                                            <Skeleton width={150} height={20} />
                                        </div>
                                    </div>
                                    {/* Sender Skeleton */}
                                    <div className="flex justify-end mt-2 w-auto">
                                        <div className="bg-gray-200 p-2 rounded-lg w-56">
                                            <Skeleton width={200} height={20} />
                                            <Skeleton width={150} height={20} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                }

                {!loading &&
                    <div className="px-2 h-full overflow-scroll no-scrollbar">
                        {post_upload_loader && (
                            <div className="h-full w-full flex justify-center items-center">
                                <MoonLoader color="black" size={30} />
                            </div>
                        )}

                        {individualMessages.messages?.map((data) => (
                            <>
                                {data.sent_to.id !== User_profile.id ? (
                                    <div className="grid grid-cols-12 pb-[8px] justify-end">
                                        <div className="col-span-12 flex items-center justify-center py-1">
                                            <p className="text-gray-400 dark:text-gray-700 text-[10px] font-normal">
                                                {data.created_at_formatted}
                                            </p>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="flex items-center gap-x-[1px] justify-end">
                                                <p className="text-blue-500 text-[10px]">
                                                    {data.sent_time}
                                                </p>
                                                <LuDot className="text-[12px] text-gray-600" />
                                                <p className="text-gray-600 text-[10px] font-semibold">You</p>
                                            </div>
                                        </div>
                                        {data.image && (
                                            <div className="col-span-12 flex justify-end ">
                                                <div className="relative flex">
                                                    <div className="relative">
                                                        <img
                                                            alt="profile"
                                                            src={`${data.image}`}
                                                            className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                                                        />
                                                        <div className="absolute -left-7 -bottom-2">
                                                            <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                                                                <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-span-3"></div>
                                        {data.body && (
                                            <div className="col-span-9 flex justify-end mt-1">
                                                <p className="text-[12px] text-white bg-[#343333] leading-[14px] px-2.5 py-1.5 rounded-md">
                                                    {data.body}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-12 pb-[8px]">
                                        {data.image && (
                                            <>
                                                <div className="col-span-12"></div>
                                                <div className="col-span-6 relative">
                                                    <div className="flex flex-col">
                                                        <p className="text-black text-[12px] font-bold">{ }</p>
                                                        <div className="relative">
                                                            <img
                                                                alt="post"
                                                                src={data.image}
                                                                className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                                                            />
                                                            <div className="absolute right-2 -bottom-2">
                                                                <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                                                                    <p className="text-white text-[10px]">400</p>
                                                                    <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                                                                </div>
                                                            </div>
                                                            <div className="absolute top-1 left-1">
                                                                <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl">
                                                                    <img
                                                                        alt="profile"
                                                                        className="h-3 w-3 rounded-full object-cover"
                                                                        src={data.created_by.avatar_url}
                                                                    />
                                                                    <p className="text-[10px] text-white">
                                                                        {data.created_by.first_name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="absolute top-[50%] right-1">
                                                        {emojiId ? null : (
                                                            <HiOutlineFaceSmile
                                                                className="text-[20px] cursor-pointer"
                                                                onClick={() => setEmojiId(data.id)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="col-span-10">
                                            {emojiId === data.id && (
                                                <div className="flex flex-row items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit">
                                                    <p
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    >
                                                        👍
                                                    </p>
                                                    <p
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    >
                                                        ❤️
                                                    </p>
                                                    <p
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    >
                                                        😃
                                                    </p>
                                                    <p
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    >
                                                        😥
                                                    </p>
                                                    <p
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    >
                                                        👎
                                                    </p>
                                                    <p
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    >
                                                        😡
                                                    </p>
                                                    <IoIosCloseCircle
                                                        className="cursor-pointer transform transition-transform hover:scale-125"
                                                        onClick={() => setEmojiId(false)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-span-9 mt-1">
                                            <div className="flex items-center gap-x-[1px]">
                                                <p className="text-gray-600 text-[10px] font-semibold">
                                                    {data.created_by.first_name}
                                                </p>
                                                <LuDot className="text-[12px] text-gray-600" />
                                                <p className="text-blue-500 text-[10px]">
                                                    {data.sent_time}
                                                </p>
                                            </div>
                                            {data.body && (
                                                <div className="grid grid-cols-12 gap-2 mt-[1px]">
                                                    {/* <div className="col-span-2">
                                                        <img
                                                            alt={"item.userName"}
                                                            src={data.created_by.avatar_url}
                                                            className="h-5 w-5 object-cover rounded-full"
                                                        />
                                                    </div> */}
                                                    <div className="col-span-10">
                                                        <div className="flex mt-0.5">
                                                            <p
                                                                className="text-[12px] text-white bg-[#343333] leading-[14px] px-2.5 py-1.5 rounded-md relative"
                                                                onClick={() => setReplyId(data.id)}
                                                            >
                                                                {data.body}
                                                                {replyId === data.id && (
                                                                    <div
                                                                        ref={menuRef}
                                                                        class="absolute -top-24 right-24 z-10 mt-0 w-24 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        aria-orientation="vertical"
                                                                        aria-labelledby="menu-button"
                                                                        tabindex="-1"
                                                                    >
                                                                        <div class="py-0" role="none">
                                                                            <a
                                                                                href="#"
                                                                                class="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-500 hover:text-white"
                                                                                role="menuitem"
                                                                                tabindex="-1"
                                                                                id="menu-item-0"
                                                                            >
                                                                                Reply
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                class="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-500 hover:text-white"
                                                                                role="menuitem"
                                                                                tabindex="-1"
                                                                                id="menu-item-0"
                                                                            >
                                                                                Copy
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                class="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-500 hover:text-white"
                                                                                role="menuitem"
                                                                                tabindex="-1"
                                                                                id="menu-item-0"
                                                                            >
                                                                                Report
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                        {individualMessages.messages?.length === 0 && (
                            <div className="h-full w-full flex justify-center items-center">
                                <p className="text-gray-700 text-[1rem] font-bold">No Messages</p>
                            </div>
                        )}
                        <div ref={bottomRef}></div>
                    </div>
                }

                <div className="sticky bottom-0 bg-white dark:bg-black z-40 p-1 border-t border-gray-400 dark:border-gray-800">
                    <form onSubmit={postMessage}>
                        <div className="flex items-center justify-between gap-1 my-1">
                            <div className="bg-black p-2 rounded-full cursor-pointer border dark:border-gray-800">
                                <FaCamera
                                    className="text-white"
                                    onClick={() => {
                                        fileRef.current?.click();
                                    }}
                                />
                                <input
                                    type="file"
                                    ref={fileRef}
                                    className="hidden"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="relative w-full">
                                <input
                                    value={inputValue}
                                    placeholder="Send a message"
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    onChange={handleChange}
                                    className="w-full h-8 bg-black text-white px-3 focus:outline-none rounded-xl border dark:border-gray-800"
                                />
                                <HiOutlineFaceSmile
                                    color="white"
                                    className="text-[20px] cursor-pointer absolute right-[10px] top-[6px]"
                                    onClick={() => setShowEmoji4(true)}
                                />

                                {showEmoji4 && (
                                    <div className="flex flex-col absolute bottom-10 right-0 items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit">
                                        <p
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => handleSelectEmoji("👍")}
                                        >
                                            👍
                                        </p>
                                        <p
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => handleSelectEmoji("❤️")}
                                        >
                                            ❤️
                                        </p>
                                        <p
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => handleSelectEmoji("😃")}
                                        >
                                            😃
                                        </p>
                                        <p
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => handleSelectEmoji("😥")}
                                        >
                                            😥
                                        </p>
                                        <p
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => handleSelectEmoji("👎")}
                                        >
                                            👎
                                        </p>
                                        <p
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => handleSelectEmoji("😡")}
                                        >
                                            😡
                                        </p>
                                        <IoIosCloseCircle
                                            className="cursor-pointer transform transition-transform hover:scale-125"
                                            onClick={() => setShowEmoji4(false)}
                                        />
                                    </div>
                                )}

                                {selectImage && (
                                    <div className="h-32 w-36 absolute bottom-10 rounded-xl grid grid-cols-4">
                                        <img
                                            src={selectImage}
                                            className="h-32 w-36 col-span-3  object-cover rounded-xl"
                                        />
                                        <div className="col-span-1 relative">
                                            <IoIosCloseCircle
                                                color="red"
                                                onClick={() => setSelectImage(null)}
                                                className="cursor-pointer absolute transform transition-transform hover:scale-125"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {isInputFocused && (
                                <button
                                    type="submit"
                                    className="bg-black p-2 rounded-full cursor-pointer z-50 border dark:border-gray-800"
                                >
                                    {!loading ? (
                                        <IoSend className="text-white" />
                                    ) : (
                                        <MoonLoader color="#fff" size={14} />
                                    )}
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>

            {showReport &&
                <ReportModal
                    setReportPost={setShowReport}
                />
            }

            {showBlockUserModal &&
                <BlockModal setShowBlockUserModal={setShowBlockUserModal} />
            }

            {showMuteNotificationsModal &&
                <MuteNotificationModal
                    setShowMuteNotificationsModal={() => setShowMuteNotificationsModal(false)}
                />
            }
        </Fragment>
    );
};

export default UserChat;
