import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button
} from "@material-tailwind/react";
export default function AddReviewCard(props) {
  const { cancel, menuRef, isFullScreen } = props;
  const [count, setCount] = useState(0);
  const [selected, setSelected] = useState("");

  const handleChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= 200) {
      setCount(inputText.length);
    }
  };
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  return (
    <Card
      className={`rounded-none w-[85%] dark:bg-black dark:border-gray-800 dark:border-2 dark:text-gray-400 h-[50%]
 `}
      ref={menuRef}
    >
      <CardBody className="p-2">
        <Typography
          color="blue-gray"
          className={`mb-1 dark:text-gray-400 text-center text-[12px] font-bold`}
        >
          Add Your Review
        </Typography>
        <Typography
          color="blue-gray"
          className={`mb-1 text-[8px] dark:text-gray-400 text-center`}
        >
          We will fix our article according to your suggestion. Please select
          from the options or write your suggestions.
        </Typography>
        <Typography className={`mb-2 px-4  h-6`}>
          <select
            value={selected}
            id=""
            class="bg-gray-50 border h-full rounded-lg dark:text-gray-300 dark:bg-gray-800 outline-none border-gray-300 text-gray-900 text-[12px] w-full"
            onChange={handleSelect}
          >
            <option value={"Very good"}>Very good</option>
            <option value={"Good"}>Good</option>
            <option value={"Average"}>Average</option>
            <option value={"Bad"}>Bad</option>
          </select>
        </Typography>
        <Typography className={`px-4`}>
          <div className="bg-[#fff] dark:text-gray-300 dark:bg-gray-800 rounded-lg border border-gray-500">
            <textarea
              rows="3"
              maxLength={200}
              onChange={handleChange}
              placeholder="Type Something"
              className="w-full bg-[#fff] dark:text-gray-300 dark:bg-gray-800 px-[5px] py-[5px] focus:outline-none resize-none rounded-lg placeholder:text-[12px] text-[12px] font-semibold text-gray-700"
            />
            <p className="absolute right-8 text-gray-700 text-[12px] -mt-6">
              {count}/200
            </p>
          </div>
        </Typography>
      </CardBody>
      <CardFooter className="flex justify-center items-center gap-4 md:p-1">
        <Button
          variant="outlined"
          className={`p-1 px-3 text-[8px] dark:bg-white dark:text-black rounded-sm`}
        >
          Send
        </Button>
        <Button
          variant="outlined"
          className={`rounded-sm text-[8px] bg-[red] text-[#fff] p-1 px-3
            `}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
      </CardFooter>
    </Card>
  );
}
