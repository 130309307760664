import React, { Fragment } from "react";
import { TbStarHalfFilled } from "react-icons/tb";

export default function Trending({ usersData = [], handleNavigate, loading, SearchItem }) {
    console.log(usersData, "usersData");

    // Filter usersData based on SearchItem
    const filteredUsers = SearchItem
        ? usersData?.filter((user) => {
            const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
            const username = user.username.toLowerCase();
            const searchTerm = SearchItem.toLowerCase();

            // Check if the search term matches the name or username
            return fullName.includes(searchTerm) || username.includes(searchTerm);
        })
        : usersData;

    return (
        <Fragment>
            <div className="w-full h-[100%] px-2 overflow-y-scroll pb-40">
                {loading && (
                    <div className="text-gray-600 text-[13px] text-center mt-5">
                        Loading...
                    </div>
                )}

                {!loading &&
                    (Array.isArray(filteredUsers) ? filteredUsers : []).map((data) => (
                        <Fragment key={data?.id}>
                            <div
                                className="grid grid-cols-12 items-center pt-2 pb-2 px-1 hover:bg-[#9a989844] z-50 text-black cursor-pointer"
                            >
                                <div className="col-span-2">
                                    <div className="relative flex items-center justify-center border shadow-[1px_1px_2px_1px_#f687b3] rounded-md h-8 w-8 p-[1px]">
                                        <img
                                            alt={data.first_name}
                                            src={data.avatar_url}
                                            className="h-7 w-7 rounded-md object-cover"
                                            onClick={() => handleNavigate(data.id)}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-8 ml-[-2px]">
                                    <div className="grid grid-cols-12 w-full">
                                        <div className="col-span-12 flex justify-between items-center">
                                            <div className="flex gap-1 items-center">
                                                <p
                                                    className="text-[14px] md:text-[12px] dark:text-[white] font-bold first-letter:uppercase line-clamp-2"
                                                    onClick={() => handleNavigate(data.id)}
                                                >
                                                    {data?.first_name} {data?.last_name}
                                                </p>
                                                <p>
                                                    <TbStarHalfFilled className="text-blue-600 text-[12px]" />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <p className="text-[10px] line-clamp-1 dark:text-[white]">
                                                {data?.username}
                                                <span className="text-gray-600 dark:text-gray-400">
                                                    {data?.mutual_friends?.length > 0
                                                        ? `.Followed ${data.mutual_friends[0]?.username} 
                                                          ${data?.mutual_friends?.length > 1 ? `+${data?.mutual_friends?.length - 1} others` : ""}`
                                                        : ""}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-300 dark:bg-gray-700 border-0" />
                        </Fragment>
                    ))}

                {!filteredUsers?.length && !loading && (
                    <div className="text-gray-600 text-[13px] text-center mt-5">
                        No users found
                    </div>
                )
                }
            </div>
        </Fragment>
    );
}