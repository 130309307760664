import React from "react";
import { useNavigate } from "react-router-dom";

export default function HashTag({hashtagData}) {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full px-2 overflow-y-scroll pb-40">
      {hashtagData?.map((data) => (
        <div
          className="grid grid-cols-12 gap-1 mt-1 cursor-pointer"
          key={data.post_count}
          onClick={() => navigate(`/hashtag/${data.hashtag}`)}
        >
          <div className="col-span-2 flex justify-center items-center">
            <button className=" h-8 w-8  dark:text-white bg-[#2e2d2d] text-[#fff] rounded-full text-[10px] p-2 font-bold">
              #
            </button>
          </div>
          <div className="col-span-10" >
            <p className="text-[14px] dark:text-white font-bold">
              {data.hashtag}
            </p>
            <p className="text-gray-500 text-[10px] ">{data.post_count} posts</p>
          </div>
        </div>
      ))}
      {
        hashtagData?.length === 0 && (
          <div className="text-gray-600 text-[13px] text-center mt-5">
            No Hashtag Found
          </div>
        )
      }
    </div>
  );
}
