import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const settingsOptions = [
    { path: "/settings/account", label: "Accounts" },
    { path: "/settings/security", label: "Security" },
    { path: "/settings/privacy", label: "Privacy" },
    { path: "/settings/preferences", label: "Preferences" },
    { path: "/settings/notifications", label: "Notifications" },
    { path: "/settings/display", label: "Display" },
    { path: "/settings/whatsnew", label: "What’s New" },
];

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <p className="hidden dark:text-white md:block text-center text-[20px] font-semibold py-1">
                Settings
            </p>

            <div className="grid grid-cols-1 gap-4 px-4 mt-3">
                {settingsOptions.map((option) => (
                    <div
                        key={option.path}
                        onClick={() => navigate(option.path)}
                        className="col-span-1 flex items-center justify-center bg-gray-900 rounded-lg py-3 px-2 cursor-pointer shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
                    >
                        <p className="text-[14px] font-semibold text-white hover:text-cyan-400">
                            {option.label}
                        </p>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default LandingPage;