import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const Uploadvideo = createAsyncThunk("Uploadvideo", async (data) => {
  try {
    const response = data;
    return response;
  } catch (error) {
    return error;
  }
});

export const uploadVideoData = createAsyncThunk(
  "uploadVideoData",
  async (data, { dispatch }) => {

    const form_data = new FormData();
    form_data.append("video", data.video);
    form_data.append("body", data.body);
    form_data.append("is_private", data.is_private ? "True" : "False");
    form_data.append("interest", data.interest);
    const response = await axiosInstance.post(`/post/create/`, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(setUploadProgress(percentCompleted));
      },
    });
    try {

      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const UploadvideoSlice = createSlice({
  name: "Uploadvideo",
  initialState: {
    SelectedVideo: {},
    loading: false,
    error: null,
    progress: 0,
  },
  reducers: {
    setUploadProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    // AccountProfile action promises
    builder.addCase(Uploadvideo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Uploadvideo.fulfilled, (state, action) => {
      state.SelectedVideo = action.payload;
      state.loading = false;
    });
    builder.addCase(Uploadvideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    /* uploadVideoData  */
    builder.addCase(uploadVideoData.pending, (state) => {
      state.loading = true;
      state.progress = 0;
    });
    builder.addCase(uploadVideoData.fulfilled, (state, action) => {
      state.loading = false;
      state.progress = 100;
    });
    builder.addCase(uploadVideoData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.progress = 0;
    });
  },
});

export default UploadvideoSlice.reducer;
export const { setUploadProgress } = UploadvideoSlice.actions;
