import React from "react";
import { useLocation, useParams } from "react-router-dom";
import MobileLayout from "../Components/MobileVersion/MobilePanel/Layout";

export default function WebLayout({ children }) {
    const location = useLocation();
    const param = useParams();
    const { id } = param;
    
    return (
        <section className="w-full md:h-[100vh] flex justify-center items-center md:pt-[10px] bg-[#cae4e4]">
            <div className="flex justify-center overflow-auto no-scrollbar w-full md:w-[40%] md:h-[88vh] h-screen">
                {location.pathname === `/home` || location.pathname === `/home/${id}` ||
                    location.pathname === `/watchvideo/${id}` ? (
                    children
                ) : (
                    <MobileLayout>{children}</MobileLayout>
                )}
            </div>
        </section>
    );
}
