import React, { Fragment } from "react";
import RecommendedProduct from "../../Component/shoppage/RecommendedProduct";
import PopularProduct from "../../Component/shoppage/PopularProduct";
import SaleProduct from "../../Component/shoppage/SaleProduct";
import InputButton from "../../Component/shoppage/InputButton";

const Category = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
            Copyright © 2025 Galileo One Inc
         </p>
         <div className="pt-8 bg-black">
            <RecommendedProduct />
            <PopularProduct />
            <SaleProduct />
            <div className="pb-8">
               <InputButton />
            </div>
         </div>
      </Fragment>
   )
}

export default Category