import React, { useState } from "react";
import SideBar from ".././../Component/shoppage/SideBar";
import InputButton from "../../Component/shoppage/InputButton";
import SugestedProducts from "../../Component/shoppage/SugestedProducts";
import { FiShoppingCart } from "react-icons/fi";
import JustInProduct from "../../Component/shoppage/JustInProduct";
import { useNavigate } from "react-router-dom";

const ShopPage = () => {
    const navigate = useNavigate();
    const [toggleCheck, setToggleChecked] = useState(true);
    return (
        <div className="dark:bg-black dark:dark:text-white h-full relative  overflow-hidden">
            <p className="absolute text-red-700 uppercase font-bold text-[10px] right-1 w-full text-center  z-30">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="px-2 flex items-center justify-between mt-6">
                <div className="w-[25%] flex gap-x-4 items-center">
                    <div
                        onClick={() => navigate("/basket")}
                        class="relative inline-flex text-red-700 items-center cursor-pointer"
                    >
                        <FiShoppingCart className="dark:dark:text-white text-black" />
                        <span class="absolute rounded-full  text-[8px] font-medium content-[''] leading-none grid place-items-center top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4 bg-red-500 text-white min-w-[14px] min-h-[14px]">
                            5
                        </span>
                    </div>
                    <div className="dark:text-white items-center flex gap-x-1">
                        <p className="text-[10px] font-semibold">
                            {toggleCheck ? "Global" : "Local"}
                        </p>
                        <label class="inline-flex items-center  cursor-pointer">
                            <input
                                type="checkbox"
                                value=""
                                class="sr-only peer"
                                checked={toggleCheck}
                                onChange={() => setToggleChecked(!toggleCheck)}
                            />
                            <div class="relative w-7 h-4 md:w-5 md:h-3 bg-gray-200 peer-focus:outline-none peer-focus:ring-3 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[3px] after:bg-[#fff] after:border-gray-300 after:border after:rounded-full  md:after:h-2 md:after:w-2 after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>
                </div>
                <div className="dark:text-white text-[20px] font-bold w-[25%] flex justify-center items-center">
                    Shop
                </div>
                <div className="w-25%">
                    {/* <p className="text-[12px] dark:text-white">Location</p> */}
                </div>
                <div className="flex justify-center gap-2">
                    <SideBar />
                </div>
            </div>
            <div className="overflow-y-scroll no-scrollbar h-[-webkit-fill-available] absolute">
                <div className="grid grid-cols-1">
                    <InputButton />
                    <SugestedProducts />
                    <JustInProduct />
                </div>
            </div>
        </div>
    );
};

export default ShopPage;
