import { Button } from "@material-tailwind/react";
import React from "react";

export default function InputBoxSection({ handleShowModal }) {
   return (
      <div className="grid grid-cols-12 justify-between  gap-y-5">
         <Button
            className="col-span-12 flex justify-center items-center rounded-full bg-[#00A3FF] text-[12px] p-2 py-2"
            onClick={handleShowModal}
         >
            Give Feedback
         </Button>
         <div className="col-span-12 grid grid-cols-6 relative  justify-end items-center h-full rounded-full border-2 border-gray-400 dark:border-gray-800 dark:bg-black bg-white">
            <input
               placeholder="Enter your email"
               className={`h-full rounded-full  outline-none dark:border-gray-800 dark:text-gray-400 dark:bg-black col-span-4 px-3 py-2 placeholder:text-[10px]
                text-[12px] `}
            />
            <Button className={`bg-[#00A3FF] rounded-full text-[12px] px-2 py-1  col-span-2 mr-1`}>
               Subscribe
            </Button>
         </div>
      </div>
   );
}
