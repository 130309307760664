import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";

export default function TermsOfUse() {
   const location = useLocation();
   const navigate = useNavigate()
   const [terms, setTerms] = useState(false);

   useEffect(() => {
      if (location.pathname === "/terms-of-uses") {
         setTerms(true);
      }
   }, [location.pathname]);

   const termsUse = [
      { dec: "On Creating an Account", id: 1 },
      {
         dec: " On Modification, Suspension And, Termination of Your Account",
         id: 2,
      },
      { dec: "On Deleting Your Accoun", id: 3 },
      { dec: "Things We Do Not Do and Are Not Responsible For", id: 4 },
      { dec: "Indemnification", id: 5 },
      { dec: "Dispute And Governing Law", id: 6 },
      { dec: "Account Information and Security", id: 7 },
      { dec: "Intellectual Property", id: 8 },
      { dec: " Feedback to us", id: 9 },
      { dec: "Limitation of Liability", id: 10 },
      {
         dec: "Providing consistent and seamless experiences across the Company Products",
         id: 11,
      },
      { dec: " Ensuring access to our Service", id: 12 },
      {
         dec: "Connecting you with brands, products, and services in ways you care about",
         id: 13,
      },
      { dec: "Research and innovation", id: 14 },
      { dec: " How you can't use CC", id: 15 },
      { dec: "Other Things", id: 16 },
   ];

   const createAccount = [
      "Don’t break the law.",
      " Don’t lie to people.",
      " Don’t offer prohibited items. ",
      " Don’t victimize anyone.",
      "Don’t spam.",
      "Don’t harm anyone’s computer.",
      "Don’t abuse other users’ personal information.",
   ];

   const Indemnification = [
      "your access to or use of our Services, including information and content provided in connection therewith;",
      " your breach of our Terms or applicable law; or",
      "any misrepresentation made by you. You will cooperate as fully as required by us in the defence or settlement of any Third-Party Claim. Your rights concerning CC are not modified by the foregoing indemnification if the laws of your country or territory of residence, applicable as a result of your use of our Services, do not permit it.",
   ];

   const useCC = [
      {
         id: 1,
         qus: "i. You can't impersonate others or provide inaccurate information.",
         ans: "You don't have to disclose your identity on CC, but you must provide us with accurate and up-to-date information (including registration information), which may include providing personal data. Also, you may not impersonate someone or something you aren't, and you can't create an account for someone else unless you have their express permission.",
      },
      {
         id: 2,
         qus: "ii. You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.",
      },
      {
         id: 3,
         qus: "iii. You can't violate (or help or encourage others to violate) these Terms or our policies, including in particular the 6644 Community Guidelines.",
         ans: "If you post branded content, you must comply with our Branded Content Policies, which require you to use our branded content tool. Learn how to report conduct or content in our",
      },
      {
         id: 4,
         qus: "iv. You can't do anything to interfere with or impair the intended operation of the Service.",
         ans: "This includes misusing any reporting, dispute, or appeals channel, such as by making fraudulent or groundless reports or appeals.",
      },
      {
         id: 5,
         qus: "v. You can't attempt to create accounts or access or collect information in unauthorized ways.",
         ans: "This includes creating accounts or collecting information in an automated way without our express permission.",
      },
      {
         id: 6,
         qus: "vi. You can’t sell, license, or purchase any account or data obtained from us or our Service.",
         ans: "This includes attempts to buy, sell, or transfer any aspect of your account (including your username); solicit, collect, or use login credentials or badges of other users; or request or collect CC usernames, passwords, or misappropriate access tokens.",
      },
      {
         id: 7,
         qus: "vii. You can't post someone else’s private or confidential information without permission or do anything that violates someone else's rights, including intellectual property rights (e.g., copyright infringement, trademark infringement, counterfeit, or pirated goods).",
         ans: "You may use someone else's works under exceptions or limitations to copyright and related rights under applicable law. You represent your own or have obtained all necessary rights to the content you post or share. Learn more, including how to report content that you think infringes your intellectual property rights, here.",
      },
      {
         id: 8,
         qus: "viii. You can’t modify, translate, create derivative works of, or reverse engineer our products or their components.",
      },
      {
         id: 9,
         qus: "ix. You can't use a domain name or URL in your username without our prior written consent.",
      },
   ];

   const otherthings = [
      {
         qus: "A. You must be 18 years or older to use our Services.",
         ans: "Minors under 18 and at least 13 years of age are only permitted to use our Services through an account owned by a parent or legal guardian with their appropriate permission and under their direct supervision. Children under 13 years are not permitted to use CC or the Services. You are responsible for any account activity conducted by a minor on your account, and there may be commercial products or services available that you may want to consider to limit a minor's access to material online. For more information, see CC Minors Policy.",
      },
      {
         qus: "B. Be honest with us.",
         ans: "Provide accurate information about yourself. Using false information or impersonating another person or company through your account is prohibited.",
      },
      {
         qus: "C. Choose an appropriate name.",
         ans: "If you decide to not have your full name serve as the name associated with your account, you may not use language that is offensive, vulgar, infringes someone’s intellectual property rights, or otherwise violates the Terms.",
      },
      {
         qus: "D. You're responsible for your account.",
         ans: "You’re solely responsible for any activity on your account. If you’re sharing an account with others, the person whose financial information is on the account will ultimately be responsible for all activity. If you’re registering as a business entity, you guarantee that you have the authority to agree to the Terms on behalf of the business. Also, your accounts are not transferable.",
      },
      {
         qus: "E. Protect your password.",
         ans: "As we mentioned above, you’re solely responsible for any activity on your account, so it’s important to keep your account password secure. Here’s a Help article on how to make your account more secure.",
      },
      {
         qus: "F. Let's be clear about our relationship.",
         ans: "These Terms don't create any agency, partnership, joint venture, employment, or franchisee relationship between you and CC.",
         ans1: "This detailed Help article should answer any questions you may have about registering an account with CC.",
         ans2: "These Terms constitute the entire agreement between you and the Company relating to your access to and use of the Services. We may assign our rights and obligations under these Terms. Under no circumstances may you assign your rights and obligations under these Terms, including in the event of a change of control or by operation of law, without our prior written consent. The failure of the Company to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision. We will not be liable for any delay or failure to perform any obligation under these Terms where the delay or failure results from any cause beyond our reasonable control. Except as otherwise provided herein, the agreement is intended solely for the parties' benefit and is not intended to confer third-party beneficiary rights upon any other person or entity.",
      },
   ];

   const scrollToAnswer = (id) => {
      document.getElementById(id).scrollIntoView({
         behavior: "smooth",
         block: "start",
      });
   };

   return (
      <Fragment>
         <div className={`grid grid-cols-12 relative ${terms ? "md:px-28" : ""}`}>
            {/* <div className={`col-span-12 bg-[#D9D9D9] sticky top-0 h-6`}>
               <p className="text-red-700 text-[10px] font-bold text-center pt-[4px]">
                  CC TERMS OF USE PAGE / FE / V2 / JULY 06, 2024
               </p>
               <p className="text-red-700 text-[10px] font-bold text-center pt-[4px]">
                  Copyright © 2025 Galileo One Inc
               </p>
            </div> */}

            <div className={`col-span-12 mt-1 ${terms ? "px-3" : "px-3"}`}>
               <div className={``}>
                  <IoCaretBackCircle onClick={() => navigate(-1)} className="text-[1.2rem] cursor-pointer text-black dark:text-white" />
               </div>

               <div className="">
                  <h1 className={`text-center text-black dark:text-white ${terms ? "text-[36px]" : "text-[24px]"} font-bold mb-2`}>
                     Terms Of Use
                  </h1>
               </div>
               <div className="mt-4">
                  <div>
                     <h1 className={`${terms ? "text-[24px]" : "text-[16px]"} font-bold mb-2 text-black dark:text-white`}>
                        What are Terms of Use?
                     </h1>
                     <p className={` text-justify text-black dark:text-white ${terms ? " text-[14px] mt-4" : " text-[12px] mt-2"}`}>
                        Terms are the rules and guidelines of acceptable behavior and
                        other useful sections to which users must agree to use or access
                        our website and mobile application.
                     </p>
                     <p className="text-[14px] text-black dark:text-white mt-4">Terms of Service:</p>
                     <p className={`text-justify text-black dark:text-white ${terms ? " text-[14px] mt-4" : "text-[12px] mt-1"}`}>
                        The user must agree to the following terms to use our offered
                        services:
                     </p>
                     <ol className="list-decimal px-4 mt-2">
                        {termsUse.map((item) => (
                           <li
                              className={`text-justify text-blue-700 dark:text-blue-500 hover:text-blue-800 cursor-pointer ${terms ? "text-[14px] mt-4" : " text-[12px]"}`}
                              onClick={() => scrollToAnswer(item.id)}
                           >
                              {item.dec}
                           </li>
                        ))}
                     </ol>
                  </div>
               </div>

               <div className="mt-4" id="1">
                  <h1 className={`${terms ? "text-[14px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     1. On Creating an Account
                  </h1>
                  <p className={`text-justify ${terms ? " text-[12px] mt-1" : " text-[12px] mt-1"} text-black dark:text-white`}>
                     You must be 18 or over to sign up. For creating your accounts you
                     need to follow certain rules.
                  </p>
                  <ol className="list-disc px-3 mt-2">
                     {createAccount.map((item) => (
                        <li className={`text-justify ${terms ? " text-[12px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                           {item}
                        </li>
                     ))}
                  </ol>
               </div>

               <div className="mt-4" id="2">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     2. On Modification, Suspension, and Termination of Your Account.
                  </h1>
                  <p className={`text-justify ${terms ? "text-[12px] mt-1" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     We may modify, suspend, or terminate your account or access to the
                     Platform if, in our sole discretion, we determine that you have
                     violated this Agreement, including any of the policies or
                     guidelines that are part of this Agreement, that it is in the best
                     interest of the CC community, or to protect our brand or Platform.
                     When this happens, we will notify you of the reasons for the
                     modification, suspension, or termination. We also may remove
                     accounts of members who are inactive for an extended period.
                     Please email policy@cc.com if you believe the modification,
                     suspension, or termination has occurred in error. A member who can
                     moderate or manage a particular portion of our Platform also has
                     the ability, in his or her sole discretion, to modify, suspend, or
                     terminate your access to that portion of the Platform.
                  </p>
               </div>

               <div className="mt-4 " id="3">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     3. On Deleting Your Account
                  </h1>
                  <p className={`text-justify ${terms ? " text-[14px] mt-4" : " text-[12px] mt-1"} text-black dark:text-white`}>
                     If you delete the account the account is deleted.
                  </p>
               </div>

               <div className="mt-4" id="4">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     4. Things we do not do and are not Responsible For
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     We don’t get involved in disputes between users, or between users
                     and any third party relating to the use of the Services. You’re
                     solely responsible for any resulting damage or loss to any party.
                  </p>
               </div>

               <div className="mt-4" id="5">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     5. Indemnification
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     If you do something that gets us used, or breaks any of the
                     promises you make on this website, we are not responsible.
                  </p>
                  <p className={`text-justify ${terms ? " text-[14px] mt-4" : " text-[12px] mt-2"} text-black dark:text-white`}>
                     If anyone brings a claim ("Third-Party Claim") against us related
                     to your actions, information, or content on CC, or any other use
                     of our Services by you, you will, to the maximum extent permitted
                     by applicable law, indemnify, and hold the CC Parties harmless
                     from and against all liabilities, damages, losses, and expenses of
                     any kind (including reasonable legal fees and costs) relating to,
                     arising out of, or in any way in connection with any of the
                     following:
                  </p>
                  <ol className="list-disc px-3">
                     {Indemnification.map((item) => (
                        <li className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                           {item}
                        </li>
                     ))}
                  </ol>
               </div>

               <div className="mt-4" id="6">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     6. Dispute Resolution and Governing Law
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     Please contact us if you’re facing any issues before resorting to
                     the courts. Our support team is ready to answer your queries.
                  </p>
               </div>

               <div className="mt-4" id="7">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-boldtext-black dark:text-white`}>
                     7. Account Information and Security
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"}text-black dark:text-white`}>
                     When you register, you provide us with some basic information,
                     including an email address and a password. Keep your email address
                     and other account information current and accurate. Also, you
                     agree to maintain the security and confidentiality of your
                     password (or else we may need to disable your account). We
                     strongly encourage you to choose a strong and unique password that
                     is not shared with any other account or online service and
                     practice other healthy password security habits to help avoid
                     unauthorized access to your account. You alone are responsible for
                     anything that happens from your failure to maintain that security
                     and confidentiality, such as by sharing your account credentials
                     with others. If someone is using your password or accessing your
                     account without your permission, email us at abuse@cc.com.
                  </p>
               </div>

               <div className="mt-4" id="8">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     8. Intellectual Property
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : " text-[12px] mt-1"}text-black dark:text-white`}>
                     Intellectual Property the Service and its original content
                     (excluding Content provided by You or other users), features, and
                     functionality are and will remain the exclusive property of the
                     Company and its licensors. The Service is protected by copyright,
                     trademark, and other laws of both the Country and foreign
                     countries. Our trademarks and trade dress may not be used in
                     connection with any product or service without the prior written
                     consent of the Company.
                  </p>
               </div>

               <div className="mt-4" id="9">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     9. Feedback to us
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     Your Feedback to Us You assign all rights, title, and interest in
                     any Feedback You provide the Company. If for any reason such
                     assignment is ineffective, you agree to grant the Company a
                     non-exclusive, perpetual, irrevocable, royalty-free, worldwide
                     right and license to use, reproduce, disclose, sub-license,
                     distribute, modify and exploit such Feedback without restriction.
                  </p>
               </div>

               <div className="mt-4" id="10">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     10. Limitation of Liability
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     Notwithstanding any damages that You might incur, the entire
                     liability of the Company and any of its suppliers under any
                     provision of this Terms and Your exclusive remedy for all of the
                     foregoing shall be limited to the amount paid by You through the
                     Service or 100 USD if You haven't purchased anything through the
                     Service. To the maximum extent permitted by applicable law, in no
                     event shall the Company or its suppliers be liable for any
                     special, incidental, indirect, or consequential damages whatsoever
                     (including, but not limited to, damages for loss of profits, loss
                     of data or other information, for business interruption, for
                     personal injury, loss of privacy arising out of or in any way
                     related to the use of or inability to use the Service, third-party
                     software and/or third-party hardware used with the Service, or
                     otherwise in connection with any provision of this Terms), even if
                     the Company or any supplier has been advised of the possibility of
                     such damages and even if the remedy fails of its essential
                     purpose. Some states do not allow the exclusion of implied
                     warranties or limitation of liability for incidental or
                     consequential damages, which means that some of the above
                     limitations may not apply. In these states, each party's liability
                     will be limited to the greatest extent permitted by law.
                  </p>
               </div>

               <div className="mt-4" id="11">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     11. Providing consistent and seamless experiences across the
                     Company Products
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : " text-[12px] mt-1"} text-black dark:text-white`}>
                     The company shares technology, systems, insights, and
                     information-including the information we have about you (learn
                     more in the Data Policy) to provide services that are better,
                     safer, and more secure. We also provide ways to interact across
                     the Company Products that you use, and designed systems to achieve
                     a seamless and consistent experience across the Company Products.
                  </p>
               </div>

               <div className="mt-4" id="12">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     12. Ensuring access to our Service
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     To operate our global Service, we must store and transfer data
                     across our systems around the world, including outside of your
                     country of residence. The use of this global infrastructure is
                     necessary to provide our Service.
                  </p>
               </div>

               <div className="mt-4" id="13">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     13. Connecting you with brands, products, and services in ways you
                     care about
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     It is the third-party partners, to show you ads, offers, and other
                     sponsored content that we believe will be meaningful to you. And
                     we try to make that content as relevant as all your other
                     experiences.
                  </p>
               </div>

               <div className="mt-4" id="14">
                  <h1 className={`${terms ? "text-[24px]" : "text-[16px]"} font-bold text-black dark:text-white`}>
                     14. Research and innovation
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     We use the information we have to study our Service and
                     collaborate with others on research to make our Service better and
                     contribute to the well-being of our community.
                  </p>
               </div>

               <div className="mt-4" id="15">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     15. How You Can't Use CC
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     Providing a safe and open Service for a broad community requires
                     that we all do our part.
                  </p>
                  <p className="font-bold mb-2text-justify text-black dark:text-white">
                     i. You can't impersonate others or provide inaccurate information.
                  </p>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[11px] mt-1"} text-black dark:text-white`}>
                     You don't have to disclose your identity on CC, but you must
                     provide us with accurate and up-to-date information (including
                     registration information), which may include providing personal
                     data. Also, you may not impersonate someone or something you
                     aren't, and you can't create an account for someone else unless
                     you have their express permission.
                  </p>
                  {useCC.map((item) => (
                     <>
                        <h1 className={` font-bold  text-justify ${terms ? "text-[16px] mb-2" : "text-[12px]"} text-black dark:text-white`}>
                           {item?.qus}
                        </h1>
                        <p
                           className={`text-justify ${terms ? "text-[14px] mb-4" : "text-[11px] mt-1"} text-black dark:text-white`}>
                           {item.ans}
                           {item.id === 3 ? (
                              <span className="font-bold text-black dark:text-white">Help center</span>
                           ) : (
                              ""
                           )}
                        </p>
                     </>
                  ))}
               </div>

               <div className="mt-4 mb-4" id="16">
                  <h1 className={`${terms ? "text-[24px]" : "text-[14px]"} font-bold text-black dark:text-white`}>
                     16. Other things
                  </h1>
                  <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[12px] mt-1"} text-black dark:text-white`}>
                     These are our other terms of use and rules:
                  </p>
                  <p className="font-bold text-[14px] mb-2 text-justify text-black dark:text-white">
                     You’ll need to create an account with CC to use some of our
                     Services. Here are a few rules about accounts with CC:
                  </p>
                  {otherthings.map((item) => (
                     <>
                        <h1 className={`font-bold ${terms ? "text-[16px]" : "text-[12px]"} mb-2 text-black dark:text-white`}>
                           {item?.qus}
                        </h1>
                        <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[11px] mt-2"} text-black dark:text-white`}>
                           {item.ans}
                        </p>
                        <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[11px] mt-2"} text-black dark:text-white`}>
                           {item?.ans1}
                        </p>
                        <p className={`text-justify ${terms ? "text-[14px] mt-4" : "text-[11px] mt-2"} text-black dark:text-white`}>
                           {item?.ans2}
                        </p>
                     </>
                  ))}
               </div>
            </div>
         </div>
      </Fragment>
   );
}
