import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";

/* create the group */
export const createGroup = createAsyncThunk("createGroup", async (form_data) => {
    try {
        const response = await axiosInstance.post(
            `/group/ccgroups/`,
            form_data,
            {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});


/* get the group list */
export const getGroupList = createAsyncThunk(
    "getGroupList",
    async () => {
        try {
            const response = await axiosInstance.get(`/group/ccgroups/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const getGroupByUserId = createAsyncThunk(
    "getGroupByUserId",
    async (userId) => {
        try {
            const response = await axiosInstance.get(`/group/user/${userId}/groups/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);


/* group slice */
export const getGroupDetails = createAsyncThunk(
    "getGroupDetails",
    async (groupId) => {
        try {
            const response = await axiosInstance.get(`/group/ccgroups/${groupId}/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);


export const updateGroup = createAsyncThunk("updateGroup", async ({ groupId, form_data }) => {

    try {
        const response = await axiosInstance.put(
            `/group/ccgroups/${groupId}/`,
            form_data,
            {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});



export const deleteGroup = createAsyncThunk(
    "deleteGroup",
    async (groupId) => {
        try {
            const response = await axiosInstance.delete(`/group/ccgroups/${groupId}/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

/* group status change api like private or public group */
export const groupStatusChange = createAsyncThunk(
    "groupStatusChange",
    async (groupId) => {
        try {
            const response = await axiosInstance.post(`/group/ccgroups/${groupId}/toggle-type/`, {});
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const groupJoin = createAsyncThunk(
    "groupJoin",
    async (groupId) => {
        try {
            const response = await axiosInstance.post(`/group/groups/${groupId}/join/`, {});
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const groupLeave = createAsyncThunk(
    "groupLeave",
    async (groupId) => {
        try {
            const response = await axiosInstance.post(`/group/groups/${groupId}/leave/`, {});
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);


export const getAllRequest = createAsyncThunk(
    "getAllRequest",
    async ({ groupId }) => {
        try {
            const response = await axiosInstance.get(`/group/groups/${groupId}/join-requests/`);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);

export const acceptRequest = createAsyncThunk(
    "acceptRequest",
    async ({ groupId, requestedId, data }) => {
        try {
            const response = await axiosInstance.post(`/group/groups/${groupId}/join-requests/${requestedId}/manage/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }
);



export const GroupSlice = createSlice({
    name: "GroupSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        // create group action promises
        builder.addCase(createGroup.pending, (state) => {
            state.loading = true;
            state.data = []
        });
        builder.addCase(createGroup.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(createGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // // get group list action promises
        // builder.addCase(getGroupList.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(getGroupList.fulfilled, (state, action) => {
        //     state.data = action.payload;
        //     state.loading = false;
        // });
        // builder.addCase(getGroupList.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // });

        // get group list action promises
        builder.addCase(getGroupByUserId.pending, (state) => {
            state.loading = true;
            state.data = []

        });
        builder.addCase(getGroupByUserId.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getGroupByUserId.rejected, (state, action) => {
            state.loading = false;
            console.log(action.payload, "payload")
            state.error = action.payload;
        });
        // get group action promises
        builder.addCase(getGroupDetails.pending, (state) => {
            state.loading = true;
            state.data = []
        });
        builder.addCase(getGroupDetails.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getGroupDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get group action promises
        builder.addCase(updateGroup.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateGroup.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(updateGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get group action promises
        builder.addCase(getAllRequest.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllRequest.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getAllRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get acceptRequest promises
        builder.addCase(acceptRequest.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(acceptRequest.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(acceptRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default GroupSlice.reducer;
