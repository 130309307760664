import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";


export const reportGroup = createAsyncThunk("reportGroup", async ({ groupId, data }) => {
    try {
        const response = await axiosInstance.post(
            `/group/groups/${groupId}/report/ `, data
        );
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});


export const GroupReportSlice = createSlice({
    name: "GroupReportSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(reportGroup.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(reportGroup.fulfilled, (state, action) => {
            state.data = action;
            state.loading = false;
        });
        builder.addCase(reportGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default GroupReportSlice.reducer;
