import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { IoArrowBackCircle } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import { FaRegPlusSquare } from "react-icons/fa";
import { TbPhotoEdit } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
    createGroupPost,
    getSinglePost,
    updateGroupPost,
} from "../../Redux/ApiSlice/Group/GroupPostsSlice";
import toast, { Toaster } from "react-hot-toast";
import { getGroupDetails } from "../../Redux/ApiSlice/Group/GroupSlice";

const CreatedGroupPost = () => {
    const params = useParams();
    const { groupId, postId } = params;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const groupDetails = useSelector((state) => state.group);

    const [showMenu, setShowMenu] = useState(false);
    const [isCheckedComment, setIsCheckedComment] = useState(false);
    const [isCheckedShare, setIsCheckedShare] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // State to store selected image
    const [selectedImageFile, setSelectedImageFile] = useState(null); // State to store selected image
    const [selectedVideo, setSelectedVideo] = useState(null); // State to store selected image
    const [selectedVideoFile, setSelectedVideoFile] = useState(null); // State to store selected image
    const [showImage, setShowImage] = useState(null);
    const [caption, setCaption] = useState("");
    const [groupName, setGroupName] = useState("");
    const [showToast, setShowToast] = useState(false);

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        // Store selected image in state
        const file = event.target.files[0];
        if (!file) return;
        const fileUrl = URL.createObjectURL(file);
        if (file.type === "video/mp4") {
            setSelectedVideoFile(file);
            setSelectedVideo(fileUrl);
            setSelectedImageFile(null);
            setSelectedImage(null);
            setOpen(!open);
            setShowImage(true);
            return;
        } else {
            setSelectedImageFile(file);
            setSelectedImage(fileUrl);
            setSelectedVideoFile(null);
            setSelectedVideo(null);
            setOpen(!open);
            setShowImage(true);
        }
    };

    const handleSubmit = async () => {
        setShowToast(true);
        const toastId = toast.loading("Creating Post...");

        const form_data = new FormData();
        if (selectedImageFile) {
            form_data.append("image", selectedImageFile);
        }
        if (selectedVideoFile) {
            form_data.append("video", selectedVideoFile);
        }
        form_data.append("post_description", caption);
        form_data.append("comment_enabled", isCheckedComment);
        form_data.append("share_enabled", isCheckedShare);

        try {
            const response = await dispatch(createGroupPost({ groupId, form_data }));
            if (response.meta.requestStatus === "fulfilled") {
                toast.success("Post Created Successfully", { id: toastId });

                setTimeout(() => {
                    navigate(`/createdgroup/${groupId}`);
                }, [2000]);
            }
        } catch (error) {
            console.error("Error:", error.response.data);
            toast.error("Failed to Create Post", { id: toastId });
        }
    };

    const handleUpdate = async () => {
        setShowToast(true);
        const toastId = toast.loading("Updating Post...");

        const form_data = new FormData();
        if (selectedImageFile) {
            form_data.append("image", selectedImageFile);
        }
        if (selectedVideoFile) {
            form_data.append("video", selectedVideoFile);
        }
        form_data.append("post_description", caption);
        form_data.append("comment_enabled", isCheckedComment);
        form_data.append("share_enabled", isCheckedShare);

        try {
            const response = await dispatch(updateGroupPost({ postId, form_data }));
            if (response.meta.requestStatus === "fulfilled") {
                toast.success("Post Updated Successfully", { id: toastId });

                setTimeout(() => {
                    navigate(`/createdgroup/${groupId}`);
                }, [2000]);
            }
        } catch (error) {
            console.error("Error:", error.response.data);
            toast.error("Failed to Update Post", { id: toastId });
        }
    };

    const handleGetPost = useCallback(async () => {
        try {
            const response = await dispatch(getSinglePost({ postId }));
            const result = response?.payload;
            setCaption(result.post_description);
            setIsCheckedComment(result?.comment_enabled);
            setIsCheckedShare(result?.share_enabled);
            setSelectedImage(result?.media?.image_url);
            setSelectedVideo(result?.media?.video_url);
            setShowImage(true);
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }, []);

    const getGroupData = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails(groupId));
            setGroupName(response?.payload?.group_name);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    useEffect(() => {
        getGroupData();
        if (postId) {
            handleGetPost();
        }
    }, [postId]);

    return (
        <div className="bg-white dark:bg-black h-full overflow-y-scroll">
            <Toaster position="top-right" />
            <p className="absolute top-0 left-1/2 transform -translate-x-1/2 text-center text-[#ff3b3b] font-bold md:text-[10px]"            >
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="grid grid-cols-12 px-3 pt-3 items-center">
                {/* <p className="text-[#ff3b3b] text-center font-bold text-[12px] md:text-[10px]">
                        CC GROUP OWNER POST MEDIA - FE - V2 - JUNE 25, 2024
                    </p> */}
                <div className="col-span-2">
                    <IoArrowBackCircle
                        className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="col-span-8 text-center">
                    <p className="text-black dark:text-white text-[20px] md:text-[16px]">{groupName}</p>
                </div>
                <div className="col-span-2 flex justify-end">
                    {showMenu ? (
                        <p
                            className="text-black dark:text-white hover:text-red-600 text-[12px] font-bold cursor-pointer"
                            onClick={() => setShowMenu(false)}
                        >
                            Close
                        </p>
                    ) : (
                        <HiDotsVertical
                            className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                            onClick={() => setShowMenu(!showMenu)}
                        />
                    )}
                </div>
            </div>

            {showMenu && (
                <div className="absolute top-8 right-2 bg-white border border-gray-300 z-50 rounded">
                    <p
                        onClick={() => navigate(-1)}
                        className="text-black text-[12px] px-2 py-0.5 cursor-pointer border-b border-gray-300"
                    >
                        Draft
                    </p>
                    <p
                        onClick={() => navigate(-1)}
                        className="text-black text-[12px] px-2 py-0.5 cursor-pointer"
                    >
                        Delete
                    </p>
                </div>
            )}
            <div className="px-3 pt-2 mt-1 border-t border-b border-gray-300 dark:border-gray-700">
                <div className="flex items-center gap-4">
                    <p className="uppercase text-black dark:text-white font-bold text-[20px] md:text-[18px]">
                        Add Media
                    </p>
                    {!postId && (
                        <GoPlusCircle className="text-black dark:text-white text-[20px] md:text-[18px] cursor-pointer" />
                    )}
                </div>
                <div className="pt-1">
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/* , video/*"
                        className="hidden"
                        onChange={handleFileInputChange}
                    />

                    {!showImage && !postId && (
                        <div className="flex justify-center items-center bg-gray-300 h-[25vh] md:h-[20vh] w-full object-cover rounded">
                            <FaRegPlusSquare
                                onClick={handleIconClick}
                                className="text-gray-600 text-[20px] cursor-pointer"
                            />
                        </div>
                    )}
                    {showImage && (
                        <div className="flex flex-col">
                            <div className="relative group">
                                {selectedImage && (
                                    <img
                                        src={selectedImage}
                                        className="h-[25vh] md:h-[20vh] w-full object-cover rounded"
                                    />
                                )}
                                {selectedVideo && (
                                    <video
                                        muted
                                        autoPlay
                                        src={selectedVideo}
                                        className="h-[25vh] md:h-[20vh] w-full object-cover rounded"
                                    />
                                )}

                                {!postId && (
                                    <div className="absolute inset-0 z-10 items-center justify-center hidden h-[25vh] md:h-[20vh] rounded w-full object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                                        <button className="flex items-center justify-center  h-2 w-2 text-center  cursor-pointer ring-1 ring-transparent ">
                                            <TbPhotoEdit color="white" onClick={handleIconClick} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="pt-3">
                    <p className="uppercase text-black dark:text-white font-bold text-[20px] md:text-[18px]">
                        Add Caption
                    </p>
                    <textarea
                        rows="4"
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                        placeholder="Type Something"
                        className="w-full border border-gray-400 p-[8px] focus:outline-none resize-none rounded-md"
                    />
                </div>
                <div className="pt-3">
                    <p className="uppercase text-black dark:text-white font-bold text-[20px] md:text-[18px]">
                        Settings
                    </p>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-12 items-center">
                            <div className="col-span-5">
                                <p className="text-black dark:text-white text-[16px] md:text-[14px]">
                                    Comments
                                </p>
                            </div>
                            <div className="col-span-7">
                                <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                    <input
                                        type="checkbox"
                                        name="autoSaver"
                                        className="sr-only"
                                        checked={isCheckedComment}
                                        onChange={() => setIsCheckedComment(!isCheckedComment)}
                                    />
                                    <span
                                        className={`slider mr-3 flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 
                                            ${isCheckedComment ? "bg-[#3ccc2f]" : "bg-gray-300 dark:bg-white"}`
                                        }
                                    >
                                        <span
                                            className={`dot h-[13px] w-[13px] rounded-full bg-white dark:bg-black duration-200 
                                                ${isCheckedComment ? "translate-x-5" : ""}`
                                            }
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-center">
                            <div className="col-span-5">
                                <p className="text-black dark:text-white text-[16px] md:text-[14px]">Share</p>
                            </div>
                            <div className="col-span-7">
                                <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                    <input
                                        type="checkbox"
                                        name="autoSaver"
                                        className="sr-only"
                                        checked={isCheckedShare}
                                        onChange={() => setIsCheckedShare(!isCheckedShare)}
                                    />
                                    <span
                                        className={`slider mr-3 flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 
                                            ${isCheckedShare ? "bg-[#3ccc2f]" : "bg-gray-300 dark:bg-white"}`
                                        }
                                    >
                                        <span
                                            className={`dot h-[13px] w-[13px] rounded-full bg-white dark:bg-black duration-200 
                                                ${isCheckedShare ? "translate-x-5" : ""}`
                                            }
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-2 flex justify-between items-center">
                    <div className="flex items-center">
                        <label
                            for="default-checkbox"
                            className="me-2 text-[16px] md:text-[14px] font-medium text-black dark:text-white"
                        >
                            Poll
                        </label>
                        <input
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-indigo-600 bg-gray-100 border-none rounded cursor-pointer"
                        />
                    </div>
                    <div className="flex items-center">
                        <label
                            for="default-checkbox"
                            className="me-2 text-[16px] md:text-[14px] font-medium text-black dark:text-white"
                        >
                            Links
                        </label>
                        <input
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-indigo-600 bg-gray-100 border-none rounded cursor-pointer"
                        />
                    </div>
                    <button
                        className="bg-black dark:bg-white text-white dark:text-black px-3 py-[2px] rounded"
                        onClick={() => (postId ? handleUpdate() : handleSubmit())}
                    >
                        {postId ? "Update" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreatedGroupPost;
