import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { IoArrowBackCircle } from 'react-icons/io5'
import CreatedGroupSliderMenu from '../../Component/CreatedGroup/CreatedGroupSliderMenu'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroupPost, getSinglePost } from '../../Redux/ApiSlice/Group/GroupPostsSlice';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Card } from '@material-tailwind/react';
import { saveGroupPost } from '../../Redux/ApiSlice/Group/GroupPostSaveSlice';
import toast, { Toaster } from 'react-hot-toast';
import { useOutsideClick } from '../../Hooks/useOutsideClick';
import { GoMute, GoUnmute } from 'react-icons/go';
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa';
import ReactPlayer from 'react-player';

export default function GroupSinglePost() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { groupId, postId } = params;

    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const groupPosts = useSelector((state) => state.groupPosts)
    const { User_profile } = ProfileDetails;
    const [postData, setPostData] = useState({});
    const [showMenu, setShowMenu] = useState(null)
    const menuRef = useRef()


    const getGroupPostData = useCallback(async () => {
        try {
            await dispatch(getSinglePost({ postId }))
        } catch (error) {
            console.log(error)
        }
    }, [dispatch, postId])

    console.log(groupPosts, "groupPosts", postData)
    useEffect(() => {
        setPostData(groupPosts?.data)
    }, [groupPosts?.data])


    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };


    function timeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);
  
        const intervals = {
           year: 31536000, // 60 * 60 * 24 * 365
           month: 2592000, // 60 * 60 * 24 * 30
           day: 86400,     // 60 * 60 * 24
           hour: 3600,     // 60 * 60
           minute: 60,
           second: 1
        };
  
        for (const key in intervals) {
           const interval = Math.floor(seconds / intervals[key]);
           if (interval >= 1) {
              return `${interval}${key[0]} ago`;
           }
        }
  
        return "just now";  // In case it's less than a second ago
     }
  


    useOutsideClick(menuRef, () => setShowMenu(null));

    useEffect(() => {
        getGroupPostData()
    }, [getGroupPostData])


    return (
        <Fragment>
            <Toaster position='top-right' />
            <div className='w-full h-full bg-white dark:bg-black'>
                <div className='grid grid-cols-12 w-full p-2 sticky top-0 z-40 bg-white dark:bg-black'>
                    <p className="col-span-12 text-[#ff3b3b] text-center font-bold text-[8px] uppercase">
                        Copyright © 2025 Galileo One Inc
                    </p>
                    <div className='col-span-12 flex justify-start items-center'>
                        <IoArrowBackCircle
                            className="text-black dark:text-white text-[30px] md:text-[24px] cursor-pointer"
                            onClick={() => navigate(-1)}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 w-full p-2 sticky top-0 z-40 bg-white dark:bg-black'>

                    <div className='col-span-1 flex justify-start items-center'>
                        <img src={postData?.created_by?.avatar_url} alt="profile" className="w-7 h-7 rounded-full object-cover" />
                    </div>
                    <div className='col-span-8 flex flex-col  justify-start px-2'>
                        <p className='text-black dark:text-white font-semibold text-[13px]'>{postData?.created_by?.first_name} {postData?.created_by?.last_name}</p>
                        <p className='text-blue-800 font-bold text-[10px]'>{timeAgo(postData?.created_at)}</p>
                    </div>
                    <div className='col-span-3 flex justify-end items-center relative'>
                        <BsThreeDotsVertical className='text-[1.2rem] text-black dark:text-white cursor-pointer' onClick={() => setShowMenu(true)} />
                        {showMenu && (
                            <Card
                                className="w-24 absolute top-4 right-4 rounded-md p-1 text-black bg-white z-40"
                                ref={menuRef}
                            >
                                <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]" >
                                    {postData?.saved_status ? "Unsave" : " Save"}
                                </p>
                                <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]" /* onClick={() => handleHide(postData?.id) } */    >
                                    Hide
                                </p>
                                <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                    Block User
                                </p>
                                <p
                                    className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                    onClick={() => navigate(`/createdgroup/${groupId}/postreport/${postData?.id}`)}
                                >
                                    Report
                                </p>
                                {User_profile?.id === postData?.created_by?.id && <p
                                    className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"

                                >
                                    Delete
                                </p>}
                            </Card>
                        )}


                    </div>
                    <div className='col-span-12 flex justify-start items-center py-1'>
                        <p className='text-black dark:text-white text-[13px]'>{postData?.post_description}</p>
                    </div>
                    <div className='col-span-12 flex justify-start items-center'>
                        {postData?.image_url && <img src={postData?.image_url} alt="post" className="w-full h-96 object-cover" />}


                        {postData?.video_url &&
                            <div className="mt-1 rounded-sm relative z-30" >
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    muted={muted}
                                    url={postData?.video_url}
                                    className="cursor-pointer"
                                    playing={playing[postData?.id] || false}
                                    onProgress={(state) => handleProgress(postData?.id, state)}
                                    onDuration={(duration) => handleDuration(postData?.id, duration)}
                                    onClick={() => navigate(`/group/${groupId}/post/${postData?.id}/`)}
                                />
                                <button
                                    onClick={() => handlePlayPause(postData?.id)}
                                    className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                >
                                    {playing[postData?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                </button>
                                <button
                                    onClick={handleMute}
                                    className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                >
                                    {muted ? <GoMute /> : <GoUnmute />}
                                </button>
                                <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                    {formatDuration(duration[postData?.id] - playedSeconds[postData?.id] || 0)}
                                </button>
                            </div>
                        }


                    </div>
                </div>
            </div>
        </Fragment>
    )
}
