import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PostModal from "../../Component/videopage/PostModal";
import ProfileAccountModal from "../../Component/Modals/HomePageModal/ProfileAccountModal";
import { VideoCardFooterIcons } from "../../Datas/Datas";
import HomePagePannel from "../../Components/Panels/HomePagePanel";
import SecretSauceButton from "../../Component/SecretSauceButton/SecretSauceButton";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import Footer from "../../Component/Footer/Footer";

export default function WatchVideo() {
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();
    const getAllPost = useSelector((state) => state.WatchVideo);
    const { videoData } = getAllPost;
    const [setLikeLikeEmoji] = useState(false);

    const hashTag = "#crazy #money #plane #toy";
    const spilThashTags = hashTag.split("#");
    const summary = " Guess how much this dude spent on this?";
    const [nextButton, setNextButton] = useState(null);
    const [preButton, setPreButton] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(1);
    const [videoDataList, setVideoDataList] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    // Detect screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        handleResize(); // Check on initial render
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const selectedVideo = videoData?.find((video) => video.id === id);
        if (selectedVideo) {
            // Move the selected video to the top of the list
            const updatedVideos = [
                selectedVideo,
                ...videoData.filter((video) => video.id !== id),
            ];
            console.log(updatedVideos, "updatedVideos");
            setVideoDataList(updatedVideos);
        }
    }, [videoData]);

    const [openBottom, setOpenBottom] = useState(false);
    const openDrawerBottom = () => setOpenBottom(true);
    const closeDrawerBottom = () => setOpenBottom(false);
    const [openAccountBottom, setOpenAccountBottom] = useState(false);
    const openDrawerAccountModal = () => setOpenAccountBottom(!openAccountBottom);

    const reloadPage = () => {
        // For Reload the page
        window.location.reload();
    };

    const handleNavigate = (value) => {
        const replaceValue = value.replaceAll(" ", "");
        switch (replaceValue) {
            case "Neighborhood":
                return navigate(`/neighborhood`);
            case "YourFeed":
                return reloadPage();
            case "YourFriends":
                return reloadPage();
            case "Home":
                return navigate("/home");
            case "Createvideo":
                return openDrawerBottom();
            case "Messages":
                return navigate(`/mobileMessages`);
            case "Helmet1":
                return navigate(`/commingsoon`);
            case "Account":
                // return openDrawerAccountModal();
                return navigate(`/account`);
            case "Groups":
                return navigate(`/othergroup/list/`);
            default:
                // navigate(`/${replaceValue}`);
                break;
        }
    };

    const navigateToVideo = (newIndex) => {
        const videoId = videoDataList[newIndex]?.id;
        if (videoId) {
            navigate(`/watchvideo/${videoId}`);
        }
    };

    const handleNext = () => {
        if (id) {
            const newIndex = (currentIndex + 1) % videoDataList.length;
            navigateToVideo(newIndex);
        }

        setCurrentIndex((prevIndex) => (prevIndex + 1) % videoDataList.length);
        setDirection(1);
    };

    const handlePrev = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 1 + videoDataList.length) % videoDataList.length
        );
        const newIndex = (currentIndex - 1) % videoDataList.length;
        navigateToVideo(newIndex);
        setDirection(-1);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
                if (currentIndex !== 0) {
                    handlePrev();
                }
            } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
                if (currentIndex !== videoDataList.length - 1) {
                    handleNext();
                }
            }
        };
        // Add event listener for arrow keys
        window.addEventListener("keydown", handleKeyDown);

        // Clean up event listener
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentIndex, videoDataList]); // Add dependencies to ensure the latest state is used

    return (
        <>
            <div className="hidden md:flex  h-full text-center items-center md:w-[10%] mr-4">
                <button
                    disabled={currentIndex === 0}
                    onClick={handlePrev}
                    className={`w-20 top-2/4  h-16 bg-blue-gray-200 hover:bg-[#b3acac] rounded-full  items-center text-center justify-center flex disabled:hidden`}
                >
                    <BiSolidLeftArrow />
                </button>
            </div>
            <div className="md:w-[53%] w-full md:h-[88vh] bg-black border-black md:border-y-[1px] relative md:rounded-[30px] md:border-x-[1px]  overflow-hidden">
                <div className=" h-[85%] md:h-[85%] rounded-none  relative">
                    <HomePagePannel
                        AllPosts={videoDataList}
                        preButton={preButton}
                        summary={summary}
                        setLikeLikeEmoji={setLikeLikeEmoji}
                        spilThashTags={spilThashTags}
                        setNextButton={setNextButton}
                        setPreButton={setPreButton}
                        id={id}
                        itsNotHome={true}
                        setCurrentIndex={setCurrentIndex}
                        currentIndex={currentIndex}
                        setDirection={setDirection}
                        direction={direction}
                        isMobile={isMobile}
                    />
                </div>

                <SecretSauceButton />

                <div className="h-[5.5vh] bg-black dark:bg-black">
                    <Footer
                        isFixedDark={true}
                        handleNavigate={handleNavigate}
                        openDrawerBottom={openDrawerBottom}
                        VideoCardHeaderIcons={VideoCardFooterIcons}
                    />
                </div>

                <PostModal
                    openBottom={openBottom}
                    openDrawerBottom={openDrawerBottom}
                    closeDrawerBottom={closeDrawerBottom}
                />

                <ProfileAccountModal
                    openBottom={openAccountBottom}
                    openDrawerBottom={openDrawerAccountModal}
                    closeDrawerBottom={openDrawerAccountModal}
                />
            </div>
            <div className="hidden  md:flex  h-full text-center items-center md:w-[10%] ml-4">
                <button
                    onClick={handleNext}
                    disabled={currentIndex === videoDataList.length - 1}
                    className={`w-20 top-2/4  h-16 right-0 bg-blue-gray-200 hover:bg-[#b3acac] rounded-full  items-center text-center justify-center flex disabled:hidden`}
                >
                    <BiSolidRightArrow />
                </button>
            </div>
        </>
    );
}
