import React, { Fragment } from "react";
import DesktopNav from "./DesktopNav";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircleSharp } from "react-icons/io5";

const Security = ({ isMobile }) => {
   const navigate = useNavigate();
   return (
      <Fragment>
         <div className="px-3 dark:text-white">
            <p className="text-center font-bold text-[21px] mt-2">
               <IoCaretBackCircleSharp
                  onClick={() => navigate(-1)}
                  className="cursor-pointer dark:text-black dark:bg-white rounded-full" 
               />
            </p>
            <p className="text-center font-bold text-[22px] mb-1">Security</p>
            <p className="text-justify text-[12px]">
               You to setup MFA, last seen and logged in status, deactivate or delete
               your accounts from here.
            </p>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Multifactor Authentication</p>
               <p className="text-justify text-[12px] mt-1">
                  Enabling two-factor authentication makes it extra difficult for
                  anyone other than you to access your account.
               </p>
               <p className="text-justify text-[12px] mt-1">
                  In addition to using your EMAIL and PASSWORD to log in, you'll enter
                  a SECURITY CODE generated by an authenticator app or sent to you as
                  a text message. Once enabled, you will be logged out of all other
                  devices except this one for security.
               </p>
               <button className="bg-black dark:text-white dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black px-3 py-1 rounded-full text-white text-[12px] mt-2">
                  Add Secondary Email
               </button>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Session History</p>
               <p className="text-justify text-[12px] mt-1">
                  Review recent login activity ON your account. Revoke or logout from
                  any sessions that you do not recognize and immediately change your
                  password.
               </p>
               <table className="w-full mt-2 text-left">
                  <tr className="border-spacing-4 text-[10px] border-b-2 border-gray-700">
                     <th>Last Seen</th>
                     <th>Device</th>
                     <th>IP</th>
                     <th>Location</th>
                     <th>Status</th>
                  </tr>
                  <tr className="text-[#126C02] text-[8px]">
                     <td>Just Now</td>
                     <td>Edge 107, Window 10</td>
                     <td>103.159.35.181</td>
                     <td>newyork, US</td>
                     <td>Sign out </td>
                  </tr>
                  <tr className="text-[8px]">
                     <td>Nov 10, 2024</td>
                     <td>Edge 107, Window 10</td>
                     <td>43.159.35.181</td>
                     <td>Lansing, US</td>
                     <td>Sign out </td>
                  </tr>
                  <tr className="text-[8px]">
                     <td>Nov 09, 2024</td>
                     <td>Edge 106, Window 10</td>
                     <td>42.21.35.181</td>
                     <td>Detroit, US</td>
                     <td>Sign out </td>
                  </tr>
                  <tr className="text-[8px]">
                     <td>Nov 08, 2024</td>
                     <td>Safari 13.2, Mac 14.2</td>
                     <td>45.159.35.181</td>
                     <td>Jackson, US</td>
                     <td>Sign out </td>
                  </tr>
               </table>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Deactivate Account</p>
               <p className="text-justify text-[12px] mt-1">
                  If you wish to deactivate your account, you will be asked for
                  confirmation. When you DEACTIVATE your account:
               </p>
               <ul className="text-[11px] mt-1 list-disc pl-8">
                  <li>Your account will be inactive, until you reopen it.</li>
                  <li>Your profile will no longer appear anywhere on CC.</li>
                  <li>Your account settings will remain intact.</li>
               </ul>
               <p className="text-justify text-[12px] mt-1">
                  You can REACTIVATE your account anytime, just sign back in to Chaa Ching .
                  Learn More
               </p>
               <button className={`bg-black dark:text-white dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black px-3 py-1 rounded-full text-white ${isMobile ? "text-[10px]" : "text-[12px]"} mt-3`}>
                  Deactivate account
               </button>
            </div>
            <div className="mt-5">
               <p className="text-start font-bold text-[16px]">Deactivate Account</p>
               <p className="text-justify text-[12px] mt-1">
                  If you wish to delete your account, you will be asked for
                  confirmation. Deleting your account will remove all of your content
                  and data associated with it. This is in line with GDPR regulations.{" "}
                  <br />
                  Before deleting, we will deactivate your account for a period of 14
                  days. In this period if you decide you do not want to quit Chaa Ching , just
                  login back and you are good to go. On the 14th day we will send you
                  a reminder email, if you still wish to delete your account, please
                  ignore that email. Learn More If you later decide to use CC again,
                  you’ll need to create a new account. We recommend saving your
                  profile data so you have a copy of your journey inside CC.
               </p>
               <div className="flex items-center gap-4 mt-3 mb-3">
                  <button className={`bg-black dark:text-white dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black    px-3 py-1 rounded-full text-white ${isMobile ? "text-[10px]" : "text-[12px]"}`}>
                     Delete account
                  </button>
                  <p className="text-[12px] font-semibold underline cursor-pointer text-[#232323] dark:text-[#897bff]">
                     Deactivate account instead
                  </p>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Security;
