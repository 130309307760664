import React, { useState, useRef, useEffect } from "react";
import Comment from "./Comment";
import { IoClose } from "react-icons/io5";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { CiBookmarkPlus, CiSearch } from "react-icons/ci";
import { FaHeart, FaRegHeart, FaRegComment, FaRegShareSquare } from "react-icons/fa";
import { commentsData, neighborhoodNewsData } from "../../data";

const PinboardPopup = ({ pinPost, onClose }) => {
    const [likes, setLikes] = useState({});
    const [isBookmarks, setIsBookmarks] = useState(false);
    const [sharePopupOpen, setSharePopupOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [commentPopupOpen, setCommentPopupOpen] = useState(false);
    const sharePopupRef = useRef(null);
    const commentPopupRef = useRef(null);

    const handleLikeToggle = (id) => {
        setLikes((prevLikes) => ({
            ...prevLikes,
            [id]: !prevLikes[id],
        }));
    };

    const handleClickOutside = (event) => {
        if (sharePopupRef.current && !sharePopupRef.current.contains(event.target)) {
            setSharePopupOpen(false);
        }
        if (commentPopupRef.current && !commentPopupRef.current.contains(event.target)) {
            setCommentPopupOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filteredResults = neighborhoodNewsData.filter((data) =>
        data.firstName.toLowerCase().includes(searchInput.toLowerCase())
    );

    if (!pinPost) return null;

    return (
        <div className="absolute inset-0 h-[60vh] bg-black bg-opacity-70 flex items-center justify-center z-50" onClick={onClose}>
            <div className="bg-white dark:bg-[#2d2b2b] dark:text-gray-300 rounded-lg relative h-[45vh] w-[95%] overflow-y-scroll" onClick={(e) => e.stopPropagation()}>
                <div className="px-3 pt-4">
                    <IoClose
                        onClick={onClose}
                        className="absolute top-3 right-2 text-[24px] cursor-pointer"
                    />
                    <div className="flex items-center mb-2">
                        <img
                            alt={pinPost.userName}
                            src={pinPost.profileImage}
                            className="w-10 h-10 object-cover rounded-full"
                        />
                        <div className="flex flex-col ml-2">
                            <p className="text-[12px] font-bold">{pinPost.userName}</p>
                            <p className="text-[10px] text-gray-500">10 min ago</p>
                        </div>
                    </div>
                    <p className="text-[12px] font-bold">{pinPost.title}</p>
                    <p className="text-[10px] leading-[15px] my-1">{pinPost.desc}</p>
                    <img
                        alt={pinPost.title}
                        src={pinPost.postimage}
                        className="w-full h-[160px] object-cover rounded-lg bg-gray-400"
                    />
                </div>
                <div className="flex items-center justify-between pb-2 pt-1 mx-3 border-b border-gray-400 dark:">
                    <div className="flex items-center gap-x-4 relative">
                        <div className="flex flex-col items-center">
                            {likes[pinPost.id] ? (
                                <FaHeart className="text-[16px] text-red-600 cursor-pointer" onClick={() => handleLikeToggle(pinPost.id)} />
                            ) : (
                                <FaRegHeart className="text-[16px] text-gray-600 dark:text-gray-300 cursor-pointer" onClick={() => handleLikeToggle(pinPost.id)} />
                            )}
                            <p className="text-[8px] text-gray-500">2</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <FaRegComment className="text-[16px] text-gray-600 dark:text-gray-300 cursor-pointer" onClick={() => setCommentPopupOpen(true)} />
                            <p className="text-[8px] text-gray-500">12</p>
                        </div>
                        <div className="flex flex-col items-center" onClick={() => setSharePopupOpen(!sharePopupOpen)}>
                            <FaRegShareSquare className="text-[16px] text-gray-600 dark:text-gray-300 cursor-pointer" />
                            <p className="text-[8px] text-gray-500">6</p>
                        </div>
                        {sharePopupOpen && (
                            <div ref={sharePopupRef} className="absolute left-24 -top-10 p-1 w-[10rem] bg-white dark:bg-[#2b2b2b] border border-gray-400 overflow-hidden rounded shadow-lg">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="search"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        className="w-full h-6 pl-2 pr-6 py-[2px] border dark:bg-[#2b2b2b] border-gray-300 placeholder:text-[12px] text-[10px] rounded-lg focus:outline-none"
                                    />
                                    <CiSearch className="absolute right-1 top-1/2 transform -translate-y-1/2 transition-transform text-gray-500 hover:text-gray-700 text-[18px] cursor-pointer" />
                                </div>
                                <div className="flex items-center justify-between p-1">
                                    <div className="flex items-center gap-x-3 overflow-x-scroll">
                                        {filteredResults.length > 0 ? (
                                            filteredResults.map((data) => (
                                                <div className="flex flex-col items-center cursor-pointer" key={data.id}>
                                                    <div className="h-6 w-6">
                                                        <img
                                                            alt={data.firstName}
                                                            src={data.profileImage}
                                                            className="w-6 h-6 object-cover rounded-full"
                                                        />
                                                    </div>
                                                    <p className="text-[8px]">{data.firstName}</p>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-[8px] text-gray-500">No results found</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {isBookmarks ? (
                        <BsBookmarkCheckFill className="text-[18px] text-gray-600 dark:text-gray-300 mr-[-2px] cursor-pointer" onClick={() => setIsBookmarks(!isBookmarks)} />
                    ) : (
                        <CiBookmarkPlus className="text-[22px] text-gray-600 dark:text-gray-300 mr-[-5px] cursor-pointer" onClick={() => setIsBookmarks(!isBookmarks)} />
                    )}
                </div>
                <div className="px-1 overflow-y-scroll h-[30vh]">
                    <Comment comments={commentsData} />
                </div>
                {commentPopupOpen && (
                    <div className="absolute inset-0 flex items-center justify-center h-[80vh] bg-black bg-opacity-60 z-20">
                        <div ref={commentPopupRef} className="bg-white dark:bg-[#2b2b2b] p-4 m-2 rounded shadow-lg w-80">
                            <div className="flex justify-between items-center mb-2">
                                <p className="text-[14px] font-bold">Add a Comment</p>
                                <IoClose 
                                    onClick={() => setCommentPopupOpen(false)}
                                    className="text-[20px] text-gray-900 dark:text-white hover:text-red-700 cursor-pointer"
                                />
                            </div>
                            <textarea
                                rows={4}
                                placeholder="Write your comment..."
                                className="w-full p-2 border border-gray-400 dark:border-gray-800 dark:bg-[#2b2b2b] rounded resize-none focus:outline-none text-[12px]"
                            />
                            <button className="mt-2 bg-gray-900 hover:bg-black text-[12px] text-white px-4 py-1 rounded transition-colors duration-500">
                                Comment
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PinboardPopup;