import React, { useState } from "react";
import { CiStreamOn } from "react-icons/ci";
import { GiThreeFriends } from "react-icons/gi";
import { FaBasketShopping } from "react-icons/fa6";
import VideoCardIconList from "./VideoCardIconList";
import PostModal from "../../../Component/videopage/PostModal";
import { MdMessage, MdOutlineFeed } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { VideoCardFooterIcons } from "../../../Datas/Datas";
import ProfileAccountModal from "../../../Component/Modals/HomePageModal/ProfileAccountModal";
import SecretSauceButton from "../../../Component/SecretSauceButton/SecretSauceButton";
import Footer from "../../../Component/Footer/Footer";

export default function MobileLayout({ children }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const uid = queryParams.get("uid");
    const token = queryParams.get("token");
    const [openBottom, setOpenBottom] = useState(false);
    const openDrawerBottom = () => setOpenBottom(true);
    const closeDrawerBottom = () => setOpenBottom(false);
    const navigate = useNavigate();
    const param = useParams();
    const { id } = param;
    const [openAccountBottom, setOpenAccountBottom] = useState(false);

    const openDrawerAccountModal = () => setOpenAccountBottom(true);
    const CloseDrawerAccountModal = () => setOpenAccountBottom(false);
    const accessToken = localStorage.getItem("accessToken");

    const handleCheckPath = () => {
        switch (location.pathname) {
            case "/home":
                return true;
            default:
                return false;
        }
    };
    const reloadPage = () => {
        navigate("/home");
    };

    const handleNavigate = (value) => {
        const replaceValue = value.replaceAll(" ", "");
        switch (replaceValue) {
            case "Neighborhood":
                return navigate(`/neighborhood`);
            case "YourFeed":
                return reloadPage();
            case "YourFriends":
                return reloadPage();
            case "Home":
                return navigate("/home");
            case "Createvideo":
                return openDrawerBottom();
            case "Messages":
                return navigate(`/mobileMessages`);
            case "Helmet1":
                return navigate(`/commingsoon`);
            case "Account":
                // return openDrawerAccountModal();
                return navigate(`/account`);
            case "Groups":
                return navigate(`/othergroup/list/`);
            default:
                // navigate(`/${replaceValue}`);
                break;
        }
    };

    const url = "https://thtsusa.com/changePassword?uid=YWZlZjIwZDYtOTFmNC00MGFjLTlkMjktMTY0YTM4ODkwYzA4&token=clhh7h-083b60ef8d3e3a59dae6a897cbdec29e";
    console.log(url.includes("changePassword"));

    const handleCheckPathName = () => {
        const replaceValue = location.pathname;
        if (uid && token) {
            return false;
        }
        switch (replaceValue) {
            case "/entrypage":
                return false;
            case "/uploadvideo":
                return false;
            case "/login":
                return false;
            case "/signup":
                return false;
            case "/register":
                return false;
            case "/resetpassword":
                return false;
            case replaceValue?.includes("changePassword"):
                return false;
            case "/":
                return false;
            case "/help":
                return accessToken ? true : false;
            case `/get-questions/${id}`:
                return accessToken ? true : false;
            case "/readmore-answer":
                return accessToken ? true : false;
            case "/enterotp":
                return false;
            default:
                return true;
        }
    };

    const HeaderIcons = [
        {
            icon: (
                <FaBasketShopping
                    color={`${location.pathname === "/mobileMessages" ||
                        location.pathname === "/searchresultpage"
                        ? "white"
                        : "black"
                        }`}
                    className="h-[20px] w-[20px]"
                />
            ),
            placeholder: "Shop",
        },
        {
            icon: (
                <MdMessage
                    color={`${location.pathname === "/mobileMessages" ||
                        location.pathname === "/searchresultpage"
                        ? "white"
                        : "black"
                        }`}
                    className="h-[20px] w-[20px]"
                />
            ),
            placeholder: "Messages",
        },
        {
            icon: (
                <MdOutlineFeed
                    color={`${location.pathname === "/mobileMessages" ||
                        location.pathname === "/searchresultpage"
                        ? "white"
                        : "black"
                        }`}
                    className="h-[20px] w-[20px]"
                />
            ),
            placeholder: "Your Feed",
        },

        {
            icon: (
                <GiThreeFriends
                    color={`${location.pathname === "/mobileMessages" ||
                        location.pathname === "/searchresultpage"
                        ? "white"
                        : "black"
                        }`}
                    className="h-[20px] w-[20px]"
                />
            ),
            placeholder: "Your Friends",
        },
        {
            icon: <CiStreamOn color="green" className="h-[20px] w-[20px]" />,
            placeholder: "Live Stream",
        },
    ];

    return (
        <div className="md:w-[53%] w-full md:h-[88vh] bg-black dark:bg-black border-black md:border relative md:rounded-[20px] overflow-hidden">
            {handleCheckPath() ? (
                <div className="video-card-header top-8 md:top-4">
                    <div className="h-[3%] absolute z-50 w-full flex justify-between px-2">
                        {HeaderIcons.map((item) => (
                            <div
                                className="flex flex-col justify-center items-center"
                                onClick={() => handleNavigate(`${item.placeholder}`)}
                            >
                                <div className="cursor-pointer">{item.icon} </div>
                                <p
                                    className={`${location.pathname === "/mobileMessages" ||
                                        location.pathname === "/searchresultpage"
                                        ? "text-[#fff]"
                                        : "text-[#000]"
                                        } cursor-pointer text-[7px] mt-[2px]`}
                                >
                                    {item.placeholder}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className={`video-section dark:bg-black 
                ${handleCheckPathName() ? "h-[85%]" : "h-[100%]"}  overflow-y-scroll  overflow-hidden no-scrollbar relative`
            }>
                {children}
            </div>
            <PostModal
                openBottom={openBottom}
                openDrawerBottom={openDrawerBottom}
                closeDrawerBottom={closeDrawerBottom}
            />
            {handleCheckPathName() && (
                <>
                    <SecretSauceButton />
                    <div className="h-[5.5vh] bg-white dark:bg-black">
                        <Footer
                            isFixedDark={false}
                            handleNavigate={handleNavigate}
                            VideoCardHeaderIcons={VideoCardFooterIcons}
                        />
                    </div>
                </>
            )}
            <ProfileAccountModal
                openBottom={openAccountBottom}
                closeDrawerBottom={CloseDrawerAccountModal}
            />
        </div>
    );
}
