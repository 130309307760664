import React from "react";

const SellProductPreview = () => {
    return (
        <div className="px-2 mt-2 dark:bg-black dark:text-white">
            <p className="text-[12px] font-bold">Preview Post</p>
            <div className="mt-2 border border-gray-500 p-2 rounded-md">
                <div className="flex items-center gap-x-2">
                    <img
                        alt="user"
                        className="w-8 h-8 rounded-full object-cover"
                        src="https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                    <p className="text-[12px] font-bold">John Underwood</p>
                </div>

                <div className="mt-2">
                    <p>IPhone 15 Pro</p>
                    <img
                        alt="product"
                        src="https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg"
                        className="w-full h-40 object-contain border border-gray-300 rounded-md"
                    />
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[16px] font-bold">$1520</p>
                        <p className="text-[14px] border border-gray-700 px-2 py-1 rounded">Condition</p>
                        <p className="text-[14px] border border-gray-700 px-2 py-1 rounded">Category</p>
                    </div>
                    <p className="text-[12px] leading-[13px] mt-4">
                        Unboxing iPhone 15 Pro Max box in Natural Titanium color - (It is a good condition mobile with great battery charging) contact me if you need this
                    </p>
                </div>
            </div>
            <div className="flex items-center gap-x-2 mt-2">
                <input 
                    type="checkbox"
                    className="w-4 h-4 accent-green-400 cursor-pointer"
                />
                <p className="text-[12px]">Accept Chaa Chingg Shop condition and policies</p>
            </div>
            <div className="flex items-center gap-x-2 mt-2">
                <button className="bg-blue-500 text-white px-4 text-[12px] py-1 rounded-md">Terms and Conditions</button>
                <button className="bg-blue-500 text-white px-4 text-[12px] py-1 rounded-md">Policies</button>
            </div>
        </div>
    )
}

export default SellProductPreview