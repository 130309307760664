import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdLock } from "react-icons/md";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaSearch, FaShareAlt } from "react-icons/fa";
import { IoSearchCircle } from "react-icons/io5";
import { IoIosArrowForward, IoIosUnlock } from "react-icons/io";
import { SocialIcon } from "react-social-icons";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const CreatedGroupProfileSection = ({
    setOpen,
    handleOpenTool,
    groupLock,
    groupId,
    groupDetails,
    openDrawer,
    isDrawerOpen,
    handleLeaveGroup,
    handleJoinGroup,
}) => {
    const navigate = useNavigate();
    const menuRef = useRef(null);
    const [showShare, setShowShare] = useState(false);
    const [userPosition, setUserPosition] = useState(0);
    const [socialMediaPosition, setSocialMediaPosition] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const textRef = useRef(null);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const userData = [
        {
            id: 1,
            userImg:
                "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 2,
            userImg:
                "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 3,
            userImg:
                "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 4,
            userImg:
                "https://images.unsplash.com/photo-1574701148212-8518049c7b2c?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 5,
            userImg:
                "https://images.unsplash.com/photo-1622236297949-eb7f7aa3a3cc?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 6,
            userImg:
                "https://images.unsplash.com/photo-1578979879663-4ba6a968a50a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ];

    const socialMediaData = [
        {
            id: 1,
            iconname: "telegram",
        },
        {
            id: 2,
            iconname: "whatsapp",
        },
        {
            id: 3,
            iconname: "facebook",
        },
        {
            id: 4,
            iconname: "x",
        },
        {
            id: 5,
            iconname: "snapchat",
        },
        {
            id: 6,
            iconname: "instagram",
        },
    ];

    const handleUserClick = () => {
        setUserPosition(
            (prevPosition) => (prevPosition + 1) % (userData.length - 3)
        );
    };

    const handleSocialClick = () => {
        setSocialMediaPosition(
            (prevPosition) => (prevPosition + 1) % (socialMediaData.length - 3)
        );
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowShare(null); // Close menu if clicked outside
        }
        if (textRef.current && !textRef.current.contains(event.target)) {
            setShowMore(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="bg-white dark:bg-black pt-3 mb-2 md:mb-0 overflow-y-scroll">
            <div className="grid grid-cols-12 px-3 pb-0.5 mb-1 items-center border-b border-gray-300 dark:border-gray-700">
                <div className="col-span-2">
                    <IoArrowBackCircle
                        className="text-black dark:text-white text-[30px] md:text-[24px] cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="col-span-8 text-center">
                    <p className="text-black dark:text-white text-[20px] md:text-[17px]">
                        {groupDetails?.group_name}
                    </p>
                </div>
                <div className="col-span-2">
                    <div className="flex items-center gap-2 float-end">
                        {groupDetails?.current_user_role !== "member" ? (
                            groupLock ? (
                                <MdLock
                                    className="text-black dark:text-white text-[30px] md:text-[24px] cursor-pointer"
                                    onClick={() => setOpen(true)}
                                />
                            ) : (
                                <IoIosUnlock
                                    className="text-black dark:text-white text-[30px] md:text-[24px] cursor-pointer"
                                    onClick={() => setOpen(true)}
                                />
                            )
                        ) : null}

                        <button onClick={openDrawer}>
                            {isDrawerOpen ? (
                                <XMarkIcon
                                    className={`h-8 w-8 md:h-7 md:w-7 stroke-2 text-black dark:text-white`}
                                />
                            ) : (
                                <Bars3Icon
                                    className={`h-8 w-8 md:h-7 md:w-7 stroke-2 text-black dark:text-white`}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-12 px-3 pt-1.5 relative">
                <div className="col-span-3">
                    <img
                        alt="groupprofilepicture"
                        src={groupDetails?.group_profile_picture}
                        className="h-20 md:h-16 w-20 md:w-16 object-cover rounded-full border border-gray-300"
                    />
                </div>
                <div className="col-span-7 px-2">
                    <div className="flex gap-2 justify-between">
                        <div className="flex flex-col gap-2">
                            <button
                                onClick={() =>
                                    groupDetails?.user_joined
                                        ? handleLeaveGroup()
                                        : handleJoinGroup()
                                }
                                className="hover:bg-[#f80000c8] bg-[#F80000] text-white font-bold px-2 py-1 w-[6rem] md:w-[4.3rem] text-[16px] md:text-[12px] rounded"
                            >
                                {groupDetails?.user_joined ? "Leave" : "Join"}
                            </button>
                            <div className="flex flex-col items-center mt-[-2px]">
                                <span className="text-[18px] md:text-[15px] text-black dark:text-white font-bold">
                                    {groupDetails?.member_count}
                                </span>
                                <span className="text-[16px] md:text-[14px] text-black dark:text-white mt-[-5px]">
                                    Members
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <button
                                className="hover:bg-[#31c629c8] bg-[#31c629] text-white font-bold px-2 py-1 text-[16px] md:text-[12px] w-[6rem] md:w-[4.3rem] rounded"
                                onClick={() => handleOpenTool()}
                            >
                                Tools
                            </button>
                            <button className="hover:bg-[#712495c8] bg-[#712495] text-white font-bold px-2 py-1 text-[16px] md:text-[12px] mt-[6px] w-[6rem] md:w-[4.3rem] rounded">
                                Promote
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="flex flex-col gap-[9px] items-end justify-between">
                        <div
                            onClick={() => navigate(`/createdgroup/search/${groupId}`)}
                            className="hover:bg-[#FF6600] hover:text-white text-white dark:text-black bg-black dark:bg-white p-[6px] rounded cursor-pointer"
                        >
                            <FaSearch className="text-[20px] md:text-[14px]" />
                        </div>
                        <div
                            onClick={() => setShowShare(!showShare)}
                            className="hover:bg-[#FF6600] hover:text-white text-white dark:text-black bg-black dark:bg-white p-[6px] mt-[4px] rounded cursor-pointer"
                        >
                            <FaShareAlt className="text-[20px] md:text-[14px]" />
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-2 mb-1">
                    <p
                        ref={textRef}
                        onClick={handleShowMore}
                        className={`text-black dark:text-white text-[12px] ${showMore ? "line-clamp-none" : "line-clamp-2"} leading-[14px]`}
                    >
                        {groupDetails?.description} 
                        <span className="text-[12px] ml-1 text-semibold text-blue-500 cursor-pointer">
                            {showMore ? "Show Less" : "Show More"}
                        </span>
                    </p>
                </div>
                {showShare && (
                    <div
                        className="absolute top-0 right-1 bg-white border border-gray-300 rounded"
                        ref={menuRef}
                    >
                        <div className="flex items-center gap-2 px-1 pt-1">
                            <p className="text-black text-[13px]">Share With : </p>
                            <IoSearchCircle className="text-[24px] cursor-pointer" />
                        </div>

                        <div className="flex gap-1 px-1 relative">
                            {userData.slice(userPosition, userPosition + 4).map((item) => (
                                <div key={item.id}>
                                    <img
                                        alt="img"
                                        src={item.userImg}
                                        className="h-7 w-7 bg-black rounded-full object-cover"
                                    />
                                </div>
                            ))}
                            <p
                                className="h-7 w-7 bg-black text-white hover:bg-[#FF6600] rounded-full flex justify-center items-center cursor-pointer"
                                onClick={handleUserClick}
                            >
                                <IoIosArrowForward className="text-[18px]" />
                            </p>
                        </div>

                        <hr className="w-full mt-[6px] my-[6px] h-[0.5px] bg-gray-400 border-0" />

                        <div className="flex gap-1 px-1 pb-2">
                            {socialMediaData
                                .slice(socialMediaPosition, socialMediaPosition + 4)
                                .map((item) => (
                                    <div key={item.id}>
                                        <SocialIcon
                                            network={item.iconname}
                                            url="#"
                                            style={{ height: "28px", width: "28px" }}
                                        />
                                    </div>
                                ))}
                            <p
                                className="h-7 w-7 bg-black text-white hover:bg-[#FF6600] rounded-full flex justify-center items-center cursor-pointer"
                                onClick={handleSocialClick}
                            >
                                <IoIosArrowForward className="text-[18px]" />
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreatedGroupProfileSection;
