import React, { Fragment, useRef, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AccountProfileSection from "../../../Component/AccountPage/AccountProfileSection";
import AccountTab from "../../../Component/AccountPage/AccountTab";
import AccountTabModal from "../../../Component/AccountPage/AccountTabModal";
import { useOutsideClick } from "../../../Hooks/useOutsideClick";
import AccountSidebar from "../../../Component/AccountPage/AccountSidebar";

export default function EnterOtpContainer() {

   const navigate = useNavigate();

   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const [show, setShow] = useState(false);
   const [activeTab, setActiveTab] = useState("");
   const ref = useRef(null);

   const handleClickOutside = () => {
      setShow(null);
      setActiveTab("");
   };
   useOutsideClick(ref, handleClickOutside);

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);


   return (
      <Fragment>
         <div className="bg-[#fff] dark:bg-black overflow-hidden h-full relative ">
            {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
               CC ACCOUNT PRIVATE ENTEROTP  PAGE-FE - V11 /JULY 01, 2024
            </p> */}
            <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
               Copyright © 2025 Galileo One Inc
            </p>
            <div className="">
               <AccountProfileSection
                  handleOpenSideBar={openDrawer}
                  handleCloseSideBar={closeDrawer}
                  isDrawerOpen={isDrawerOpen}
                  setActiveTab={setActiveTab}
                  setShow={setShow}
                  show={show}
               />
            </div>
            <AccountTab activeTab={"Private"} />
            <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
               <div className="grid grid-cols-12 text-start">
                  <IoArrowBackCircle
                     onClick={() => navigate(-1)}
                     className=" col-span-2 text-[1.5rem] dark:text-white cursor-pointer"
                  />
               </div>
               <h1 className="col-span-8 text-center text-2xl font-bold text-gray-800 dark:text-gray-100 ml-2">Enter OTP</h1>
               <div class="max-w-md mx-auto mt-1">
                  <form class="px-4 py-6">
                     <div class="flex justify-center gap-2 mb-6">
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                     </div>
                     <div class="flex items-center justify-center">
                        <button
                           class="bg-orange-700 dark:bg-gray-700 dark:hover:bg-[#545353] hover:bg-orange-800 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                           type="button"
                           onClick={() => navigate("/account/private/setPassword")}
                        >
                           Verify
                        </button>
                        <a
                           class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-600 ml-4"
                           href="#"
                        >
                           Resend OTP
                        </a>
                     </div>
                  </form>
               </div>
            </div>
            <div className="grid grid-cols-12 overflow-hidden">
               <div className="col-span-12">
                  <AccountSidebar
                     isDrawerOpen={isDrawerOpen}
                     setIsDrawerOpen={setIsDrawerOpen}
                     openDrawer={openDrawer}
                     closeDrawer={closeDrawer}
                  />
               </div>
            </div>

         </div>
         {show && (
            <div className={"absolute h-full w-full top-0 overflow-hidden"}>
               <div
                  className="absolute h-screen w-full top-0 opacity-100 bg-opacity-70 bg-black z-50"
                  onClick={handleClickOutside}
               />
               <div
                  ref={ref}
                  className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
               >
                  <AccountTabModal
                     ref={ref}
                     activeTab={activeTab}
                     setActiveTab={setActiveTab}
                  />
               </div>
            </div>
         )}

      </Fragment>
   );
}
