import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaImages } from "react-icons/fa6";
import { MdGifBox } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import { VscUnmute, VscMute } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { getGroupDetails } from "../../Redux/ApiSlice/Group/GroupSlice";

const CreatedGroupChatRoom = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { groupId } = params;
    const groupDetails = useSelector((state) => state.group);

    const navigate = useNavigate();
    const [isMute, setIsMute] = useState(false);
    const [isShowMenu, setIsShowMenu] = useState(false);
    const menuRef = useRef(null);

    const getGroupData = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails(groupId));
            const result = response.payload;
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    useEffect(() => {
        getGroupData();
    }, [getGroupData]);

    const demoChats = [
        {
            id: 1,
            userName: "Michael Smith",
            time: "8:30 AM",
            isSender: false,
            message: "Good morning! How are you?",
            userImg: "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            id: 2,
            userName: "You",
            time: "8:32 AM",
            isSender: true,
            message: "Good morning! I'm doing well, just having my coffee. You?",
            userImg: "https://randomuser.me/api/portraits/men/2.jpg"
        },
        {
            id: 3,
            userName: "Ashley Williams",
            time: "8:34 AM",
            isSender: false,
            message: "Hey guys! Hope you're having a good day so far.",
            userImg: "https://randomuser.me/api/portraits/women/1.jpg"
        },
        {
            id: 4,
            userName: "You",
            time: "8:35 AM",
            isSender: true,
            message: "Hey Ashley! Yeah, just getting started. What about you?",
            userImg: "https://randomuser.me/api/portraits/men/2.jpg"
        },
        {
            id: 5,
            userName: "Michael Smith",
            time: "8:36 AM",
            isSender: false,
            message: "Same here. Just checking emails and getting ready for work.",
            userImg: "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            id: 6,
            userName: "Ashley Williams",
            time: "8:38 AM",
            isSender: false,
            message: "Nice! I’m about to head out for a walk. The weather is great today.",
            userImg: "https://randomuser.me/api/portraits/women/1.jpg"
        },
        {
            id: 7,
            userName: "You",
            time: "8:40 AM",
            isSender: true,
            message: "Sounds like a great plan! Enjoy your walk!",
            userImg: "https://randomuser.me/api/portraits/men/2.jpg"
        },
        {
            id: 8,
            userName: "Michael Smith",
            time: "8:42 AM",
            isSender: false,
            message: "Yeah, have a nice walk, Ashley!",
            userImg: "https://randomuser.me/api/portraits/men/1.jpg"
        }
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsShowMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="bg-white dark:bg-black h-full pb-10 overflow-y-scroll">
            <div className="sticky top-0 bg-white dark:bg-black z-10 ">
                <p className="absolute top-0 left-1/2 transform -translate-x-1/2 text-center text-[#ff3b3b] font-bold md:text-[10px]"            >
                    Copyright © 2025 Galileo One Inc
                </p>
                <div className="grid grid-cols-12 px-2 pt-3 pb-1 items-center border-b border-gray-300 dark:border-gray-700">
                    <div className="col-span-2">
                        <IoArrowBackCircle
                            onClick={() => navigate(-1)}
                            className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center gap-x-2">
                        <p className="text-black dark:text-white text-[18px] md:text-[14px] font-semibold">
                            {groupDetails?.data?.group_name}
                        </p>
                        {isMute &&
                            <VscMute className="cursor-pointer text-[15px] text-black dark:text-white" />
                        }
                    </div>
                    <div className="col-span-2 flex justify-end">
                        <HiDotsVertical
                            onClick={() => setIsShowMenu(true)}
                            className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                        />
                    </div>
                </div>
            </div>

            {isShowMenu &&
                <div ref={menuRef} className="bg-white border border-gray-400 py-1 min-w-[4.5rem] absolute right-2 top-11 rounded-md z-50">
                    <div
                        onClick={() => setIsMute(!isMute)}
                        className="px-2 flex justify-between items-center gap-x-2 border-b-[0.5px] border-gray-400"
                    >
                        <p className="text-black hover:text-red-500 text-[13px] py-0.5 cursor-pointer">
                            {isMute ? "Unmute" : "Mute"}
                        </p>
                        {isMute ? <VscUnmute className="cursor-pointer text-[15px]" /> : <VscMute className="cursor-pointer text-[15px]" />}
                    </div>
                    <div className="px-2 flex justify-between items-center gap-x-2" onClick={() => navigate("/createdgroup/invite")}>
                        <p className="text-black hover:text-red-500 text-[13px] py-0.5 cursor-pointer">Invite</p>
                        <FaUserPlus className="cursor-pointer text-[15px]" />
                    </div>
                </div>
            }

            {demoChats.map((item) => (
                <div className="px-2 mt-2" key={item.id}>
                    {item.isSender ?
                        <div className="grid grid-cols-12 pb-[8px] justify-end">
                            <div className="col-span-12">
                                <div className="flex items-center gap-[5px] justify-end">
                                    <p className="text-[#3ea8d8] text-[12px] md:text-[9px]">{item.time}</p>
                                    <p className="text-black dark:text-white text-[16px] md:text-[12px]">{item.userName}</p>
                                    <img
                                        alt={item.userName}
                                        src={item.userImg}
                                        className="h-6 w-6 md:h-5 md:w-5 object-cover rounded-full"
                                    />
                                </div>
                            </div>
                            <div className="col-span-3"></div>
                            <div className="col-span-9 flex justify-end mt-[3px]">
                                <p className="text-[14px] md:text-[12px] text-white bg-[#343333] leading-[14px] p-[5px] rounded-md">
                                    {item.message}
                                </p>
                            </div>
                        </div>
                        :
                        <div className="grid grid-cols-12 pb-[8px]">
                            <div className="col-span-1">
                                <img
                                    alt={item.userName}
                                    src={item.userImg}
                                    className="h-6 w-6 md:h-5 md:w-5 object-cover rounded-full"
                                />
                            </div>
                            <div className="col-span-8">
                                <div className="flex items-center gap-2">
                                    <p className="text-black dark:text-white text-[16px] md:text-[12px]">{item.userName}</p>
                                    <p className="text-[#3ea8d8] text-[12px] md:text-[9px]">{item.time}</p>
                                </div>
                                <div className="flex mt-[1px]">
                                    <p className="text-[14px] md:text-[12px] text-white bg-[#343333] leading-[14px] p-[5px] rounded-md">
                                        {item.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            ))}

            <div className="absolute bottom-0 bg-black w-full">
                <div className="flex justify-between items-center gap-1 py-1 px-1">
                    <FaImages className="text-white text-[45px] md:text-[30px] cursor-pointer" />
                    <MdGifBox className="text-white text-[45px] md:text-[30px] cursor-pointer" />
                    <input
                        type="text"
                        placeholder="Message"
                        className="px-2 py-1 w-full rounded-md focus:outline-none bg-white"
                    />
                    <IoSend className="text-white text-[45px] md:text-[20px] cursor-pointer" />
                </div>
            </div>
        </div>
    );
};

export default CreatedGroupChatRoom;
