import React from "react";
import { ListItem, Card } from "@material-tailwind/react";

export default function ToolsModal({ Toolref }) {
    return (
        <Card
            ref={Toolref}
            className="w-[80%] h-auto rounded-md shadow-md p-1 bg-blue-900 dark:bg-black text-white mt-10 z-50 relative"
        >
            <p className="absolute -bottom-9 z-50 text-[red] font-extrabold text-[10px] text-center">
                Copyright © 2025 Galileo One Inc
            </p>
            {/* <p className="absolute -bottom-9 z-50 text-[red] font-extrabold text-[10px] text-center">
                CC USER CREATED GROUP TOOL PAGE / FE / V2 / JULY 08, 2024
            </p> */}
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Moderator Application
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Reported
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Removed
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Mail
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Banned
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Muted
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Analytics
            </ListItem>
            <ListItem className="flex justify-center text-center font-bold text-[14px]">
                Rules
            </ListItem>
        </Card>
    );
}
