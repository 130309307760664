import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";

export default function FeedBackFromCard({ handleCancel, handleInputChange, handleFeedback }) {
    return (
        <Card className="rounded-lg w-full shadow-[rgba(0,_0,_0,_0.24)_1px_3px_8px] p-4 border-2 bg-white dark:bg-black border-gray-400 dark:border-gray-800">
            <CardBody className="p-1">
                <Typography className="mb-1 text-[12px] font-bold text-black dark:text-white">
                    Feedback Form
                </Typography>
                <form className="mt-2 flex flex-col ">
                    <div className="grid grid-cols-6 gap-3">
                        <div className="col-span-3">
                            <label
                                className="block text-gray-700 dark:text-gray-500 text-[10px] font-bold mb-0.5"
                                for="username"
                            >
                                First Name
                            </label>
                            <input
                                type="text"
                                name="first_name"
                                onChange={handleInputChange}
                                placeholder="Enter Your First Name"
                                className="w-full p-1 text-[12px] placeholder:text-[10px] border border-gray-300 rounded-lg focus:outline-none"
                            />
                        </div>
                        <div className="col-span-3">
                            <label
                                for="username"
                                className="block text-gray-700 dark:text-gray-500 text-[10px] font-bold mb-0.5"
                            >
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="last_name"
                                onChange={handleInputChange}
                                placeholder="Enter Your First Last"
                                className="w-full p-1 text-[12px] placeholder:text-[10px] border border-gray-300 rounded-lg focus:outline-none"
                            />
                        </div>
                    </div>
                    <div className="w-full gap-3 mt-2">
                        <label class="block text-gray-700 dark:text-gray-500 text-[10px] font-bold mb-0.5">
                            Email
                        </label>
                        <input
                            type="text"
                            name="email"
                            onChange={handleInputChange}
                            placeholder="Enter Your Email"
                            className="w-full p-1 text-[12px] placeholder:text-[10px] border border-gray-300 rounded-lg focus:outline-none"
                        />
                    </div>
                    <div className="mt-4">
                        <label class="block text-gray-700 dark:text-gray-500 text-[10px] font-bold mb-0.5">
                            Feedback
                        </label>
                        <textarea
                            required
                            rows="2"
                            name="feedback"
                            onChange={handleInputChange}
                            placeholder="Enter Your Message..."
                            className="w-full p-1 text-[12px] placeholder:text-[10px] border border-gray-300 rounded-lg focus:outline-none resize-none"
                        />
                    </div>
                </form>
            </CardBody>
            <CardFooter className="flex justify-center items-center gap-6 p-2 ">
                <Button onClick={handleFeedback} className="bg-[#00A3FF] rounded-full text-[6px] px-3 py-2 text-center ">
                    Save
                </Button>
                <Button
                    className="bg-[#00A3FF] rounded-full text-[6px] px-3 py-2 text-center "
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </CardFooter>
        </Card>
    );
}
