import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ccLogo } from "../Datas/Assests";


export default function AfterInstallation() {
    const navigate = useNavigate();
    const [userEntry, setUserEntry] = useState(false);

    const handleClick = useCallback(() => {
        if (userEntry) {
            navigate("/login");
        }
        if (!userEntry) {
            setUserEntry((prev) => !prev);
        }
    }, [userEntry]);

    useEffect(() => {

        setTimeout(() => {
            handleClick();
        }, 2000);
    }, [userEntry, handleClick]);
    return (
        <div className="h-full w-full bg-[#179fff]">
            <div className="h-full w-full flex flex-col justify-center items-center">
                <div className="w-full flex justify-center items-center">
                    <img src={ccLogo} className="h-28 w-28" alt="logo" />
                </div>
                {userEntry &&
                    <p className="text-[1.8rem] text-[#fff] font-bold text-center">
                        WATCH ENJOY CONNECT
                    </p>
                }
            </div>
            {/* <p className="text-[14px] md:text-[10px] text-red-700 font-bold absolute top-0 w-full text-center">
        CC AFTER INSTALATION - {userEntry ? 2 : 1} / V-2 / APRIL 02 , 2024
      </p> */}
            <p className="absolute text-[black] text-[14px] md:text-[10px] top-8 font-bold w-full text-center px-4">
                Copyright © 2025 Galileo One Inc
            </p>
        </div>
    );
}
