import React, { Fragment, useState, useEffect, useRef } from "react";
import { HiMiniBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import Chat from "../pages/neighborhood/Chat";
import Pinboard from "../pages/neighborhood/Pinboard";
import News from "../pages/neighborhood/News";
import { IoMdAddCircleOutline } from "react-icons/io";
import SliderMenu from "../pages/neighborhood/SliderMenu";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";

const Neighborhood = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("news");
    const [filter, setFilter] = useState("Most Recent");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const dropdownRef = useRef(null);

    const filters = ["Most Recent", "This Week", "This Month", "YTD", "All"];

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Fragment>
            <div className="sticky top-0 bg-white dark:bg-black pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 dark:border-gray-800 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer text-black dark:text-white"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px] text-black dark:text-white">
                            Your Neighborhood
                        </p>
                        <p className="flex items-center text-[10px] text-black dark:text-white">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            Downtown, Springfield
                        </p>
                    </div>
                    <div className="flex items-center gap-x-2 relative">
                        <IoMdAddCircleOutline
                            onClick={() => navigate("/neighborhood/news/create")}
                            className="absolute -left-7 text-[24px] cursor-pointer text-black dark:text-white"
                        />
                        <HiMiniBars3
                            onClick={toggleMenu}
                            className="text-[24px] cursor-pointer text-black dark:text-white"
                        />
                    </div>
                </div>

                <div className="p-2 flex items-center justify-between border-b border-gray-400 dark:border-gray-800">
                    <div
                        onClick={() => setActiveTab("pinboard")}
                        className={`flex items-center justify-center text-white 
                            h-[30px] w-[6rem] cursor-pointer rounded-full dark:text-gray-300 transition-colors duration-300 
                            ${activeTab === "pinboard" ? "bg-[#8a8686]" : "bg-black dark:bg-[#302f2f] hover:bg-[#FF9900]"}
                        `}
                    >
                        <p className="text-[10px] flex flex-col items-center justify-center leading-[11px]">
                            <span>Community</span>
                            <span>PinBoard</span>
                        </p>
                    </div>
                    <div
                        onClick={() => setActiveTab("news")}
                        className={`flex items-center justify-center text-white 
                            h-[30px] w-[6rem] cursor-pointer rounded-full dark:text-white transition-colors duration-300 
                            ${activeTab === "news" ? "bg-[#8a8686]" : "bg-black dark:bg-[#302f2f] hover:bg-[#FF9900]"}
                        `}
                    >
                        <p className="text-[12px]">
                            News
                        </p>
                    </div>
                    <div
                        onClick={() => setActiveTab("chat")}
                        className={`flex items-center justify-center text-white 
                            h-[30px] w-[6rem] cursor-pointer rounded-full dark:text-gray-300 transition-colors duration-300 
                            ${activeTab === "chat" ? "bg-[#8a8686]" : "bg-black dark:bg-[#302f2f] hover:bg-[#FF9900]"}
                        `}
                    >
                        <p className="text-[10px] flex flex-col items-center justify-center leading-[11px]">
                            <span>Community</span>
                            <span>Chat</span>
                        </p>
                    </div>
                </div>

                {activeTab === "news" && (
                    <div className="flex items-center justify-between">
                        <div
                            ref={dropdownRef}
                            className="m-2 flex items-center gap-x-[5px] border-b border-gray-400 dark:border-gray-800 w-fit relative"
                        >
                            <p className="text-[12px] text-gray-500" onClick={() => setDropdownOpen(!dropdownOpen)}>{filter}</p>
                            <FaChevronDown
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className={`text-[10px] text-gray-500 cursor-pointer transition-transform duration-300 ${dropdownOpen ? "rotate-180" : ""}`}
                            />
                            {dropdownOpen && (
                                <div className="absolute w-[6rem] top-full left-0 mt-1 bg-white dark:bg-[#272222] border border-gray-400 overflow-hidden rounded shadow-lg">
                                    {filters.filter(f => f !== filter).map((f) => (
                                        <p
                                            key={f}
                                            onClick={() => handleFilterChange(f)}
                                            className="text-[12px] text-gray-500 cursor-pointer px-2 py-1 hover:bg-gray-300 hover:text-black"
                                        >
                                            {f}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        <p
                            onClick={() => navigate("/neighborhood/news/create")}
                            className="mr-2.5 mt-0.5 text-[10px] font-semibold text-light-blue-500 cursor-pointer"
                        >
                            Post To News
                        </p>
                    </div>
                )}
            </div>

            {activeTab === "pinboard" && <Pinboard />}
            {activeTab === "news" && <News />}
            {activeTab === "chat" && <Chat />}
            {menuOpen && <SliderMenu onClose={() => setMenuOpen(false)} toggleMenu={toggleMenu} />}
        </Fragment>
    )
}

export default Neighborhood;