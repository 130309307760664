import React from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { IoMdStar, IoMdStarHalf, IoMdStarOutline } from "react-icons/io";

const MoreDetails = () => {
   return (
      <div className="mt-3">
         <Tabs id="custom-animation" value="summery">
            <TabsHeader>
               <Tab value="summery" className="text-[9px] font-semibold">PRODUCT SUMMARY</Tab>
               <Tab value="snapshots" className="text-[9px] font-semibold">PRODUCT SNAPSHOTS</Tab>
               <Tab value="reviews" className="text-[9px] font-semibold">CUSTOMER REVIEWS</Tab>
               <Tab value="category" className="text-[9px] font-semibold">BRAND CATEGORY</Tab>
            </TabsHeader>
            <TabsBody>
               <TabPanel value="summery" className="-ml-[15px] mb-5">
                  <p className="font-bold text-[#000] text-[16px]">Product Description</p>
                  <p className="text-[13px]">
                     FORGED IN TITANIUM — iPhone 15 Pro has a strong and light aerospace-grade titanium design with a textured
                     matte-glass back. It also features a Ceramic Shield front thats tougher than any smartphone glass. And its
                     splash, water, and dust resistant.
                  </p>
                  <div className="grid grid-cols-12 text-[13px] mt-3">
                     <div className="col-span-6">
                        <p className="mb-2">Manufacturer</p>
                        <p className="mb-2">Country</p>
                        <p className="mb-2">Product Model</p>
                        <p className="mb-2">Product Quality</p>
                     </div>
                     <div className="col-span-6">
                        <p className="mb-2">US</p>
                        <p className="mb-2">US</p>
                        <p className="mb-2">ZZXCTGY01201</p>
                        <p className="mb-2">21.3*11.22 cm</p>
                     </div>
                  </div>
               </TabPanel>
               <TabPanel value="snapshots" className="mb-10">
                  <iframe
                  title="shopPage"
                     width="100%"
                     height="140"
                     src="https://www.youtube.com/embed/w_JEezynhrc?si=ZUuLYv0_tn49T352"
                     frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                     allowFullScreen
                  />
               </TabPanel>
               <TabPanel value="reviews" className="mb-10">
                  <div className="flex gap-2 items-center mb-2">
                     <MdOutlineFilterList className="text-black dark:text-white text-[22px] cursor-pointer"/>
                     <select className="focus:outline-none border-none py-1 px-1 dark:rounded-xl dark:text-white cursor-pointer text-black dark:bg-[#6c6c6c]" value="XXX">
                        <option>Filter Reviews</option>
                        <option>1 Star</option>
                        <option>2 Star</option>
                        <option>3 Star</option>
                        <option>4 Star</option>
                        <option>5 Star</option>
                        <option>Recent Review</option>
                        <option>Oldest Review</option>
                     </select>
                  </div>
                  <div className="h-[30vh] overflow-y-scroll comment-remove-scrollbar">
                     <div className="mt-0 mb-3">
                        <div className="flex items-center gap-3">
                           <img
                              alt="img"
                              className="h-[40px] w-[40px] rounded-full object-cover"
                              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                           <div>
                              <p className="text-[#000] text-[16px] font-medium">John Smith</p>
                              <div className="flex items-center gap-2 -mt-1">
                                 <p className="text-[13px]">4.5</p>
                                 <div className="flex text-green-400 text-[11px]">
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStarHalf />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <p className="text-[12px] mt-2">The iPhone 15 Pro's stunning camera quality truly elevates my photography game, capturing crisp details and vibrant colors effortlessly.</p>
                     </div>
                     <div className="mt-0 mb-3">
                        <div className="flex items-center gap-3">
                           <img
                              alt="img"
                              className="h-[40px] w-[40px] rounded-full object-cover"
                              src="https://images.unsplash.com/photo-1601455763557-db1bea8a9a5a?q=80&w=2112&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                           <div>
                              <p className="text-[#000] text-[16px] font-medium">Christopher Davis</p>
                              <div className="flex items-center gap-2 -mt-1">
                                 <p className="text-[13px]">3.5</p>
                                 <div className="flex text-green-400 text-[11px]">
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStarHalf />
                                    <IoMdStarOutline />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <p className="text-[12px] mt-2">The lightning-fast performance of the iPhone 15 Pro makes multitasking a breeze, while its sleek design exudes elegance and sophistication.</p>
                     </div>
                     <div className="mt-0 mb-3">
                        <div className="flex items-center gap-3">
                           <img
                              alt="img"
                              className="h-[40px] w-[40px] rounded-full object-cover"
                              src="https://images.unsplash.com/photo-1641894252843-9794796577be?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                           <div>
                              <p className="text-[#000] text-[16px] font-medium">Robert Anderson</p>
                              <div className="flex items-center gap-2 -mt-1">
                                 <p className="text-[13px]">3.5</p>
                                 <div className="flex text-green-400 text-[11px]">
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStarOutline />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <p className="text-[12px] mt-2">Impressed by the iPhone 15 Pro's immersive OLED display, delivering rich colors and deep blacks for an unparalleled viewing experience.</p>
                     </div>
                     <div className="mt-0 mb-3">
                        <div className="flex items-center gap-3">
                           <img
                              alt="img"
                              className="h-[40px] w-[40px] rounded-full object-cover"
                              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                           <div>
                              <p className="text-[#000] text-[16px] font-medium">Jessica Martinez</p>
                              <div className="flex items-center gap-2 -mt-1">
                                 <p className="text-[13px]">3.5</p>
                                 <div className="flex text-green-400 text-[11px]">
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                    <IoMdStar />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <p className="text-[12px] mt-2">The enhanced battery life of the iPhone 15 Pro ensures I stay connected all day long, even with heavy usage, providing peace of mind.</p>
                     </div>
                  </div>
               </TabPanel>
               <TabPanel value="category" className="mb-10">
                  Mobile - Apple Iphone
               </TabPanel>
            </TabsBody>
         </Tabs>
      </div>
   );
}

export default MoreDetails;
