import React, { useCallback, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { MdBlockFlipped } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaCrown } from "react-icons/fa6";
import { AiFillMessage } from "react-icons/ai";
import {
    deleteGroupMembersList,
    getGroupMembersList,
} from "../../Redux/ApiSlice/Group/GroupMembersSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { promoteGroupModerator } from "../../Redux/ApiSlice/Group/GroupModeratorsSlice";
import toast, { Toaster } from "react-hot-toast";
import { UserAccountFollow } from "../../Redux/ApiSlice/AccountProfileSlice";
import { messagesChatRoomCreate } from "../../Redux/ApiSlice/MessagesSlice";

const CreatedGroupMembers = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pramas = useParams();
    const { groupId } = pramas;
    const groupMembers = useSelector((state) => state.groupMembers);
    const { data, loading, error } = groupMembers;
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;
    const groupDetails = useSelector((state) => state.group);
    const [groupMembersList, setGroupMembersList] = useState([]);
    const [assignModerator, setAssignModerator] = useState(false);
    const [removeMembers, setRemoveMembers] = useState(false);
    const [individualChecked, setIndividualChecked] = useState({});

    const handleIndividualChange = (event, id) => {
        const { checked } = event.target;
        setIndividualChecked((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    useEffect(() => {
        if (data?.members) {
            setGroupMembersList(data?.members);
        }
    }, [data]);

    const handleNavigate = (id) => {
        if (User_profile?.id !== id) {
            navigate(`/otheruseraccount/${id}`);
        } else {
            navigate("/account");
        }
    };

    const getAllMembers = useCallback(async () => {
        try {
            dispatch(getGroupMembersList(groupId));
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    }, [dispatch, groupId]);

    const handleSubmit = async () => {
        if (assignModerator) {
            handleSignAdmin();
        }
        if (removeMembers) {
            handleRemoveMembers();
        }
    };

    const handleSignAdmin = async () => {
        try {
            const response = await dispatch(
                promoteGroupModerator({
                    groupId,
                    data: { user_ids: Object.keys(individualChecked) },
                })
            );
            if (response.type === "promoteGroupModerator/fulfilled") {
                handleCancel();
                toast.success(response?.payload?.message);
                getAllMembers();
            }
        } catch (error) {
            console.log(error);
            handleCancel();
        }
    };

    const handleRemoveMembers = async () => {
        try {
            const response = await dispatch(
                deleteGroupMembersList({
                    groupId,
                    data: { user_ids: Object.keys(individualChecked) },
                })
            );
            if (response.type === "deleteGroupMembersList/fulfilled") {
                handleCancel();
                toast.success(response?.payload?.message);
                getAllMembers();
            }
        } catch (error) {
            console.log(error);
            handleCancel();
        }
    };
    const handleFollowFun = async (id) => {
        try {
            const response = await dispatch(UserAccountFollow(id));
            if (response.type === "UserAccountFollow/fulfilled") {
                toast.success(response?.payload?.message);
                getAllMembers();
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleMesage = async (id) => {
        try {
            const response = await dispatch(messagesChatRoomCreate(id));
            if (response.type === "messagesChatRoomCreate/fulfilled") {
                navigate(`/mobileMessages/userChat/${response.payload.id}`);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleCancel = () => {
        setIndividualChecked({});
        setAssignModerator(false);
        setRemoveMembers(false);
    };

    useEffect(() => {
        getAllMembers();
    }, [getAllMembers]);

    return (
        <div className="bg-white dark:bg-black h-full overflow-y-scroll relative">
            <Toaster position="top-right" />
            <p className="absolute top-0 left-1/2 transform -translate-x-1/2 text-center text-[#ff3b3b] font-bold md:text-[10px]"            >
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="grid grid-cols-12 px-2 pt-3 pb-1 items-center border-b border-gray-300 dark:border-gray-700">
                <div className="col-span-2">
                    <IoArrowBackCircle
                        onClick={() => navigate(-1)}
                        className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                    />
                </div>
                <div className="col-span-8 text-center">
                    <p className="text-black dark:text-white text-[18px] md:text-[14px]">
                        {groupDetails?.data?.group_name} Group Members
                    </p>
                </div>
                <div className="col-span-2 flex justify-end">
                    {showMenu ? (
                        <p
                            className="text-black dark:text-white hover:text-red-600 text-[12px] font-bold cursor-pointer"
                            onClick={() => setShowMenu(false)}
                        >
                            Close
                        </p>
                    ) : (
                        groupDetails?.data?.current_user_role !== "member" && (
                            <HiDotsVertical
                                className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                                onClick={() => setShowMenu(!showMenu)}
                            />
                        )
                    )}
                </div>
            </div>

            {showMenu && (
                <div className="absolute top-14 md:top-10 right-2 bg-white border border-gray-300 z-50 rounded">
                    <p
                        onClick={() => setAssignModerator(true)}
                        className="text-black hover:text-red-500 text-[12px] px-2 py-1 cursor-pointer border-b border-gray-300"
                    >
                        Assign Moderator
                    </p>
                    <p
                        onClick={() => setRemoveMembers(true)}
                        className="text-black hover:text-red-500 text-[12px] px-2 py-1 cursor-pointer"
                    >
                        Remove Members
                    </p>
                </div>
            )}

            <div className="px-2 mt-2 pb-2 relative border-b border-gray-300 dark:border-gray-700">
                <input
                    type="text"
                    placeholder="Search"
                    className="w-full focus:outline-none border border-gray-700 py-1 pl-8 pr-2 rounded-full"
                />
                <IoSearchSharp className="absolute top-[7px] left-[16px] text-[20px] cursor-pointer" />
            </div>

            {loading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                    <div className="grid grid-cols-12 p-2 py-0 mt-1" key={item}>
                        <div className="col-span-1 flex justify-center items-center">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={20}
                                circle
                            />
                        </div>
                        <div className="col-span-2 flex justify-start items-center">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={30}
                                width={30}
                                circle={true}
                            />
                        </div>
                        <div className="col-span-4">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={100}
                            />
                        </div>
                        <div className="col-span-5 flex justify-end  gap-2 items-center">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={20}
                                circle
                            />
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={20}
                                circle
                            />
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                height={20}
                                width={30}
                            />
                        </div>
                    </div>
                ))
                : groupMembersList?.map((data) => (
                    <>
                        <div
                            key={data.id}
                            className="grid grid-cols-12 px-2 items-center pt-2 pb-2 hover:bg-gray-100 hover:dark:bg-gray-900 border-b border-gray-300 dark:border-gray-900"
                        >
                            <div className="col-span-1 flex justify-center items-center">
                                {data.is_admin && (
                                    <FaCrown className="text-[25px] md:text-[20px] text-yellow-800" />
                                )}
                                {!data.is_admin && (assignModerator || removeMembers) && (
                                    <div class="inline-flex items-center">
                                        <label
                                            htmlFor={`check-${data.id}`}
                                            class="relative flex items-center rounded-full cursor-pointer"
                                        >
                                            <input
                                                type="checkbox"
                                                id={`check-${data.id}`}
                                                checked={individualChecked[data.id] || false}
                                                onChange={(e) => handleIndividualChange(e, data.id)}
                                                class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-100 before:opacity-0 before:transition-opacity checked:border-none checked:bg-cyan-400 checked:before:bg-white"
                                            />
                                            <span class="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-3.5 w-3.5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    stroke="currentColor"
                                                    strokeWidth="1"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-span-2 ml-[4px]">
                                <img
                                    alt={data.first_name}
                                    src={data.avatar_url}
                                    onClick={() => handleNavigate(data?.id)}
                                    className="h-10 w-10 md:h-8 md:w-8 rounded-full object-cover cursor-pointer"
                                />
                            </div>
                            <div className="col-span-3 ml-[-6px]">
                                <p
                                    className="text-[16px] md:text-[13px] text-black dark:text-white leading-[14px] cursor-pointer whitespace-nowrap"
                                    onClick={() => handleNavigate(data?.id)}
                                >
                                    {data.first_name} {data.last_name}
                                </p>
                            </div>
                            <div className="col-span-6 flex justify-end gap-x-2 items-center">
                                {/* {data.is_admin ? null :
                                <button className="h-7 w-7 md:h-6 md:w-6 bg-[#ffffff] text-black flex justify-center items-center rounded-full">
                                    <MdBlockFlipped className="text-[15px] md:text-[13px]" />
                                </button>
                                } */}
                                {User_profile?.id !== data.id && (
                                    <button className="h-7 w-7 md:h-6 md:w-6 bg-black dark:bg-white text-white dark:text-black flex justify-center items-center rounded-full">
                                        <AiFillMessage
                                            className="text-[15px] md:text-[13px]"
                                            onClick={() => handleMesage(data?.id)}
                                        />
                                    </button>
                                )}
                                {User_profile?.id !== data.id && (
                                    <button
                                        onClick={() => handleFollowFun(data?.id)}
                                        className="h-7 w-12  md:h-6 text-[10px] px-3 font-bold bg-black dark:bg-white text-white dark:text-black flex justify-center items-center rounded-md"
                                    >
                                        {data?.is_following ? "UnFollow" : "Follow"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                ))}

            {groupMembersList?.length === 0 && !loading && (
                <div className="h-full w-full flex justify-center items-center">
                    <p className="text-black dark:text-white text-center">No members found</p>
                </div>
            )}
            {(assignModerator || removeMembers) && (
                <div className=" absolute z-30 py-2 bottom-0 bg-gray-300 dark:bg-gray-900 flex justify-between gap-2 w-full px-3">
                    <button
                        className="bg-white dark:bg-black text-black dark:text-white rounded-full text-[14px] hover:font-bold py-[5px] px-6  cursor-pointer"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-white dark:bg-black text-black dark:text-white rounded-full text-[14px] hover:font-bold py-[5px] px-6  cursor-pointer"
                        onClick={() => handleSubmit()}
                    >
                        {removeMembers ? "Remove" : "Assign"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default CreatedGroupMembers;
