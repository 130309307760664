import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const HomepageVideo = createAsyncThunk("HomepageVideo", async () => {
  const response = await axiosInstance.get("/post/");
  try {
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});
export const getHomePageVideo = createAsyncThunk("getHomePageVideo", async (value) => {
  // const response = await axiosInstance.get(`/post/paginated_post/?page=${value}`);
  const response = await axiosInstance.get(`/post/recomm_post/?page=${value}`);
  try {
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});
export const getVideoShared = createAsyncThunk("getVideoShared", async (postId) => {
  const response = await axiosInstance.get(`api/post/view_shared_post/${postId}/`);
  try {
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});

export const HomepageVideoLike = createAsyncThunk(
  "HomepageVideoLike",
  async (key) => {
    const response = await axiosInstance.post(`/post/like/${key}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const HomepageVideoGetComment = createAsyncThunk(
  "HomepageVideoGetComment",
  async (id) => {
    const response = await axiosInstance.get(`post/get_comment/${id}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const HomepageVideoCreateComment = createAsyncThunk(
  "HomepageVideoCreateComment",
  async (data) => {
    const response = await axiosInstance.post(`post/comment/${data.key}/`, {
      post_comment_box: data.body,
    });
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const HomepageVideoDeleteComment = createAsyncThunk(
  "HomepageVideoDeleteComment",
  async (data) => {
    const response = await axiosInstance.delete(`post/delete_comment/${data}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const HomepageVideoUpdateComment = createAsyncThunk(
  "HomepageVideoUpdateComment",
  async (data) => {
    const response = await axiosInstance.patch(
      `/post/update_comment/${data.key}/`,
      {
        post_comment_box: data.body,
      }
    );
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const HomepageVideoReplayComment = createAsyncThunk(
  "HomepageVideoReplayComment",
  async (data) => {
    const response = await axiosInstance.post(
      `post/comment/${data.key}/replies/`,
      {
        post_comment_box: data.body,
      }
    );
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const HomepageVideoReplayCommentDelete = createAsyncThunk(
  "HomepageVideoReplayCommentDelete",
  async (data) => {
    const response = await axiosInstance.delete(
      `post/comment/${data}/delete_reply/`
    );
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const HomepageVideoSlice = createSlice({
  name: "HomepageVideo",
  initialState: {
    AllPosts: [],
    videoData: {},
    homePageVideos: [],
    postCommants: [],
    currentIndex: 0,
    pageNumber: 1,
    loading: false,
    error: null,
  },
  reducers: {
    // HomepageVideo action reducers

    setCurrentIndexfn: (state, action) => {
      state.currentIndex = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setVideoLike: (state, action) => {
      const postId = action.payload;

      const videoIndex = state.homePageVideos.findIndex((item) => item.id === postId);

      if (videoIndex !== -1) {
        state.homePageVideos[videoIndex] = {
          ...state.homePageVideos[videoIndex],
          likes_count: state.homePageVideos[videoIndex].likes_count + 1,
          userliked: true,
        };
      }
    },
    removeVideoLike: (state, action) => {
      const postId = action.payload;

      const videoIndex = state.homePageVideos.findIndex((item) => item.id === postId);

      if (videoIndex !== -1) {
        state.homePageVideos[videoIndex] = {
          ...state.homePageVideos[videoIndex],
          likes_count: state.homePageVideos[videoIndex].likes_count - 1,
          userliked: false,
        };
      }

    }
  },
  extraReducers: (builder) => {
    // HomepageVideo action promises
    builder.addCase(getHomePageVideo.pending, (state) => {
      state.loading = true;
      state.videoData = {};
    });
    builder.addCase(getHomePageVideo.fulfilled, (state, action) => {
      state.videoData = action.payload;
      const mergedVideos = [...state.homePageVideos, ...action.payload.post];

      // Remove duplicates using a Map (based on id)
      // const uniqueVideos = Array.from(new Map(mergedVideos.map(video => [video.id, video])).values());
  
      state.homePageVideos = mergedVideos;
      state.loading = false;
    });
    builder.addCase(getHomePageVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(HomepageVideo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(HomepageVideo.fulfilled, (state, action) => {
      state.AllPosts = action.payload;
      state.loading = false;
    });
    builder.addCase(HomepageVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    /* HomeVideoFootage comments */
    builder.addCase(HomepageVideoGetComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(HomepageVideoGetComment.fulfilled, (state, action) => {
      state.postCommants = action.payload;
      state.loading = false;
    });
    builder.addCase(HomepageVideoGetComment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    /* HomepageVideoReplayComment comments */
    builder.addCase(HomepageVideoReplayComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(HomepageVideoReplayComment.fulfilled, (state, action) => {
      // state.postCommants = action.payload;
      state.loading = false;
    });
    builder.addCase(HomepageVideoReplayComment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    /* HomepageVideoReplayCommentDelete comments */
    builder.addCase(HomepageVideoReplayCommentDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      HomepageVideoReplayCommentDelete.fulfilled,
      (state, action) => {
        // state.postCommants = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      HomepageVideoReplayCommentDelete.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export const { setCurrentIndexfn, setPageNumber, setVideoLike, removeVideoLike } = HomepageVideoSlice.actions;
export default HomepageVideoSlice.reducer;
