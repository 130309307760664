import React, { useState, useRef, useCallback, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { FaArrowCircleUp } from "react-icons/fa";
import { Card } from "@material-tailwind/react";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { IoArrowBackCircleSharp, IoHeartCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getGroupPostsList } from "../../Redux/ApiSlice/Group/GroupPostsSlice";
import {
    CommentLike,
    CreateComment,
    DeleteComment,
    GetComment,
    ReplayComment,
} from "../../Redux/ApiSlice/CommentsSlice";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { BiSolidLike } from "react-icons/bi";
import { FaShare } from "react-icons/fa6";
import ReportModal from "../../Component/Modals/Group/ReportModal";

export default function GroupPostComments() {
    const params = useParams();
    const { groupId, postId } = params;
    const navigate = useNavigate();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const groupPosts = useSelector((state) => state.groupPosts);
    const comments = useSelector((state) => state.comments);
    const profile = useSelector((state) => state.AccountProfile);
    // console.log(profile, "profile");
    const post = groupPosts?.data?.results?.find((post) => post.id === postId);
    const [showId, setShowId] = useState(0);
    const [showReply, setShowReply] = useState(0);
    const [likeReply, setLikeReply] = useState(0);
    const [comment, setComment] = useState("");
    const [commentId, setCommentId] = useState(null);
    const [commentsData, setCommentsData] = useState([]);
    const [showReplyPopup, setShowReplyPopup] = useState(null);
    const [showReport, setShowReport] = useState(false);

    const handleLikeReply = (id) =>
        likeReply ? setLikeReply(null) : setLikeReply(id);
    const handleShow = (id) => setShowId(id);
    const handleShowReply = (id) =>
        showReply ? setShowReply(null) : setShowReply(id);
    const hideElement = () => {
        setShowId(null);
    };

    useOutsideClick(ref, hideElement);

    console.log(showReply, "commentsData");

    const handleReply = (id) => {
        setCommentId(id);
        setShowReplyPopup(true);
    };

    const handleCloseReply = () => {
        setShowReplyPopup(false);
        setCommentId(null);
        setComment("");
    };

    useEffect(() => {
        if (comments?.postCommants) {
            setCommentsData(comments.postCommants);
        }
    }, [comments]);

    const getGroupPostData = useCallback(async () => {
        try {
            await dispatch(getGroupPostsList(groupId));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    const getGroupPostCommentsData = useCallback(async () => {
        try {
            await dispatch(GetComment(postId));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, postId]);

    const handleCreateComments = async () => {
        const data = {
            body: comment,
            key: postId,
        };
        if (!comment) {
            return toast.error("Please Enter Comment");
        }
        const toastId = toast.loading("Creating Comment...");
        try {
            const res = await dispatch(CreateComment(data));
            toast.dismiss(toastId);
            if (res.type === "CreateComment/fulfilled") {
                toast.success("Comment Created Successfully");
                getGroupPostCommentsData();
                setComment("");
            }

            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteComments = async (id) => {
        try {
            const toastId = toast.loading("Deleting Comment...");
            const res = await dispatch(DeleteComment(id));

            if (res.type === "DeleteComment/fulfilled") {
                toast.dismiss(toastId);
                getGroupPostCommentsData();
                toast.success("Comment Deleted Successfully");
                setComment("");
            }
        } catch (error) {
            toast.error("Error Deleting Comment");
            console.log(error);
        }
    };

    const handleCommentLike = async (id) => {
        toast.loading("Liking Comment...");
        try {
            const res = await dispatch(CommentLike(id));
            if (res.type === "CommentLike/fulfilled") {
                toast.dismiss();
                // getGroupPostCommentsData()
                toast.success("Comment Like Successfully");
                setComment("");
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Error Like Comment");
            console.log(error);
        }
    };

    const handleCreateReply = async () => {
        const data = {
            body: comment,
            key: commentId,
        };
        if (!comment) {
            return toast.error("Please Enter Comment");
        }
        const toastId = toast.loading("Creating Comment Reply...");
        try {
            const res = await dispatch(ReplayComment(data));
            toast.dismiss(toastId);
            if (res.type === "ReplayComment/fulfilled") {
                toast.success("Comment Reply Created Successfully");
                getGroupPostCommentsData();
                setComment("");
                setShowReplyPopup(false);
            }

            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleReportComment = (id) => {
        setShowReport(true);
        setCommentId(id);
    };

    useEffect(() => {
        getGroupPostData();
        getGroupPostCommentsData();
    }, [getGroupPostData, getGroupPostCommentsData]);

    function timeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);

        const intervals = {
            year: 31536000, // 60 * 60 * 24 * 365
            month: 2592000, // 60 * 60 * 24 * 30
            day: 86400, // 60 * 60 * 24
            hour: 3600, // 60 * 60
            minute: 60,
            second: 1,
        };

        for (const key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval}${key[0]} ago`;
            }
        }

        return "just now"; // In case it's less than a second ago
    }
    const LikeReplyFn = ({ handleLike, id }) => {
        return (
            <div className="flex items-center gap-[3px] justify-center">
                <p className="text-[#3ea8d8] text-[9px]">1k</p>
                {/* <IoHeartCircle
                    color={likeReply === data.replyId ? "red" : "white"}
                    className={` text-[14px] cursor-pointer`}
                    onClick={() => handleLikeReply(data?.replyId)}
                /> */}
                <BiSolidLike
                    // color={likeReply === id ? "red" : "white"}
                    // className={` text-[14px] cursor-pointer`}
                    onClick={() => handleLike(id)}
                    className={`text-gray-600 dark:text-white`}
                />
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-black overflow-hidden h-full relative">
            {/* <p className="w-full text-center text-[10px] text-red-900">
                CC USER CREATED GROUP POST COMMENTS PAGE / FE V2 / JULY 05, 2024
            </p> */}
            <Toaster position="top-right" />
            <p className="w-full text-center text-[10px] text-red-900">
                Copyright © 2025 Galileo One Inc
            </p>
            <div className="grid grid-cols-12 items-start pt-1 pb-2 px-2">
                <div className="col-span-1 mt-3">
                    <IoMdCloseCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] md:text-[20px] text-red-500 ml-[-2px] flex justify-center items-center rounded-full cursor-pointer transform transition duration-300 ease-in-out hover:scale-125"
                    />
                </div>
                {!groupPosts.loading ? (
                    <div className="col-span-11 ml-[2px]">
                        <div className="flex items-center justify-between gap-3 mt-2">
                            <div>
                                <img
                                    alt="profilepic"
                                    className="h-10 md:h-8 w-10 md:w-8 rounded-full object-cover"
                                    src={post?.created_by?.avatar}
                                />
                                <p className="text-black dark:text-white text-[18px] md:text-[14px] mt-1">
                                    {post?.created_by?.first_name} {post?.created_by?.last_name}
                                </p>
                                <p className="text-[#3ea8d8] text-[14px] md:text-[10px] font-semibold -mt-0.5">
                                    10 Day Ago
                                </p>
                            </div>
                            <div>
                                <FaShare className="text-[1.2rem] text-black dark:text-white cursor-pointer" />
                            </div>
                        </div>
                        {post?.image_url && (
                            <img
                                alt="postimg"
                                className="w-full h-40 object-cover mt-2 rounded-[10px]"
                                src={post?.image_url}
                            />
                        )}
                        {post?.video_url && (
                            <video
                                alt="postimg"
                                className="w-full h-40 object-cover mt-2 rounded-[10px]"
                                src={post?.video_url}
                            />
                        )}
                    </div>
                ) : (
                    <div className="col-span-9">
                        <div className="flex items-center gap-3 mt-2">
                            <Skeleton
                                // baseColor="#202020"
                                // highlightColor="#444"
                                circle
                                height={30}
                                width={30}
                            />
                            <div className="flex flex-col gap-1">
                                <Skeleton
                                    baseColor="#202020"
                                    highlightColor="#444"
                                    width={100}
                                />
                                <Skeleton
                                    baseColor="#202020"
                                    highlightColor="#444"
                                    width={50}
                                />
                            </div>
                        </div>
                        <Skeleton
                            baseColor="#202020"
                            highlightColor="#444"
                            height={100}
                            width={280}
                            className="rounded-3xl w-full"
                        />
                    </div>
                )}
            </div>
            <div className="relative h-[60%] overflow-y-scroll pb-24 bg-white dark:bg-black">
                {comments.loading
                    ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map((data) => (
                        <div
                            className="grid grid-cols-12 items-center pt-1 pb-2 px-3"
                            key={data}
                        >
                            <div className="col-span-12 w-full">
                                <div className="flex justify-between items-center">
                                    <div className="flex justify-between items-center gap-3">
                                        <Skeleton
                                            baseColor="#202020"
                                            highlightColor="#444"
                                            circle
                                            height={20}
                                            width={20}
                                        />
                                        <Skeleton
                                            baseColor="#202020"
                                            highlightColor="#444"
                                            width={100}
                                        />
                                        <Skeleton
                                            baseColor="#202020"
                                            highlightColor="#444"
                                            width={50}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            baseColor="#202020"
                                            highlightColor="#444"
                                            width={20}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1"></div>
                            <div className="col-span-9 pl-2">
                                <Skeleton
                                    baseColor="#202020"
                                    highlightColor="#444"
                                    width={200}
                                />
                            </div>
                        </div>
                    ))
                    : commentsData.map((data) => (
                        <div className="px-3 pt-2 hover:bg-gray-200 dark:hover:bg-gray-900" key={data.id}>
                            <div className="grid grid-cols-12 pb-[8px]">
                                <div className="col-span-1">
                                    <img
                                        alt={data.userName}
                                        src={data.created_by.avatar_url}
                                        className="h-7 w-7 md:h-5 md:w-5 object-cover rounded-full"
                                    />
                                </div>
                                <div className="col-span-10 ml-[3px]">
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center gap-2">
                                            <p className="text-black dark:text-white text-[16px] md:text-[14px]">
                                                {data.created_by.first_name}
                                                {data?.created_by?.last_name}{" "}
                                            </p>
                                            <p className="text-[#3ea8d8] text-[12px] md:text-[9px]">
                                                {timeAgo(data?.created_at)}
                                            </p>
                                        </div>
                                        <LikeReplyFn
                                            id={data?.id}
                                            handleLike={handleCommentLike}
                                        />
                                    </div>
                                    <div className="flex mt-[1px]">
                                        <p className="text-[14px] md:text-[12px] text-black dark:text-white bg-[#34333] leading-[14px] p-[8px] pl-0 rounded-md">
                                            {data.post_comment_box}
                                        </p>
                                    </div>
                                    <div>
                                        {data.replies?.length > 0 && (
                                            <p
                                                className="text-[#3ea8d8] text-[14px] md:text-[12px] cursor-pointer"
                                                onClick={() => handleShowReply(data.id)}
                                            >
                                                {showReply ? "Hide" : "show"} replies
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-1 flex justify-end relative">
                                    <BsThreeDots
                                        className="text-[#3ea8d8] text-[16px] cursor-pointer "
                                        onClick={() => handleShow(data.id)}
                                    />
                                    {data.id === showId && (
                                        <Card
                                            className="w-20 absolute top-4 right-4 rounded-md p-1 z-50"
                                            ref={ref}
                                        >
                                            <p
                                                className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                                onClick={() => handleReply(data.id)}
                                            >
                                                Reply
                                            </p>
                                            {profile.User_profile.id === data.created_by.id && (
                                                <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                                    Edit
                                                </p>
                                            )}
                                            {profile.User_profile.id === data.created_by.id && (
                                                <p
                                                    className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                                    onClick={() => handleDeleteComments(data.id)}
                                                >
                                                    Delete
                                                </p>
                                            )}
                                            {profile.User_profile.id !== data.created_by.id && (
                                                <p
                                                    className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                                    onClick={() => handleReportComment(data.id)}
                                                >
                                                    Report
                                                </p>
                                            )}
                                        </Card>
                                    )}
                                </div>
                            </div>

                            {/* this need to working right now */}

                            {showReply === data.id && (
                                <>
                                    {data.replies?.map((item) => (
                                        <div
                                            className="grid grid-cols-12 pb-[8px] justify-end"
                                            key={item.replyId}
                                        >
                                            <div className="col-span-1"></div>
                                            <div className="col-span-9">
                                                <div className="flex items-center gap-[6px] justify-start">
                                                    <img
                                                        alt={item?.created_by?.first_name}
                                                        src={item?.created_by?.avatar_url}
                                                        className="h-7 w-7 md:h-5 md:w-5 object-cover rounded-full"
                                                    />
                                                    <p className="text-black dark:text-white text-[14px] md:text-[12px]">
                                                        {item?.created_by?.first_name}
                                                    </p>
                                                    <p className="text-[#3ea8d8] text-[11px] md:text-[9px]">
                                                        {item?.time?.last_name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-2 flex justify-between items-center relative">
                                                <BsThreeDotsVertical
                                                    className="text-[#3ea8d8] text-[16px] cursor-pointer absolute right-[-6px]"
                                                    onClick={() => handleShow(item?.id)}
                                                />
                                                {item?.id === showId && (
                                                    <Card
                                                        className="w-20 absolute top-4 right-4 rounded-md p-1 text-black z-50"
                                                        ref={ref}
                                                    >
                                                        <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                                            Delete
                                                        </p>{" "}
                                                        <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                                            Report
                                                        </p>
                                                    </Card>
                                                )}
                                            </div>
                                            <div className="col-span-2"></div>
                                            <div className="col-span-9 flex justify-start mt-[2px]">
                                                <p className="text-[14px] md:text-[12px] text-gray-500 leading-[14px] p-[5px]">
                                                    {item?.post_comment_box}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    ))}

                {commentsData.length === 0 && (
                    <div className="flex justify-center items-center h-[200px]">
                        <p className="text-black dark:text-white text-[12px]">No Comments Found</p>
                    </div>
                )}
            </div>
            {showReplyPopup &&
                commentsData
                    .filter((item) => item.id === commentId)
                    .map((data) => (
                        <div className="sticky bottom-10 bg-gray-200 dark:bg-gray-900 w-full  items-center px-3">
                            <div className="flex justify-between items-center gap-x-2 gap-1 py-1 px-2 relative w-full">
                                <p className="text-black dark:text-white text-[10px] w-full">replay to</p>
                                <p
                                    className="text-red-900 text-[12px] font-semibold w-full text-end cursor-pointer"
                                    onClick={handleCloseReply}
                                >
                                    close
                                </p>
                            </div>
                            <div className="flex items-center gap-x-2 gap-1 py-1 px-2 relative w-full">
                                <img
                                    alt="profile"
                                    src={data?.created_by?.avatar_url}
                                    className="h-7 w-7 rounded-full object-cover"
                                />
                                <p className="text-black dark:text-white text-[10px]">
                                    {data?.created_by?.first_name} {data?.created_by?.last_name}
                                </p>
                            </div>
                            <p className="text-black dark:text-white text-[10px] line-clamp-2 px-3">
                                {data?.post_comment_box}
                            </p>
                        </div>
                    ))}
            <div className="sticky bottom-0 bg-[#0c0c0c] w-full flex items-center">
                <div className="flex justify-between items-center gap-x-2 gap-1 py-1 px-2 relative w-full">
                    <img
                        alt="profile"
                        src={profile.User_profile.avatar}
                        className="h-8 w-8 rounded-full object-cover"
                    />
                    <input
                        type="text"
                        value={comment}
                        placeholder="Add a comment..."
                        className="py-2 md:py-1 rounded-md focus:outline-none bg-white w-full pl-3 pr-8"
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <FaArrowCircleUp
                        className="absolute right-3 items-center text-black text-[24px] md:text-[20px] cursor-pointer"
                        onClick={() =>
                            showReplyPopup ? handleCreateReply() : handleCreateComments()
                        }
                    />
                </div>
            </div>

            {showReport && (
                <ReportModal
                    setShowReport={setShowReport}
                    groupId={groupId}
                    //   groupDetails={groupDetails}
                    commentId={commentId}
                    reportComment={true}
                />
            )}
        </div>
    );
}
