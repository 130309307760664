import React, { Fragment, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Comment from "./Comment";
import { neighborhoodNewsData, commentsData } from "../../data";

import { IoMdClose } from "react-icons/io";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { CiBookmarkPlus, CiSearch } from "react-icons/ci";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import { FaChevronDown, FaHeart, FaRegComment, FaRegHeart, FaRegShareSquare } from "react-icons/fa";

const NewsDetails = () => {
    const { id } = useParams();
    const news = neighborhoodNewsData.find((item) => item.id === parseInt(id));

    const menuRef = useRef(null);
    const [likes, setLikes] = useState({});
    const [activeMenuId, setActiveMenuId] = useState(null);
    const navigate = useNavigate();
    const [isBookmarks, setIsBookmarks] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [filter, setFilter] = useState("Most Recent");
    const filters = ["Most Recent", "Top Comments"];

    const [sharePopupOpen, setSharePopupOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const sharePopupRef = useRef(null);

    const [commentPopupOpen, setCommentPopupOpen] = useState(false);
    const commentPopupRef = useRef(null);

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setDropdownOpen(false);
    };

    const handleMenuToggle = (id, event) => {
        event.stopPropagation();
        setActiveMenuId(activeMenuId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setActiveMenuId(null);
        }
        if (sharePopupRef.current && !sharePopupRef.current.contains(event.target)) {
            setSharePopupOpen(false);
        }
        if (commentPopupRef.current && !commentPopupRef.current.contains(event.target)) {
            setCommentPopupOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLikeToggle = (id) => {
        setLikes((prevLikes) => ({
            ...prevLikes,
            [id]: !prevLikes[id],
        }));
    };

    const menuItems = ["Message", "Mute", "Report", "Block"];

    const filteredResults = neighborhoodNewsData.filter((data) =>
        data.firstName.toLowerCase().includes(searchInput.toLowerCase())
    );

    if (!news) {
        return <div>News not found</div>;
    }

    return (
        <Fragment>
            <div className="sticky -top-[15rem] bg-white dark:bg-black dark:text-white pb-[2px] z-10">
                <div className="flex items-center justify-center gap-x-4 border-b border-gray-400 dark:border-gray-800 p-2 relative">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="absolute left-2 text-[24px] cursor-pointer"
                    />
                    <p className="text-[16px] font-bold line-clamp-1 ml-2 uppercase">
                        {news.title}
                    </p>
                </div>
                <div className="px-2 pb-2 my-2 border-b border-gray-400 dark:border-gray-800">
                    <div className="flex items-start">
                        <div className="w-14">
                            <img
                                alt={news.userName}
                                src={news.profileImage}
                                className="w-10 h-10 object-cover rounded-full"
                            />
                        </div>
                        <div className="flex items-start justify-between w-full ml-1">
                            <div className="flex flex-col">
                                <p className="text-[14px] font-bold">
                                    {news.userName}
                                </p>
                                <p className="text-[10px] text-gray-500">
                                    {news.time}
                                </p>
                                <p className="flex items-center text-[10px] text-gray-500">
                                    <IoLocationOutline className="text-[14px] ml-[-3px]" />
                                    {news.location}
                                </p>
                            </div>
                            <div className="relative" ref={menuRef}>
                                <HiOutlineDotsVertical
                                    className="text-[18px] cursor-pointer text-gray-600 mr-[-5px]"
                                    onClick={(event) => handleMenuToggle(news.id, event)}
                                />
                                {activeMenuId === news.id && (
                                    <div className="absolute right-0 mt-2 w-16 bg-white dark:bg-[#242323] border dark:border-gray-800 overflow-hidden rounded shadow-lg">
                                        {menuItems.map((item, index) => (
                                            <div
                                                key={index}
                                                className="px-2 py-1 text-[12px] text-gray-700 dark:text-gray-100 dark:hover:bg-[#2b2b2b] hover:bg-gray-100 hover:text-black cursor-pointer"
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <p className="text-[12px] font-bold">
                            {news.title}
                        </p>
                        <img
                            alt={news.title}
                            src={news.postImage}
                            className="w-full h-[160px] object-cover rounded-lg mt-1 cursor-pointer"
                        />
                        <p className="text-[10px] leading-[15px] mt-2">
                            {news.description}
                        </p>
                        <div className="flex items-center justify-between mt-1">
                            <div className="flex items-center gap-x-4 relative">
                                <div className="flex flex-col items-center">
                                    {likes[news.id] ? (
                                        <FaHeart className="text-[16px] text-red-600 cursor-pointer" onClick={() => handleLikeToggle(news.id)} />
                                    ) : (
                                        <FaRegHeart className="text-[16px] text-gray-600 cursor-pointer" onClick={() => handleLikeToggle(news.id)} />
                                    )}
                                    <p className="text-[8px] text-gray-500">{news.likes + (likes[news.id] ? 1 : 0)}</p>
                                </div>
                                <div className="flex flex-col items-center">
                                    <FaRegComment className="text-[16px] text-gray-600 cursor-pointer" onClick={() => setCommentPopupOpen(true)} />
                                    <p className="text-[8px] text-gray-500">{news.comments}</p>
                                </div>
                                <div className="flex flex-col items-center" onClick={() => setSharePopupOpen(!sharePopupOpen)}>
                                    <FaRegShareSquare className="text-[16px] text-gray-600 cursor-pointer" />
                                    <p className="text-[8px] text-gray-500">{news.shares}</p>
                                </div>
                                {sharePopupOpen && (
                                    <div ref={sharePopupRef} className="absolute left-24 -top-10 p-1 w-[10rem] bg-white dark:bg-[#2b2b2b] border dark:border-gray-800 overflow-hidden rounded shadow-lg">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="search"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                                className="w-full h-6 pl-2 pr-6 py-[2px] border dark:bg-[#272626] border-gray-800 placeholder:text-[12px] text-[10px] rounded-lg focus:outline-none"
                                            />
                                            <CiSearch className="absolute right-1 top-1/2 transform -translate-y-1/2 transition-transform text-gray-500 hover:text-gray-700 text-[18px] cursor-pointer" />
                                        </div>
                                        <div className="flex items-center justify-between p-1">
                                            <div className="flex items-center gap-x-3 overflow-x-scroll">
                                                {filteredResults.length > 0 ? (
                                                    filteredResults.map((data) => (
                                                        <div className="flex flex-col items-center cursor-pointer" key={data.id}>
                                                            <div className="h-6 w-6">
                                                                <img
                                                                    alt={data.firstName}
                                                                    src={data.profileImage}
                                                                    className="w-6 h-6 object-cover rounded-full"
                                                                />
                                                            </div>
                                                            <p className="text-[8px]">{data.firstName}</p>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="text-[8px] text-gray-500">No results found</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {isBookmarks ? (
                                <BsBookmarkCheckFill className="text-[18px] text-gray-600 dark:text-blue-400 mr-[-2px] cursor-pointer" onClick={() => setIsBookmarks(!isBookmarks)} />
                            ) : (
                                <CiBookmarkPlus className="text-[22px] text-gray-600 mr-[-5px] cursor-pointer" onClick={() => setIsBookmarks(!isBookmarks)} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center mt-[-2px] relative">
                    <div className="absolute left-0">
                        <div
                            ref={dropdownRef}
                            className="m-2 flex items-center gap-x-[5px] border-b dark:border-gray-800 w-fit relative"
                        >
                            <p className="text-[10px] text-gray-500" onClick={() => setDropdownOpen(!dropdownOpen)}>{filter}</p>
                            <FaChevronDown
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className={`text-[8px] text-gray-500 cursor-pointer transition-transform duration-300 ${dropdownOpen ? "rotate-180" : ""}`}
                            />
                            {dropdownOpen && (
                                <div className="absolute w-[5.5rem] top-full left-0 mt-1 bg-white dark:bg-[#272525] border dark:border-gray-800 overflow-hidden rounded shadow-lg">
                                    {filters.filter(f => f !== filter).map((f) => (
                                        <p
                                            key={f}
                                            onClick={() => handleFilterChange(f)}
                                            className="text-[10px] text-gray-500 dark:text-gray-200 dark:hover:bg-[#2b2b2b] cursor-pointer px-2 py-1 hover:bg-gray-300 hover:text-black"
                                        >
                                            {f}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <p className="text-[14px] font-bold">Comments</p>
                </div>
            </div>

            <div className="mb-5">
                <Comment comments={commentsData} />
            </div>

            {commentPopupOpen && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
                    <div ref={commentPopupRef} className="bg-white dark:bg-[#2b2b2b] dark:border p-4 m-2 rounded shadow-lg w-80">
                        <div className="flex justify-between items-center mb-2">
                            <p className="text-[14px] font-bold dark:text-gray-100">Add a Comment</p>
                            <IoMdClose 
                                onClick={() => setCommentPopupOpen(false)}
                                className="text-[20px] text-gray-900 dark:text-white hover:text-red-700 cursor-pointer"
                            />
                        </div>
                        <textarea
                            rows={4}
                            placeholder="Write your comment..."
                            className="w-full p-2 border border-gray-400 dark:border-gray-800 dark:text-gray-200 dark:bg-[#2b2b2b] rounded resize-none focus:outline-none text-[12px]"
                        />
                        <button className="mt-2 bg-gray-900 hover:bg-black text-[12px] text-white px-4 py-1 rounded transition-colors duration-500">
                            Comment
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default NewsDetails;