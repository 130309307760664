import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsMailbox2 } from "react-icons/bs";
import { TbPhotoEdit } from "react-icons/tb";
import { FaUserCheck } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import ShareModal from "../Modals/ShareModal";
import { IoNotifications } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { getPublicPrivateToggle } from "../../Redux/ApiSlice/UserStatusSlice";
import AccountNameEditModal from "../../Component/Modals/AccountPageModal/AccountNameEditModal";
import { AccountSettingModal } from "../../Component/Modals/AccountPageModal/AccountSettingModal";
import AccountProfileEditModal from "../../Component/Modals/AccountPageModal/AccountProfileEditModal";
import AccountDescriptionModal from "../../Component/Modals/AccountPageModal/AccountDescriptionModal";
import {
    AccountProfile,
    AccountProfileDescription,
    AccountProfileImageEdit,
    AccountProfilePosts,
    UserAccountFollowerlist,
    UserAccountFollowinglist,
} from "../../Redux/ApiSlice/AccountProfileSlice";


const theme = {
    dialog: {
        defaultProps: {
            size: "md",
            dismiss: {},
            animate: {
                unmount: {},
                mount: {},
            },
            className: "",
        },
        valid: {
            sizes: ["xs", "sm", "md", "lg", "xl", "xxl"],
        },
        styles: {
            base: {
                backdrop: {
                    display: "grid",
                    placeItems: "place-items-center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "w-screen",
                    height: "h-screen",
                    backgroundColor: "bg-black",
                    backgroundOpacity: "bg-opacity-60",
                    backdropFilter: "backdrop-blur-sm",
                },
                container: {
                    position: "relative",
                    bg: "bg-white",
                    m: "m-4",
                    borderRadius: "rounded-lg",
                    boxShadow: "shadow-2xl",
                    color: "text-blue-gray-500",
                    fontSmoothing: "antialiased",
                    fontSize: "text-base",
                    fontWeight: "font-light",
                    lineHeight: "leading-relaxed",
                },
            },
            sizes: {
                xs: {
                    width: "w-full md:w-3/5 lg:w-2/5 2xl:w-1/4",
                    minWidth: "min-w-[80%] md:min-w-[60%] lg:min-w-[40%] 2xl:min-w-[25%]",
                    maxWidth: "max-w-[80%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[25%]",
                },
                sm: {
                    width: "w-full md:w-2/3 lg:w-2/4 2xl:w-1/3",
                    minWidth:
                        "min-w-[80%] md:min-w-[66.666667%] lg:min-w-[50%] 2xl:min-w-[33.333333%]",
                    maxWidth:
                        "max-w-[80%] md:max-w-[66.666667%] lg:max-w-[50%] 2xl:max-w-[33.333333%]",
                },
                md: {
                    width: "w-full md:w-3/4 lg:w-3/5 2xl:w-2/5",
                    minWidth: "min-w-[90%] md:min-w-[75%] lg:min-w-[60%] 2xl:min-w-[40%]",
                    maxWidth: "max-w-[90%] md:max-w-[75%] lg:max-w-[60%] 2xl:max-w-[40%]",
                },
                lg: {
                    width: "w-full md:w-5/6 lg:w-3/4 2xl:w-3/5",
                    minWidth:
                        "min-w-[90%] md:min-w-[83.333333%] lg:min-w-[75%] 2xl:min-w-[60%]",
                    maxWidth:
                        "max-w-[90%] md:max-w-[83.333333%] lg:max-w-[75%] 2xl:max-w-[60%]",
                },
                xl: {
                    width: "w-full md:w-5/6 2xl:w-3/4",
                    minWidth: "min-w-[95%] md:min-w-[83.333333%] 2xl:min-w-[75%]",
                    maxWidth: "max-w-[95%] md:max-w-[83.333333%] 2xl:max-w-[75%]",
                },
                xxl: {
                    display: "flex",
                    flexDirection: "flex-col",
                    width: "w-screen",
                    minWidth: "min-w-[100vw]",
                    maxWidth: "max-w-[100vw]",
                    height: "h-screen",
                    minHeight: "min-h-[100vh]",
                    maxHeight: "max-h-[100vh]",
                    m: "m-0",
                    borderRadius: "rounded-none",
                },
            },
        },
    },
};

const AccountProfileSection = React.memo(({
    handleOpenSideBar,
    isDrawerOpen,
    setActiveTab,
    setShow, show
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedImg, setSelectedImg] = useState(null);
    const [showSettingIcon, setShowSettingIcon] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDecModal, setOpenDecModal] = useState(false);
    const [share, setShare] = useState(false);
    // const [activeTab, setActiveTab] = useState("");
    const [switchTab, setSwitchTab] = useState(false);
    const switchTabRef = useRef(null);
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const {
        User_profile,
        loading,
        User_followingList,
        User_followersList,
        User_posts,
    } = ProfileDetails;

    const [accountDec, setAccountDec] = useState({
        about_us: User_profile.about_us,
    });

    const [ProfileName, setProfileName] = useState({
        first_name: "",
        last_name: "",
    });

    const openDrawer = () => setShowSettingIcon(true);
    const closeDrawer = () => setShowSettingIcon(false);
    const [openProfileEdit, setOpenProfileEdit] = useState(false);
    const handleOpen = () => setOpen(!open);
    const handleOpenDescription = () => setOpenDecModal(!openDecModal);

    const handleOpenProfileIcon = () => {
        setSelectedImg(null);
        setOpenProfileEdit(!openProfileEdit);
    };

    const handleSubmitDec = async (editDec) => {
        setAccountDec(editDec);
        handleOpenDescription();
        await dispatch(AccountProfileDescription(editDec));
        dispatch(AccountProfile());
    };

    const handleSubmitProfileImage = async (selected) => {
        handleOpenProfileIcon();
        setOpenProfileEdit(!openProfileEdit);
        await dispatch(AccountProfileImageEdit(selected));
        dispatch(AccountProfile());
    };

    const handleOpenShareModal = () => {
        setShare(!share);
    };

    useEffect(() => {
        dispatch(AccountProfile());
        dispatch(AccountProfilePosts(User_profile.id));
        setProfileName({
            first_name: User_profile.first_name || "",
            last_name: User_profile.last_name || "",
        });
        dispatch(UserAccountFollowerlist());
        dispatch(UserAccountFollowinglist());
    }, [
        dispatch,
        User_profile.first_name,
        User_profile.last_name,
        User_profile.id,
    ]);

    let originalURL = User_profile.avatar;

    const ref = useRef();

    const handleSelect = (value) => {
        setActiveTab(value);
        setShow(!show);
    };

    const handleClickOutside = () => {
        setShow(null);
        setActiveTab("");
    };
    useOutsideClick(ref, handleClickOutside);

    useOutsideClick(switchTabRef, () => setSwitchTab(false));

    useEffect(() => {
        dispatch(getPublicPrivateToggle());
    }, []);

    return (
        <div className={show ? "overflow-hidden" : ""}>
            <div className="grid grid-cols-12 px-2 -mt-1.5">
                <div className="col-span-6 flex items-center gap-x-1 relative">
                    <p className="text-[#000000] dark:text-white font-bold text-[12px] flex items-center gap-x-1">
                        <span>
                            {(() => {
                                const fullName = `${User_profile.username}`;
                                return fullName.length <= 12 ? fullName : fullName.substring(0, 12) + '...';
                            })()}
                        </span>
                        <span>
                            <MdKeyboardArrowDown
                                onClick={() => setSwitchTab(!switchTab)}
                                className={`cursor-pointer text-[1.3rem] ml-[3px] dark:text-white ${switchTab && "rotate-180"}`}
                            />
                        </span>
                    </p>
                    <FiPlusCircle
                        onClick={() => setSwitchTab(true)}
                        className="text-[14px] ml-[4px] text-black dark:text-white cursor-pointer"
                    />
                    {switchTab && (
                        <div ref={switchTabRef} className="absolute top-8 z-50 left-0 bg-white border border-gray-400 rounded-lg overflow-hidden">
                            <p className="px-2 py-1 font-bold text-[12px] flex items-center text-black cursor-pointer hover:bg-gray-200">
                                {(() => {
                                    const fullName = `${User_profile.username}`;
                                    return fullName.length <= 12 ? fullName : fullName.substring(0, 12) + '...';
                                })()}
                            </p>
                            <p className="px-2 py-1 font-bold text-[12px] flex items-center text-black cursor-pointer border-t border-gray-400 hover:bg-gray-200">
                                Add New Account
                            </p>
                        </div>
                    )}
                </div>
                <div className="col-span-6 flex justify-end items-center gap-x-3">
                    <div
                        onClick={() => navigate("/notification")}
                        class="relative inline-flex text-red-700 items-center cursor-pointer"
                    >
                        <IoNotifications className="text-[16px] text-black dark:text-white" />
                        {true &&
                            <span class="absolute rounded-full text-[8px] font-medium content-[''] leading-none grid place-items-center top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4 bg-red-500 text-white min-w-[14px] min-h-[14px]">
                                5
                            </span>
                        }
                    </div>
                    <div className="grid grid-cols-12 overflow-hidden mt-[4px]">
                        <div className="col-span-12">
                            <button onClick={handleOpenSideBar} className="mt-[3px]">
                                {isDrawerOpen ? (
                                    <XMarkIcon className={`h-6 w-6 stroke-2 text-black dark:text-white`} />
                                ) : (
                                    <Bars3Icon className={`h-6 w-6 stroke-2 text-black dark:text-white`} />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 px-2 mt-1 relative">
                <div className="col-span-6 h-full">
                    <div className="flex flex-col">
                        <div className="relative group w-fit h-fit rounded-[50%] cursor-pointer">
                            <img
                                alt="IMG"
                                src={originalURL}
                                className="h-[5rem] w-[5rem] rounded-[50%] object-cover"
                            />
                            <div onClick={handleOpenProfileIcon} className="absolute inset-0 z-10 items-center justify-center hidden h-[5rem] w-[5rem] rounded-[50%] object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                                <button className="flex items-center justify-center h-4 w-4 text-center rounded-full cursor-pointer ring-1 ring-transparent ">
                                    <TbPhotoEdit className="text-white" />
                                </button>
                            </div>
                        </div>
                        <div>
                            <p className="text-[#000000] dark:text-white font-bold text-sm flex">
                                {User_profile.first_name} {User_profile.last_name}
                            </p>
                            {User_profile?.portfolio && (
                                <Link
                                    to={User_profile?.portfolio}
                                    className="text-black dark:text-white hover:text-blue-700 cursor-pointer text-[10px] leading-[14px] line-clamp-1"
                                >
                                    {User_profile?.portfolio}
                                </Link>
                            )}
                            <p className="text-[#000000] dark:text-white font-medium text-[12px] mt-1 line-clamp-2 leading-[14px] cursor-pointer">
                                {User_profile.about_us}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center col-span-6">
                    <div className="flex flex-col justify-center items-center pb-5">
                        <div className="w-full h-10 items-center mt-1 justify-center flex">
                            <div
                                onClick={() => handleSelect("posts")}
                                className="h-10 text-[8px] font-bold text-black dark:text-white justify-center items-center flex flex-col w-10"
                            >
                                <p className="flex items-center justify-center relative w-full">
                                    <p className="absolute left-0 text-[12px] cursor-pointer">
                                        {User_profile?.post_count}
                                    </p>
                                    <BsMailbox2 className="text-[1rem] cursor-pointer dark:text-white" />
                                </p>
                                <p className="text-[8px] font-bold text-black dark:text-white mt-0.5 cursor-pointer">
                                    Posts
                                </p>
                            </div>
                        </div>
                        <div className="relative flex justify-center gap-x-12">
                            <div
                                onClick={() => handleSelect("followings")}
                                className="w-auto h-10 text-[8px] font-bold text-black dark:text-white justify-center items-center flex flex-col"
                            >
                                <p className="flex items-center justify-center relative w-full cursor-pointer">
                                    <p className="absolute left-0 text-[12px]">
                                        {User_followingList?.following_count}
                                    </p>
                                    <FaUserCheck className="text-[1rem]" />
                                </p>
                                <p className="text-[8px] font-bold text-black dark:text-white mt-0.5 cursor-pointer">
                                    Following
                                </p>
                            </div>
                            <div
                                onClick={() => handleSelect("followers")}
                                className="w-auto h-10 text-[8px] font-bold text-black dark:text-white justify-center items-center flex flex-col"
                            >
                                <p className="flex items-center justify-center relative w-full cursor-pointer">
                                    <p className="absolute left-0 text-[12px]">
                                        {User_followersList?.followers_count}
                                    </p>
                                    <FaUserGroup className="text-[1rem] dark:text-white" />
                                </p>
                                <p className="text-[8px] font-bold text-black dark:text-white mt-0.5 cursor-pointer">
                                    Followers
                                </p>
                            </div>
                        </div>
                        <div className="w-full items-center justify-center flex mt-1">
                            <button
                                onClick={() => navigate("/account/edit/profile")}
                                className="px-2 py-0.5 text-[12px] bg-[#232323] dark:bg-[#2d2d33] text-white rounded-md"
                            >
                                Edit Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <AccountNameEditModal
                open={open}
                handleOpen={handleOpen}
                theme={theme}
                User_profile={User_profile}
                loading={loading}
                ProfileName={ProfileName}
                setProfileName={setProfileName}
            />

            <AccountDescriptionModal
                open={openDecModal}
                handleOpen={handleOpenDescription}
                theme={theme}
                accountDec={accountDec}
                setAccountDec={setAccountDec}
                handleSubmitDec={handleSubmitDec}
            />

            <AccountProfileEditModal
                open={openProfileEdit}
                handleOpen={handleOpenProfileIcon}
                theme={theme}
                setSelectedImg={setSelectedImg}
                selectedImg={selectedImg}
                User_profile={User_profile}
                replacedURL={originalURL}
                dispatch={dispatch}
                handleSubmitProfileImage={handleSubmitProfileImage}
            />

            <AccountSettingModal
                openDrawer={openDrawer}
                open={showSettingIcon}
                closeDrawer={closeDrawer}
                User_profile={User_profile}
            />

            <ShareModal
                openBottom={share}
                closeDrawerBottom={handleOpenShareModal}
            />
        </div>
    );
});

export default AccountProfileSection;
