import React, { Fragment, useRef, useState } from "react";
import CreatePassword from "./Private/CreatePassword";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import AccountTabModal from "../../Component/AccountPage/AccountTabModal";
import AccountSidebar from "../../Component/AccountPage/AccountSidebar";
import { useOutsideClick } from "../../Hooks/useOutsideClick";

const PrivateCreate = () => {

   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const [show, setShow] = useState(false);
   const [activeTab, setActiveTab] = useState("");
   const ref = useRef(null);

   const handleClickOutside = () => {
      setShow(null);
      setActiveTab("");
   };
   useOutsideClick(ref, handleClickOutside);

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   return (
      <Fragment>
         <div className="bg-[#fff] dark:bg-black overflow-hidden h-full relative ">
            {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT PRIVATE PAGE-FE - V11 / JULY 1, 2024
         </p> */}
            <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
               Copyright © 2025 Galileo One Inc
            </p>
            <div className="">
               <AccountProfileSection
                  handleOpenSideBar={openDrawer}
                  handleCloseSideBar={closeDrawer}
                  isDrawerOpen={isDrawerOpen}
                  setActiveTab={setActiveTab}
                  setShow={setShow}
                  show={show} />
            </div>
            <AccountTab activeTab={"Private"} />
            <div className="overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
               <CreatePassword />
            </div>

            <div className="grid grid-cols-12 overflow-hidden">
               <div className="col-span-12">
                  <AccountSidebar
                     isDrawerOpen={isDrawerOpen}
                     setIsDrawerOpen={setIsDrawerOpen}
                     openDrawer={openDrawer}
                     closeDrawer={closeDrawer}
                  />
               </div>
            </div>



         </div>
         {show && (
            <div className={"absolute h-full w-full top-0 overflow-hidden"}>
               <div
                  className="absolute h-screen w-full top-0 opacity-100 bg-opacity-70 bg-black z-50"
                  onClick={handleClickOutside}
               />
               <div
                  ref={ref}
                  className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
               >
                  <AccountTabModal
                     ref={ref}
                     activeTab={activeTab}
                     setActiveTab={setActiveTab}
                  />
               </div>
            </div>
         )}

      </Fragment>
   );
};
export default PrivateCreate;
