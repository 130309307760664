import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
   const naviagte = useNavigate()
   const [currentPassword, setCurrentPassword] = React.useState("")
   const [newPassword, setNewPassword] = React.useState("")
   const currentPass = localStorage.getItem("Privatepassword");

   const handleSave = () => {
      if (currentPassword !== currentPass) {
         toast.error("Current password is incorrect")
         return
      }
      else {
         if (currentPassword.length === 6 || newPassword.length === 6) {
            toast.error("Please fill all the fields")
            return
         }
         else {
            toast.success("Password Changed Successfully")
            localStorage.setItem("Privatepassword", newPassword)
            naviagte("/account/private")
         }
      }
   }

   return (
      <div className="h-full w-full bg-gradient-to-r flex flex-col justify-center items-center px-2">
         <Toaster position="top-right" />
         <h1 className="text-blue-600 font-bold text-[1.5rem] ">FORGOT PASSWORD?</h1>
         <div className="mb-4 mt-3 w-full px-8">
            <label
               htmlFor="username"
               className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
            >
               Current Password
            </label>
            <input
               type="text"
               max={6}
               value={currentPassword}
               onChange={(e) => e.target.value.length <= 6 ? setCurrentPassword(e.target.value) : null}
               id="username"
               placeholder="Current 6 digit password...."
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
         </div>
         <div className="mb-4 w-full px-8">
            <label
               htmlFor="username"
               className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
            >
               New Password
            </label>
            <input
               type="text"
               max={6}
               value={newPassword}
               onChange={(e) => e.target.value.length <= 6 ? setNewPassword(e.target.value) : null}
               id="username"
               placeholder="New 6 digit password...."
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
         </div>
         <button
            onClick={handleSave}
            className="bg-orange-700 hover:bg-orange-800 dark:bg-gray-700 dark:hover:bg-[#545353] text-white font-bold py-[2px] px-4 rounded focus:outline-none focus:shadow-outline"
         >
            Save
         </button>
      </div>
   );
}
