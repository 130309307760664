import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { FaCommentDots, FaFlag, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { FaShareSquare } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import OtherGroupProfileSection from "../Component/OtherUserGroup/OtherGroupProfileSection";
import OtherGroupTab from "../Component/OtherUserGroup/OtherGroupTab";
import { Card } from "@material-tailwind/react";
import { useOutsideClick } from "../Hooks/useOutsideClick";
import { useNavigate, useParams } from "react-router-dom";
import { getGroupDetails, groupJoin, groupLeave, groupStatusChange } from "../Redux/ApiSlice/Group/GroupSlice";
import { deleteGroupPost, getGroupPostsList, mostCommentedPostsList, newPostsList, topPostsList, trendingPostsList } from "../Redux/ApiSlice/Group/GroupPostsSlice";
import { useDispatch, useSelector } from "react-redux";
import ReportModal from "../Component/Modals/Group/ReportModal";
import LeaveModal from "../Component/Modals/Group/LeaveModal";
import toast, { Toaster } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { GoMute, GoUnmute } from "react-icons/go";
import { HomepageVideoLike } from "../Redux/ApiSlice/HomePageVideoSlice";
import ReactPlayer from "react-player";
import { SocialIcon } from "react-social-icons";
import { IoSearchCircle } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

const OtherUserGroupsPage = () => {
   const params = useParams()
   const { groupId } = params;
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [showLeave, setShowLeave] = useState(false);
   const groupDetails = useSelector((state) => state.group)
   const groupPosts = useSelector((state) => state.groupPosts)
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile } = ProfileDetails;

   const Toolref = useRef(null);
   const [menuId, setMenuId] = useState(null);
   const [open, setOpen] = useState(false);
   const [openTool, setOpenTool] = useState(false);
   const [groupLock, setGroupLock] = useState(false);
   const [postData, setPostData] = useState([])
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [GroupData, setGroupData] = useState({})
   const [showReport, setShowReport] = useState(false);
   // VIDEO PLAYER FUNCTIONALITY
   const [playing, setPlaying] = useState({});
   const [muted, setMuted] = useState(false);
   const [playedSeconds, setPlayedSeconds] = useState({});
   const [duration, setDuration] = useState({});
   const menuRef = useRef(null);
   const [showShare, setShowShare] = useState(false);
   const [userPosition, setUserPosition] = useState(0);
   const [socialMediaPosition, setSocialMediaPosition] = useState(0);
   const [isExpanded, setIsExpanded] = useState(false);

   useEffect(() => {
      setGroupData(groupDetails)
   }, [groupDetails])

   
   const toggleSearchBar = () => {
      setIsExpanded(!isExpanded);
   };

  const handleLeaveGroup = async () => {
      try {
         const response = await dispatch(groupLeave(groupId))
         setGroupData({ ...GroupData, data: { ...GroupData?.data, user_joined: !GroupData?.data?.user_joined } })
         toast.success(response?.payload?.message)
      } catch (error) {
         console.log(error)
      }
   }

   const handleJoinGroup = async () => {
      try {
         const response = await dispatch(groupJoin(groupId))
         toast.success(response?.payload?.message)
         setGroupData({ ...GroupData, data: { ...GroupData?.data, user_joined: !GroupData?.data?.user_joined } })
      } catch (error) {
         console.log(error)
      }
   }

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   useEffect(() => {
      setPostData(groupPosts?.data?.results)
   }, [groupPosts])

   const handlePlayPause = (id) => {
      setPlaying(prev => {
         const newState = { ...prev };
         Object.keys(newState).forEach(key => {
            newState[key] = false;
         });
         newState[id] = !prev[id]; // Toggle between play and pause
         return newState;
      });
   };

   // SOUND FUNCTIONALITY
   const handleMute = () => {
      setMuted(!muted);
   };

   const handleProgress = (id, state) => {
      setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
   };

   const handleDuration = (id, duration) => {
      setDuration(prev => ({ ...prev, [id]: duration }));
   };

   const formatDuration = (seconds) => {
      const date = new Date(0);
      date.setSeconds(seconds);
      const timeString = date.toISOString().substr(14, 5);
      return timeString;
   };



   const handleClickThreeDots = (id) => setMenuId(id);

   useOutsideClick(menuRef, () => setMenuId(null));
   useOutsideClick(Toolref, () => setOpenTool(null));

   const getGroupData = useCallback(async () => {
      try {
         const response = await dispatch(getGroupDetails(groupId))
         await dispatch(getGroupPostsList(groupId))
         const result = response.payload

      } catch (error) {
         console.log(error)
      }
   }, [dispatch, groupId])

   const getGroupPostData = useCallback(async () => {
      try {
         await dispatch(getGroupPostsList(groupId))
      } catch (error) {
         console.log(error)
      }
   }, [dispatch, groupId])

   const getMostCommentedPostData = useCallback(async () => {
      try {

         await dispatch(mostCommentedPostsList(groupId))

      } catch (error) {
         console.log(error)
      }
   }, [dispatch, groupId])

   const trendingPostData = useCallback(async () => {
      try {

         await dispatch(trendingPostsList(groupId))

      } catch (error) {
         console.log(error)
      }
   }, [dispatch, groupId])

   const topPostData = useCallback(async () => {
      try {
         await dispatch(topPostsList(groupId))
      } catch (error) {
         console.log(error)
      }
   }, [dispatch, groupId])

   const newPostData = useCallback(async () => {
      try {
         await dispatch(newPostsList(groupId))
      } catch (error) {
         console.log(error)
      }
   }, [dispatch, groupId])


   // const handleHide = async (postId) => {
   //    try {
   //       await dispatch(hideGroupPost({ groupId, postId }))
   //       // const newData = postData.filter((item) => item.id !== postId)
   //       // setPostData(newData)
   //       setMenuId(null)
   //       toast.success("post hidden successfully")
   //    } catch (error) {
   //       toast.error("post failed to hide the post")
   //       console.log(error)
   //    }
   // }

   function timeAgo(timestamp) {
      const now = new Date();
      const past = new Date(timestamp);
      const seconds = Math.floor((now - past) / 1000);

      const intervals = {
         year: 31536000, // 60 * 60 * 24 * 365
         month: 2592000, // 60 * 60 * 24 * 30
         day: 86400,     // 60 * 60 * 24
         hour: 3600,     // 60 * 60
         minute: 60,
         second: 1
      };

      for (const key in intervals) {
         const interval = Math.floor(seconds / intervals[key]);
         if (interval >= 1) {
            return `${interval}${key[0]} ago`;
         }
      }

      return "just now";  // In case it's less than a second ago
   }


   const handleDeletePost = async (postId) => {
      try {
         const response = await dispatch(deleteGroupPost({ groupId, postId }))
         console.log(response)
         const newData = postData.filter((item) => item.id !== postId)
         setPostData(newData)
         setMenuId(null)
         toast.success("post deleted successfully")
      } catch (error) {
         toast.error("post failed to delete")
      }
   }


   const handleLikeVideo = useCallback(async (id) => {
      try {
         const responce = await dispatch(HomepageVideoLike(id))

         if (responce.payload?.message === "like removed") {
            setPostData((prevPosts) =>
               prevPosts.map((post) =>
                  post.id === id ? { ...post, like_status: !post.like_status, likes_count: post.likes_count - 1 } : post
               )
            );
         }
         else {
            setPostData((prevPosts) =>
               prevPosts.map((post) =>
                  post.id === id ? { ...post, like_status: !post.like_status, likes_count: post.likes_count + 1 } : post
               )
            );
         }
      } catch (error) {
         console.log(error)
      }
   }, [dispatch]);

   useEffect(() => {
      if (GroupData?.data?.user_joined) {
         navigate(`/createdgroup/${groupId}`)
      }
      setGroupLock(GroupData?.data?.group_type === "private" ? true : false)
   }, [GroupData])

   useEffect(() => {
      getGroupData()
      getGroupPostData()
   }, [getGroupData, getGroupPostData])


   const userData = [
      {
         id: 1,
         userImg:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 2,
         userImg:
            "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 3,
         userImg:
            "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 4,
         userImg:
            "https://images.unsplash.com/photo-1574701148212-8518049c7b2c?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 5,
         userImg:
            "https://images.unsplash.com/photo-1622236297949-eb7f7aa3a3cc?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 6,
         userImg:
            "https://images.unsplash.com/photo-1578979879663-4ba6a968a50a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
   ];

   const socialMediaData = [
      {
         id: 1,
         iconname: "telegram",
      },
      {
         id: 2,
         iconname: "whatsapp",
      },
      {
         id: 3,
         iconname: "facebook",
      },
      {
         id: 4,
         iconname: "x",
      },
      {
         id: 5,
         iconname: "snapchat",
      },
      {
         id: 6,
         iconname: "instagram",
      },
   ];

   const handleUserClick = () => {
      setUserPosition(
         (prevPosition) => (prevPosition + 1) % (userData.length - 3)
      );
   };

   const handleSocialClick = () => {
      setSocialMediaPosition(
         (prevPosition) => (prevPosition + 1) % (socialMediaData.length - 3)
      );
   };

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setShowShare(null); // Close menu if clicked outside
         setIsExpanded(false)
      }
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);




   useEffect(() => {
      setPostData(groupPosts?.data?.results)
   }, [groupPosts])


   useEffect(() => {
      setGroupData(groupDetails)
   }, [groupDetails])

   const handleSelectMenu = (id) => setMenuId(id);
   useOutsideClick(menuRef, () => setMenuId(0));


   function timeAgo(timestamp) {
      const now = new Date();
      const past = new Date(timestamp);
      const seconds = Math.floor((now - past) / 1000);

      const intervals = {
         year: 31536000, // 60 * 60 * 24 * 365
         month: 2592000, // 60 * 60 * 24 * 30
         day: 86400,     // 60 * 60 * 24
         hour: 3600,     // 60 * 60
         minute: 60,
         second: 1
      };

      for (const key in intervals) {
         const interval = Math.floor(seconds / intervals[key]);
         if (interval >= 1) {
            return `${interval}${key[0]} ago`;
         }
      }

      return "just now";  // In case it's less than a second ago
   }


   useEffect(() => {
      getGroupData()
   }, [getGroupData])


   return (
      <Fragment>
         <section className="h-full overflow-hidden dark:bg-black dark:dark:text-white">
            <Toaster position="top-right" />
            <div className="w-full flex justify-center items-center absolute top-0">
               <p className="text-[#ff3b3b] text-center font-bold text-[10px] uppercase">
                  Copyright © 2025 Galileo One Inc
               </p>
               {/* <p className="text-[#ff3b3b] text-center font-bold text-[10px] uppercase">
                  CC / OTHERS GROUP HOMEPAGE / FE / V6 / oct 11, 2024
               </p> */}
            </div>

            <OtherGroupProfileSection handleLeaveGroup={handleLeaveGroup}
               groupId={groupId}
               groupDetails={GroupData?.data}
               showLeave={showLeave}
               setShowLeave={setShowLeave}
               setGroupData={setGroupData}
               GroupData={GroupData}
               setShowReport={setShowReport}
            />
            <OtherGroupTab
               groupId={groupId}
               getGroupPosts={getGroupPostData}
               mostComments={getMostCommentedPostData}
               trendingPosts={trendingPostData}
               topPosts={topPostData}
               newPosts={newPostData}
            />

            <div className="h-[55%] md:h-[50%] overflow-y-scroll">
      
               <div className="grid grid-cols-12 overflow-y-scroll">

                  {groupPosts.loading ?

                     <div className="col-span-12 flex  justify-center items-center h-[25vh]">
                        <ThreeDots
                           visible={true}
                           height="90"
                           width="90"
                           color="#656665"
                           radius="9"
                           ariaLabel="three-dots-loading"
                           wrapperStyle={{}}
                           wrapperClass=""

                        />
                     </div>
                     :
                     postData?.length > 0 && postData?.map((item) => (
                        <>
                           <div className="col-span-12 grid grid-cols-12 px-3 pt-2 pb-1" key={item?.id}>
                              <div className="col-span-2">
                                 <img
                                    alt="profile"
                                    className="h-12 md:h-10 w-12 md:w-10 rounded-full object-cover"
                                    src={item?.created_by?.avatar}
                                 />
                              </div>
                              <div className="col-span-9">
                                 <p className="dark:text-white text-[18px] md:text-[15px]">{item?.created_by?.first_name} {item?.created_by?.last_name}</p>
                                 <p className="text-[#3ea8d8] text-[15px] md:text-[12px] font-bold">{timeAgo(item?.created_at)}</p>
                              </div>
                              <div className="col-span-1 relative">
                                 <HiDotsVertical
                                    className="dark:text-white text-[25px] absolute -right-2 cursor-pointer"
                                    onClick={() => handleClickThreeDots(item?.id)}
                                 />
                                 {menuId === item?.id && (
                                    <Card
                                       className="w-24 absolute top-4 right-4 rounded-md p-1 text-black border-black border-1 bg-white z-40"
                                       ref={menuRef}
                                    >
                           
                                       <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                          Block User
                                       </p>
                                       <p
                                          className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                          onClick={() => navigate(`/createdgroup/${groupId}/postreport/${item?.id}`)}
                                       >
                                          Report
                                       </p>
                                       {User_profile?.id === item?.created_by?.id && <p
                                          className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                          onClick={() => handleDeletePost(item?.id)}
                                       >
                                          Delete
                                       </p>}
                                    </Card>
                                 )}
                              </div>
                              <div className="col-span-2"></div>
                              <div className="col-span-10">
                                 <p className="dark:text-white text-[15px]">
                                    {item?.post_description}
                                 </p>
                                 {item?.image_url && <img
                                    alt="postimg"
                                    className="w-full object-cover pt-2 rounded cursor-pointer"
                                    src={item?.image_url}
                                    onClick={() => navigate(`/group/${groupId}/post/${item?.id}/`)}
                                 />}

                                 {item?.video_url &&
                                    <div className="mt-1 rounded-sm relative z-30" >
                                       <ReactPlayer
                                          onClick={() => navigate(`/group/${groupId}/post/${item?.id}/`)}
                                          url={item?.video_url}
                                          playing={playing[item?.id] || false}
                                          muted={muted}
                                          onProgress={(state) => handleProgress(item?.id, state)}
                                          onDuration={(duration) => handleDuration(item?.id, duration)}
                                          width="100%"
                                          height="100%"
                                          className="cursor-pointer"
                                       />
                                       <button
                                          onClick={() => handlePlayPause(item?.id)}
                                          className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                       >
                                          {playing[item?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                       </button>
                                       <button
                                          onClick={handleMute}
                                          className="absolute z-30 bottom-2 right-2 text-[13px] dark:text-white bg-[#0000008b] p-[4px] rounded-full"
                                       >
                                          {muted ? <GoMute /> : <GoUnmute />}
                                       </button>
                                       <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] dark:text-white px-[4px] py-[2px] rounded-md">
                                          {formatDuration(duration[item?.id] - playedSeconds[item?.id] || 0)}
                                       </button>
                                    </div>
                                 }


                              </div>
                              <div className="col-span-12">
                                 <div className="flex justify-end gap-[12px] pt-[6px]">
                                    <FaFlag
                                       className="dark:text-white cursor-pointer hover:text-[#F80000]"
                                       onClick={() => navigate(`/createdgroup/${groupId}/postreport/${item?.id}`)}
                                    />
                                    <AiFillLike className={`${item?.like_status ? "text-[#31c629]" : "dark:text-white"}  cursor-pointer hover:text-[#31c629]`} onClick={() => handleLikeVideo(item?.id)} />
                                    {/* <LiaSave onClick={() => handleSave(item?.id)} className={` ${item?.saved_status ? "text-blue-700 " : "dark:text-white "}  cursor-pointer hover:text-[#31c629]`} /> */}
                                    <FaCommentDots
                                       className="dark:text-white cursor-pointer hover:text-[#31c629]"
                                       onClick={() => navigate(`/createdgroup/${groupId}/post/${item?.id}/comment`)}
                                    />
                                    <div className="relative">
                                       <FaShareSquare className="dark:text-white cursor-pointer hover:text-[#31c629]" onClick={() => setShowShare(item?.id)} />
                                       {showShare === item?.id && <div
                                          className="absolute bottom-6 right-1 bg-white rounded"
                                          ref={menuRef}
                                       >
                                          <div className="flex items-center gap-2 py-1 px-2">
                                             {!isExpanded && <div className="flex gap-2 ">
                                                <p className="text-black text-[13px]">Share With : </p>
                                                <IoSearchCircle className="text-[24px] cursor-pointer" onClick={toggleSearchBar} />

                                             </div>
                                             }
                                             <div
                                                className={`ml-2 transition-all duration-300 ease-in-out ${isExpanded ? 'w-full' : 'w-0'
                                                   } overflow-hidden`}
                                             >
                                                <input
                                                   type="text"
                                                   placeholder="Search..."
                                                   className={`w-full px-2 py-[1px] border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 ${isExpanded ? 'opacity-100' : 'opacity-0'
                                                      }`}
                                                />
                                             </div>
                                          </div>

                                          <div className="flex gap-1 px-1 relative">
                                             {userData.slice(userPosition, userPosition + 4).map((item) => (
                                                <div key={item.id}>
                                                   <img
                                                      alt="img"
                                                      src={item.userImg}
                                                      className="h-7 w-7 bg-black rounded-full object-cover"
                                                   />
                                                </div>
                                             ))}
                                             <p
                                                className="h-7 w-7 bg-black dark:text-white hover:bg-[#FF6600] rounded-full flex justify-center items-center cursor-pointer"
                                                onClick={handleUserClick}
                                             >
                                                <IoIosArrowForward className="text-[18px]" />
                                             </p>
                                          </div>

                                          <hr className="w-full mt-[6px] my-[6px] h-[0.5px] bg-gray-800 border-0" />

                                          <div className="flex gap-1 px-1 pb-2">
                                             {socialMediaData
                                                .slice(socialMediaPosition, socialMediaPosition + 4)
                                                .map((item) => (
                                                   <div key={item.id}>
                                                      <SocialIcon
                                                         network={item.iconname}
                                                         url="#"
                                                         style={{ height: "28px", width: "28px" }}
                                                      />
                                                   </div>
                                                ))}
                                             <p
                                                className="h-7 w-7 bg-black dark:text-white hover:bg-[#FF6600] rounded-full flex justify-center items-center cursor-pointer"
                                                onClick={handleSocialClick}
                                             >
                                                <IoIosArrowForward className="text-[18px]" />
                                             </p>
                                          </div>
                                       </div>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <hr className="col-span-12 w-full bg-gray-400 h-[1px]" />
                        </>
                     ))
                  }

                  {!groupPosts.loading && postData?.length === 0 && (
                     <div className="col-span-12 flex justify-center items-center ">
                        <p className="text-gray-700 text-[16px]">No Posts Yet</p>
                     </div>
                  )}

               </div>
            </div>
            {showReport && <ReportModal groupId={groupId}

               setShowReport={setShowReport}

               groupDetails={GroupData?.data} />}

            {showLeave && <LeaveModal handleLeave={handleLeaveGroup} cancel={() => setShowLeave(false)} />}

         </section>
      </Fragment>
   );
};

export default OtherUserGroupsPage;