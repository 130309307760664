import React, { Fragment, useState } from "react";
import { MdClose } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { UnBloackModal } from "../Modals/Settings/UnBloackModal";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1,
   },
};

const Privacy = ({ isMobile }) => {
   const navigate = useNavigate();
   const [isChecked1, setIsChecked1] = useState(false);
   const [isChecked2, setIsChecked2] = useState(false);
   const [isChecked3, setIsChecked3] = useState(false);
   const [isChecked4, setIsChecked4] = useState(false);
   const [isChecked5, setIsChecked5] = useState(false);
   const [isChecked6, setIsChecked6] = useState(false);
   const [isChecked7, setIsChecked7] = useState(false);
   const [isChecked8, setIsChecked8] = useState(false);
   const [open, setOpen] = useState(false);

   const handleCheckbox1 = () => {
      setIsChecked1(!isChecked1);
   };

   const handleCheckbox2 = () => {
      setIsChecked2(!isChecked2);
   };

   const handleCheckbox3 = () => {
      setIsChecked3(!isChecked3);
   };

   const handleCheckbox4 = () => {
      setIsChecked4(!isChecked4);
   };

   const handleCheckbox5 = () => {
      setIsChecked5(!isChecked5);
   };

   const handleCheckbox6 = () => {
      setIsChecked6(!isChecked6);
   };

   const handleCheckbox7 = () => {
      setIsChecked7(!isChecked7);
   };

   const handleCheckbox8 = () => {
      setIsChecked8(!isChecked8);
   };

   const handleOpen = () => setOpen(!open);

   const sampleData = [
      {
         name: "Veronica",
         image:
            "https://th.bing.com/th/id/OIP.oyEI7Z0cZaXpMexP2nCqVQHaFj?w=250&h=187&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
      {
         name: "Stella",
         image:
            "https://th.bing.com/th/id/OIP.JLBwl-I1dT0F1sJAHQc8qQHaEK?w=274&h=180&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
      {
         name: "Mathew",
         image:
            "https://th.bing.com/th/id/OIP.fypDPPjKoONeHAF4SdTCnQHaIh?w=162&h=187&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
      {
         name: "Nina",
         image:
            "https://th.bing.com/th/id/OIP.gaoOqi05212_Alqymzpm_AHaEK?w=268&h=183&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
   ];

   return (
      <Fragment>
         <div className="px-3 dark:bg-black dark:text-white">
            <p className="text-center font-bold text-[21px] mt-2">
               <IoCaretBackCircleSharp
                  onClick={() => navigate(-1)}
                  className="cursor-pointer dark:text-black  dark:bg-white rounded-full"
               />
            </p>
            <p className="text-center font-bold text-[22px] mb-2">Privacy</p>
            <p className="text-justify text-[12px] font-normal">
               Control who can discover you, contact you and the information others
               can see on your profile. As of May 25th, 2024, CC is compliant with
               the European General Data Protection Regulation (GDPR).
               <span className="underline underline-offset-1 cursor-pointer">Learn More</span>
            </p>
            <div className="mt-5">
               <p className="text-start text-[16px] text-black  dark:text-[#fff]  font-bold">
                  Discoverability
               </p>
               <p className="text-justify text-[12px] font-normal mt-1">
                  Make your account visible by other users in Discover,
                  recommendations, trends and other features.
                  <span className="underline underline-offset-1 cursor-pointer">Learn More</span>
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-bold">
                     Opt-out of search engine indexing
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked1}
                        onChange={handleCheckbox1}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked1 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked1
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked1 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <p className="text-justify text-[12px] mt-1">
                  If you don't want search engines outside of CC to link directly to
                  your CC profile, change this setting. Your changes will not take
                  effect until search engines reindex. Which is outside the scope of
                  CC. <span className="underline underline-offset-1 cursor-pointer">Learn More</span>
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-bold">
                     Allow others to Discover your Account in CC
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked2}
                        onChange={handleCheckbox2}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked2 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked2
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked2 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <p className="text-justify text-[12px] mt-1">
                  Make your account visible by other users in Discover,
                  recommendations, trends and other features.
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-bold">
                     Allow others to search your Account in CC by Name
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked3}
                        onChange={handleCheckbox3}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked3 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked3
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked3 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <p className="text-justify text-[12px] mt-1">
                  Make others to search your account by your name.
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-bold">
                     Allow others to search your Account in CC by Name
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked4}
                        onChange={handleCheckbox4}
                     />

                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked4 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked4
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked4 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <p className="text-justify text-[12px] mt-1">
                  Make others to search your account by your name.
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-bold">
                     Allow others to search your Account in CC by Name
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked5}
                        onChange={handleCheckbox5}
                     />

                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked5 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked5
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked5 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <p className="text-justify text-[12px] mt-1">
                  Make others to search your account by your name.
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-bold">
                     Choose who can search your Account in CC
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked6}
                        onChange={handleCheckbox6}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked6 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked6
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked6 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
            <div className="mt-5  ">
               <p className="text-start text-[16px] text-black  dark:text-[#fff] font-bold">
                  Reachability
               </p>
               <div className="flex justify-between items-start gap-2 mt-2">
                  <p className="text-[11px] font-normal">
                     Manage Who can contact you on CC.
                  </p>
                  <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                     <option>Anyone</option>
                     <option>Friends</option>
                  </select>
               </div>
               <div className="flex justify-between items-start gap-2 mt-2">
                  <p className="text-[11px] font-normal">
                     Choose who can send you contact requests
                  </p>
                  <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                     <option>Anyone</option>
                     <option>Friends</option>
                  </select>
               </div>
               <div className="flex justify-between items-start gap-2 mt-2">
                  <p className="text-[11px] font-normal">
                     Choose who can send you direct message (DM) requests
                  </p>
                  <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                     <option>Anyone</option>
                     <option>Friends</option>
                  </select>
               </div>
               <div className="flex justify-between items-start gap-2 mt-2">
                  <p className="text-[11px] font-normal">Choose who can DM you</p>
                  <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                     <option>Anyone</option>
                     <option>Friends</option>
                  </select>
               </div>
            </div>
            <div className="mt-5">
               <p className="text-start text-[16px] text-black  dark:text-[#fff] font-bold">
                  Visibility
               </p>
               <div className="flex justify-between items-start mt-2">
                  <p className="text-start text-[11px] font-bold">
                     Manage what people see when they view your profile.
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked7}
                        onChange={handleCheckbox7}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked7 ? "bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked7
                              ? "bg-[#ffffff]"
                              : "bg-[#00FF47]"} shadow-2xl duration-200 ${isChecked7 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <div className="flex justify-between items-start mt-2">
                  <p className="text-start text-[11px] font-bold">
                     Make YOUR account Private.
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked8}
                        onChange={handleCheckbox8}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked8 ? "bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked8
                              ? "bg-[#ffffff]"
                              : "bg-[#00FF47]"} shadow-2xl duration-200 ${isChecked8 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <p className="text-justify text-[11px] mt-1">
                  Enabling this setting will only show YOUR name and Profile Picture
                  TO OTHER USERS. Turn this setting off, If you want to choose
                  visibility for your biography, interests and feeds SEPARATELY.
               </p>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Allow others to see your online status
               </p>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isChecked8}
                     onChange={handleCheckbox8}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked8 ? "bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                     <span className={`dot h-[13px] w-[13px] rounded-full ${isChecked8
                        ? "bg-[#ffffff]"
                        : "bg-[#00FF47]"} shadow-2xl duration-200 ${isChecked8 ? "translate-x-5" : ""}`}
                     />
                  </span>
               </label>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Choose who can see your online status
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  A green dot next to your profile picture will show people that
                  you're active.
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Choose who can see your profile - About tab
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Choose who can see your profile - Contacts tab
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Choose who can see your profile - Interests tab
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Choose who can see your profile - Media tab
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">Choose who can see your feeds</p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Choose who can comment your feeds
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="flex justify-between items-start gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Allow others to post feeds on your profile
               </p>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isChecked8}
                     onChange={handleCheckbox8}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked8 ? "bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                     <span
                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked8
                           ? "bg-[#ffffff]"
                           : "bg-[#00FF47]"} shadow-2xl duration-200 ${isChecked8 ? "translate-x-5" : ""}`}
                     />
                  </span>
               </label>
            </div>
            <div className="flex justify-between items-center gap-2 mt-2">
               <p className="text-[11px] font-bold">
                  Allow others to post feeds on your profile
               </p>
               <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                  <option>Anyone</option>
                  <option>Friends</option>
               </select>
            </div>
            <div className="mt-5">
               <p className="text-start text-[16px] text-black  dark:text-[#fff] font-bold">
                  Tagging
               </p>
               <div className="flex justify-between items-center gap-2 mt-2">
                  <p className="text-[11px] font-bold">Manage who can tag you.</p>
               </div>
               <div className="flex justify-between items-start gap-2 mt-2">
                  <p className="text-[11px] font-bold">
                     Allow others to tag you in their feeds or comments
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked8}
                        onChange={handleCheckbox8}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked8 ? "bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked8
                              ? "bg-[#ffffff]"
                              : "bg-[#00FF47]"} shadow-2xl duration-200 ${isChecked8 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <div className="flex justify-between items-start gap-2 mt-2">
                  <p className="text-[11px] font-bold">
                     Choose who can tag you in their feeds or comments
                  </p>
                  <select className="border border-black dark:text-black  px-[3px] py-[3px] rounded-md">
                     <option>Anyone</option>
                     <option>Friends</option>
                  </select>
               </div>
            </div>
            <div className="mt-5 flex justify-between">
               <p className="text-start text-[16px] text-black  dark:text-[#fff] font-bold ">
                  Blocked Users lists
               </p>
               <p className="text-blue-700 hover:text-blue-800 text-[12px] font-bold cursor-pointer">
                  View More
               </p>
            </div>
            <div className="mb-6">
               <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               >
                  {sampleData.map((data) => (
                     <div className="mt-2">
                        <div className="relative w-[90%]  rounded-2xl ">
                           <img
                              className="object-cover rounded-2xl h-24 w-full"
                              src={data.image}
                           />
                           <p className="text-[12px] text-black  dark:text-[#fff] text-center mt-2 font-semibold">
                              {data.name}
                           </p>

                           <MdClose
                              className="text-[0.7rem] text-white absolute top-2 right-2 hidden md:block cursor-pointer bg-gray-600 rounded-sm"
                              onClick={handleOpen}
                           />
                        </div>
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>
         <UnBloackModal handleOpen={handleOpen} open={open} />
      </Fragment>
   );
};

export default Privacy;
