import React from "react";
import { useNavigate } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { IoCaretBackCircleSharp } from "react-icons/io5";

const InputButtonBlack = () => {
   const navigate = useNavigate()
   return (
      <div className="px-2 grid grid-cols-12 items-center mt-6 gap-x-1">
         <div className="col-span-1">
            <IoCaretBackCircleSharp
               onClick={() => navigate(-1)}
               className="text-[#000] dark:text-white text-[24px] cursor-pointer"
            />
         </div>
         <div className="col-span-9 ml-2">
            <input
               type="text"
               placeholder="Search for products"
               className="w-full px-4 py-1 text-[14px] rounded-full border border-gray-400 focus:outline-none"
            />
         </div>
         <div className="col-span-2 flex justify-end items-center">
            <div
               onClick={() => navigate("/basket")}
               class="relative inline-flex text-red-700 items-center mr-[10px]"
            >
               <FiShoppingCart
                  onClick={() => navigate("/basket")}
                  className="text-gray-800 dark:text-white text-[22px] cursor-pointer"
               />
               <span class="absolute rounded-full  text-[8px] font-medium content-[''] leading-none grid place-items-center top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4 bg-red-500 text-white min-w-[14px] min-h-[14px]">
                  5
               </span>
            </div>
         </div>
      </div>
   )
}

export default InputButtonBlack