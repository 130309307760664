import React from 'react'

export default function LeaveModal({ cancel , handleLeave }) {
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-start pt-4 justify-center z-50">
            <div className="bg-white px-2 py-1 rounded-lg shadow-lg w-11/12 max-w-lg relative" >
                <div div className="flex justify-center items-center">
                    <p className="text-[14px] text-[red] font-bold py-1"> Are you sure you want to leave the group?</p>
                </div>
                <div className="w-full flex justify-between px-5 h-[3rem] items-center">
                    <button
                        className="rounded-full bg-[#ff3232] hover:bg-[#ea7777] text-[12px] font-bold px-5 h-7 text-[#fff]"
                        onClick={handleLeave}
                    >  Yes, Leave
                    </button>
                    <button
                        onClick={cancel}
                        className="rounded-full text-[12px] px-5 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div >
    )
}
