import React, { useCallback, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import VideoCarousel from "../../Component/Home/VideoCarousel";
import { postReshare } from "../../Redux/ApiSlice/ResharedSlice";
import { getSavedFolders } from "../../Redux/ApiSlice/SavedVideosSlice";
import { getVideoShared } from "../../Redux/ApiSlice/HomePageVideoSlice";
import { userStatusChangeOld } from "../../Redux/ApiSlice/UserStatusSlice";
import { UserAccountFollow } from "../../Redux/ApiSlice/AccountProfileSlice";

export default function HomePagePanel(props) {
    const {
        setPreButton,
        setNextButton,
        AllPosts,
        setPageCount,
        itsNotHome,
        newUser,
        setCurrentIndex,
        currentIndex,
        direction,
        setDirection,
        handleNext,
        handlePrev,
        isMobile
    } = props;

    const [posts, setPosts] = useState([]);
    const [showWelcome, setShowWelcome] = useState(false);
    const [isFollow, setIsFollow] = useState(false);
    const [resharedPostId, setResharedPostId] = useState(null);
    const [rehsaredMesaage, setResharedMessage] = useState(null);
    const pathname = window.location.pathname;

    const dispatch = useDispatch();
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;

    const fetchData = useCallback(async () => {
        await dispatch(getSavedFolders(User_profile.id));
    }, [dispatch, User_profile.id]);

    const handleReshared = async (postId) => {
        try {
            const response = await dispatch(postReshare({ postId }));

            if (response?.payload?.message === "Repost undone successfully!") {
                const updatedArray = posts?.map((item) => {
                    return {
                        ...item,
                        userreshared:
                            item.id === postId ? !item?.userreshared : item?.userreshared,
                    };
                });
                setPosts(updatedArray);
                setResharedPostId(postId);
                setResharedMessage("Reshare Remove");
            } else {
                const updatedArray = posts?.map((item) => {
                    return {
                        ...item,
                        userreshared:
                            item.id === postId ? !item?.userreshared : item?.userreshared,
                    };
                });
                setPosts(updatedArray);
                setResharedPostId(postId);
                setResharedMessage("Reshared");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleReadUserStatus = useCallback(async () => {
        try {
            const response = await dispatch(userStatusChangeOld());
            setShowWelcome(response?.payload?.new_user);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    const handleFollowFun = async (id) => {
        try {
            const response = await dispatch(UserAccountFollow(id));
            const updatedArray = posts?.map((item) => {
                return {
                    ...item,
                    userfollowing:
                        item.created_by.id === id
                            ? !item?.userfollowing
                            : item?.userfollowing,
                };
            });
            setPosts(updatedArray);

            setIsFollow(true);

            setTimeout(() => {
                setIsFollow(false);
            }, 2000)

            toast.success(response?.payload?.message);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (newUser) {
            setShowWelcome(newUser);
            setTimeout(() => {
                handleReadUserStatus();
            }, 4000);
        }
    }, [newUser]);

    useEffect(() => {
        setPosts(AllPosts);
    }, [AllPosts, currentIndex]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (pathname === "/home") {
            fetchData();
        } else {
            dispatch(getVideoShared());
        }
    }, [fetchData, dispatch]);

    return (
        <>
            <div className="video-item overflow-hidden h-full w-full block">
                <Toaster position="top-right" />
                <VideoCarousel
                    videos={posts}
                    totalVideos={posts?.length}
                    setCurrentIndex={setCurrentIndex}
                    currentIndex={currentIndex}
                    direction={direction}
                    fetchData={fetchData}
                    itsNotHome={itsNotHome}
                    setDirection={setDirection}
                    setPreButton={setPreButton}
                    setPageCount={setPageCount}
                    setNextButton={setNextButton}
                    handleReshared={handleReshared}
                    newUser={showWelcome}
                    handleReadUserStatus={handleReadUserStatus}
                    resharedPostId={resharedPostId}
                    rehsaredMesaage={rehsaredMesaage}
                    setResharedPostId={setResharedPostId}
                    handleFollowFun={handleFollowFun}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    isMobile={isMobile}
                    isFollow={isFollow}
                />
            </div>
        </>
    );
}