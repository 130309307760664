import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const postLocation = createAsyncThunk("postLocation", async (data) => {
    const response = await axiosInstance.post(`/location/add/`, data);
    console.log(response, "response1234");
    try {
        const result = response.data;
        return result;
    } catch (error) {
        return error;
    }
});
 
export const LocationSlice = createSlice({
    name: "Location",
    initialState: {
        loading: false,
        error: null,
        location: {},

    },
    extraReducers: (builder) => {
        // Recommended Friends
        builder.addCase(postLocation.pending, (state) => {
            state.loading = true;
            state.location = {};
        });
        builder.addCase(postLocation.fulfilled, (state, action) => {
            state.loading = false;
            state.location = action.payload;
        });
        builder.addCase(postLocation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default LocationSlice.reducer;