import React, { Fragment } from "react";
import { FaRegEye, FaSmile } from "react-icons/fa";
import { Avatar } from "@material-tailwind/react";
import useSelectVideo from "../../Hooks/useSelectVideo";
import VideoCard from "../../Common/VideoCard";

export default function Posts(props) {
  const { posts = [], loading } = props;
  const filterData = posts?.filter((item) => item.video_url !== null);

  const selectVideo = useSelectVideo();

  const handleSelectVideo = async (id) => {
    selectVideo(id, filterData);
  };

  return (
    <Fragment>
      <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar px-2">
        {filterData?.map((data) => (
          <VideoCard
            id={data.id}
            body={data.body}
            belowDecs={true}
            threeDots={false}
            views={data.views}
            video={data.video_url}
            likes_count={data.likes_count}
            selectVideo={handleSelectVideo}
            avatar_url={data.created_by.avatar_url}
          />
        ))}

      </div>
      {filterData.length === 0 && !loading && (
        <div className="text-gray-600 text-[13px] text-center mt-5">
          No Post Found
        </div>
      )}
    </Fragment>
  );
}
