import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { LiaSave } from "react-icons/lia";
import { AiFillLike } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { Card } from "@material-tailwind/react";
import toast, { Toaster } from "react-hot-toast";
import { GoMute, GoUnmute } from "react-icons/go";
import { IoArrowBackCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { deleteGroupPost } from "../../Redux/ApiSlice/Group/GroupPostsSlice";
import { HomepageVideoLike } from "../../Redux/ApiSlice/HomePageVideoSlice";
import CreatedGroupSliderMenu from "../../Component/CreatedGroup/CreatedGroupSliderMenu";
import { getGroupPostHide, hideGroupPost } from "../../Redux/ApiSlice/Group/GroupPostHide";
import { getSaveGroupPost, saveGroupPost } from "../../Redux/ApiSlice/Group/GroupPostSaveSlice";
import { FaCommentDots, FaFlag, FaPauseCircle, FaPlayCircle, FaShareSquare } from "react-icons/fa";

export default function HiddenPosts() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { groupId } = params;

    const savedPost = useSelector((state) => state.groupSavedPosts)
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const hiddenPost = useSelector((state) => state.GroupHiddenPosts);

    const { User_profile } = ProfileDetails;

    const [savedPostData, setSavedPostData] = useState([]);
    const [hiddenPostData, setHiddenPostData] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const menuRef = useRef(null);

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    useOutsideClick(menuRef, () => setMenuId(null));

    useEffect(() => {
        dispatch(getGroupPostHide({ groupId }))
    }, [groupId])

    useEffect(() => {
        if (hiddenPost?.data?.hidden_posts) {
            setHiddenPostData(hiddenPost?.data?.hidden_posts);
        }
        console.log(hiddenPost?.data?.hidden_posts)
    }, [hiddenPost?.data])

    useEffect(() => {
        if (savedPost?.data?.saved_posts) {
            setSavedPostData(savedPost?.data?.saved_posts);
        }
    }, [savedPost?.data])

    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});
    const [menuId, setMenuId] = useState(null);

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    const handleClickThreeDots = (id) => setMenuId(id);

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    const getSavedPosts = useCallback(async () => {
        try {
            await dispatch(getSaveGroupPost({ groupId }));
        } catch (error) {
            return error;
        }
    }, [dispatch, groupId])

    const handleSave = async (postId) => {
        try {
            const response = await dispatch(saveGroupPost({ groupId, postId }))
            setSavedPostData((prevPosts) =>
                prevPosts.map((post) =>
                    post.id === postId ? { ...post, saved_status: !post.saved_status } : post
                )
            );

            setMenuId(null)
            if (response?.payload?.detail) {
                toast.success(response?.payload?.detail)
            } else {
                toast.success("post Unsaved successfully")
            }
        } catch (error) {
            toast.error("post failed to save")
        }
    }

    const handleHide = async (postId) => {
        try {
            await dispatch(hideGroupPost({ groupId, postId }));
            const newData = savedPostData.filter((item) => item.id !== postId)
            setSavedPostData(newData)
            setMenuId(null)
            toast.success("post hidden successfully")
            navigate(`/createdgroup/${groupId}`)
        } catch (error) {
            toast.error("post failed to hide the post")
        }
    }

    const handleDeletePost = async (postId) => {
        try {
            await dispatch(deleteGroupPost({ groupId, postId }))
            const newData = savedPostData.filter((item) => item.id !== postId)
            setSavedPostData(newData)
            setMenuId(null)
            toast.success("post deleted successfully")
        } catch (error) {
            toast.error("post failed to delete")
        }
    }

    const handleLikeVideo = useCallback(async (id) => {
        try {
            const responce = await dispatch(HomepageVideoLike(id))

            if (responce.payload?.message === "like removed") {
                setSavedPostData((prevPosts) =>
                    prevPosts.map((post) =>
                        post.id === id ? { ...post, like_status: !post.like_status, likes_count: post.likes_count - 1 } : post
                    )
                );
            }
            else {
                setSavedPostData((prevPosts) =>
                    prevPosts.map((post) =>
                        post.id === id ? { ...post, like_status: !post.like_status, likes_count: post.likes_count + 1 } : post
                    )
                );
            }
        } catch (error) {
            console.log(error)
        }
    }, [dispatch]);

    function timeAgo(timestamp) {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);

        const intervals = {
            year: 31536000, // 60 * 60 * 24 * 365
            month: 2592000, // 60 * 60 * 24 * 30
            day: 86400,     // 60 * 60 * 24
            hour: 3600,     // 60 * 60
            minute: 60,
            second: 1
        };

        for (const key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval}${key[0]} ago`;
            }
        }
        return "just now";  // In case it's less than a second ago
    }

    useEffect(() => {
        getSavedPosts();
    }, [getSavedPosts])

    return (
        <Fragment>
            <div className="overflow-hidden bg-white dark:bg-black h-full overflow-y-scroll w-full relative">
                <Toaster position="top-right" />
                <div className="grid grid-cols-12 w-full px-2 pb-1 sticky top-0 z-50 bg-white dark:bg-black border-b border-gray-700">
                    <p className="col-span-12 text-[#ff3b3b] text-center font-bold text-[8px] uppercase">
                        Copyright © 2025 Galileo One Inc
                    </p>
                    <div className="col-span-2 flex justify-start items-center">
                        <IoArrowBackCircle
                            className="text-black dark:text-white text-[30px] md:text-[20px] cursor-pointer"
                            onClick={() => navigate(-1)}
                        />
                    </div>
                    <div className="col-span-8 flex justify-center items-center">
                        <p className="text-black dark:text-white text-[20px] md:text-[17px]">
                            Hidden Posts
                        </p>
                    </div>
                    <div className="col-span-2 flex justify-end items-center">
                        <button onClick={openDrawer}>
                            {isDrawerOpen ? (
                                <XMarkIcon className={`h-8 w-8 md:h-6 md:w-6 stroke-2 text-black dark:text-white`} />
                            ) : (
                                <Bars3Icon className={`h-8 w-8 md:h-6 md:w-6 stroke-2 text-black dark:text-white`} />
                            )}
                        </button>
                    </div>
                </div>
               
                {hiddenPostData?.length && hiddenPostData?.map((item) => (
                    <div className="col-span-12 grid grid-cols-12 gap-y-2 px-2 py-2 border-b border-gray-700" key={item?.id}>
                        <div className="col-span-2">
                            <img
                                alt="profilepic"
                                src={item?.created_by?.avatar}
                                className="h-10 w-10 rounded-full object-cover border border-gray-900"
                            />
                        </div>
                        <div className="col-span-9 ml-[-4px]">
                            <p className="text-[12px] text-black dark:text-white md:text-[14px]">
                                {item?.created_by?.first_name} {item?.created_by?.last_name}
                            </p>
                            <p className="text-[12px] text-[#3ea8d8] md:text-[11px] font-bold">
                                {timeAgo(item?.created_at)}
                            </p>
                        </div>
                        <div className="col-span-1 relative">
                            <HiDotsVertical
                                className="text-[20px] text-black dark:text-white z-20 absolute -right-2 cursor-pointer"
                                onClick={() => handleClickThreeDots(item?.id)}
                            />
                            {menuId === item?.id && (
                                <Card
                                    ref={menuRef}
                                    className="w-24 absolute top-2 right-2 rounded-md p-1 text-black bg-white z-10"
                                >
                                    <p
                                        onClick={() => handleSave(item?.id)}
                                        className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                    >
                                        {item?.saved_status ? "Unsave" : "Save"}
                                    </p>
                                    <p
                                        onClick={() => handleHide(item?.id)}
                                        className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                    >
                                        Unhide
                                    </p>
                                    <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                        Block User
                                    </p>
                                    <p
                                        onClick={() => navigate(`/createdgroup/${groupId}/postreport/${item?.id}`)}
                                        className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                    >
                                        Report
                                    </p>
                                    {User_profile?.id === item?.created_by?.id &&
                                        <p
                                            onClick={() => handleDeletePost(item?.id)}
                                            className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                                        >
                                            Delete
                                        </p>}
                                </Card>
                            )}
                        </div>

                        <div className="col-span-12 w-full">
                            <p className="text-[12px] text-black dark:text-white mb-1">
                                {item?.post_description}
                            </p>

                            {item?.image_url &&
                                <img
                                    alt="post"
                                    src={item?.image_url}
                                    className="w-full object-cover rounded"
                                />
                            }

                            {item?.video_url &&
                                <div className="mt-1 rounded-sm relative z-40">
                                    <ReactPlayer
                                        width="100%"
                                        height="100%"
                                        muted={muted}
                                        url={item?.video_url}
                                        className="cursor-pointer"
                                        playing={playing[item?.id] || false}
                                        // onClick={() => navigate(`/postview/${item?.id}`)}
                                        onProgress={(state) => handleProgress(item?.id, state)}
                                        onDuration={(duration) => handleDuration(item?.id, duration)}
                                    />
                                    <button
                                        onClick={() => handlePlayPause(item?.id)}
                                        className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[12px] p-[6px] rounded-full"
                                    >
                                        {playing[item?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                    </button>
                                    <button
                                        onClick={handleMute}
                                        className="absolute z-30 bottom-2 right-2 text-[13px] text-black dark:text-white bg-[#0000008b] p-[4px] rounded-full"
                                    >
                                        {muted ? <GoMute /> : <GoUnmute />}
                                    </button>
                                    <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-black dark:text-white px-[4px] py-[2px] rounded-md">
                                        {formatDuration(duration[item?.id] - playedSeconds[item?.id] || 0)}
                                    </button>
                                </div>
                            }
                        </div>

                        <div className="col-span-12">
                            <div className="flex items-center justify-end gap-x-[12px]">
                                <FaFlag
                                    onClick={() => navigate(`/createdgroup/${groupId}/postreport/${item?.id}`)}
                                    className="text-[14px] text-black dark:text-white cursor-pointer hover:text-[#d27c7c]"
                                />
                                <AiFillLike 
                                    onClick={() => handleLikeVideo(item?.id)} 
                                    className={`text-[18px] ${item?.like_status ? "text-[#67d960]" : "text-black dark:text-white"}  cursor-pointer hover:text-[#67d960]`} 
                                />
                                <LiaSave 
                                    onClick={() => handleSave(item?.id)} 
                                    className={`text-[18px] ${item?.saved_status ? "text-blue-700 " : "text-black dark:text-white "}  cursor-pointer hover:text-[#67d960]`} />
                                <FaCommentDots
                                    onClick={() => navigate(`/createdgroup/${groupId}/post/${item?.id}/comment`)}
                                    className="text-[16px] text-black dark:text-white cursor-pointer hover:text-[#67d960]"
                                />
                                <FaShareSquare className="text-[15px] text-black dark:text-white cursor-pointer hover:text-[#67d960]" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <CreatedGroupSliderMenu
                groupId={groupId}
                isDrawerOpen={isDrawerOpen}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
            />
        </Fragment>
    )
}
