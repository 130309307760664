import React, { Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { REACT_APP_MAIN_URL } from "../API/MainUrl";
import { ccLogo } from "../Datas/Assests";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

const LostPassword = () => {
   const navigate = useNavigate();
   //   const dispatch = useDispatch();
   const [email, setEmail] = useState(null);

   const handleChange = (e) => {
      setEmail(e.target.value);
   };

   const handleSendOtp = async (data) => {
      toast.loading("Loading...");
      try {
         const formData = new FormData();
         formData.append("email", data);
         console.log(formData, "test"); // FormData {}
         const response = await axios.post(
            `${REACT_APP_MAIN_URL}api/forgot-password/`,
            formData
         );
         const result = response.data;
         if (result.success === true) {
            toast.dismiss();
            await toast.success(result.message);
            formik.resetForm();
         }
         return result;
      } catch (error) {
         toast.dismiss();
         toast.error(error.message);
         return error;
      }
   };

   const validateSchema = Yup.object({
      email: Yup.string().email("Invalid email").required("Required"),
   });

   const formik = useFormik({
      initialValues: { email: "" },
      validationSchema: validateSchema,
      onSubmit: (values, { resetForm }) => {
         console.log(values, "values");
         if (values.email) {
            handleSendOtp(values.email);
         }
      },
   });

   return (
      <Fragment>
         <div className="h-full w-full bg-white block relative">
            <div className="w-full h-[84%]">
               <div className="grid grid-cols-12 bg-[#fff] justify-end items-center">
                  <p className="text-[#ff3b3b]  font-extrabold text-[10px] top-0 w-full text-center absolute">
                     Copyright © 2025 Galileo One Inc
                  </p>
                  {/* <p className="text-[#ff3b3b]  font-extrabold text-[10px] top-0 w-full text-center absolute">
            CC FORGOTPASSWORD - FE - V3 ,JULY 06 , 2024
          </p> */}

                  <div className="col-span-12 flex justify-center item-center mt-20">
                     <img src={ccLogo} className="h-28 w-28" alt="logo" />
                  </div>

                  <h3 className="col-span-12 text-center font-extrabold text-xl">
                     Lost Your Password?
                  </h3>
                  <p className="col-span-12 text-center text-[14px] font-semibold  w-full px-4 my-4">
                     Please enter your email for a verification link.
                  </p>
               </div>
               <div className="my-4 w-full bg-[#fff] px-4">
                  <form className="w-full h-full">
                     <div className="flex flex-col h-2/6">
                        <label className="mb-2 text-gray-500">Email</label>
                        <input
                           type="email"
                           placeholder="Enter your email address"
                           value={email}
                           onChange={handleChange}
                           className="p-4 md:p-2 border-gray-500  border-2 border-solid outline-none rounded-md"
                        />
                     </div>

                     <div className="grid grid-cols-4 items-center mt-6 gap-4">
                        <Button
                           className="p-3 md:p-2 bg-black text-[#fff] col-span-2"
                           onClick={() => navigate(-1)}
                        >
                           Back
                        </Button>
                        <Button
                           className="p-3 md:p-2 bg-black text-[#fff] col-span-2"
                           onClick={(e) => handleSendOtp(email)}
                        >
                           Send Link
                        </Button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
         <Toaster position="top-right" />
      </Fragment>
   );
};

export default LostPassword;
