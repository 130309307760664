import React, { useCallback, useEffect, useState } from "react";
import { OtherUserPosts } from "./OtherUserPosts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from "../../Component/Modals/ShareModal";
import FollowersList from "../../Component/OtherUserAccount/Followerslist";
import FollowingList from "../../Component/OtherUserAccount/FollowingList";
import { messagesChatRoomCreate } from "../../Redux/ApiSlice/MessagesSlice";
import ProfileSection from "../../Component/OtherUserAccount/ProfileSection";
import { otherUserFollowersList, otherUserFollowingList, OtherUserProfile, UserAccountFollow } from "../../Redux/ApiSlice/AccountProfileSlice";
import { FollowingTab } from "../../Component/AccountPage/FollowingTab";
import { FollowersTab } from "../../Component/AccountPage/FollowersTab";
import { a } from "@react-spring/web";

export default function OtherUserAccount() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { otherUser_profile, User_profile, User_followingList,
        User_followersList, } = ProfileDetails || {};
    const {
        followers = [],
        following = [],
        posts = [],
        is_private = false,
        is_following = false,
    } = otherUser_profile || {};

    const [count, setCount] = useState("allPosts");
    const [openShare, setOpenShare] = useState(false);
    const [otherUserFollowingYou, setOtherUserFollowingYou] = useState(false);
    const Messages = useSelector((state) => state.messages);
    const { loading } = Messages;

    const follow = following?.some((item) => item.following_id === User_profile.id)
    const handleFetch = useCallback(async () => {
        await dispatch(OtherUserProfile(id));
        if (follow) {
            setOtherUserFollowingYou(true);
        }
    }, [id, dispatch, follow]);

    const getFollowAndFollower = useCallback(async () => {
        try {
            if (count === "Followers") {
                await dispatch(otherUserFollowersList(id));
            }
            else if (count === "Following") {
                await dispatch(otherUserFollowingList(id));
            }
            else if (count === "Posted" || count === "allPosts") {
                await dispatch(OtherUserProfile(id));
            }
        } catch (error) {
            console.error("Error fetching followers or following:", error);
        }
    }, [id, count, dispatch]);

    const handleShare = () => setOpenShare(!openShare);
    const handleFollowFun = useCallback(async () => {
        await dispatch(UserAccountFollow(id));
        if (count === "Followers") {
            await dispatch(otherUserFollowersList(id));
        }
        else if (count === "Following") {
            await dispatch(otherUserFollowingList(id));
        }
        else if (count === "Posted" || count === "allPosts") {
            await dispatch(OtherUserProfile(id));
        }
        await dispatch(OtherUserProfile(id));
    }, [dispatch, id,]);

    const handleStartChat = async () => {
        try {
            const responce = await dispatch(messagesChatRoomCreate(id));
            if (responce.payload.id) {
                navigate(`/mobileMessages/userChat/${responce.payload.id}`);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        handleFetch();
        getFollowAndFollower();
    }, [handleFetch, getFollowAndFollower]);

    const checkContision = (is_private && is_following && otherUserFollowingYou)

    console.log("is_private", checkContision);
    console.log("is_following", is_following);
    console.log("otherUserFollowingYou", otherUserFollowingYou);
    console.log("User_profile", User_profile);
    console.log("otherUser_profile", otherUser_profile);
    return (
        <>
            <section className="bg-white dark:bg-black dark:text-white h-full w-full">
                <ProfileSection
                    userId={id}
                    setCount={setCount}
                    count={count}
                    otherUser_profile={otherUser_profile}
                    otherUserFollowingYou={otherUserFollowingYou}
                    handleFollowFun={handleFollowFun}
                    handleStartChat={handleStartChat}
                    handleShare={handleShare}
                    loading={loading}
                />
                <div className="p-0 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available] mt-0">
                    {((is_private && is_following && otherUserFollowingYou) ||
                        !is_private) && (
                        <>
                            {count === "Following" && (
                                <div className="mt-2">
                                    {/* <FollowingList datalist={following} isOtherAccount={true} /> */}
                                    <FollowingTab datalist={User_followingList} isOtherAccount={true} otheruser_id={id} />
                                </div>
                            )}
                            {count === "Followers" && (
                                <div className="mt-2">
                                    {/* <FollowersList datalist={followers} isOtherAccount={true} /> */}
                                    <FollowersTab datalist={User_followersList} isOtherAccount={true} otheruser_id={id} />

                                </div>
                            )}
                            {(count === "Posted" || count === "allPosts") && (
                                <OtherUserPosts User_posts={posts} />
                            )}
                        </>
                    )}
                </div>
            </section>
            <ShareModal openBottom={openShare} closeDrawerBottom={handleShare} />
        </>
    );
}
