import React from "react";
import ImageSlider from "../../Component/shoppage/detailspage/ImageSlider";
import AboutProducts from "../../Component/shoppage/detailspage/AboutProducts";
import SimilarProduct from "../../Component/shoppage/SimilarProducts";
import RecentProducts from "../../Component/shoppage/RecentProducts";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";
import SugestedProducts from "../../Component/shoppage/SugestedProducts";

const ShopDetails = () => {
   return (
      <div className="h-full w-full overflow-hidden">
         <div className="h-full overflow-scroll no-scrollbar relative">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               Copyright © 2025 Galileo One Inc
            </p>
            <div className="px-2">
               <InputButtonBlack />
            </div>
            <ImageSlider />
            <AboutProducts />
            <SimilarProduct />
            <RecentProducts />
            <SugestedProducts />
         </div>
      </div>
   );
};

export default ShopDetails;
