import React, { Fragment, useState, useEffect, useRef } from "react";
import { IoCaretBackCircle, IoMenu, IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import SellProductPreview from "./SellProductPreview";

const SellProduct = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const menuRef = useRef(null);

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div className="sticky top-0 grid grid-cols-12 px-4 py-2 dark:bg-black dark:text-white items-center z-10">
                <div className="col-span-2 flex justify-start">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[20px] cursor-pointer dark:text-white"
                    />
                </div>
                <div className="col-span-8 flex justify-center">
                    <p className="text-center text-[14px] dark:text-white font-semibold">Sell Your Products</p>
                </div>
                <div className="col-span-2 flex justify-end items-end">
                    {menuOpen ? (
                        <IoClose
                            onClick={handleMenu}
                            className="text-[22px] cursor-pointer  dark:text-white"
                        />
                    ) : (
                        <IoMenu
                            onClick={handleMenu}
                            className="text-[22px] cursor-pointer  dark:text-white"
                        />
                    )}
                </div>
            </div>

            {menuOpen && (
                <div ref={menuRef} className="absolute top-0  right-0 border border-gray-300 mt-12 mr-4 bg-white shadow-lg rounded-lg px-4 py-2 z-20">
                    <ul>
                        <li className="py-1 cursor-pointer">Currently Selling</li>
                        <li className="py-1 cursor-pointer">Drafts</li>
                        <li className="py-1 cursor-pointer">Payments Method</li>
                        <li className="py-1 cursor-pointer">Shop Messages</li>
                        <li className="py-1 cursor-pointer">Terms and Policies</li>
                    </ul>
                </div>
            )}

            {!isNext &&
                <div className="mt-4 px-2">
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold dark:text-white">Selling As:</p>
                        <input
                            type="text"
                            placeholder="Enter Your Name"
                            className="w-full px-1 dark:bg-black dark:text-white border-b dark:border-blue-500  placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none"
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold">Category:</p>
                        <select className="w-full px-1 dark:bg-black dark:text-white border-b dark:border-blue-500  placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none">
                            <option value="">Select Category</option>
                            <option value="electronics">Electronics</option>
                            <option value="fashion">Fashion</option>
                            <option value="home">Home</option>
                            <option value="beauty">Beauty</option>
                        </select>
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold dark:text-white">Keywords:</p>
                        <input
                            type="text"
                            placeholder="Enter Keywords"
                            className="w-full px-1 dark:bg-black dark:text-white border-b dark:border-blue-500  placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none"
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold">Condition:</p>
                        <select className="w-full px-1 dark:bg-black dark:text-white border-b dark:border-blue-500  placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none">
                            <option value="">Select Condition</option>
                            <option value="new">New</option>
                            <option value="used">Used</option>
                        </select>
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold dark:text-white">Price ($):</p>
                        <input
                            type="number"
                            placeholder="Enter Price"
                            className="w-full px-1 dark:bg-black dark:text-white border-b dark:border-blue-500  placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none"
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold dark:text-white">Upload Media:</p>
                        <input
                            type="file"
                            className="w-full px-1 py-1 border-b placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none"
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="text-[12px] font-bold dark:text-white">Description:</p>
                        <textarea
                            placeholder="Enter Description"
                            className="w-full px-1 dark:bg-black dark:text-white border-b dark:border-blue-500  placeholder:text-[12px] border-black focus:border-blue-500 focus:border-b-2 focus:outline-none resize-none"
                        />
                    </div>
                </div>
            }

            {isNext &&
                <SellProductPreview />
            }

            <div className={`px-2 mt-4 flex items-center ${isNext ? "justify-between" : "justify-end"}`}>
                {isNext &&
                    <button className="text-[14px] text-white bg-black  dark:bg-white  dark:text-black px-4 py-1 rounded-md">
                        Draft
                    </button>
                }
                <button onClick={() => setIsNext(!isNext)} className="text-[14px] text-white bg-[#126C02] px-4 py-1 rounded-md">
                    Next
                </button>
            </div>

        </Fragment>
    )
}

export default SellProduct;