import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { IoArrowBackCircle } from "react-icons/io5";
import { TbPhotoEdit } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import {
    AccountProfile,
    AccountProfiledit,
} from "../../Redux/ApiSlice/AccountProfileSlice";
import toast, { Toaster } from "react-hot-toast";

export default function ProfileEdit() {
    const menuRef = useRef();
    const dispatch = useDispatch();

    const fileInputRef = useRef(null);
    const ProfileDetails = useSelector((state) => state.AccountProfile);
    const { User_profile } = ProfileDetails;
    const [userDetails, setUserDetails] = useState({});
    const [image, setImage] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const navigate = useNavigate();

    useOutsideClick(menuRef, () => setShowConfirm(false));

    const handleChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value,
        });
    };

    // Handle save
    const handleBack = () => {
        const isFormEmpty =
            JSON.stringify(userDetails) !== JSON.stringify(User_profile);
        if (isFormEmpty) {
            setShowConfirm(isFormEmpty);
        } else {
            navigate(-1);
        }
    };

    // Warn user before leaving the page if there are unsaved changes
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue =
                    "You have unsaved changes. Do you really want to leave?";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [isDirty]);

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUserDetails({
                ...userDetails,
                avatar: file,
            });
            setImage(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async () => {
        const formatDate = new FormData();
        formatDate.append("first_name", userDetails.first_name);
        formatDate.append("last_name", userDetails.last_name);
        formatDate.append("about_us", userDetails.about_us);
        formatDate.append("portfolio", userDetails.portfolio);

        if (userDetails.avatar instanceof File) {
            formatDate.append("avatar", userDetails.avatar);
        }
        try {
            const response = await dispatch(AccountProfiledit(formatDate));
            toast.dismiss();
            toast.success(response?.payload?.message);
            console.log(response, "response");
            setShowConfirm(false);
            setTimeout(() => {
                navigate("/account");
            }, 2000);
        } catch (error) {
            toast.dismiss();
            toast.error("Failed to update");
        }
    };

    const getUserDetails = useCallback(async () => {
        await dispatch(AccountProfile());
    }, [dispatch]);

    useEffect(() => {
        getUserDetails();
    }, [getUserDetails]);

    useEffect(() => {
        setUserDetails(User_profile);
        setImage(User_profile.avatar);
    }, [User_profile]);

    return (
        <div className="h-full w-full bg-white dark:bg-black relative overflow-y-scroll">
            <Toaster position="top-right" />
            <div className="grid grid-cols-12 bg-black border-b border-gray-800 justify-center items-center w-full gap-x-4 z-50 sticky top-0 py-2">
                <div className="col-span-2">
                    <IoArrowBackCircle
                        onClick={() => handleBack()}
                        className="text-[24px] text-white cursor-pointer ml-2"
                    />
                </div>
                <h1 className="col-span-8 text-white text-[14px] font-semibold text-center">
                    Edit Profile
                </h1>
                <div className="col-span-2" />
            </div>
            <div className="grid-cols-12 px-2 mt-4 z-10">
                <div className="col-span-12 flex flex-col justify-center items-center my-4">
                    <div className="relative group">
                        <img
                            alt="IMG"
                            src={image}
                            onClick={handleIconClick}
                            className="h-[6rem] w-[6rem] rounded-[50%] object-cover"
                        />
                        <div onClick={handleIconClick} className="absolute inset-0 z-10 cursor-pointer items-center justify-center hidden h-[6rem] w-[6rem] rounded-[50%] object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                            <button className="flex items-center justify-center h-4 w-4 text-center rounded-full ring-1 ring-transparent ">
                                <TbPhotoEdit className="text-white" />
                            </button>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div
                        onClick={handleIconClick}
                        className="text-black dark:text-white text-[14px] font-bold cursor-pointer"
                    >
                        edit profile picture
                    </div>
                </div>
                <div className="col-span-12 grid grid-cols-12 my-4 items-start">
                    <p className="col-span-4 text-[14px] text-black dark:text-white font-semibold">
                        First Name
                    </p>
                    <div className="col-span-8">
                        <input
                            type="text"
                            name="first_name"
                            onChange={handleChange}
                            placeholder="Enter your name"
                            value={userDetails?.first_name}
                            className="w-full px-2 py-1.5 focus:outline-none rounded-sm bg-gray-200 placeholder:text-[12px] text-[14px]"
                        />
                    </div>
                </div>
                <div className="col-span-12 grid grid-cols-12 my-4">
                    <p className=" col-span-4 text-[14px] text-black dark:text-white font-bold">
                        Last Name
                    </p>
                    <div className="col-span-8">
                        <input
                            type="text"
                            name="last_name"
                            onChange={handleChange}
                            placeholder="Enter your name"
                            value={userDetails?.last_name}
                            className="w-full px-2 py-1.5 focus:outline-none rounded-sm bg-gray-200 placeholder:text-[12px] text-[14px]"
                        />
                    </div>
                </div>
                <div className="col-span-12 grid grid-cols-12 my-4">
                    <p className=" col-span-4 text-[14px] text-black dark:text-white font-bold">
                        Username
                    </p>
                    <div className="col-span-8">
                        <input
                            type="text"
                            name="username"
                            onChange={handleChange}
                            placeholder="Enter your username"
                            value={userDetails?.username}
                            className="w-full px-2 py-1.5 focus:outline-none rounded-sm bg-gray-200 placeholder:text-[12px] text-[14px]"
                        />
                    </div>
                </div>
                <div className="col-span-12 grid grid-cols-12 my-4">
                    <p className="col-span-4 text-[14px] text-black dark:text-white font-bold">Bio</p>
                    <div className="col-span-8">
                        <textarea
                            type="text"
                            name="about_us"
                            rows={4}
                            onChange={handleChange}
                            placeholder="Bio..."
                            value={userDetails.about_us}
                            className="w-full px-2 py-1.5 focus:outline-none rounded-sm bg-gray-200 placeholder:text-[12px] text-[14px] leading-5"
                        />
                    </div>
                </div>
                <div className="col-span-12 grid grid-cols-12 my-4">
                    <p className=" col-span-4 text-[14px] text-black dark:text-white font-bold">
                        Gender
                    </p>
                    <div className="col-span-8">
                        <select
                            name="gender"
                            id="underline_select"
                            onChange={handleChange}
                            value={userDetails.gender}
                            class="w-full px-2 py-1.5 focus:outline-none rounded-sm bg-gray-200 placeholder:text-[12px] text-[14px] block appearance-none"
                        >
                            <option selected value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 grid grid-cols-12 my-4">
                    <p className=" col-span-4 text-[14px] text-black dark:text-white font-bold">Links</p>
                    <div className="col-span-8">
                        <input
                            type="url"
                            value={userDetails?.portfolio}
                            onChange={handleChange}
                            name="portfolio"
                            placeholder="https://www.example.com"
                            className="w-full px-2 py-1.5 focus:outline-none rounded-sm bg-gray-200 placeholder:text-[12px] text-[14px]"
                        />
                    </div>
                </div>
                <div className="col-span-12 flex items-center justify-center my-4">
                    <Button
                        onClick={() => handleSubmit()}
                        className="w-[50%] py-1.5 rounded-[5px] bg-gray-800 hover:bg-blue-600 text-white text-[12px]"
                    >
                        Save
                    </Button>
                </div>
            </div>

            {showConfirm && (
                <div className="absolute flex justify-center items-center top-0 h-full w-full">
                    <div className="absolute h-screen w-full top-0 opacity-100 bg-opacity-50 bg-gray-700 z-20"></div>
                    <Card className="w-[90%] px-2 py-3 absolute  z-50" ref={menuRef}>
                        <CardBody className="text-[14px] p-0 text-center">
                            If You want to save the changes click on the save button
                        </CardBody>
                        <CardFooter className="flex justify-center items-center gap-4 w-full p-0 mt-2">
                            <Button
                                className="px-2 py-2 w-14 bg-gray-400 text-white text-[10px]"
                                onClick={() => navigate(-1)}
                            >
                                cancel
                            </Button>
                            <Button
                                className="px-2 py-2 w-14  text-[10px]"
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            )}
        </div>
    );
}
