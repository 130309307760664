import axios from "axios";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router
import { REACT_APP_MAIN_URL } from "./MainUrl";

const axiosInstance = axios.create({
    headers: { "Content-Type": "application/json" },
    baseURL: `${REACT_APP_MAIN_URL}api`,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        const accessToken = `Bearer ${token}`;
        if (accessToken) {
            if (config.headers) config.headers.Authorization = accessToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const navigate = useNavigate(); // React Router navigate

        // Handle specific error status codes
        if (error.response) {
            const { status } = error.response;

            // Redirect to error page based on status code
            switch (status) {
                case 403: // Unauthorized
                    navigate("/error400");
                    break;
                case 404: // Not Found
                    navigate("/notfound");
                    break;
                case 500: // Internal Server Error
                    navigate("/error");
                    break;
                // Add more cases for other error status codes if needed
                default:
                    // Handle other errors
                    break;
            }
        } else {
            // Handle other types of errors (e.g., network errors)
            console.error("Error:", error.message);
        }
        // store.dispatch(updateProgress(0));
        return Promise.reject(error);
    }
);

export default axiosInstance;
