import React from "react";
import { FiSave } from "react-icons/fi";
import { MdGroups2 } from "react-icons/md";
import { BsMailbox2 } from "react-icons/bs";
import { FaShieldAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaThumbsUp, FaRepeat } from "react-icons/fa6";

const tabs = [
    { name: "Posts", icon: BsMailbox2, path: "/account/post" },
    { name: "Saved", icon: FiSave, path: "/account/saved" },
    { name: "Groups", icon: MdGroups2, path: "/account/group" },
    { name: "Reshared", icon: FaRepeat, path: "/account/repost" },
    { name: "Liked", icon: FaThumbsUp, path: "/account/liked" }
];

export default function AccountTab({ activeTab }) {
    const navigate = useNavigate();

    return (
        <div className="sticky top-0 flex bg-white dark:bg-black py-2 px-2 z-30">
            <div className="bg-[#232323] dark:bg-[#2d2d33] rounded-md w-full">
                <div className="flex items-center justify-between py-1 px-1">
                    {tabs.map((tab) => (
                        <div
                            key={tab.name}
                            onClick={() => navigate(tab.path)}
                            className={`flex flex-col justify-center items-center w-[15%] rounded-md pt-[3px] pb-0.5 cursor-pointer 
                                ${activeTab === tab.name
                                    ? " bg-deep-orange-700 dark:bg-black text-white"
                                    : "text-white hover:bg-[#171717] hover:text-white bg-[#232323] dark:bg-[#2d2d33] "
                                }`}
                        >
                            <tab.icon className="text-[17px]" />
                            <p className="text-[9px] mt-[0.5] font-semibold whitespace-pre-line cursor-pointer">
                                {tab.name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}