import React, { useState, useEffect, useCallback, Fragment, useRef } from "react";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import { useDispatch, useSelector } from "react-redux";
import { LikedTab } from "../../Component/AccountPage/LikedTab";
import AccountSidebar from "../../Component/AccountPage/AccountSidebar";
import { UserlikedVideos } from "../../Redux/ApiSlice/LikedVideosSlice";
import AccountTabModal from "../../Component/AccountPage/AccountTabModal";
import { useOutsideClick } from "../../Hooks/useOutsideClick";

export default function Liked() {
    const dispatch = useDispatch();
    const likedVideos = useSelector((state) => state.userLikedPosts);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const ref = useRef(null);
    const handleClickOutside = () => {
        setShow(null);
        setActiveTab("");
    };
    useOutsideClick(ref, handleClickOutside);

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    // const [loading, setLoading] = useState(false);

    const { User_likedVideos, loading } = likedVideos;

    const fetchData = useCallback(async () => {
        // setLoading(true);
        try {
            await dispatch(UserlikedVideos());
            // setLoading(false);
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    }, [dispatch]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Fragment>
            <div className={`bg-[#fff] dark:bg-black overflow-hidden h-full relative ${isDrawerOpen ? "" : "overflow-y-scroll"}`}>
                {/* <p className="text-[#ff3b3b] text-center w-full mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    CC ACCOUNT LIKED PAGE-FE - 11 / JUNE 29, 2024
                </p> */}
                <p className="text-[#ff3b3b] text-center w-full mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
                    Copyright © 2025 Galileo One Inc
                </p>
                <AccountProfileSection
                    handleOpenSideBar={openDrawer}
                    handleCloseSideBar={closeDrawer}
                    isDrawerOpen={isDrawerOpen}
                    setActiveTab={setActiveTab}
                    setShow={setShow}
                    show={show}
                />
                <div className="sticky top-0 z-30">
                    <AccountTab activeTab={"Liked"} />
                </div>
                <div className="w-full h-full overflow-y-scroll">
                    <LikedTab
                        loading={loading}
                        User_likedVideos={User_likedVideos?.Liked_Posts}
                    />
                </div>
                <div className="grid grid-cols-12 overflow-hidden">
                    <div className="col-span-12">
                        <AccountSidebar
                            isDrawerOpen={isDrawerOpen}
                            setIsDrawerOpen={setIsDrawerOpen}
                            openDrawer={openDrawer}
                            closeDrawer={closeDrawer}
                        />
                    </div>
                </div>
            </div>

            {show && (
                <div className={"absolute h-full w-full top-0 overflow-hidden"}>
                    <div
                        className="absolute h-screen w-full top-0 opacity-100 bg-opacity-70 bg-black z-50"
                        onClick={handleClickOutside}
                    />
                    <div
                        ref={ref}
                        className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
                    >
                        <AccountTabModal
                            ref={ref}
                            // data={data}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
}
