import React, { Fragment } from 'react'
import Display from '../../Component/settingspage/Display'

export default function DisplayPage() {
   return (
      <Fragment>
         <p className="absolute  top-1 text-red-700 uppercase font-bold w-full text-[10px] text-center">
            cc settings display/ fe / v1 / nov 18, 2024
         </p>
         <div className="pt-1">
            <Display />
         </div>
      </Fragment>
   )
}
