import React, { useState } from "react";
import footerLogo from "../../Assets/logo/footer_logo.png";
import Ticker from "../Ticker";

export default function SecretSauceButton() {
    const [showPopup, setShowPopup] = useState(false);

    const handleImageClick = () => {
        setShowPopup(!showPopup);
    };

    return (
        <>
            <div className="h-[9%] grid grid-cols-12 items-center text-white bg-[#3a3a3a] px-2.5 py-1">
                <div className="col-span-10 flex justify-start items-center">
                    <Ticker />
                </div>
                <div className="col-span-2 flex items-center justify-end">
                    <img
                        alt="logo"
                        src={footerLogo}
                        className="object-cover cursor-pointer w-[2.5rem] bg-[#38B6FF] rounded-lg px-[4px] pt-[2px] pb-[1px]"
                        onClick={handleImageClick}
                    />
                </div>
            </div>
            {showPopup && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white text-black p-10 rounded shadow-lg relative">
                        <div className="flex flex-col gap-y-2">
                            <button
                                onClick={() => setShowPopup(false)}
                                className="text-[12px] py-2 px-4 bg-black text-white rounded"
                            >
                                Your CC
                            </button>
                            <button
                                onClick={() => setShowPopup(false)}
                                className="text-[12px] py-2 px-4 bg-black text-white rounded"
                            >
                                Spartans Home
                            </button>
                        </div>
                        <p
                            onClick={() => setShowPopup(false)}
                            className="text-[12px] cursor-pointer text-blue-500 hover:text-light-blue-600 absolute top-2 right-2"
                        >
                            close
                        </p>
                    </div>
                </div>
            )}
        </>
    );
}