import React, { Fragment, useState } from "react"
import DesktopNav from "./DesktopNav";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Preferences = () => {
    const navigate = useNavigate()
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [isChecked5, setIsChecked5] = useState(false);
    const [isChecked6, setIsChecked6] = useState(false);
    const [isChecked7, setIsChecked7] = useState(false);
    const [isChecked8, setIsChecked8] = useState(false);
    const [isChecked9, setIsChecked9] = useState(false);
    const [isChecked10, setIsChecked10] = useState(false);

    const handleCheckbox1 = () => {
        setIsChecked1(!isChecked1);
    }

    const handleCheckbox2 = () => {
        setIsChecked2(!isChecked2);
    }

    const handleCheckbox3 = () => {
        setIsChecked3(!isChecked3);
    }

    const handleCheckbox4 = () => {
        setIsChecked4(!isChecked4);
    }

    const handleCheckbox5 = () => {
        setIsChecked5(!isChecked5);
    }

    const handleCheckbox6 = () => {
        setIsChecked6(!isChecked6);
    }

    const handleCheckbox7 = () => {
        setIsChecked7(!isChecked7);
    }

    const handleCheckbox8 = () => {
        setIsChecked8(!isChecked8);
    }

    const handleCheckbox9 = () => {
        setIsChecked9(!isChecked9);
    }
    const handleCheckbox10 = () => {
        setIsChecked10(!isChecked10);
    }

    return (
        <div className="dark:text-white px-3">
            <p className="text-center font-bold text-[21px] mt-2">
                <IoCaretBackCircleSharp
                    onClick={() => navigate(-1)}
                    className="cursor-pointer dark:text-black dark:bg-white rounded-full"
                />
            </p>
            <p className="text-center text-[22px] font-bold">Preference</p>
            <div >
                <p className="text-justify text-[12px] font-normal">
                    Control what you see across Chaa Ching . To see changes, refresh the page,
                    or restart Chaa Ching  on your devices. Completing this information helps us give you a better user experience.
                </p>
                <div className="mt-5">
                    <p className="text-start text-[16px] font-bold">Video preferences</p>
                    <p className="text-start text-[12px] mt-1">Manage default settings for videos</p>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between">
                            <p className="text-[14px] font-bold">Enable Floating video player</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked1}
                                    onChange={handleCheckbox1}
                                />
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked1 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked1
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked1 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                        <p className="mt-1 text-[10px] font-normal text-justify">Keep content playing in the corner when navigating to a different page.</p>
                    </div>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between">
                            <p className="text-[14px] font-bold">Enable Autoplay</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked2}
                                    onChange={handleCheckbox2}
                                />
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked2 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked2
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked2 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                        <p className="mt-1 text-[10px] font-normal text-justify">Autoplay the next related item when a file (video or audio) finishes playing.</p>
                    </div>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between">
                            <p className="text-[14px] font-bold">Mute Videos by default</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked3}
                                    onChange={handleCheckbox3}
                                />
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked3 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked3
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked3 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex justify-between items-center my-5">
                        <p className="text-[16px] font-bold">Language preferences:</p>
                        <select className="border border-black dark:text-black px-[6px] py-[6px] rounded-md text-[13px] focus:outline-none">
                            <option className="p-[2px] text-[10px] font-semibold">English</option>
                            <option className="p-[2px] text-[10px] font-semibold">Spanish</option>
                        </select>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="text-start text-[16px] font-bold">GIF s Preferences</p>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between">
                            <p className="text-[13px] font-bold">Play GIFs Automatically</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked4}
                                    onChange={handleCheckbox4}
                                />
                                {/* <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked4 ? 'bg-[#00FF47]' : 'bg-[#d9d9d9]'}`}>
                                    <span className={`dot h-[13px] w-[13px] rounded-full ${isChecked4 ? 'bg-[#ffffff]' : 'bg-[#00FF47]'} shadow-2xl duration-200 ${isChecked4 ? 'translate-x-5' : ''}`}></span>
                                </span> */}
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked4 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked4
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked4 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="text-start text-[16px] font-bold">Flash Preferences</p>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between">
                            <p className="text-[13px] font-bold">Mute Flash videos by default</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked5}
                                    onChange={handleCheckbox5}
                                />
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked5 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked5
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked5 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="text-start text-[16px] font-bold">Sensitive Content Preferences</p>
                    <div className="mt-4 ml-5">
                        <p className="text-[10px] font-normal text-justify">By default, we try to hide potentially MATURE content. CONTENT that IS REPORTED AS mature content BY OTHER USERS, will ALSO be removed. <span className="underline">Learn More</span></p>
                        <div className="flex justify-between items-center mt-3">
                            <p className="text-[13px] font-bold">Hide Sensitive content</p>
                            <select className="border border-black dark:text-black px-[6px] py-[6px] rounded-md text-[13px] focus:outline-none">
                                <option className="p-[2px] text-[10px] font-semibold">Anyone</option>
                                <option className="p-[2px] text-[10px] font-semibold">Friends</option>
                            </select>
                        </div>
                        <p className="text-[10px] font-normal text-justify mt-2">Hide additional potentially mature content, marked as sensitive. You can block SENSITIVE content entirely from your Chaa Ching  feeds.  SENSITIVE CONTENT cannot contain restricted content and IS deleted if reported.</p>
                        <p className="text-[13px] text-start mt-2 font-bold">Muted words list</p>
                        <p className="text-[10px] font-normal text-justify mt-1">
                            Muting words and terms on Chaa Ching  is useful for many purposes, from attempting to
                            childproof the service, to avoiding seeing particular subjects, or even to avoid
                            spoilers for TV shows and movies. FEEDS, Comments and livestream chat containing these words will be blocked.
                        </p>
                        <button className="bg-black px-3 py-1 rounded-full text-white  dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black  text-[10px] font-semibold mt-3">Add</button>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="text-start text-[16px] font-bold">ADs Preferences</p>
                    <div className="mt-4 ml-5">
                        <p className="text-[10px] font-normal text-justify">CC offers you choices about receiving interest-based ads. You can choose not to receive interest-based ads.</p>
                        <div className="flex justify-between items-start mt-3">
                            <p className="text-[13px] font-bold">Enable interest-based ads for you in CC</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked6}
                                    onChange={handleCheckbox6}
                                />

                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked6 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked6
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked6 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                        <p className="text-[10px] font-normal text-justify mt-1">Once you enable interest-based TARGETED ads we may share your data with our marketing and advertising partners using cookies and other technologies.</p>
                        <p className="text-[13px] text-start mt-2 font-bold">Go Advetisement free</p>
                        <p className="text-[10px] font-normal text-justify mt-1">
                            It's ad-free Chaa Ching ! Ad-Free Chaa Ching  gives you more of what you love and less of what you don't. What are you waiting for? Subscribe for $30/month. Subscriptions renew automatically. Learn More
                        </p>
                        <button className="bg-black px-3 py-1 rounded-full  dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black  text-white text-[10px] font-semibold mt-3">Subscribe to Ads free Chaa Ching </button>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="text-start text-[16px] font-bold">Search preferences</p>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between items-start mt-3">
                            <p className="text-[13px] font-bold">Enable Improved search</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked7}
                                    onChange={handleCheckbox7}
                                />

                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked7 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked7
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked7 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                        <p className="text-[10px] font-normal text-justify mt-1">Allow CC to use your search history to provide more relevant recommendations.</p>
                        <p className="text-[13px] text-start mt-2 font-bold">Reset Search history</p>
                        <p className="text-[10px] font-normal text-justify mt-1">
                            Clear your recently viewed search results and we'll no longer show them on the site. Searches that you do in the future will be recorded.
                        </p>
                        <button className="bg-black px-3 py-1 rounded-full  dark:bg-[#45b75c] dark:hover:bg-[#5eee7b] dark:hover:text-black  text-white text-[10px] font-semibold mt-3">Reset Search history</button>
                    </div>
                </div>
                <div className="mt-5 mb-3">
                    <p className="text-start text-[16px] font-bold">Consents</p>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between items-start mt-3">
                            <p className="text-[10px] font-normal">Allow Chaa Ching  to use your data to improve Chaa Ching  toggle button</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked8}
                                    onChange={handleCheckbox8}
                                />

                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked8 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked8
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked8 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>

                    </div>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between items-start mt-3 gap-4">
                            <p className="text-[10px] font-normal text-justify">Turning this off means we will no longer track how you use and/or navigate Chaa Ching  outside of data needed to provide the service, meet our commitments to our users, and satisfy our legal requirements. Chaa Ching  uses SUCH DATA to give you a better experience AND to make it more pleasant for you.</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked10}
                                    onChange={handleCheckbox10}
                                />

                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked10 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked10
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked10 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>

                    </div>
                    <div className="mt-4 ml-5">
                        <div className="flex justify-between items-start mt-3">
                            <p className="text-[10px] font-normal">Allow CC to track screen reader usage</p>
                            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked9}
                                    onChange={handleCheckbox9}
                                />
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked9 ? "bg-[black] dark:bg-[#00FF47]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked9
                                            ? "bg-[#ffffff]"
                                            : "bg-[black]"} shadow-2xl duration-200 ${isChecked9 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                        <p className="text-[10px] font-normal text-justify mt-2">Allow CC to track when you are using a screen  reader on CC, so that we can work on accessibility improvements based on the data gathered.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preferences