import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import LandingPage from "../../Component/settingspage/LandingPage";

const SettingsPage = () => {
   const navigate = useNavigate();

   return (
      <Fragment>
         <div className="sticky top-0 flex items-center justify-between bg-[#1E1E1E] dark:bg-[#201e1e] border-b border-gray-500 py-1.5 px-3 z-10">
            <IoCaretBackCircle
               onClick={() => navigate(-1)}
               className="text-[20px] text-white cursor-pointer"
            />
         </div>
         <LandingPage />
      </Fragment>
   );
};

export default SettingsPage;
