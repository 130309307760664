import React from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

export function UnBloackModal({ handleOpen, open }) {
  return (
    <>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none "
      >
        <Card className="mx-auto w-full max-w-[24rem] rounded-xl">
          <CardBody className="flex flex-col gap-4">
            <Typography
              variant="h4"
              color="blue-gray"
              className="text-[12px] text-center"
            >
              Are you sure you want to unblock this user ? After you unblock
              them you will start receiving notifications from them.
            </Typography>
          </CardBody>
          <CardFooter className="pt-0 flex justify-center gap-x-4 px-10">
            <Button className="text-black bg-white" onClick={handleOpen}>
              Cancel
            </Button>
            <Button className="bg-purple-500" onClick={handleOpen}>
              UnBlock
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}
