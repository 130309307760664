import React, { Fragment } from "react";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NeighborSettings = () => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="sticky top-0 bg-white dark:text-gray-200 dark:bg-black pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer dark:text-gray-400"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px] dark:text-gray-400">
                            Settings
                        </p>
                    </div>
                    <div className="block w-6 h-6"></div>
                </div>
            </div>
            <div className="h-full w-full flex justify-center items-center text-black dark:text-gray-400">Comming Soon</div>

        </Fragment>
    )
}

export default NeighborSettings