import React, { Fragment, useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { notificationsData } from "../data";
import { useNavigate } from "react-router-dom";
import { VscUnmute, VscMute } from "react-icons/vsc";
import { BiLike, BiCommentDetail } from "react-icons/bi";
import { IoCaretBackCircle, IoCaretDown } from "react-icons/io5";

const Notification = () => {
    const navigate = useNavigate();
    const [selectedFilter, setSelectedFilter] = useState("all");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const dropdownRef = useRef(null);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setIsDropdownOpen(false);
    };

    const filteredNotifications = notificationsData.filter(notification => {
        if (selectedFilter === "all") return true;
        return notification.category.toLowerCase() === selectedFilter;
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div className="sticky top-0 grid grid-cols-12 items-center px-4 py-2 bg-black border-b border-gray-300 dark:border-gray-800 z-10">
                <div className="col-span-2 flex justify-start">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[20px] cursor-pointer text-white"
                    />
                </div>
                <div className="col-span-8 flex justify-center">
                    <p className="text-center text-[14px] text-white font-semibold">Notifications</p>
                </div>
                <div className="col-span-2 flex justify-end">
                    {isMuted ?
                        <VscMute
                            onClick={() => setIsMuted(!isMuted)}
                            className="text-[14px] text-white cursor-pointer"
                        />
                        :
                        <VscUnmute
                            onClick={() => setIsMuted(!isMuted)}
                            className="text-[14px] text-white cursor-pointer"
                        />
                    }
                </div>
            </div>
            <div className="relative mt-2 px-2 w-1/2" ref={dropdownRef}>
                <div
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="w-full flex items-center justify-between gap-x-2 bg-gray-200 px-2 py-1 rounded cursor-pointer"
                >
                    <span className="text-[12px]">{selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)}</span>
                    <motion.div
                        animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <IoCaretDown className="text-[14px]" />
                    </motion.div>
                </div>
                {isDropdownOpen && (
                    <div className="absolute left-2 w-fit bg-white border border-gray-200 shadow-md rounded mt-1 z-20 overflow-hidden">
                        {["all", "comments", "likes", "tags", "follow requests"].map((filter, index, array) => (
                            <div
                                key={filter}
                                onClick={() => handleFilterChange(filter)}
                                className={`pl-2 pr-[3.2rem] py-2 hover:bg-gray-800 hover:text-white cursor-pointer text-[12px] border-b border-gray-200 whitespace-nowrap 
                                    ${index === array.length - 1 ? 'border-b-0' : ''}`
                                }
                            >
                                {filter.charAt(0).toUpperCase() + filter.slice(1)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="mt-1">
                {filteredNotifications.length > 0 ? (
                    filteredNotifications.map((notification, index) => (
                        <div className="grid grid-cols-12 px-2 py-2 border-b border-gray-300 dark:border-gray-800" key={index}>
                            <div className="col-span-2">
                                <img
                                    src={notification.image}
                                    alt="notification"
                                    className="w-8 h-8 rounded-full object-cover"
                                />
                            </div>
                            <div className="col-span-6 ml-[-10px]">
                                <p className="text-[12px] line-clamp-1 font-medium dark:text-white">{notification.name}</p>
                                <p className="text-[10px] line-clamp-1 leading-[14px] text-light-blue-500 font-medium">{notification.time}</p>
                                <div className="flex items-center gap-x-0.5 mt-0.5">
                                    {notification.message &&
                                        <p className="text-[10px] line-clamp-2 leading-[14px] text-gray-500 font-medium">
                                            <span className="font-semibold text-gray-500">comment:</span> "{notification.message}"
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="col-span-4 relative rounded-md">
                                <img
                                    alt="notification"
                                    src={notification.postImage}
                                    className="w-full h-14 object-cover rounded-md bg-gray-100"
                                />
                                <div className="absolute left-0 top-0 bg-black bg-opacity-50 px-1 py-0.5 rounded-br-md rounded-tl-md">
                                    {notification.category === "like" &&
                                        <BiLike className="text-[12px] text-white" />
                                    }
                                    {notification.category === "comment" &&
                                        <BiCommentDetail className="text-[12px] text-white" />
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-[12px] text-gray-500 mt-8">
                        No notifications found for "{selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)}"
                    </p>
                )}
            </div>
        </Fragment>
    )
}

export default Notification;