import React from "react";
import { StoreTitle } from "../../Component/HashTag/StoreTitle";
import ProductListSection3 from "../../Component/HashTag/ProductListSection3";

export default function HashTagStore() {
   return (
      <div className="h-full bg-white dark:bg-black relative overflow-hidden">
         <StoreTitle />
         <div className="mt-1 absolute w-full h-[-webkit-fill-available] overflow-scroll no-scrollbar">
            <ProductListSection3 />
         </div>
         <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold">
            Copyright © 2025 Galileo One Inc
         </p>
      </div>
   );
}


