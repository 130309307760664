import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";
import LikedEmptyData from "../../Assets/YourAccountIcons/LikeEmptyData.png";
import Skeleton from "react-loading-skeleton";

export function LikedTab({ User_likedVideos, loading }) {
    const selectVideo = useSelectVideo();
    const [search, setSearch] = useState("");
    const [LikedVideos, setLikedVideos] = useState([]);

    const handleChangeSearch = (event) => {
        const inputValue = event.target.value;
        setSearch(inputValue);

        // Filter data based on search query
        const filtered = User_likedVideos.filter((item) =>
            item.post_description.toLowerCase().includes(inputValue.toLowerCase())
        );

        if (filtered.length === 0) {
            setLikedVideos(User_likedVideos);
        } else {
            setLikedVideos(filtered);
        }
    };

    const handleSelectVideo = async (id) => {
        selectVideo(id, LikedVideos);
    };

    useEffect(() => {
        setLikedVideos(User_likedVideos);
    }, [User_likedVideos]);


    return (
        <>
            {loading ? (
                <div className="grid grid-cols-2 gap-2 p-2 overflow-scroll no-scrollbar">
                    {[1, 2, 3, 4, 5, 6].map((data) => (
                        <div key={data} className="col-span-1">
                            <Skeleton
                                height={170}
                                width={150}
                            // baseColor="#202020"
                            // highlightColor="#444"
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className="flex justify-center px-2 items-center rounded-md overflow-hidden">
                        <div className="bg-[#232323] dark:bg-[#2d2d33] flex py-1.5 w-full justify-center items-center rounded-md overflow-hidden">
                            <div className="w-[10%] flex justify-center">
                                <FaSearch className="cursor-pointer text-white" />
                            </div>
                            <div className="w-full mr-2">
                                <input
                                    type="text"
                                    value={search}
                                    onChange={handleChangeSearch}
                                    placeholder={`Find Your Liked videos `}
                                    className="w-full placeholder:text-sm font-medium outline-none px-1 py-[2px] rounded text-[#000]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 p-2 gap-2 overflow-y-scroll">
                        {LikedVideos?.map((item) => (
                            <VideoCard
                                id={item.id}
                                belowDecs={true}
                                body={item.post_description}
                                threeDots={false}
                                video={item.video_url}
                                view_count={item.view_count}
                                likes_count={item.likes_count}
                                selectVideo={handleSelectVideo}
                                avatar_url={item.created_by.avatar_url}
                            />
                        ))}
                    </div>
                </>
            )}
            {LikedVideos?.length === 0 && !loading && (
                <div className="flex justify-center items-center flex-col">
                    <img src={LikedEmptyData} alt="" className="h-32 w-32" />
                    <p className="text-[10px] text-[#c0b8b8] font-semibold">
                        No liked video yet
                    </p>
                </div>
            )}
        </>
    );
}
