import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../API/API";


export const createGroupPostComments = createAsyncThunk(
    "createGroupPostComments",
    async ({ postId, form_data }) => {
        try {
            const response = await axiosInstance.post(
                `/post/${postId}/comments/`, form_data);
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);


export const getGroupPostComments = createAsyncThunk(
    "getGroupPostComments",
    async ({ postId }) => {

        try {
            const response = await axiosInstance.get(`/post/${postId}/comments/`);
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);

export const postCommentReport = createAsyncThunk(
    "postCommentReport",
    async ({ commentId, data }) => {
        try {
            const response = await axiosInstance.post(
                `/group/groups/group-post-comment-reports/${commentId}/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            return error;
        }
    }
);


export const GroupPostCommentsSlice = createSlice({
    name: "GroupPostCommentsSlice",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {

        // get group list action promises
        builder.addCase(getGroupPostComments.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getGroupPostComments.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getGroupPostComments.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get group list action promises
        builder.addCase(postCommentReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postCommentReport.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(postCommentReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export default GroupPostCommentsSlice.reducer;
