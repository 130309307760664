import { Fragment, useCallback, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Avatar } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupByUserId } from "../../Redux/ApiSlice/Group/GroupSlice";

export function GroupsTab({ userId }) {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [grouplist, setGrouplist] = useState([]);
    const dispatch = useDispatch();
    const groupData = useSelector((state) => state.group);
    const { data, loading } = groupData;

    useEffect(() => {
        setGrouplist(data);
    }, [data]);

    const filterGroupData = grouplist?.filter((item) =>
        item?.group_name.toLowerCase().includes(search.toLowerCase())
    );

    const handleNavigate = (groupId) => {
        navigate(`/createdgroup/${groupId}`);
    };

    const fetchData = useCallback(async () => {
        try {
            await dispatch(getGroupByUserId(userId));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, userId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Fragment>
            {!loading && grouplist?.length ? (
                <div className="overflow-scroll h-full no-scrollbar">
                    <div className="flex justify-between items-center">
                        <p
                            onClick={() => navigate(`/createnewgroup`)}
                            className="text-[12px] text-blue-700 font-semibold cursor-pointer"
                        >
                            Create Group
                        </p>
                        <p className="text-[12px] text-black dark:text-white font-semibold">
                            {grouplist?.length} Groups
                        </p>
                    </div>
                    <div className="flex mb-2 justify-center items-center rounded-md overflow-hidden">
                        <div className="bg-[#232323] dark:bg-[#232323] flex py-1.5 w-full justify-center items-center rounded-md overflow-hidden">
                            <div className="w-[10%] flex justify-center">
                                <FaSearch className="cursor-pointer text-white dark:text-black" />
                            </div>
                            <div className="w-full h-[80%] mr-2">
                                <input
                                    type="text"
                                    value={search}
                                    placeholder="Find Your Groups"
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="w-full placeholder:text-sm font-medium outline-none px-1.5 py-[2px] rounded text-[#000]"
                                />
                            </div>
                        </div>
                    </div>
                    {filterGroupData?.map((sub) => (
                        <div
                            key={sub.id}
                            onClick={() => handleNavigate(sub.id)}
                            className="group grid grid-cols-12 h-auto px-1 hover:bg-gray-200 dark:hover:bg-gray-800 rounded-lg hover:h-20 hover:mb-1 hover:items-center hover:justify-center transition-all duration-200 ease-in-out cursor-pointer"
                        >
                            <div className="rounded-full h-10 w-10 col-span-2 flex justify-center items-center">
                                <Avatar
                                    src={sub.group_profile_picture}
                                    alt="avatar"
                                    size="xs"
                                    className="h-10 w-10"
                                />
                            </div>
                            <div className="h-auto relative col-span-10">
                                <h5 className="text-[#000] dark:text-white font-semibold tex-[12px]">
                                    {sub.group_name}
                                </h5>
                                <p className="text-[10px] text-[#000] dark:text-white line-clamp-1">
                                    {sub.description}
                                </p>
                                <div className="flex items-center mt-2">
                                    <div className="flex -space-x-1 overflow-hidden">
                                        {sub.members.map((data, index) => (
                                            index < 4 && (
                                                <img
                                                    key={data?.id}
                                                    alt="membersProfile"
                                                    src={data?.profile_pic}
                                                    className="inline-block h-4 w-4 rounded-full invisible group-hover:visible object-cover"
                                                />
                                            )
                                        ))}
                                    </div>
                                    <p className="ml-2 text-center text-[10px] flex item-center font-bold text-black dark:text-white invisible group-hover:visible">
                                        {sub.member_count} Members
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : groupData.loading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                    <div className="grid grid-cols-12 p-2 py-0 mt-1 w-full" key={item}>
                        <div className="col-span-2 flex justify-start items-center">
                            <Skeleton
                                height={40}
                                width={40}
                                circle={true}
                                className="rounded-full"
                            />
                        </div>
                        <div className="col-span-10 flex items-center justify-start ">
                            <Skeleton
                                height={30}
                                width={250}
                            />
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex justify-center items-center h-full">
                    <p className="text-gray-500">No groups found</p>
                </div>
            )}
        </Fragment>
    );
}