import React, { Fragment, useCallback, useEffect, useState } from "react";
import SliderMenu from "./SliderMenu";
import { HiMiniBars3 } from "react-icons/hi2";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
// import { neighborhoodNewsData } from "../../data";
import { useDispatch, useSelector } from "react-redux";
import { getNearByNeighbors } from "../../Redux/ApiSlice/Neighborhood/NeighborhoodSlice";

const YourNeighbor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const neighborhoodData = useSelector((state) => state.Neighborhood);
    console.log(neighborhoodData, "neighborhood data");
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const getNeighborhood = useCallback(
        async () => {
            try {
                await dispatch(getNearByNeighbors());
            } catch (error) {
                console.error("Error fetching neighborhood data:", error);
            }
        }, [dispatch]);

    useEffect(() => {
        getNeighborhood();
    }
        , [getNeighborhood]);

    return (
        <Fragment>
            <div className="sticky top-0 bg-white dark:text-gray-200 dark:bg-black pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer dark:text-gray-400"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px] dark:text-gray-400">
                            Your Neighborhood
                        </p>
                        <p className="flex items-center text-[10px] text-gray-500">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            Downtown, Springfield
                        </p>
                    </div>
                    <HiMiniBars3
                        onClick={toggleMenu}
                        className="text-[24px] cursor-pointer dark:text-gray-400"
                    />
                </div>
            </div>

            <div className="px-2 mt-2">
                {neighborhoodData?.neighborhood?.nearby_users?.length !== 0 && <p className="text-[12px] font-bold text-start text-gray-500">
                    Your Neighbor
                </p>}
                {neighborhoodData?.neighborhood?.nearby_users?.map((data) => (
                    <div className="flex items-start justify-between mt-2" key={data.user_id}>
                        <div className="flex items-center">
                            <div className="w-12 h-10">
                                <img
                                    alt={data.username}
                                    src={data.avatar_url}
                                    className="w-10 h-10 object-cover rounded-full"
                                />
                            </div>
                            <div className="flex flex-col mt-[2px]">
                                <p className="text-[13px] font-bold dark:text-gray-200">{data.username}</p>
                                <p className="flex items-center text-[10px] text-gray-500">
                                    <IoLocationOutline className="text-[14px] ml-[-2px] mt-[-1px]" />
                                    {data.city}, {data.country}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
                {neighborhoodData?.neighborhood?.nearby_users?.length === 0 &&
                    <p className="text-center text-gray-500 text-[13px] mt-2">
                        No neighbors found
                    </p>
                }
            </div>

            {menuOpen &&
                <SliderMenu
                    toggleMenu={toggleMenu}
                    onClose={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    )
}

export default YourNeighbor