import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackCircle, IoReorderThreeOutline } from "react-icons/io5";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Media from "./SearchComponent/Media";
import Comments from "./SearchComponent/Comments";
import User from "./SearchComponent/User";
// import SliderMenu from "./SearchComponent/SliderMenu";
import { searchGroupComments, searchGroupMedia } from "../../Redux/ApiSlice/Group/GroupSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { getGroupMembersList } from "../../Redux/ApiSlice/Group/GroupMembersSlice";
import { ThreeDots } from "react-loader-spinner";
import CreatedGroupSliderMenu from "./CreatedGroupSliderMenu";
import { getGroupDetails } from "../../Redux/ApiSlice/Group/GroupSlice";

const CreatedGroupSearch = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { groupId } = params;
    const searchResult = useSelector((state) => state.groupSearch);
    const searchUsers = useSelector((state) => state.groupMembers);
    const groupDetails = useSelector((state) => state.group);
    const { data, commentData } = searchResult;

    const navigate = useNavigate();
    const [show, setShow] = useState("media");
    const [searchItem, setSearchItem] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const handleChange = (e) => {
        setSearchItem(e.target.value)
    }

    const getGroupData = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails(groupId));
            const result = response.payload;
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    useEffect(() => {
        getGroupData();
    }, [getGroupData]);


    const getMedia = useCallback(async (value) => {
        try {
            await dispatch(searchGroupMedia({ groupId, query: value ? value : "" }));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    const getComments = useCallback(async (value) => {
        try {
            await dispatch(searchGroupComments({ groupId, query: value ? value : "" }));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);

    const getUsers = useCallback(async (value) => {
        try {
            await dispatch(getGroupMembersList(groupId));
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, groupId]);


    // Debounce logic
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchItem);
        }, 500); // Delay of 500ms

        return () => {
            clearTimeout(handler);
        };
    }, [searchItem]);

    // API Call
    useEffect(() => {
        if (debouncedSearchTerm) {
            if (show === "media") {
                getMedia(debouncedSearchTerm);
            } else if (show === "comments") {
                getComments(debouncedSearchTerm)
            } else if (show === "user") {
                getUsers()
            }
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        getMedia()
        getComments()
        getUsers()
    }, [getMedia, getComments, getUsers, show])

    return (
        <>
            <div className="bg-white dark:bg-black h-full">
                <div className="sticky top-0 bg-white dark:bg-black z-10">
                    <div className="grid grid-cols-12 px-3 pt-1 mb-2 items-center border-b border-gray-400 dark:border-gray-700">
                        <p className="col-span-12 text-[#ff3b3b] text-center font-bold text-[10px] md:text-[8px]">
                            Copyright © 2025 Galileo One Inc
                        </p>
                        {/* <p className="col-span-12 text-[#ff3b3b] text-center font-bold text-[10px] md:text-[8px]">
                            CC GROUP OWNER SEARCHPAGE - FE - V2 - JUNE 25, 2024
                        </p> */}
                        <div className="col-span-2">
                            <IoArrowBackCircle className="text-black dark:text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => navigate(-1)} />
                        </div>
                        <div className="col-span-8 text-center">
                            <p className="text-black dark:text-white text-[22px] md:text-[17px]">{groupDetails?.data?.group_name}</p>
                        </div>
                        <div className="col-span-2">
                            <div className="flex float-end">
                                <IoReorderThreeOutline className="text-[2.1rem] cursor-pointer text-black dark:text-white" onClick={() => openDrawer()} />
                            </div>
                        </div>
                    </div>

                    <div className="relative px-5">
                        <MagnifyingGlassIcon className="h-6 w-6 md:h-5 md:w-5 absolute top-[8px] left-[30px] md:left-[25px] cursor-pointer" />
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchItem}
                            onChange={handleChange}
                            className="bg-white border text-black border-gray-700 focus:outline-none w-full pl-10 md:pl-8 pr-2 py-[0.5rem] md:py-[0.3rem] rounded-full"
                        />
                    </div>

                    <div className="flex justify-between items-center px-5 pt-2 pb-2 mb-2 border-b border-gray-400 dark:border-gray-700">
                        <button
                            onClick={() => setShow("media")}
                            className={`${show === "media" ? "bg-gray-700 text-white" : "bg-black dark:bg-white text-white dark:text-black hover:bg-gray-900"} rounded-full text-[18px] md:text-[12px] px-6 md:px-4 py-[3px]`}
                        >
                            Media
                        </button>
                        <button
                            onClick={() => setShow("comments")}
                            className={`${show === "comments" ? "bg-gray-700 text-white" : "bg-black dark:bg-white text-white dark:text-black hover:bg-gray-900"} rounded-full text-[18px] md:text-[12px] px-6 md:px-4 py-[3px]`}
                        >
                            Comments
                        </button>
                        <button
                            onClick={() => setShow("user")}
                            className={`${show === "user" ? "bg-gray-700 text-white" : "bg-black dark:bg-white text-white dark:text-black hover:bg-gray-900"} rounded-full text-[18px] md:text-[12px] px-6 md:px-4 py-[3px]`}
                        >
                            Users
                        </button>
                    </div>
                </div>

                {searchResult.loading || searchUsers.loading ?
                    <div className="col-span-12 flex  justify-center items-center h-[25vh]">
                        <ThreeDots
                            visible={true}
                            height="90"
                            width="90"
                            color="#656665"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""

                        />
                    </div> :
                    <div className="px-1">
                        {show === "media" && <Media MediaData={data?.posts || []}
                            getMedia={getMedia}
                            groupId={groupId}
                        />}
                        {show === "comments" && <Comments commentsData={commentData?.comments || []}
                            getComments={getComments}
                            groupId={groupId}
                        />}
                        {show === "user" && <User userData={searchUsers?.data?.members} searchItem={searchItem}
                            getUsers={getUsers}
                            groupId={groupId}
                        />}
                    </div>}
            </div>

            {isDrawerOpen && <CreatedGroupSliderMenu isDrawerOpen={isDrawerOpen}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
            />}

        </>
    )
}

export default CreatedGroupSearch